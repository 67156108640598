/* eslint-disable no-prototype-builtins */
import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { InputField } from 'components/atoms/inputs/InputField';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import dayjs from 'dayjs';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteClientRefThunk, searchClientsThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { NewPlusIcon } from 'utills/svgs/NewPlusIcon';
import { generateId } from 'utills/uid';
import { toastHandler } from 'responseHanlder';
import {
    defaultDesignationOpt_Ind,
    defaultDesignationOpt_nonInd,
    getUniqueDesignations,
    TOAST_TYPE_ERROR
} from 'utills/globalVars';
import { validateStartEndDate } from 'utills/baseValidations';
import { convertSpacesToUnderscores } from 'utills/handleText';

export const RelatedEntities = ({
    setCurrentStep,
    client,
    entities,
    setEntities,
    onBack,
    onNext,
    designationOptions = getUniqueDesignations(defaultDesignationOpt_nonInd) ?? [],
    client_type = 'non-individual',
    addClientFormObj
}) => {
    const dispatch = useDispatch();
    const handleAddEntity = () => {
        const newEntities = [...entities];
        const entity = {
            fields: [
                {
                    value: '',
                    label: 'Entity',
                    placeholder: 'Search by Name/Reference No.',
                    id: generateId(),
                    type: 'combobox',
                    name: 'business_client_id',
                    targetProperty: 'display_name'
                },
                {
                    value: '',
                    label: 'Display Name',
                    placeholder: 'Enter Display Name',
                    id: generateId(),
                    name: 'display_name',
                    type: 'text',
                    disabled: false
                },

                {
                    value: '',
                    label: 'Designation',
                    placeholder: 'Select Designation',
                    id: generateId(),
                    name: 'designation',
                    options: designationOptions,
                    type: 'dropdown'
                },

                {
                    value: '',
                    label: 'Number of Units',
                    placeholder: 'Enter number of units',
                    id: generateId(),
                    name: 'no_of_unit',
                    type: 'number',
                    is_conditional: false,
                    data_name: 'Unit Holder'
                },

                {
                    value: '',
                    label: 'Partnership percentage',
                    placeholder: 'Enter Partnership percentage',
                    id: generateId(),
                    name: 'partnership_percentage',
                    type: 'number',
                    is_conditional: false,
                    data_name: 'Partner'
                },

                {
                    value: '',
                    label: 'Number of Shares',
                    placeholder: 'Enter Number of Shares',
                    id: generateId(),
                    name: 'percentage_of_shares',
                    type: 'number',
                    is_conditional: false,
                    data_name: 'Share Holder'
                },
                {
                    value: '',
                    label: 'Start date',
                    placeholder: 'Enter start date',
                    name: 'start_date',
                    id: generateId(),
                    type: 'date'
                },
                {
                    value: 'Yes',
                    options: ['Yes', 'No'],
                    label: 'Is this designation currently in effect?',
                    placeholder: '',
                    id: generateId(),
                    name: 'currently_effective',
                    type: 'switch'
                },
                {
                    value: '',
                    label: 'End date',
                    placeholder: 'Enter end date',
                    id: generateId(),
                    name: 'end_date',
                    type: 'date'
                }
            ],
            link_profile: false,
            bcr_id: null
        };
        newEntities.push(entity);
        setEntities(newEntities);
    };
    const handleDeleteEntity = (entityIndex, bcr_id, updateRow = true, isCombobox = false) => {
        const newEntities = [...entities];

        if (client && bcr_id) {
            handleDeleteClientRef(entityIndex, bcr_id, updateRow);
            return;
        }

        if (!bcr_id && isCombobox) {
            newEntities[entityIndex].fields[0].value = '';
            return;
        }

        newEntities.splice(entityIndex, 1);
        setEntities(newEntities);
    };
    const handleComboxboxEntityRemove = (fieldIndex, entityIndex, _person) => {
        const newEntities = [...entities];
        const field = newEntities[entityIndex].fields[fieldIndex];

        field.value = '';

        newEntities[entityIndex].fields[1].value = '';
        newEntities[entityIndex].fields[1].disabled = false;

        setEntities(newEntities);
    };

    const handleComboxboxEntity = (fieldIndex, entityIndex, person) => {
        const newEntities = [...entities];
        const field = newEntities[entityIndex].fields[fieldIndex];
        const designationField = newEntities[entityIndex]?.fields?.find((field) => field.name === 'designation');
        let selectedPersonType = person?.client_category;

        if (selectedPersonType === 'Trust') {
            selectedPersonType = convertSpacesToUnderscores(person?.trust_structure?.toLowerCase());
        }

        let defaultListOfDesignations =
            client_type === 'individual' ? defaultDesignationOpt_Ind : defaultDesignationOpt_nonInd;

        const default_options_ind = getUniqueDesignations(defaultListOfDesignations);
        let newOptions = default_options_ind;
        if (person) {
            const selectClientCategory = addClientFormObj?.client_category;
            newOptions = defaultListOfDesignations?.[selectedPersonType?.toLowerCase()]?.filter((obj) => {
                if (obj?.hasOwnProperty('activeOnly')) {
                    if (selectClientCategory?.toLowerCase() === obj.activeOnly) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            });
        }
        designationField.options = newOptions;

        if (person && person?.business_client_id !== field?.value) {
            field.value = person?.business_client_id;
            if (person?.display_name) {
                newEntities[entityIndex].fields[1].value = person?.display_name || 'name';
                newEntities[entityIndex].fields[1].disabled = true;
            }
        }
        setEntities(newEntities);
    };

    const checkDesignationValidation = (partnerIndex, currentDesignationValue) => {
        const newEntities = [...entities];
        const currentEntityArray = newEntities[partnerIndex];
        const currentEntityValue = currentEntityArray.fields.find((field) => field.label === 'Entity')?.value;

        // check if same entity is already found in the Inherited related references
        if (client) {
            if (client?.inherited_related_references && client?.inherited_related_references?.length > 0) {
                const isFound = client?.inherited_related_references.find(
                    (obj) =>
                        obj?.business_client_id === currentEntityValue && obj?.designation === currentDesignationValue
                );

                if (isFound) {
                    toastHandler('Entity Already exist with same designation ', TOAST_TYPE_ERROR);
                    return false;
                }
            }
        }

        // Check if Same Entity Found in the Existing Entries
        for (const [index, entity] of newEntities.entries()) {
            if (index === partnerIndex) {
                continue;
            }
            const entityValue = entity.fields.find((field) => field.label === 'Entity')?.value;
            const designationValue = entity.fields.find((field) => field.label === 'Designation')?.value;
            if (!entityValue || !designationValue) {
                continue;
            }
            if (entityValue === currentEntityValue && designationValue === currentDesignationValue) {
                toastHandler('Duplicate Designation Found', TOAST_TYPE_ERROR);
                return false;
            }
        }
        return true;
    };
    const handleEntityValue = (id, value, entityIndex, fieldIndex, _refNo = null) => {
        const newEntities = [...entities];
        const field = newEntities[entityIndex].fields.find((field) => field.id === id);

        if (field) {
            field.value = value;
        }
        setEntities(newEntities);
    };

    const handleEntityDropdown = (value, entityIndex, fieldIndex, _refNo = null) => {
        const newEntities = [...entities];
        newEntities[entityIndex].fields[fieldIndex].value = value;
        const _newIV = newEntities[entityIndex].fields.map((field) => {
            if (field.type === 'number' && field.hasOwnProperty('is_conditional') === true) {
                if (field.data_name === value) {
                    field.is_conditional = true;
                } else {
                    field.is_conditional = false;
                }
            }
            return field;
        });

        setEntities(newEntities);
    };

    const handleDeleteClientRef = (entityIndex, bcr, updateRow) => {
        dispatch(setLoader(true));
        dispatch(deleteClientRefThunk({ bcr_id: bcr }))
            .then((response) => {
                if (response.payload) {
                    if (updateRow) {
                        const newEntities = [...entities];
                        newEntities.splice(entityIndex, 1);
                        setEntities(newEntities);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };
    const handleLinkProfile = (checked, entityIndex) => {
        const newEntities = [...entities];
        newEntities[entityIndex].link_profile = checked;
        setEntities(newEntities);
    };

    const handleOnNext = () => {
        let hasError = false;
        entities?.forEach((entity, index) => {
            const startDateField = entity?.fields?.find((field) => field?.name === 'start_date');

            const endDateField = entity?.fields?.find((field) => field?.name === 'end_date');

            if (startDateField?.value && endDateField?.value) {
                if (!validateStartEndDate(startDateField?.value, endDateField?.value)) {
                    hasError = true;
                    return toastHandler(
                        `Start date cannot be greater than end date in entity ${index + 1}`,
                        TOAST_TYPE_ERROR
                    );
                } else {
                    hasError = false;
                }
            }
        });

        if (!hasError) {
            onNext();
        }
    };
    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="table-title font-poppins font-semibold">Related Entities</div>
                <div onClick={handleAddEntity} className="cursor-pointer">
                    <NewPlusIcon />
                </div>
            </div>
            <div>
                <div className="mt-5">
                    {entities?.map((partner, partnerIndex) => (
                        <div className=" !bg-dimThird p-5  rounded-xl mt-5  " key={'partnerIndex' + partnerIndex}>
                            <div
                                onClick={() => handleDeleteEntity(partnerIndex, partner?.bcr_id)}
                                className="cursor-pointer w-fit ml-auto flex justify-end items-center"
                            >
                                <label className="invisible"> f</label>
                                <BgDeleteIcon />
                            </div>

                            <div className=" default-forms grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 items-end gap-5  rounded-xl ">
                                {partner.fields
                                    .filter(
                                        (field) =>
                                            (field.type === 'number' && field.is_conditional) || field.type !== 'number'
                                    )
                                    .map((field, fieldIndex) => (
                                        <div className="w" key={'parent' + fieldIndex}>
                                            {field.type === 'combobox' && (
                                                <div className="">
                                                    <label className="inputLabel">{field.label}</label>

                                                    <ComboboxComponentSSR
                                                        fetchDataFunction={(payload) =>
                                                            fetchReferencesListFun({
                                                                ...payload,
                                                                type: 'non-individual',
                                                                ...(client
                                                                    ? { business_client_id: client?.business_client_id }
                                                                    : {})
                                                            })
                                                        }
                                                        defaultSelected={field?.value}
                                                        removePreviousSelected
                                                        onRemove={(_e) => {
                                                            handleComboxboxEntityRemove(fieldIndex, partnerIndex, null);
                                                        }}
                                                        // filterSelectedValueTargetIndex={0}
                                                        // filterSelectedValues={entities || []}
                                                        onChange={(res) => {
                                                            const obj = res[0];
                                                            handleComboxboxEntity(fieldIndex, partnerIndex, obj);
                                                        }}
                                                        targetProperty="business_client_id"
                                                        placeholder={field.placeholder || ''}
                                                        limit={1}
                                                        sx="rounded-xl ring-gray-400 !bg-transparent"
                                                        showNameOnly
                                                    />
                                                </div>
                                            )}

                                            {field.type === 'dropdown' && !field?.disabled && (
                                                <div>
                                                    <div>
                                                        <label>{field?.label}</label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <SimpleSelectMenu
                                                            placeholder="Select"
                                                            targetProperty="label"
                                                            selectedValue={field.value}
                                                            valuePropertyName="label"
                                                            optionsData={field.options || []}
                                                            sx="rounded-xl mt-2 py-3 ring-gray-400 !font-normal cursor-pointer !bg-transparent"
                                                            showChipOnly={false}
                                                            onChangeValidation={({ label }) => {
                                                                return checkDesignationValidation(partnerIndex, label);
                                                            }}
                                                            onChangeValue={(obj) => {
                                                                handleEntityValue(
                                                                    field?.id,
                                                                    obj.label,
                                                                    partnerIndex,
                                                                    fieldIndex
                                                                );
                                                                handleEntityDropdown(
                                                                    obj.label,
                                                                    partnerIndex,
                                                                    fieldIndex
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {field.type === 'text' && (
                                                <InputField
                                                    label={field?.label}
                                                    maxLength={30}
                                                    allowSpaces={true}
                                                    onChange={(e) => {
                                                        handleEntityValue(
                                                            field?.id,
                                                            e.target.value,
                                                            partnerIndex,
                                                            fieldIndex
                                                        );
                                                    }}
                                                    name={field?.label}
                                                    inputValue={field.value}
                                                    disabled={field?.disabled ? true : false}
                                                    placeholder={field?.placeholder}
                                                    required
                                                    classes="mt-2 bg-transparent"
                                                />
                                            )}

                                            {field.type === 'number' && !field?.disabled && field.is_conditional ? (
                                                <div>
                                                    <label className="inputLabel">{field.label}</label>
                                                    <input
                                                        type="number"
                                                        disabled={field?.disabled ? true : false}
                                                        placeholder={field?.placeholder}
                                                        value={field?.value}
                                                        onChange={(e) => {
                                                            handleEntityValue(
                                                                field?.id,
                                                                e.target.value,
                                                                partnerIndex,
                                                                fieldIndex
                                                            );
                                                        }}
                                                        className="mt-2 bg-transparent"
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {field?.type === 'date' && field?.name === 'start_date' && (
                                                <div>
                                                    <div>
                                                        <label>{field.label}</label>
                                                    </div>
                                                    <DatePick
                                                        maxDate={dayjs()}
                                                        iconSet={{
                                                            right: CalendarSmallIco
                                                        }}
                                                        onDateChange={(date) => {
                                                            handleEntityValue(
                                                                field?.id,
                                                                dayjs(date).format('DD-MM-YYYY'),
                                                                partnerIndex,
                                                                fieldIndex
                                                            );
                                                        }}
                                                        placeholder="DD-MM-YYYY"
                                                        format="DD-MM-YYYY"
                                                        value={field?.value ? dayjs(field?.value, 'DD-MM-YYYY') : ''}
                                                        sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6] bg-transparent"
                                                    />
                                                </div>
                                            )}

                                            {field?.type === 'date' &&
                                                field?.name === 'end_date' &&
                                                partner?.fields[partner?.fields?.length - 2]?.value !== 'Yes' && (
                                                    <div>
                                                        <div>
                                                            <label>{field.label}</label>
                                                        </div>
                                                        <DatePick
                                                            maxDate={dayjs()}
                                                            iconSet={{
                                                                right: CalendarSmallIco
                                                            }}
                                                            onDateChange={(date) => {
                                                                handleEntityValue(
                                                                    field?.id,
                                                                    dayjs(date).format('DD-MM-YYYY'),
                                                                    partnerIndex,
                                                                    fieldIndex
                                                                );
                                                            }}
                                                            placeholder="DD-MM-YYYY"
                                                            format="DD-MM-YYYY"
                                                            value={
                                                                field?.value ? dayjs(field?.value, 'DD-MM-YYYY') : ''
                                                            }
                                                            sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6] bg-transparent"
                                                        />
                                                    </div>
                                                )}

                                            {field?.type === 'switch' && (
                                                <div className="">
                                                    <label className="inputLabel">{field?.label}</label>
                                                    <DefaultDoubleSwitch
                                                        items={[
                                                            {
                                                                title: field?.options[0],
                                                                selected: field?.value === field?.options[0]
                                                            },
                                                            {
                                                                title: field?.options[1],
                                                                selected: field?.value === field?.options[1]
                                                            }
                                                        ]}
                                                        onClick={(value) => {
                                                            handleEntityValue(
                                                                field?.id,
                                                                value,
                                                                partnerIndex,
                                                                fieldIndex
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </div>

                            <div className="mt-4 flex gap-2 items-center buttons-font-lighter font-poppins">
                                <div>
                                    <input
                                        type="checkbox"
                                        id={'linkProfile' + partnerIndex}
                                        className={`!rounded-[3px]  bg-transparent  scale-[1.1]   `}
                                        checked={partner?.link_profile || false}
                                        onChange={(e) => handleLinkProfile(e.target.checked, partnerIndex)}
                                    />
                                </div>{' '}
                                <label htmlFor={'linkProfile' + partnerIndex}>I would like to link the profile</label>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center mt-24 items-center">
                    <div className="flex gap-8 items-center">
                        <Button
                            title="Back"
                            classes={
                                'border-blueish text-blueish border-2 h-[3rem] rounded-lg buttons-font-lighter font-fira px-14'
                            }
                            atClick={() => (onBack ? onBack() : setCurrentStep(4))}
                            type="button"
                        />
                        <Button
                            title={'Submit'}
                            classes={'ann-btn rounded-lg px-14 buttons-font-lighter font-fira'}
                            atClick={() => handleOnNext()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
