import React from 'react';

export const VsdFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#004481" />
            <path
                d="M12.5859 11.7969L10.0078 23H7.17969L4.60156 11.7969H7.42969L8.58594 16.8438L9.75 11.7969H12.5859ZM14.9609 23L13.4609 21.6719V19.625H16.3047V20.3281H17.9375V18.4688H14.9609L13.4609 17.1562V13.125L14.9609 11.7969H19.2812L20.7891 13.125V15.1797H17.9375V14.4688H16.3047V16.2812H19.2812L20.7891 17.6094V21.6719L19.2812 23H14.9609ZM29.3984 21.6875L27.8906 23H22.0703V11.7969H27.8906L29.3984 13.125V21.6875ZM24.8984 14.6328V20.1719H26.5469V14.6328H24.8984Z"
                fill="white"
            />
        </svg>
    );
};
