import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { Button } from 'components/atoms/buttons/Button';
import { CollapsDivComponent } from 'components/molecules/collapsDivComponent/CollapsDivComponent';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { AnimatePresence } from 'framer-motion';
import { cn } from 'pages/documents/hooks/helper';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DeleteIcon } from 'utills/svgs/DeleteIcon';
import { DownloadIcon2 } from 'utills/svgs/DownloadIcon2';
import { motion } from 'framer-motion';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { SignIcon } from 'utills/svgs/SignIcon';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import SkeletonTable from 'components/atoms/SkeletonTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoDataFound } from 'components/common/NoDataFound';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { createDeedThunk, getDeedsThunk, JobManagerDeleteItemsThunk } from 'store/JobManager';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { useNavigate, useParams } from 'react-router-dom';
import { CollateFilesIcon } from 'utills/svgs/CollateFilesIcon';
import CollateDocuments from '../../Documents/CollateDocuments';
import FormateDateTime from 'components/atoms/FormateDateTime';
import { downloadFile } from 'utills/filesHandlers';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import CommandPalettes from 'components/atoms/CommandPalettes';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import { AddClient } from 'components/appusers-dash/subcomponents/AddClient';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';
import { SmallLoader } from 'components/common/SmallLoader';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import dayjs from 'dayjs';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
const DeedBuilder = ({ setCollateListReload, handleGetSingleJob }) => {
    const checkbox = useRef();
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data: job } = useSelector((state) => state.getSingleJob);
    const { isLoading } = useSelector((state) => state.global);
    const { hasPermissions } = useRoleGuard();
    const [viewList, setViewList] = useState(false);
    const [deedDate, setDeedDate] = useState(dayjs());

    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;
    const [loadingStates, setLoadingStates] = useState({}); // State to track loading for each button

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [selectedPdf, setSelectedPdf] = useState('');

    const [action, setAction] = useState(null);

    const handleBtnAction = ({ title, action }) => {
        setAction({ title, list: selectedIds, open: true, action: action });
    };

    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [page, setPage] = useState(1);

    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        getData();
    }, [page]);

    const getData = async (hasReset = false, callBackFun) => {
        const payload = {
            page: page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            job_id: id ?? '',
            business_id
        };

        setHasMoreContent(true);

        try {
            const response = await dispatch(getDeedsThunk(payload));
            if (response.payload) {
                const cloneData = [...(!hasReset ? pageData || [] : []), ...response.payload.rows];
                setPageData(cloneData);
                setHasMoreContent(cloneData.length >= response.payload.count ? false : true);
                if (callBackFun) {
                    callBackFun();
                    setViewList(true);
                }
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const handlePageChange = () => {
        setPage(page + 1);
    };

    useLayoutEffect(() => {
        const isIndeterminate = selectedIds.length > 0 && selectedIds.length < pageData.length;
        setChecked(pageData?.length > 0 && selectedIds.length === pageData.length);
        setIndeterminate(isIndeterminate);

        if (checkbox.current) {
            checkbox.current.indeterminate = isIndeterminate;
        }
    }, [selectedIds]);

    function toggleAll() {
        const newIds = [];
        pageData?.forEach((obj) => {
            newIds.push(obj);
        });

        setSelectedIds(checked || indeterminate ? [] : newIds);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    const handleSelectId = (obj) => {
        const newIds = [...selectedIds];
        const findId = newIds?.find((item) => item?.job_drive_id === obj?.job_drive_id);

        if (findId) {
            const indexOfId = newIds.indexOf(findId);
            newIds.splice(indexOfId, 1);
            setSelectedIds(newIds);
        } else {
            newIds.push(obj);
            setSelectedIds(newIds);
        }
    };

    const dynamicButtons = [
        {
            icon: <CollateFilesIcon className={'w-6'} />,
            title: 'Collate Files',
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleBtnAction({ title: 'Collate Documents', action: 'collateDocs' });
            }
        },
        {
            icon: <DeleteIcon className={'w-6'} />,
            title: 'Delete',
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleDeleteItem(selectedIds?.map((item) => item?.job_drive_id));
            }
        },
        {
            icon: <PlusCircleIcon className={'w-7'} />,
            title: 'Create Deed',
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                setViewList(!viewList);
            }
        }
    ];

    const [actions, setActions] = useState(dynamicButtons || []);

    useEffect(() => {
        if (!selectedIds?.length > 0) {
            setActions([dynamicButtons?.reverse()[0]]);
        } else if (selectedIds?.length < 2 && selectedIds?.length > 0) {
            setActions(dynamicButtons.slice(1, dynamicButtons.length));
        } else {
            setActions(dynamicButtons);
        }
    }, [selectedIds]);

    const handleDeleteItem = (ids) => {
        const jsonPayload = {
            business_id,
            job_id: id,
            item_ids: ids.join(',')
        };
        dispatch(setLoader(true));
        dispatch(JobManagerDeleteItemsThunk(jsonPayload))
            .then((res) => {
                if (res.payload) {
                    setSelectedIds([]);
                    getData(true);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleAddDeed = (client) => {
        if (deedDate) {
            dispatch(setLoader(true));
            dispatch(
                createDeedThunk({
                    job_id: id ?? '',
                    business_id,
                    deed_date: dayjs(deedDate).format('DD-MM-YYYY'),
                    business_client_id: client?.business_client_id ?? ''
                })
            )
                .then((response) => {
                    if (response.payload) {
                        setDeedDate(dayjs());
                        setViewList(!viewList);
                        getData(true);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const [selectedClient, setSelectedClient] = useState(null);

    const fetchCustomer = (id) => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .then((response) => {
                setSelectedClient(response.payload);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleDownload = (url, index) => {
        setLoadingStates((prev) => ({ ...prev, [index]: true }));
        const downloadPromise = new Promise((resolve, reject) => {
            try {
                downloadFile(url); // Call the function
                setTimeout(() => resolve(), 1500); // Simulate a delay
            } catch (error) {
                reject(error);
            }
        });
        downloadPromise.finally(() => {
            setLoadingStates((prev) => ({ ...prev, [index]: false }));
        });
    };
    return (
        <div>
            <CollapsDivComponent
                title={'Deed Builder'}
                h_one="max-h-[70px]"
                h_two="max-h-[350px]"
                classes={cn('px-5 ', job?.meta ? 'py-3' : 'py-3')}
                defaultClose
                extra={
                    <div className="flex items-center justify-end gap-5">
                        <AnimatePresence>
                            {actions?.map((btnObj, index) => (
                                <motion.span
                                    key={btnObj.title}
                                    initial={{ x: '100px', opacity: 0 }}
                                    animate={{
                                        x: 0,
                                        opacity: 1
                                    }}
                                    exit={{ opacity: 0 }}
                                    transition={{
                                        animate: { delay: `0.${index + 1}`, type: 'tween', duration: 0.2 },
                                        exit: { type: 'tween', duration: 0.3 }
                                    }}
                                >
                                    <Button
                                        classes={'bg-gray-200 !rounded-full p-2'}
                                        tooltip={btnObj.title}
                                        iconSet={{ leftIconHTML: btnObj.icon }}
                                        atClick={() => btnObj?.onClick()}
                                        tooltipDirection={'none'}
                                        disabled={disabled}
                                    />
                                </motion.span>
                            ))}
                        </AnimatePresence>
                    </div>
                }
            >
                {selectedClient && (
                    <AddClient
                        open={selectedClient ? true : false}
                        setOpen={() => {
                            setSelectedClient(null);
                        }}
                        client={selectedClient}
                        handleReload={() => {
                            getData(true, handleGetSingleJob);
                            setSelectedClient(null);
                        }}
                    />
                )}

                <CommandPalettes
                    open={viewList}
                    setOpen={setViewList}
                    list={job?.clients?.filter((item) => item?.client_category?.toLowerCase() !== 'individual')}
                    profileTopRightChip={(event) => (
                        <span className="cursor-pointer" onClick={() => fetchCustomer(event?.business_client_id)}>
                            <BgEditIcon />
                        </span>
                    )}
                    actionButton={(event) => (
                        <div className="w-full flex-col gap-2">
                            <dl className="grid grid-cols-1 gap-x-5 gap-y-3 text-sm text-gray-700 mb-3 mt-6">
                                <dt className="col-end-1 font-semibold text-gray-900 content-center">Deed Date</dt>
                                <dd className="">
                                    <DatePick
                                        iconSet={{
                                            right: CalendarSmallIco
                                        }}
                                        onDateChange={(date) => {
                                            setDeedDate(date);
                                        }}
                                        placeholder="Select Date"
                                        value={deedDate}
                                        sx={`addTaskDate !h-[2.5rem] text-gray-900 !rounded-lg !w-full bg-[#fff] hover:bg-[#FAF9F6] ${
                                            !deedDate ? '!border-red-400' : '!border-gray-400'
                                        }
                                        `}
                                    />
                                    {!deedDate && (
                                        <SecondaryParagraph sx={'text-red-600 !text-[12px]'}>
                                            Deed Date is Required!
                                        </SecondaryParagraph>
                                    )}
                                </dd>
                            </dl>
                            <Button
                                title={'Create Deed'}
                                atClick={() => {
                                    handleAddDeed(event);
                                }}
                                classes={
                                    'bg-linear text-white buttons-font-lighter rounded-lg py-2 px-5 w-full  font-fira'
                                }
                            />
                        </div>
                    )}
                />

                {selectedPdf && (
                    <GlobalPDFViewer
                        url={selectedPdf}
                        setOpen={() => setSelectedPdf('')}
                        open={selectedPdf ? true : false}
                    />
                )}
                {action && action?.open && action?.action === 'collateDocs' && (
                    <CollateDocuments
                        open={!!action}
                        setOpen={(e) => {
                            if (!e) {
                                setAction(null);
                            }
                        }}
                        setFileList={setSelectedIds}
                        title={action?.title}
                        action={action?.action}
                        list={selectedIds}
                        handleSelectId={handleSelectId}
                        getMainTableData={() => {
                            getData(true);
                            setCollateListReload(true);
                        }}
                    />
                )}
                <div className="my-5 max-h-[540px] overflow-auto">
                    {pageData?.length === 0 && !isLoading ? (
                        <NoDataFound height="200px" message="No Record Found!" />
                    ) : (
                        <InfiniteScroll
                            height={'300px'}
                            next={handlePageChange}
                            dataLength={pageData.length}
                            hasMore={hasMoreContent}
                            loader={<SkeletonTable columns={6} />}
                        >
                            <table className="min-w-full border-separate border-spacing-0">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts p-4 !px-0 !min-w-[40px] !w-[40px] text-center'
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                className="rounded-[3px] cursor-pointer"
                                                ref={checkbox}
                                                checked={checked}
                                                onChange={toggleAll}
                                            />
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts !w-[200px] text-left'
                                            }
                                        >
                                            Name
                                        </th>

                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                            }
                                        >
                                            Date & Time
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'
                                            }
                                        >
                                            Created By
                                        </th>

                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'
                                            }
                                        >
                                            Status
                                        </th>

                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   tableHeadings primary-fonts !w-[200px] pl-[10px] text-center'
                                            }
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-transparent gap-0">
                                    {pageData &&
                                        pageData?.length > 0 &&
                                        pageData?.map((dataObj, index) => {
                                            return (
                                                <tr
                                                    key={'req_doc_table' + index}
                                                    className="border-y relative  divide-gray-300"
                                                >
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap !text-[16px] p-4 !px-0 text-center',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <div className="min-w-[18px]">
                                                            <input
                                                                type="checkbox"
                                                                className="rounded-[3px] cursor-pointer"
                                                                checked={selectedIds?.some(
                                                                    (item) => item.job_drive_id === dataObj.job_drive_id
                                                                )}
                                                                onClick={() => handleSelectId(dataObj)}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap !text-[16px] py-2 !max-w-[200px]',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        {dataObj?.url ? (
                                                            <div
                                                                className="flex items-center gap-2 cursor-pointer overflow-hidden"
                                                                onClick={() => {
                                                                    if (
                                                                        dataObj?.url &&
                                                                        dataObj?.url?.includes('.pdf')
                                                                    ) {
                                                                        setSelectedPdf(dataObj?.url);
                                                                    }
                                                                }}
                                                            >
                                                                <GenerateFileIcon url={dataObj?.url} sx={'w-10'} />

                                                                <div className="flex flex-col overflow-hidden">
                                                                    <div className="app-dataObj-name flex items-center gap-2 text-ellipsis overflow-hidden">
                                                                        <div>{dataObj?.name}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        {dataObj?.created_at ? (
                                                            <FormateDateTime dateTime={dataObj?.created_at} />
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <DefaultProfileViewer
                                                            image={dataObj?.created_by?.image}
                                                            width="45px"
                                                            height="45px"
                                                            name={
                                                                dataObj?.created_by?.first_name +
                                                                ' ' +
                                                                dataObj?.created_by?.last_name
                                                            }
                                                            tooltip
                                                        />
                                                    </td>
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black capitalize',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : '',
                                                            dataObj?.status === 'active'
                                                                ? 'text-green-500'
                                                                : 'text-red-500'
                                                        )}
                                                    >
                                                        {dataObj?.status || 'N/A'}
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-block text-center',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <div className="flex justify-center items-center gap-2">
                                                            {dataObj?.mimetype?.includes('pdf') && (
                                                                <Button
                                                                    atClick={() => {
                                                                        navigate(
                                                                            `/job-manager/request-signature/${id}`,
                                                                            {
                                                                                state: [dataObj]
                                                                            }
                                                                        );
                                                                    }}
                                                                    iconSet={{
                                                                        leftIconHTML: (
                                                                            <SignIcon
                                                                                className={'w-5 text-[#0A1E46]'}
                                                                            />
                                                                        )
                                                                    }}
                                                                    classes={'!rounded-full bg-[#0A1E46]/10 p-2'}
                                                                    disabled={disabled}
                                                                />
                                                            )}
                                                            <Button
                                                                atClick={() => {
                                                                    handleDownload(dataObj?.url, index);
                                                                }}
                                                                iconSet={{
                                                                    // leftIconHTML: (
                                                                    //     <DownloadIcon2
                                                                    //         className={'w-6 text-[#B695F8]'}
                                                                    //     />
                                                                    // )
                                                                    leftIconHTML: loadingStates[index] ? (
                                                                        <SmallLoader className="w-6 text-[#B695F8] animate-spin" />
                                                                    ) : (
                                                                        <DownloadIcon2 className="w-6 text-[#B695F8]" />
                                                                    )
                                                                }}
                                                                classes={'!rounded-full bg-[#B695F8]/10 p-2'}
                                                            />

                                                            <Button
                                                                atClick={() => {
                                                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                                                        return toastHandler(
                                                                            'You do not have permission to perform this action.',
                                                                            TOAST_TYPE_ERROR
                                                                        );
                                                                    }
                                                                    handleDeleteItem([dataObj?.job_drive_id]);
                                                                }}
                                                                iconSet={{
                                                                    leftIconHTML: (
                                                                        <DeleteIcon
                                                                            className={'!min-w-4 w-5 text-[#0A1E46]'}
                                                                        />
                                                                    )
                                                                }}
                                                                classes={'!rounded-full bg-[#0A1E46]/10 p-2'}
                                                                disabled={disabled}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    )}
                </div>
            </CollapsDivComponent>
        </div>
    );
};

export default DeedBuilder;
