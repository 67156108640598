/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */

export const endpoint = {
    /**
     * auth endpoint starts here
     */
    createUser: '/auth/send-otp-to-new-signup',
    verifyEmail: '/auth/verify-registering-email',
    setPassword: '/auth/create',

    authUpdatepassword: '/auth/update-password',

    updateEmployeePassword: '/auth/create-password',
    login: '/auth/login',
    resendOtp: '/auth/send-otp-to-new-signup',
    getSecurityQuestions: '/security-questions/get',
    verifySecurityQuestions: '/auth/security-questions',
    forgotPassword: '/pre-auth/forgot-password',
    forgetPassOtp: '/pre-auth/verify-forgot-password-otp',
    forgetPassOtpAccess: '/auth/verify-update-password-otp',

    loginGetSecurityQuestions: '/pre-auth/get/user-security-questions',
    loginVerifyAnswers: '/pre-auth/verify/security-questions',
    resetPassword: '/pre-auth/reset-password',

    authEmailSendOtp: '/temp-auth/send-otp-email',
    PreauthEmailSendOtp: '/pre-auth/send-otp-email',

    authEmailSendOtpAccess: '/auth/send-otp-email',

    accessauthEmailSendOtp: '/auth/send-otp-email',

    authEmailVerifyOtp: '/temp-auth/email-auth-verify',
    googleAuth: '/temp-auth/google-auth-setup',
    googleAuthAccess: '/auth/google-auth-setup',

    googleAuthPre: '/pre-auth/google-auth-setup',

    verifyGoogleAuth: '/temp-auth/google-auth-verify',

    verifyGoogleAuthAtSetup: '/temp-auth/google-auth-verify-at-setup',

    verifyGoogleAuthAccess: '/auth/google-auth-verify',
    verifyGoogleAuthPre: '/pre-auth/google-auth-verify-at-setup',

    getUserInfo: '/users',
    generateBackupCodes: '/temp-auth/generate-backup-code',

    generateBackupCodesPre: '/pre-auth/generate-backup-code',
    generateBackupCodesAccess: '/auth/generate-backup-code',

    regenrateBackupCodes: '/auth/regenerate-backup-code',
    verifyBackupCode: '/temp-auth/backup-code-verify',
    verifyPhone: '/auth/verify-registering-phone',
    sendOtpPhone: '/auth/send-otp-phone',
    getOnboardingPre: 'onboard-pre-auth',
    verifyTwoFactorEmail: '/temp-auth/verify-setup-email',
    preverifyTwoFactorEmail: '/pre-auth/email-auth-verify',

    AccessverifyTwoFactorEmail: '/auth/verify-setup-email',

    skipSetup: '/temp-auth/skip-setup',
    getAllWidgets: '/business/get-all-widget',
    updateSelectWidget: '/business/update-selected-widget',
    sentOtpToPhone: '/pre-auth/send-otp-phone',
    sentOtpToPhoneTemp: '/temp-auth/send-otp-phone',

    sendOtpPhoneRegister: '/auth/send-phone-otp-on-register',
    verifyPhoneOtp: '/auth/verify-otp-phone',
    verifyPhoneOtp: '/auth/verify-otp-phone',
    preverifyPhoneOtp: '/pre-auth/verify-otp-phone',

    verifyPhoneOtpTemp: '/temp-auth/verify-otp-phone',

    getAuthInfo: '/pre-auth/info',

    /**
     * auth endpoint startsends here
     */

    businessOwnerAddProfile: '/business/owner/update',
    businessOwnerGetProfile: '/business/owner',
    addBranchLocation: '/business/branches',
    getBranchLocations: '/business/branches/get-all',
    updateBranch: '/business/branches/update',
    addBusiness: '/business/update',
    getBusinessprofile: '/business/get',
    addService: '/services',
    getAllServices: '/services/get',
    viewOnBoardingDetails: '/business/onboard',
    getAllBusinessModules: '/role/business-module-fetch-all',
    getPermissions: '/role/business-module-fetch-all',
    fetchAllBusinessGroups: '/role/business-groups-fetch-all',
    fetchSingleRoleGroup: '/role/fetch-role-group-detail',
    sendEmployeeInvite: '/business/send-employee-invite',
    fetchInvitedEmployees: '/business/fetch-business-invited-employees',
    fetchRegisteredEmployees: '/business/fetch-business-registered-employees',
    fetchRegisteredEmployeesForTask: '/workspace/task/be-for-task',
    revokeEmployeeInvite: '/business/revoke-employee-invite',
    businessEmployeeArchive: '/business/employee-archive',
    fetchBusinessEmployee: '/business/employee',
    updateBusinessEmployee: '/business/employee-update',
    fetchBusinessCustomer: '/business/customer',
    fetchAllConnectedTenants: '/xero/apps',
    xeroGetAccessToken: '/xero/get-token',
    xeroSaveAccessToken: '/xero/save-access-token',
    xeroDisconnectThunk: '/xero/disconnect',
    xeroSynkClient: '/xero/update', //
    getAppsStatus: '/xero/apps',
    getClientSyncStatus: '/xero/sync-status',
    getXeroClients: '/xero/synkli-xero-clients',

    outlookGetAccessToken: '/microsoft/get-access-token',
    refreshOutlookSynk: 'microsoft/sync', //
    outlookSynkClient: '/microsoft/update', //
    outlookDisconnect: '/microsoft/disconnect',
    getAppsCalendars: '/microsoft/get-calendars',
    updateAppsCalendars: '/microsoft/update-calendars',

    fetchBusinessAllCustomer: '/business/all-customer',
    fetchSynkliCustomers: '/business/all-synkli-customer',
    fetchSynkliProfiles: '/business/get-profile-users',

    fetchAllCustomersForTask: '/business/customer-list',

    removeTaskChecklist: '/workspace/task-checklist/archive',
    removeTaskChecklistItem: '/workspace/task-checklist-item/archive',
    removeTaskEmployee: '/workspace/task/employee/remove',

    viewBusinessCustomerInfo: '/business/customer/info',
    getAllCustomerDeduction: '/exp-inc/get-all-deduction',
    getAllTripLogs: '/trip/vehicle-list',
    getprofitAndLoss: '/exp-inc/profit-loss',
    getRentalSchedule: '/exp-inc/rp-schedule',
    getExpenseByType: '/exp-inc/expense-by-type',
    addToClient: '/business/customer/add-to-client',
    addToInactiveClient: '/business/customer/remove-from-active-clients',
    fetchAllPermissions: '/role/permission-fetch-all',
    addRoleGroup: '/role/add-role-group',
    updateRoleGroup: '/role/update-role-group',
    fetchForms: '/business/fetch-forms',
    fetchCustomerForms: '/business/fetch-customer-forms-fb',

    fetchSubmissionFormData: '/business/fetch-submission-form-data-fb',

    postDynamicForm: '/business/create-form',
    getSingleForm: '/business/get-form',
    updateForm: '/business/update-form',
    fetchRoleGroups: '/role/fetch-role-groups',
    updateBusinesGroupPermissionStatus: '/role/update-busines-group-permission-status',
    updateBusinessGroupstatus: '/role/update-business-group-status',
    archiveSubmissionForm: '/business/archive-submission-form-data-fb',

    archiveBusinessForm: '/business/archive-form',
    updateBusinessForm: '/business/update-form',
    deleteBranch: '/business/branches',
    deleteService: '/services/inactive',
    deactiveateUsers: '/users/deactivate',
    addApp: '/business/applications',
    retriveAllApps: '/business/applications/get-all',
    getAllApps: '/business/applications/get-all',
    getAllNotifications: '/business/notification-list',
    getNotificationCount: '/business/notification-count',
    markNotificationAsRead: '/business/mar-notification',
    deleteApp: '/business/applications',
    updateFavApp: '/business/applications/fav-unfav',
    getAllNews: '/workspace/news/get-all',
    archiveNews: '/workspace/news/archive',
    getSingleNews: '/workspace/news/get',
    updateNews: '/workspace/news/update',
    addNews: '/workspace/news/create',

    updateQrcode: '/workspace/qrcode/update',
    getAllThreads: '/workspace/thread/get-all',
    addThread: '/workspace/thread/create',
    getSingleThread: '/workspace/thread/get',
    getAllComments: '/workspace/thread/comment/get-all',
    closeThread: '/workspace/thread/close',
    addComment: '/workspace/thread/comment/create',
    addReply: '/workspace/thread/comment/reply/create',
    uploadWorkSpaceImg: '/workspace/news/upload-image',
    uploadWorkSpacepdf: '/workspace/news/upload-doc',
    uploadThreadFiles: '/workspace/thread/upload-files',
    updateUserProfileImage: '/users/profile-image',
    uploadAppImage: '/business/applications/upload-image',
    createTelephonicDirectory: '/workspace/telephonic-directory/create',
    getAllTelephonicDirectories: '/workspace/telephonic-directory/get-all',
    acceptInvite: '/business/accept-employee-invite',
    blockDevice: '/temp-auth/block-device',
    resetPasswordOfEmployee: '/business/send-password-to-employee',
    authPermissions: '/role/auth-permission',
    getBusinessHours: '/business/working-hours/get',
    updateBusinessHours: '/business/working-hours',
    uploadBusinessPhoto: '/business/images',
    updatePassword: '/auth/update-password',
    getWorkspacephotos: '/business/images/get',
    deleteBusinessPhoto: '/business/images/delete',
    totalPermissions: 'users/total-permissions',
    archiveTelephonicDirectory: '/workspace/telephonic-directory/archive',
    getAllDeductions: '/workspace/deduction/get-all',
    getAllNotes: '/workspace/note/get-all',
    createNote: '/workspace/note/create',
    uploadNoteFile: '/workspace/note/upload-files',
    deleteNote: '/workspace/note/archive',
    archiveDeduction: '/workspace/deduction/archive',
    createDeduction: '/workspace/deduction/create',
    uploadDeductionFile: '/workspace/deduction/upload-file',
    getAllQrCodes: '/workspace/qrcode/get-all',
    createQrCode: '/workspace/qrcode/create',
    archiveQrCode: '/workspace/qrcode/archive',
    createTaskStatus: '/workspace/task-status/create',
    createJobsStatus: '/job-setting/status-create',
    updateJobsStatus: '/job-setting/status-update',
    createTaskType: '/workspace/task-type/create',
    createCheckList: '/workspace/create-prebuilt-checklist',
    updateChecklist: '/workspace/update-prebuilt-checklist',
    getChecklistDetails: '/workspace/get-prebuilt-checklist-detail',
    getAllTaskStatuses: '/workspace/task-status/get-all',
    getAllJobsStatuses: '/job-setting/status-get-all',
    updateStatus: '/workspace/task-status/update',
    updateTaskType: '/workspace/task-type/update',
    archiveTaskStatus: '/workspace/task-status/archive',
    archiveJobStatus: '/job-setting/status-archive',
    changeTaskSequence: '/workspace/change-sequence',
    changeJobSequence: '/job-setting/status-sequence',
    getAllTaskType: '/workspace/task-type/get-all',
    getAllPrebuiltList: '/workspace/get-prebuilt-checklist',
    archiveTaskType: '/workspace/task-type/archive',
    archivePrebuildChecklist: '/workspace/archive-checklist',
    addTask: '/workspace/task/create',
    editTask: '/workspace/task/modify',
    getAllTasks: '/workspace/task/get-all',
    getAllCompletedTasks: '/workspace/task/get-completed',
    getEmployeeTask: '/workspace/task/get-employee-task',
    getSingleTask: '/workspace/task/get',
    removeEmployeeFromTask: '/workspace/task/employee/remove',
    updateChecklistItem: '/workspace/task-checklist-item/update',
    createChecklistItem: '/workspace/task-checklist-item',
    updateTask: '/workspace/task/update',
    removeTask: '/workspace/task/archive',
    archiveTaskChecklist: '/workspace/task-checklist/archive',
    archiveTaskChecklistItem: '/workspace/task-checklist-item/archive',
    addEmployeeToTask: '/workspace/task/employee/add',
    uploadTaskFiles: '/workspace/note/upload-files',
    createTaskComment: '/workspace/task-activity',
    getActivity: '/workspace/task-activity/get',
    deleteActivity: '/workspace/task-activity/delete',
    deleteSingleCommentFile: '/workspace/task-activity/delete-file',
    updateActivity: '/workspace/task-activity/update',
    getTaskLogs: '/workspace/task/logs',
    getAllTasksLogs: '/workspace/task/complete-logs',
    uploadTaskFile: '/workspace/task/upload-files',
    archiveTaskFile: '/workspace/task/file/archive',
    shareTask: '/workspace/task/toggle-share',
    markAsCompleteTask: '/workspace/task/mark-complete',
    countByType: '/workspace/task/count-by-type',
    getClientReferences: '/business-client/get-references',
    archiveNote: '/workspace/note/archive',
    uploadBusinessLogo: '/business/logo',
    deleteAnnFile: 'workspace/news-files/archive',
    updateService: 'services/update',
    updateCustomerForm: '/business/update-customer-form-data-fb',
    deleteFormFile: '/business/delete-field-file',
    yearlyStatistics: '/workspace/task/yearly-statistics-of-completed-tasks',
    weeklyStateEmployee: '/workspace/task/weekly-statistics-of-completed-tasks',
    employeeEfficiency: '/workspace/task/employee-efficiency',
    singleEmployeeTaks: '/workspace/task/employee-tasks',
    taskCountByType: '/workspace/task/completed-tasks-count-by-types',
    weeklyStatsCompleted: '/workspace/task/weekly-statistics-of-completed-tasks',
    taskReopen: '/workspace/task/reopen',
    getTaskEmpInfo: 'workspace/task/completed-tasks-status-of-employees',
    refreshToken: '/auth/generate-access-token',
    singleFormLogs: '/customers/form-activity',
    logout: '/auth/logout',
    getCSRF: '/auth/generate-csrf',
    removeFCM: '/users/remove-fcm-token',
    customerActivity: '/customers/activity',
    fetchSingleFormFb: '/business/get-single-form-fb',
    documentDetailToSign: '/customers/file-manager/document-detail-for-customer',
    submitSignedSocument: '/customers/file-manager/submit-signed-document',
    uploadFile: 'users/upload',
    getFiles: 'customers/file-manager/list',
    requestToSignDocument: '/customers/file-manager/signature-request',
    deleteFile: '/files/delete',
    fileManagerInfo: '/customers/file-manager/info',
    filesRevoke: 'files/revoke-request',
    fileManagerRecentActivity: 'files/file-manager-activity',
    saveSignature: '/users/signature',
    getSignatures: '/users/get-signatures',
    deleteSignature: '/users/delete-signatures',
    deductionsInfo: '/exp-inc/get/stats',
    getTfnDetails: '/exp-inc/get/tfn',
    getRentalProperty: '/exp-inc/get/rental-property',
    getSummaryForBusiness: '/exp-inc/get/summary',
    getSummaryForTripLogs: '/trip/summary-list',
    summaryDetailChart: '/exp-inc/get/summary-detail-chart',
    tripDetailChart: '/trip/summary-chart',
    getSummaryDetails: '/exp-inc/get/summary-detail',
    getTripSummaryDetails: '/trip/summary-detail',
    downloadCsv: '/exp-inc/download/csv',
    downloadPdf: '/exp-inc/download/pdf',
    downloadImages: '/exp-inc/download/images',
    downloadAllZip: '/exp-inc/download/all-zip',
    getBusinesses: '/exp-inc/get/business',
    getRentalBusiness: '/exp-inc/get/rental-property',
    getSummaryLogs: '/customers/summary/logs',
    getLeads: '/leads/get-all-leads',
    downloadLogsPfCsv: '/customers/pdf/activity',
    getBusinessTasks: '/workspace/get-business-task',
    updateDragWidget: '/business/update-widget',
    resetSecurityQuestions: '/pre-auth/reset-security-questions',
    addSecurityQuestions: '/pre-auth/update-security-questions',
    disableGoogleAuth: '/auth/disable-google-auth',
    disableEmailAuth: '/auth/disable-email2fa',
    addFCMToken: '/users/add-fcm-token',
    getLoggedInUserInfo: '/users/logged-in-user-info',
    getOnboardingStepDetails: '/business/onboarding-steps',
    getFullTasks: '/workspace/task/get-completed',
    getTaskReportsInfo: '/workspace/task/task-report',
    employeeInfoAndTasks: '/workspace/task/completed-tasks-status-of-employees',

    deleteThreadFile: '/workspace/thread/file/archive',
    updateDirectory: '/workspace/telephonic-directory/update',
    editApp: '/business/applications/update',

    delAppImage: 'business/applications/removeFile',
    delDeductionFile: '/workspace/deduction/file/archive',
    updateDeduction: '/workspace/deduction/update',
    delNoteFile: '/workspace/note/file/archive',
    updateNote: '/workspace/note/update',
    updateThread: '/workspace/thread/update',
    downloadFileManagerLogsPdf: '/files/file-manager-activity/pdf',
    downaloadDeductionsLogsPdf: '/customers/summary/logs/export-pdf',
    downloadTaskDetailsLogsPdf: '/workspace/task/logs/export-pdf',

    getCustomerStats: 'business/customer-stats',

    // Client Nomination
    getClientNomination: '/business-client/get-client-nomination',
    resetClientNomination: '/business-client/reset-client-nomination',
    sendReminderClientNomination: '/business-client/manual-remind-client-nomination',

    // invite client flow
    sendClientInvite: '/business-client/send-invite',
    getInvitedClients: '/business-client/invites',
    resendClientInvite: '/business-client/resend-invite',
    revokeInvitedClient: '/business-client/revoke-invite',
    deleteClientInvite: 'business-client/delete-invite',
    updateClientInvite: '/business-client/update-invite',
    downloadBulkInviteTemplate: 'business-client/bulk-invite-template',
    uploadBulkClientInviteFile: '/business-client/bulk-invites',
    getinvitedFiles: '/business-client/invited-files',
    downloadFailedEntry: '/business-client/download-failed-invites',
    downloadSingleFormLogsPdf: '/customers/form-activity/export-pdf',
    addDesignations: '/designation',
    getAllDesignations: '/designation/get',
    inactiveDesignation: '/designation/inactive',
    editDesignation: '/designation/update',

    getTaskHistory: '/workspace/task/status-activity',
    addLead: '/leads',
    deleteLead: '/leads/inactive',
    updateLead: '/leads/update',

    addManualClient: '/business-client/create-client',
    updateManualClient: '/business-client/update-client',
    verifyClient: '/business-client/verify-client',
    deleteClientRef: '/business-client/delete-reference',
    deleteBusinessClient: '/business-client/delete',
    getClientTypes: '/business-client/client-types',
    saveRefpattern: '/business-client/save-reference-pattern',
    saveJobRefpattern: '/jobs/save-job-reference-setting',
    checkClientUserName: '/business-client/display_name_check',
    saveDefaultRefpattern: '/business-client/set-default-reference-pattern',
    getRefPatterns: '/business-client/get-reference-pattern',
    getJobRefPatterns: '/jobs/get-job-reference-setting',
    refreshIntegrationData: '/business-client/sync-contacts-manually', //
    getformsfieldsOptions: '/business/form-field-options',
    //bulk import export clients
    downloadBulkImportTemplate: '/business-client/download-bulk-client-templates',
    uploadBulkImportFile: '/business-client/bulk-upload-clients',
    getBusinessClientInfo: '/business-client/get-file-info',
    downloadErroredClients: '/business-client/download-errored-clients',
    uploadedFileClients: '/business-client/uploaded-file-clients',
    publishForms: '/business/publish-form',
    moveClient: '/business/customer/add-to-prospective-client',
    searchClients: '/customers/search-clients',
    updateClientProfileImage: '/business-client/update-profile-image',
    checkTfnAbnAcn: '/customers/check-tfn-abn-acn',

    // job manager
    createJob: '/jobs/create',
    updateJob: '/jobs/update',
    updateJobKeyValue: '/jobs/update-by-key-val',
    getAllJobTypes: '/jobs/listing-job-type',
    getSingleJob: '/jobs/get',
    getJobActions: '/jobs/get-job-actions',
    markReadJobActions: '/jobs/mark-complete-job-actions',
    deleteJobFile: '/jobs/archive-document',
    addJobFile: '/jobs/add-file',
    deleteJobs: '/jobs/delete-job',
    getAllJobs: '/jobs/listing',
    getJobAssignees: '/jobs/job-task-employees',
    getJobTasks: '/jobs/job-task',
    removeJobClient: '/jobs/remove-client',
    removeJobManager: '/jobs/remove-manager',
    addJobClient: '/jobs/add-client',
    addManagerToJob: '/jobs/add-manager',
    closeDeleteJob: '/jobs/delete-close-jobs',
    JobDocumentCount: '/jobs/job-document-count',

    // milestone and tasks
    addMilestone: '/jobs/create-milestone',
    deleteMilestone: '/jobs/archive-milestone',
    getAllMilestones: '/jobs/listing-milestone',
    updateMilestone: '/jobs/update-milestone',
    changeMilestoneSequence: '/jobs/change-milestone-sequence',
    // getMilestoneTasks: '/jobs/milestone-task',
    getMilestoneTasks: '/workspace/milestone-task',
    createTaskbyTitle: '/workspace/task/create-by-title',
    changeMilestoneTaskSequence: '/workspace/task/change-sequence',
    createFolder: '/jobs/create-folder',
    renameFolder: '/jobs/rename-item',
    getDocumentDriveList: 'jobs/drive-list',
    uploadFilesToDocManager: 'jobs/upload-files',
    JobManagerDeleteItems: 'jobs/delete-items',
    JobManagerRequestDocRevokeAccess: 'jobs/revoke-access',
    JobManagerRequestSignature: 'jobs/signature-request',
    JobManagerCopyMove: 'jobs/copy-move',
    JobManagerMoveToClient: 'jobs/move-file-to-client',
    JobManagerRequestSignatureList: 'jobs/all-requested-document-list',
    JobManagerCollateDocList: 'jobs/list-collate-document',
    jobManagerDocumentDetailToSign: 'jobs/document-detail',
    JobManagerSubmitSignedDocument: 'jobs/submit-signed-document',
    JobManagerListActivity: 'jobs/list-activity',
    JobManagerSendActivityMsg: 'jobs/add-activity',
    collateFiles: 'jobs/collate-document',
    downloadItems: 'jobs/download-items',
    downloadSignedFiles: 'jobs/download-signed-files',

    // job Manager setting
    createTemplateDoc: 'job-setting/template-document-create',
    getTemplateDoc: 'job-setting/template-document-get',
    deleteTemplateDoc: 'job-setting/template-document-delete',
    updateTemplateDoc: 'job-setting/template-document-update',
    bulkDeleteTemplateDoc: 'job-setting/template-document-deletes',

    // job Manager Task Templates
    getTemplateTask: 'job-setting/task-template-get',
    createTemplateTask: 'job-setting/task-template-create',
    getTemplateTaskById: 'job-setting/task-template-by-id',
    deleteTemplateTask: 'job-setting/task-template-delete',
    updateTemplateTask: 'job-setting/task-template-update',
    addAttachmentTemplateTask: 'job-setting/task-template-attachment-add',
    removeAttachmentTemplateTask: 'job-setting/task-template-attachment-delete',
    removeChecklistTemplateTask: 'job-setting/task-template-checklist-delete',
    createChecklistTemplateTask: 'job-setting/task-template-checklist-create',
    createChecklistItemTemplateTask: 'job-setting/task-template-checklist-item-create',
    createTaskUsingTemplate: '/workspace/task/template-create',

    //User Email Signature

    createUserEmailSignature: '/user-signature/create',
    getUserEmailSignature: '/user-signature/get-list',
    updateUserEmailSignature: '/user-signature/update',
    removeUserEmailSignature: '/user-signature/delete',
    getDefaultSignature: '/user-signature/get-default-signature',
    setDefaultSignature: '/user-signature/set-default-signature',

    //    job templates
    createJobTemplate: '/job-setting/job-template-create',
    getJobsTemplates: '/job-setting/job-template-get',
    deleteTemplates: '/job-setting/job-template-delete',
    getSingleJobTemplate: '/job-setting/job-template-by-id',
    updateJobTemplate: '/job-setting/job-template-update',

    // job types

    createJobType: '/job-setting/create-job-type',
    getClientServices: '/job-setting/services-list',
    getAllJobTypes: '/job-setting/listing-job-type',
    deleteJobTypes: '/job-setting/archive-job-type',
    updateType: '/job-setting/update-job-type',
    getTemplateById: '/job-setting/job-template-by-id',

    // Request Documents
    createRequestDocument: '/jobs/request-document-create',
    getRequestDocument: '/jobs/request-document-list',
    getRequestedDocActivity: '/jobs/request-document-activity',
    submitDocument: '/jobs/request-document-submitted',
    updateRequestDocument: '/jobs/request-document-update',
    removeRequestDocument: '/jobs/drive-archive',
    requstDocDeleteItems: '/jobs/request-document-delete',
    requstDocDownloadItems: '/jobs/request-document-download',

    // Ethical Clearance Request
    createClearanceReq: '/ethical-clearance/create',
    getClearanceReqList: '/ethical-clearance/get-list',
    getClearanceDetail: '/ethical-clearance/get-details-by-id',
    cancelClearanceRequest: '/ethical-clearance/cancel-request',
    downloadClearanceFiles: '/ethical-clearance/download-files',
    regenrateCleranceLink: '/ethical-clearance/regenerate-link',
    confirmClearance: '/ethical-clearance/submitted',
    //groups
    createGroup: '/users/create-group',
    updateGroup: '/users/update-group',
    getGroups: '/users/list-group',
    deleteGroup: '/users/delete-group',

    // jobs fovorites
    addFavorite: '/jobs/mark-favourite',
    //job groups
    updateJobGroup: '/jobs/update-job-group',

    //client favorites

    addClientToFavorite: '/business-client/mark-favourite',

    //client groups

    addClientToGroup: '/business-client/update-client-group',
    sendBulkInvite: '/business-client/send-bulk-invite',

    //table settings
    updateTableSettings: '/users/add-table-setting',
    getTableSettings: '/users/get-table-setting',

    // Relay Room Settings
    checkCollaborationEmailExist: '/business/email-exist',
    saveCollaborationEmailExist: '/business/email-create',
    sendComposeEmail: '/jobs/relay-room-create',
    replyComposeEmail: '/jobs/relay-room-reply',
    getRelayRoomList: '/jobs/relay-room-list',
    rrEmailStarred: '/jobs/relay-room-email-starred',
    rrEmailResponseStarred: 'jobs/relay-room-responce-starred',
    deleteThread: '/jobs/relay-room-delete',
    deleteThreadResponse: '/jobs/relay-room-response-delete',
    threadDetails: '/jobs/relay-room-by-id',
    threadReadStatus: '/jobs/relay-room-email-read',
    relayroomFilesDownload: '/jobs/relay-room-file-download/',
    getTotalThreadsCount: '/jobs/email-count/',

    // draft APIs
    handleCreateDraft: '/jobs/email-draft-create',
    handleUpdateDraft: '/jobs/email-draft-update',
    getDraftList: '/jobs/email-draft-list',
    removeDraft: '/jobs/email-draft-delete',
    removeDraftFile: '/jobs/email-draft-file-delete',

    //deeds
    createDeed: '/deeds/create',
    getDeeds: '/deeds/list',

    //Notification settings

    getNotificationModules: '/notification-setting/get-module',
    getNotificationSettings: '/notification-setting/get-setting',
    updateNotificationSettings: '/notification-setting/update-module',
    resetAllNotifications: '/notification-setting/reset-setting',
    updateSettingsObj: '/notification-setting/update-setting',
    resetModule: '/notification-setting/reset-module',
    resetSetting: '/notification-setting/reset-module-setting',

    //onboarding services
    getOnboardingServices: '/business/service-get-list',
    updateOnboardingServices: '/business/service-update',
    createOnboardingServices: '/business/service-create',
    deleteOnboardingServices: '/business/service-delete',
    getIconList: '/business/service-get-icons-list',
    toggleOnboardingService: '/business/service-on-off',
    getClientsToMerge: '/business-client/get-client-to-merge',
    getClientsDirectors: '/business-client/get-company-directors',
    mergeClients: '/business-client/client-to-merge',
    getEmployeeStats: '/users/employee-stats-to-merge',
    changeEmpStatus: '/business/change-employee-status',
    duplicateJob: '/jobs/copy-job'
};

export const methods = {
    POST: 'post',
    GET: 'get',
    PUT: 'put',
    DELETE: 'delete',
    PATCH: 'patch'
};
