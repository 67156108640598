import React from 'react';

export const BmpFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#ACC1AE" />
            <path
                d="M11.1328 21.6875L9.625 23H3.80469V11.7969H9.625L11.1328 13.125V16.7109L10.3828 17.3828L11.1328 18.0391V21.6875ZM6.63281 18.8047V20.6797H8.28125V18.8047H6.63281ZM6.63281 14.0859V15.9609H8.28125V14.0859H6.63281ZM14.9219 16.8438V23H12.4141V11.7969H16.2656L17.5 16.8438L18.6562 11.7969H22.5859V23H20.0781V16.8438L18.5703 23H16.4453L14.9219 16.8438ZM26.6953 20.25V23H23.8672V11.7969H29.6875L31.1953 13.125V18.9219L29.6875 20.25H26.6953ZM26.6953 14.6328V17.4141H28.3438V14.6328H26.6953Z"
                fill="white"
            />
        </svg>
    );
};
