import React from 'react';

export const PstFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#9DACCD" />
            <path
                d="M8.05469 20.25V23H5.22656V11.7969H11.0469L12.5547 13.125V18.9219L11.0469 20.25H8.05469ZM8.05469 14.6328V17.4141H9.70312V14.6328H8.05469ZM15.3359 23L13.8359 21.6719V19.625H16.6797V20.3281H18.3125V18.4688H15.3359L13.8359 17.1562V13.125L15.3359 11.7969H19.6562L21.1641 13.125V15.1797H18.3125V14.4688H16.6797V16.2812H19.6562L21.1641 17.6094V21.6719L19.6562 23H15.3359ZM27.5469 23H24.7188V14.6484H22.4453V11.7969H29.7734V14.6484H27.5469V23Z"
                fill="white"
            />
        </svg>
    );
};
