import { combineReducers, configureStore } from '@reduxjs/toolkit';

import globalReducer from './global/globalReducer';
import { persistStore } from 'redux-persist';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
    signup,
    otpVerification,
    setPassword,
    login,
    googleAuth,
    getSecurityQuestions,
    verifySecurityQuestions,
    forgotPassword,
    verifyForgetPass,
    loginGetSecurityQuestions,
    getAllServices,
    getBranchLocations,
    getBusinessHours,
    getWorkspacephotos,
    totalPermissions,
    getBusinessprofile,
    businessOwnerGetProfile,
    viewOnBoardingDetails,
    getAuthInfo,
    googleAuthAccess
} from './auth/slices';

import {
    fetchBusinessAllCustomer,
    fetchBusinessCustomer,
    fetchSynkliCustomers,
    getBusinessClientInfo,
    getClientNomination,
    getClientReferences,
    getClientTypes,
    getClientsToMerge,
    getInvitedClients,
    getRefPatterns,
    getinvitedFiles,
    uploadedFileClients,
    viewBusinessCustomerInfo
} from './client';
import {
    fetchRoleGroups,
    authPermissions,
    fetchRegisteredEmployees,
    fetchBusinessEmployee,
    getAllBusinessModules,
    fetchAllPermissions,
    fetchRegisteredEmployeesForTask,
    getEmployeeStats
} from './settings/team/team';
import {
    fetchForms,
    fetchCustomerForms,
    fetchSubmissionFormData,
    singleFormLogs,
    customerActivity,
    getSingleForm
} from './form';

//workspace reducers
import { getAllNews, getSingleNews } from './workspace/workspaceNews';
import { getAllThreads, getSingleThread, getAllComments } from './workspace/workspaceThreads';
import { getAllTelephonicDirectories } from './workspace/workspaceTelephonicDirectories';

import { getAllNotes } from './workspace/workspaceNotes';
import { getAllDeductions, createDeduction } from './workspace/workspaceDeduction';
import { getAllQrCodes } from './workspace/workspaceQrCode';
import {
    getAllTaskStatuses,
    getAllTaskType,
    getAllTasks,
    getSingleTask,
    getActivity,
    getTaskLogs,
    getAllTasksLogs,
    getAllPrebuiltList,
    countByType,
    getFullTasks,
    getAllCompletedTasks,
    getTaskReportsInfo,
    getEmployeeTask,
    getTaskHistory,
    getAllJobsStatuses
} from './workspace/workspaceTasks';
import {
    employeeEfficiency,
    employeeInfoAndTasks,
    getTaskEmpInfo,
    singleEmployeeTaks,
    taskCountByType,
    weeklyStatsEmployee,
    yearlyStatistics
} from './workspace/workspaceEmployeeTasks';
import { weeklyStatsCompleted } from './workspace/workspaceCompletedTasks';
import { documentDetailToSign, fileManagerInfo, fileManagerRecentActivity, getSignatures } from './fileManager';
import {
    deductionsInfo,
    getAllCustomerDeduction,
    getAllTripLogs,
    getBusinesses,
    getProfitAndLoss,
    getRentalBusiness,
    getRentalProperty,
    getSummaryDetails,
    getSummaryForBusiness,
    getSummaryLogs,
    getTfnDetails,
    summaryDetailChart
} from './deductions';
import { getAllSApps } from './workspace/workspaceApps';
import { getLeads } from './leads';
import { getBusinessTasks } from './businessDashboard.js';

import { getAllDesignations } from './settings/team/workspaceSettings';

import { getAllNotifications, getNotificationCount } from './workspace/workspaceNotifications';
import { fetchAllConnectedTenants, getAppsCalendars } from './appConnectoins';
import leadsSlice from './custom-reducers/LeadsReducer';
import InviteSlice from './custom-reducers/InviteReducer';
import ClientSlice from './custom-reducers/clientsReducer';
import telephonicSlice from './custom-reducers/TelephonicReducer';
import globalPresistReducer from './global/globalPresistReducer';
import {
    getAllJobs,
    getAllJobTypes,
    getJobRefPatterns,
    jobManagerDocumentDetailToSign,
    getSingleJob,
    getJobAssignees,
    getJobTasks,
    getJobsTemplates,
    getSingleJobTemplate,
    getClientServices,
    getClearanceDetail,
    getDeeds,
    getJobActions
} from './JobManager';
import JobManagerDocumentsSlice from './custom-reducers/JobManagerDocumentReducer';
import {
    getDefaultSignature,
    getTemplateDoc,
    getTemplateTask,
    getTemplateTaskById,
    getUserEmailSignature
} from './settings/JobPortal';
import JobManagerDocumentsForActionSlice from './custom-reducers/JobManagerDocumentActionsReducer';
import { getGroups } from './groups';
import { getTableSettings } from './common';
import draftListSlice from './custom-reducers/RelayRoomDraftReducer';
import relayRoomListSlice from './custom-reducers/RelayRoomLeftBarReducer';
import { getNotificationModules, getNotificationSettings } from './settings/notification-settings';
import { getIconList, getOnboardingServices } from './settings/onboardingServices';
// import LeadsReducer from './custom-reducers/LeadsReducer';

const reducers = combineReducers({
    signup: signup.reducer,
    otpVerification: otpVerification.reducer,
    setPassword: setPassword.reducer,
    login: login.reducer,
    googleAuth: googleAuth.reducer,
    global: globalReducer.reducer,
    viewBusinessCustomerInfo: viewBusinessCustomerInfo.reducer,
    getSecurityQuestions: getSecurityQuestions.reducer,
    verifySecurityQuestions: verifySecurityQuestions.reducer,
    forgotPassword: forgotPassword.reducer,
    verifyForgetPass: verifyForgetPass.reducer,
    businessOwnerGetProfile: businessOwnerGetProfile.reducer,
    loginGetSecurityQuestions: loginGetSecurityQuestions.reducer,
    fetchBusinessCustomer: fetchBusinessCustomer.reducer,
    fetchForms: fetchForms.reducer,
    fetchCustomerForms: fetchCustomerForms.reducer,
    fetchSubmissionFormData: fetchSubmissionFormData.reducer,
    fetchRoleGroups: fetchRoleGroups.reducer,
    getAllServices: getAllServices.reducer,
    getBranchLocations: getBranchLocations.reducer,
    getAllNews: getAllNews.reducer,
    getSingleNews: getSingleNews.reducer,
    getAllThreads: getAllThreads.reducer,
    getSingleThread: getSingleThread.reducer,
    getAllComments: getAllComments.reducer,
    getAllTelephonicDirectories: getAllTelephonicDirectories.reducer,
    authPermissions: authPermissions.reducer,
    getBusinessHours: getBusinessHours.reducer,
    getWorkspacephotos: getWorkspacephotos.reducer,
    totalPermissions: totalPermissions.reducer,
    getAllDeductions: getAllDeductions.reducer,
    getAllNotes: getAllNotes.reducer,
    createDeduction: createDeduction.reducer,
    getAllQrCodes: getAllQrCodes.reducer,
    getAllTaskStatuses: getAllTaskStatuses.reducer,
    getAllJobsStatuses: getAllJobsStatuses.reducer,
    getAllNotifications: getAllNotifications.reducer,
    getNotificationCount: getNotificationCount.reducer,
    getAllTaskType: getAllTaskType.reducer,
    getAllPrebuiltList: getAllPrebuiltList.reducer,
    fetchRegisteredEmployees: fetchRegisteredEmployees.reducer,
    fetchRegisteredEmployeesForTask: fetchRegisteredEmployeesForTask.reducer,
    getAllTasks: getAllTasks.reducer,
    getAllCompletedTasks: getAllCompletedTasks.reducer,
    getEmployeeTask: getEmployeeTask.reducer,
    getSingleTask: getSingleTask.reducer,
    getActivity: getActivity.reducer,
    getTaskLogs: getTaskLogs.reducer,
    getAllTasksLogs: getAllTasksLogs.reducer,
    getBusinessprofile: getBusinessprofile.reducer,
    fetchBusinessAllCustomer: fetchBusinessAllCustomer.reducer,
    fetchAllConnectedTenants: fetchAllConnectedTenants.reducer,
    fetchBusinessEmployee: fetchBusinessEmployee.reducer,
    yearlyStatistics: yearlyStatistics.reducer,
    weeklyStatsEmployee: weeklyStatsEmployee.reducer,
    employeeEfficiency: employeeEfficiency.reducer,
    singleEmployeeTaks: singleEmployeeTaks.reducer,
    taskCountByType: taskCountByType.reducer,
    weeklyStatsCompleted: weeklyStatsCompleted.reducer,
    getTaskEmpInfo: getTaskEmpInfo.reducer,
    viewOnBoardingDetails: viewOnBoardingDetails.reducer,
    singleFormLogs: singleFormLogs.reducer,
    customerActivity: customerActivity.reducer,
    documentDetailToSign: documentDetailToSign.reducer,
    fileManagerInfo: fileManagerInfo.reducer,
    fileManagerRecentActivity: fileManagerRecentActivity.reducer,
    getSignatures: getSignatures.reducer,
    deductionsInfo: deductionsInfo.reducer,
    getTfnDetails: getTfnDetails.reducer,
    getRentalProperty: getRentalProperty.reducer,
    getSummaryForBusiness: getSummaryForBusiness.reducer,
    getSummaryDetails: getSummaryDetails.reducer,
    getBusinesses: getBusinesses.reducer,
    getRentalBusiness: getRentalBusiness.reducer,
    getSummaryLogs: getSummaryLogs.reducer,
    getAllSApps: getAllSApps.reducer,
    getLeads: getLeads.reducer,
    getBusinessTasks: getBusinessTasks.reducer,
    getAuthInfo: getAuthInfo.reducer,
    getAllCustomerDeduction: getAllCustomerDeduction.reducer,
    getAllTripLogs: getAllTripLogs.reducer,
    summaryDetailChart: summaryDetailChart.reducer,
    getprofitAndLoss: getProfitAndLoss.reducer,
    googleAuthAccess: googleAuthAccess.reducer,
    countByType: countByType.reducer,
    getFullTasks: getFullTasks.reducer,
    getTaskReportsInfo: getTaskReportsInfo.reducer,
    employeeInfoAndTasks: employeeInfoAndTasks.reducer,
    getInvitedClients: getInvitedClients.reducer,
    getinvitedFiles: getinvitedFiles.reducer,
    getAllBusinessModules: getAllBusinessModules.reducer,
    fetchAllPermissions: fetchAllPermissions.reducer,
    getAllDesignations: getAllDesignations.reducer,
    getTaskHistory: getTaskHistory.reducer,
    getClientReferences: getClientReferences.reducer,
    getRefPatterns: getRefPatterns.reducer,
    leadsReducer: leadsSlice.reducer,
    invitesReducer: InviteSlice.reducer,
    clientsReducer: ClientSlice.reducer,
    jobManagerDocReducer: JobManagerDocumentsSlice.reducer,
    jobManagerDocForActionsReducer: JobManagerDocumentsForActionSlice.reducer,
    fetchSynkliCustomers: fetchSynkliCustomers.reducer,
    telephonicSlice: telephonicSlice.reducer,
    getBusinessClientInfo: getBusinessClientInfo.reducer,
    uploadedFileClients: uploadedFileClients.reducer,
    getAppsCalendars: getAppsCalendars.reducer,
    getClientTypes: getClientTypes.reducer,
    getSingleForm: getSingleForm.reducer,
    globalPresist: globalPresistReducer.reducer,
    getJobRefPatterns: getJobRefPatterns.reducer,
    getAllJobTypes: getAllJobTypes.reducer,
    getAllJobs: getAllJobs.reducer,
    jobManagerDocumentDetailToSign: jobManagerDocumentDetailToSign.reducer,
    getSingleJob: getSingleJob.reducer,
    getJobActions: getJobActions.reducer,
    getClientNomination: getClientNomination.reducer,
    getTemplateDoc: getTemplateDoc.reducer,
    getTemplateTask: getTemplateTask.reducer,
    getTemplateTaskById: getTemplateTaskById.reducer,
    getJobAssignees: getJobAssignees.reducer,
    getJobTasks: getJobTasks.reducer,
    getJobsTemplates: getJobsTemplates.reducer,
    getSingleJobTemplate: getSingleJobTemplate.reducer,
    getClientServices: getClientServices.reducer,
    getClearanceDetail: getClearanceDetail.reducer,
    getGroups: getGroups.reducer,
    getTableSettings: getTableSettings.reducer,
    getRelayRoomList: relayRoomListSlice.reducer,
    draftListReducer: draftListSlice.reducer,
    getDeeds: getDeeds.reducer,
    getNotificationModules: getNotificationModules.reducer,
    getNotificationSettings: getNotificationSettings.reducer,
    getOnboardingServices: getOnboardingServices.reducer,
    getIconList: getIconList.reducer,

    getClientsToMerge: getClientsToMerge.reducer,

    getUserEmailSignature: getUserEmailSignature.reducer,
    getDefaultSignature: getDefaultSignature.reducer,
    getEmployeeStats: getEmployeeStats.reducer
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'signup',
        'login',
        'otpVerification',
        'googleAuth',
        'authPermissions',
        'totalPermissions',
        'globalPresist'
    ]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export const persistor = persistStore(store);

export default store;
