import React from 'react';

export const PdfFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#9F6F6C" />
            <path
                d="M7.55469 20.25V23H4.72656V11.7969H10.5469L12.0547 13.125V18.9219L10.5469 20.25H7.55469ZM7.55469 14.6328V17.4141H9.20312V14.6328H7.55469ZM20.6641 21.6875L19.1562 23H13.3359V11.7969H19.1562L20.6641 13.125V21.6875ZM16.1641 14.6328V20.1719H17.8125V14.6328H16.1641ZM24.7734 18.8047V23H21.9453V11.7969H29.2734V14.6484H24.7734V15.9609H27.7812V18.8047H24.7734Z"
                fill="white"
            />
        </svg>
    );
};
