import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { cn } from 'utills/tailwindUtil';

const Input = ({
    titleSx,
    title,
    sx,
    placeholder,
    id,
    name,
    rightIcon,
    leftIcon,
    hasError,
    useWithoutForm,
    disabled,
    value,
    onChange,
    wrapsx,
    type,
    maxLength,
    required
}) => {
    return (
        <>
            {title && (
                <label
                    htmlFor={id || ''}
                    className={cn('block buttons-font leading-6 text-black', titleSx ? titleSx : '')}
                >
                    {title}
                </label>
            )}
            <div className={cn('relative', title ? 'mt-2' : '', wrapsx ? wrapsx : '')}>
                {leftIcon && (
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        {leftIcon}
                    </div>
                )}
                {useWithoutForm ? (
                    <input
                        type={type || 'text'}
                        id={id || undefined}
                        name={name}
                        className={cn(
                            sx ? sx : '',
                            'block w-full rounded-lg shadow-sm  !placeholder-[#979797]  border-secondarybg px-2 pl-4 py-2 !outline-none font-poppins text-[0.9rem] font-[400] sm:leading-6',
                            hasError ? '!border-red-400' : ''
                        )}
                        placeholder={placeholder || ''}
                        disabled={disabled || false}
                        value={value && value}
                        onChange={onChange}
                        autoComplete="off"
                        maxLength={maxLength ?? 50}
                        required={required ?? false}
                    />
                ) : (
                    <></>
                )}
                {rightIcon && (
                    <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">{rightIcon}</div>
                )}
                {hasError && !rightIcon && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon className="w-5 h-5 text-red-500" />
                    </div>
                )}
            </div>
        </>
    );
};

export default Input;
