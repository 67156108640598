import React from 'react';

export const WebpFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.2222 0H3.77778C1.7 0 0 1.7 0 3.77778V30.2222C0 32.3 1.7 34 3.77778 34H30.2222C32.3 34 34 32.3 34 30.2222V3.77778C34 1.7 32.3 0 30.2222 0ZM12.2778 16.0556C12.2778 17.5667 10.9556 18.8889 9.44444 18.8889H7.55556V22.6667H4.72222V11.3333H9.44444C10.9556 11.3333 12.2778 12.6556 12.2778 14.1667V16.0556ZM21.7222 19.8333C21.7222 21.3444 20.4 22.6667 18.8889 22.6667H14.1667V11.3333H18.8889C20.4 11.3333 21.7222 12.6556 21.7222 14.1667V19.8333ZM29.2778 14.1667H26.4444V16.0556H29.2778V18.8889H26.4444V22.6667H23.6111V11.3333H29.2778V14.1667ZM17 14.1667H18.8889V19.8333H17V14.1667ZM7.55556 14.1667H9.44444V16.0556H7.55556V14.1667Z"
                fill="#0C7785"
            />
            <rect x="4" y="9" width="26" height="16" rx="4" fill="#0C7785" />
            <path
                d="M7.86523 17.2207L6.99219 21H4.86523L2.48047 12.5977H4.60156L5.82617 16.8691L6.79883 12.5977H8.92578L9.91016 16.8691L11.1348 12.5977H13.2559L10.8535 21H8.73242L7.86523 17.2207ZM16.212 17.8535V18.8613H19.587V21H14.0909V12.5977H19.587V14.7363H16.212V15.7207H18.4679V17.8535H16.212ZM25.9241 20.0156L24.7932 21H20.428V12.5977H24.7932L25.9241 13.5938V16.2832L25.3616 16.7871L25.9241 17.2793V20.0156ZM22.5491 19.2598H23.7854V17.8535H22.5491V19.2598ZM22.5491 14.3145V15.7207H23.7854V14.3145H22.5491ZM28.8861 18.9375V21H26.765V12.5977H31.1302L32.2611 13.5938V17.9414L31.1302 18.9375H28.8861ZM28.8861 14.7246V16.8105H30.1224V14.7246H28.8861Z"
                fill="white"
            />
        </svg>
    );
};
