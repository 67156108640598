import React from 'react';

export const DocxFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#2B3467" />
            <path
                d="M9.47803 20.9336L8.25293 22H3.52393V12.8975H8.25293L9.47803 13.9766V20.9336ZM5.82178 15.2017V19.7021H7.16113V15.2017H5.82178ZM15.248 22H11.7251L10.519 20.9336V13.9766L11.7251 12.8975H15.248L16.4731 13.9766V20.9336L15.248 22ZM12.8169 19.7783H14.1689V15.1001H12.8169V19.7783ZM22.2432 22H18.7202L17.5142 20.9336V13.9766L18.7202 12.8975H22.2432L23.4683 13.9766V16.2808H21.1514V15.2017H19.812V19.7021H21.1514V18.5913H23.4683V20.9336L22.2432 22ZM26.8071 22H24.5093V18.5532L25.7725 17.4487L24.522 16.3442V12.8975H26.8389V15.3413L27.499 15.9316L28.1592 15.3413V12.8975H30.4761V16.3442L29.2129 17.4487L30.4761 18.5532V22H28.1719V19.5688L27.499 18.9722L26.8071 19.5688V22Z"
                fill="white"
            />
        </svg>
    );
};
