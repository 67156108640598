import React from 'react';

export const MsgFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.2222 0H3.77778C1.7 0 0 1.7 0 3.77778V30.2222C0 32.3 1.7 34 3.77778 34H30.2222C32.3 34 34 32.3 34 30.2222V3.77778C34 1.7 32.3 0 30.2222 0ZM12.2778 16.0556C12.2778 17.5667 10.9556 18.8889 9.44444 18.8889H7.55556V22.6667H4.72222V11.3333H9.44444C10.9556 11.3333 12.2778 12.6556 12.2778 14.1667V16.0556ZM21.7222 19.8333C21.7222 21.3444 20.4 22.6667 18.8889 22.6667H14.1667V11.3333H18.8889C20.4 11.3333 21.7222 12.6556 21.7222 14.1667V19.8333ZM29.2778 14.1667H26.4444V16.0556H29.2778V18.8889H26.4444V22.6667H23.6111V11.3333H29.2778V14.1667ZM17 14.1667H18.8889V19.8333H17V14.1667ZM7.55556 14.1667H9.44444V16.0556H7.55556V14.1667Z"
                fill="#6683DF"
            />
            <rect x="4" y="9" width="26" height="16" rx="4" fill="#6683DF" />
            <path
                d="M5.14844 16.8438V23H2.64062V11.7969H6.49219L7.72656 16.8438L8.88281 11.7969H12.8125V23H10.3047V16.8438L8.79688 23H6.67188L5.14844 16.8438ZM15.5938 23L14.0938 21.6719V19.625H16.9375V20.3281H18.5703V18.4688H15.5938L14.0938 17.1562V13.125L15.5938 11.7969H19.9141L21.4219 13.125V15.1797H18.5703V14.4688H16.9375V16.2812H19.9141L21.4219 17.6094V21.6719L19.9141 23H15.5938ZM28.5234 23H24.1875L22.7031 21.6875V13.125L24.1875 11.7969H28.5234L30.0312 13.125V15.4297H27.3125V14.5078H25.5312V20.2656H27.3125V19.0625H26.3672V16.7109H30.0312V21.6875L28.5234 23Z"
                fill="white"
            />
        </svg>
    );
};
