/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import ProgressBarComponent from 'components/atoms/ProgressBar';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import React, { useEffect, useState } from 'react';
import { Individualinvite } from './add-client-forms/Individualinvite';
import { PartnershipInvite } from './add-client-forms/PartnershipInvite';
import { CompanyInvite } from './add-client-forms/CompanyInvite';
import { TrustInvite } from './add-client-forms/TrustInvite';
import { useSelector } from 'react-redux';
import { Loader } from 'components/common/Loader';
import { ImageUploader } from 'components/molecules/image-uploader';
import { SMSFInvite } from './add-client-forms/SMSFInvite';
import { AssociationInvite } from './add-client-forms/AssociationInvite';
import { ALL_ClIENT_TYPES, CLIENT_CATEGORIES } from 'utills/globalVars';
import { ACTIVE_CLIENT, PROSPECTIVE_CLIENT } from './add-client-forms/constants/addClient.constants';

const optlst = [
    { label: 'Individual', value: 'Individual' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'Company', value: 'Company' },
    { label: 'Trust', value: 'Trust' },
    { label: 'Self manage super fund', value: 'SMSF' },
    { label: 'Association', value: 'Association' }
];

export const AddClient = ({ open, setOpen, client, handleReload }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [addIndividualSubForm, setAddIndividualSubForm] = useState(false);

    const { isLoading } = useSelector((state) => state.global);
    const { data: ownerProfile } = useSelector((state) => state.businessOwnerGetProfile);
    const [selectedImage, setSelectedImage] = useState(null);

    const [selectedStructure, setSelectedStructure] = useState('Unit Trust');

    const [displayName, setDisplayName] = useState('');
    const [displayLastname, setDisplayLastName] = useState('');
    const [refNo, setRefNo] = useState('');
    const [inviteType, setInviteType] = useState('');
    const [profileImage, setProfileImage] = useState(null);

    const [partnerSteps, setPartnerSteps] = useState([
        {
            step: '1',
            step_heading: 'Primary Details'
        },
        {
            step: '2',
            step_heading: 'Partner Details'
        },
        {
            step: '3',
            step_heading: 'Related Entities'
        }
    ]);

    const [companySteps, setCompanySteps] = useState([
        {
            step: '1',
            step_heading: 'Primary Details'
        },
        {
            step: '2',
            step_heading: 'Director Details'
        },
        {
            step: '3',
            step_heading: 'Share Holder Detail'
        },
        {
            step: '4',
            step_heading: 'Public Officer Detail'
        },
        {
            step: '5',
            step_heading: 'Secretary Detail'
        },
        {
            step: '6',
            step_heading: 'Related Entities'
        }
    ]);

    const defaultTrustSteps = [
        {
            step: '1',
            step_i: '1',
            step_heading: 'Primary Details'
        },
        {
            step: '2',
            step_i: '2',
            step_heading: 'Trustee Details'
        },
        {
            step: '3',
            step_i: '3',
            step_heading: 'Settlor  Detail'
        },
        {
            step: '4',
            step_i: '4',
            step_heading: 'Appointer Detail'
        },
        {
            step: '5',
            step_i: '5',
            step_heading: selectedStructure === 'Unit Trust' ? 'Unit holder Detail' : 'Beneficiary Detail'
        },
        {
            step: '6',
            step_i: '6',
            step_heading: 'Related Entities'
        }
    ];
    const [trustSteps, setTrustSteps] = useState(defaultTrustSteps);

    const [smsfSteps, setSmsfSteps] = useState([
        {
            step: '1',
            step_heading: 'Primary Details'
        },

        {
            step: '2',
            step_heading: 'Trustee Details'
        },
        {
            step: '3',
            step_heading: 'Member Detail'
        },
        {
            step: '4',
            step_heading: 'Death Nomination Binding(s)'
        },
        {
            step: '5',
            step_heading: 'Related Entities'
        }
    ]);
    const [associationSteps, setAssociationSteps] = useState([
        {
            step: '1',
            step_heading: 'Primary Details'
        },
        {
            step: '2',
            step_heading: 'President Details'
        },
        {
            step: '3',
            step_heading: 'Treasurer Detail'
        },
        {
            step: '4',
            step_heading: 'Secretary Detail'
        },
        {
            step: '5',
            step_heading: 'Related Entities'
        }
    ]);

    useEffect(() => {
        if (inviteType === 'Trust') {
            let newSteps = [...defaultTrustSteps];
            newSteps[4].step_heading = selectedStructure === 'Unit Trust' ? 'Unit holder Detail' : 'Beneficiary Detail';
            if (selectedStructure === 'Unit Trust') {
                newSteps = newSteps
                    .filter((obj) => obj.step_heading !== 'Settlor  Detail' && obj.step_heading !== 'Appointer Detail')
                    .map((obj, i) => ({ ...obj, step: i + 1 }));
            }

            setTrustSteps(newSteps);
        }
    }, [selectedStructure, inviteType]);

    useEffect(() => {
        if (client) {
            setInviteType(client?.client_category);
            setRefNo(client?.client_ref_no);
            if (client.client_category === 'Individual') {
                setDisplayName(
                    client?.display_name ? client?.display_name : `${client?.first_name} ${client?.last_name}`
                );
            } else {
                setDisplayName(
                    client?.display_name ? client?.display_name : `${client?.first_name} ${client?.last_name}`
                );
            }
        }
    }, [client]);

    const findTrustStep = () => {
        if (currentStep > 1 && currentStep < 6) {
            return currentStep - 2;
        } else {
            return currentStep;
        }
    };

    const handleDisplayName = (e) => {
        let { value } = e.target;

        setDisplayName(value);
    };

    return (
        <div>
            <Slideover size="max-w-[1500px]" open={open} setOpen={setOpen}>
                <div className="add-detail px-4 text-black">
                    <div className="xl-title font-fira ">{client ? 'Edit' : 'Add'} Client</div>
                </div>

                {isLoading && <Loader />}
                <div
                    className={`py-5 pr-4 overflow-hidden ${
                        inviteType === 'Individual'
                            ? 'w-[100%] lg:w-[40%]'
                            : inviteType === 'Partnership' || inviteType === 'SMSF'
                            ? 'w-[100%] lg:w-[75%]'
                            : inviteType === 'Association'
                            ? 'w-[100%] lg:w-[85%]'
                            : !inviteType
                            ? 'w-[100%] lg:w-[40%]'
                            : 'w-[100%]'
                    }`}
                >
                    <div className="grid overflow-hidden max-w-[100%] px-1">
                        <ProgressBarComponent
                            steps={
                                inviteType === 'Partnership'
                                    ? partnerSteps
                                    : inviteType === 'Company'
                                    ? companySteps
                                    : inviteType === 'Trust'
                                    ? trustSteps
                                    : inviteType === 'SMSF'
                                    ? smsfSteps
                                    : inviteType === 'Association'
                                    ? associationSteps
                                    : [
                                          {
                                              step: '1',
                                              step_heading: 'Primary Details'
                                          },
                                          {
                                              step: '2',
                                              step_heading:
                                                  inviteType === 'Individual'
                                                      ? 'Related Entities'
                                                      : inviteType === 'Partnership'
                                                      ? 'Partner Details'
                                                      : inviteType === 'Company'
                                                      ? 'Director Details'
                                                      : inviteType === 'Trust'
                                                      ? 'Trustee Details'
                                                      : 'Client Details'
                                          }
                                      ]
                            }
                            targetProperty={'step'}
                            activeStep={
                                inviteType === 'Trust' && selectedStructure === 'Unit Trust'
                                    ? findTrustStep()
                                    : currentStep
                            }
                            isSubmitted={true}
                            is_creating={true}
                            hideArrows={true}
                            divWidthValue="230"
                        />
                    </div>
                </div>
                {currentStep < 1 && (
                    <div className="col-span-3 px-3 default-forms">
                        <div>
                            <label>Upload Image</label>
                        </div>
                        <div className="mt-5">
                            <ImageUploader
                                onImage={(image) => setProfileImage(image)}
                                selected={client ? client?.image : ''}
                                selectedImage={selectedImage}
                                setSelectedImage={setSelectedImage}
                            />
                        </div>
                    </div>
                )}

                <div className="px-3 pointer-inputs mt-5">
                    {!addIndividualSubForm && currentStep === 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 default-forms ">
                            <div>
                                <div>
                                    <label>Client Structure</label>
                                </div>

                                <SimpleSelectMenu
                                    placeholder="Client Structure"
                                    targetProperty="label"
                                    selectedValue={inviteType}
                                    valuePropertyName="value"
                                    disabled={
                                        client &&
                                        client?.client_category === CLIENT_CATEGORIES.INDIVIDUAL &&
                                        [PROSPECTIVE_CLIENT, ACTIVE_CLIENT].includes(client?.client_type)
                                            ? true
                                            : false
                                    }
                                    optionsData={optlst || []}
                                    sx="rounded-xl mt-2 py-3 ring-gray-400 bg-[#fff] !font-normal cursor-pointer"
                                    showChipOnly={false}
                                    onChangeValue={(obj) => {
                                        setInviteType(obj.value);
                                        if (obj?.value === 'Trust') {
                                            setSelectedStructure('Unit Trust');
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                <div>
                                    <label>Display Name</label>
                                </div>

                                <input
                                    type="text"
                                    onChange={(e) => handleDisplayName(e)}
                                    value={displayName}
                                    placeholder="Enter Display Name"
                                    required
                                    className={`mt-2 ${
                                        !client || ALL_ClIENT_TYPES.includes(client?.client_type)
                                            ? ''
                                            : 'bg-gray-200 !border-none !cursor-no-drop'
                                    }`}
                                    disabled={
                                        !client ? false : !ALL_ClIENT_TYPES.includes(client?.client_type) ? true : false
                                    }
                                    name="Display Name"
                                />
                            </div>
                            {client && (
                                <div>
                                    <div>
                                        {' '}
                                        <div>
                                            <label>Reference Number</label>
                                        </div>
                                        <input
                                            type="text"
                                            className="rounded-[3px] mt-2"
                                            value={refNo}
                                            onChange={(e) => setRefNo(e.target.value)}
                                            placeholder="Enter Ref No"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        ''
                    )}

                    <div>
                        <div>
                            {inviteType === 'Individual' ? (
                                <Individualinvite
                                    setOpen={setOpen}
                                    displayName={displayName}
                                    onDisplayName={(value) => setDisplayName(value)}
                                    onDisplayLastname={(value) => setDisplayLastName(value)}
                                    displayNameFirst={displayName}
                                    displayLastname={displayLastname}
                                    clientRefNo={refNo}
                                    client={client}
                                    handleReload={handleReload}
                                    ownerProfile={ownerProfile}
                                    addIndividualSubForm={addIndividualSubForm}
                                    setAddIndividualSubForm={setAddIndividualSubForm}
                                    setCurrentStep={setCurrentStep}
                                    profileImage={profileImage}
                                    currentStep={currentStep}
                                />
                            ) : inviteType === 'Partnership' ? (
                                <PartnershipInvite
                                    setOpen={setOpen}
                                    displayName={displayName}
                                    clientRefNo={refNo}
                                    client={client}
                                    handleReload={handleReload}
                                    ownerProfile={ownerProfile}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                    profileImage={profileImage}
                                />
                            ) : inviteType === 'Company' ? (
                                <CompanyInvite
                                    setOpen={setOpen}
                                    displayName={displayName}
                                    clientRefNo={refNo}
                                    client={client}
                                    handleReload={handleReload}
                                    ownerProfile={ownerProfile}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                    profileImage={profileImage}
                                />
                            ) : inviteType === 'Trust' ? (
                                <TrustInvite
                                    setOpen={setOpen}
                                    displayName={displayName}
                                    clientRefNo={refNo}
                                    client={client}
                                    handleReload={handleReload}
                                    ownerProfile={ownerProfile}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                    setSelectedStructure={setSelectedStructure}
                                    profileImage={profileImage}
                                />
                            ) : inviteType === 'SMSF' ? (
                                <SMSFInvite
                                    setOpen={setOpen}
                                    displayName={displayName}
                                    clientRefNo={refNo}
                                    client={client}
                                    handleReload={handleReload}
                                    ownerProfile={ownerProfile}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                    profileImage={profileImage}
                                />
                            ) : inviteType === 'Association' ? (
                                <AssociationInvite
                                    setOpen={setOpen}
                                    displayName={displayName}
                                    clientRefNo={refNo}
                                    client={client}
                                    handleReload={handleReload}
                                    ownerProfile={ownerProfile}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                    profileImage={profileImage}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </Slideover>
        </div>
    );
};
