import React, { _useMemo, useEffect } from 'react';
import SearchAddSelectCombobox from 'components/atoms/SearchAddSelectCombobox/SearchAddSelectCombobox';
import { BUSINESS_ID, restrictedStatusArray } from 'utills/globalVars';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { getAllJobsStatusesThunk } from 'store/workspace/workspaceTasks';
// import AddJobStatus from 'pages/settings/job-manager/job-manager-settings/AddJobStatus';

const JobsStatusFilter = ({ setFilters, filters, onRemove, JobsActiveTab = null }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const { data: jobStatuses } = useSelector((state) => state.getAllJobsStatuses);

    useEffect(() => {
        if (!jobStatuses) {
            fetchJobStatuses();
        }
    }, []);

    const fetchJobStatuses = () => {
        dispatch(setLoader(true));
        dispatch(getAllJobsStatusesThunk({ business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const filterStatusOptions = () => {
        if (['New Jobs', 'Recurring Jobs', 'Delayed Jobs'].includes(JobsActiveTab)) {
            return jobStatuses?.filter((item) => !restrictedStatusArray.includes(item?.label));
        }
        if (JobsActiveTab === 'Closed Jobs') {
            return jobStatuses?.filter((item) => restrictedStatusArray.includes(item?.label));
        }
        return jobStatuses;
    };

    return (
        <div>
            <SearchAddSelectCombobox
                options={jobStatuses ? (JobsActiveTab ? filterStatusOptions() : jobStatuses) : []}
                mainSx="!w-full !mx-0 !p-0"
                targetProperty="label"
                addStr="Job Status"
                onRemove={onRemove}
                onChange={(arr) => {
                    if (arr.length > 0) {
                        setFilters(arr);
                    } else {
                        setFilters([]);
                    }
                }}
                selectedItems={filters?.status?.length > 0 ? filters?.status : []} // Pass memoized selectedItems
                labelInside="Filter by Job Status"
                placeholder="Filter by Job Status"
                isMultiple={true}
                // childSliders={{ addItem: AddJobStatus }}
                extraChildProps={{
                    addSingle: true,
                    onComplete: () => {
                        fetchJobStatuses();
                    }
                }}
            />
        </div>
    );
};

export default JobsStatusFilter;
