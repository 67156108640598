import React, { useEffect, useState } from 'react';
import { EMAIL_LENGTH } from 'utills/constants/validation-constants/validation.constants';
import { generateRandomEmail } from 'utills/uid';

const EmailInput = ({
    value,
    onChange,
    disabled,
    required,
    placeholder,
    onError = () => {},
    classes,
    labelSx = '',
    label,
    showRandomEmail = false,
    maxLength = EMAIL_LENGTH
}) => {
    const [email, setEmail] = useState(value);
    const [isValid, setIsValid] = useState(true);
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        setEmail(value);
    }, [value]);

    const handleChange = (e) => {
        const email = e.target.value;
        setEmail(email);
        onChange(email);

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        // eslint-disable-next-line no-useless-escape
        const noSpecialCharsRegex = /^[^!#$%^&*()+={}\[\]:;"'<>,?/|~`]+$/;

        setIsValid(email === '' || (emailRegex.test(email) && noSpecialCharsRegex.test(email)));
    };

    const handleBlur = () => {
        setTouched(true);
    };

    useEffect(() => {
        if (email !== '') {
            onError(!isValid);
        } else {
            onError(false);
        }
    }, [isValid, email, onError]);

    return (
        <div className={!label ? 'mb-4' : ''}>
            <label className={labelSx ?? ''}>{label}</label>

            <input
                type="email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                required={required}
                maxLength={maxLength}
                className={`w-full px-3 py-2 border ${
                    disabled && '!bg-gray-200 !border-none !cursor-no-drop '
                } rounded focus:outline-none !ring-0 !border !border-secondarybg ${classes ? classes : ''}  
                ${!isValid && touched && email ? '!border !border-red-500 add-error-border' : 'border-gray-300'}`}
                placeholder={placeholder ?? 'Enter your email'}
            />
            {showRandomEmail && isValid && !email && (
                <span
                    className="pl-1 text-xs italic text-gray-600 cursor-pointer"
                    onClick={() =>
                        handleChange({
                            target: {
                                value: generateRandomEmail()
                            }
                        })
                    }
                >
                    Generate Random Email
                </span>
            )}
            {!isValid && touched && email && (
                <p className="mt-2 text-sm text-red-500">Please enter a valid email address.</p>
            )}
        </div>
    );
};

export default EmailInput;
