import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { request: createTemplateDocThunk } = generateThunkAndSlice(
    'createTemplateDoc',
    endpoint.createTemplateDoc,
    methods.POST
);
const { slice: getTemplateDoc, request: getTemplateDocThunk } = generateThunkAndSlice(
    'getTemplateDoc',
    endpoint.getTemplateDoc,
    methods.GET
);
const { request: deleteTemplateDocThunk } = generateThunkAndSlice(
    'deleteTemplateDoc',
    endpoint.deleteTemplateDoc,
    methods.DELETE
);

const { request: updateTemplateDocThunk } = generateThunkAndSlice(
    'updateTemplateDoc',
    endpoint.updateTemplateDoc,
    methods.PUT
);
const { request: bulkDeleteTemplateDocThunk } = generateThunkAndSlice(
    'bulkDeleteTemplateDoc',
    endpoint.bulkDeleteTemplateDoc,
    methods.DELETE
);

const { slice: getTemplateTask, request: getTemplateTaskThunk } = generateThunkAndSlice(
    'getTemplateTask',
    endpoint.getTemplateTask,
    methods.GET
);

const { slice: getTemplateTaskById, request: getTemplateTaskByIdThunk } = generateThunkAndSlice(
    'getTemplateTaskById',
    endpoint.getTemplateTaskById,
    methods.GET
);

const { request: createTemplateTaskThunk } = generateThunkAndSlice(
    'createTemplateTask',
    endpoint.createTemplateTask,
    methods.POST
);

const { request: deleteTemplateTaskThunk } = generateThunkAndSlice(
    'deleteTemplateTask',
    endpoint.deleteTemplateTask,
    methods.DELETE
);

const { request: updateTemplateTaskThunk } = generateThunkAndSlice(
    'updateTemplateTask',
    endpoint.updateTemplateTask,
    methods.PUT
);

const { request: addAttachmentTemplateTaskThunk } = generateThunkAndSlice(
    'addAttachmentTemplateTask',
    endpoint.addAttachmentTemplateTask,
    methods.PUT
);

const { request: removeAttachmentTemplateTaskThunk } = generateThunkAndSlice(
    'removeAttachmentTemplateTask',
    endpoint.removeAttachmentTemplateTask,
    methods.DELETE
);

const { request: removeChecklistTemplateTaskThunk } = generateThunkAndSlice(
    'removeChecklistTemplateTask',
    endpoint.removeChecklistTemplateTask,
    methods.DELETE
);

const { request: createChecklistTemplateTaskThunk } = generateThunkAndSlice(
    'createChecklistTemplateTask',
    endpoint.createChecklistTemplateTask,
    methods.POST
);

const { request: createChecklistItemTemplateTaskThunk } = generateThunkAndSlice(
    'createChecklistItemTemplateTask',
    endpoint.createChecklistItemTemplateTask,
    methods.POST
);

/// User Email Signature

const { request: createUserEmailSignatureThunk } = generateThunkAndSlice(
    'createUserEmailSignature',
    endpoint.createUserEmailSignature,
    methods.POST
);
const { slice: getUserEmailSignature, request: getUserEmailSignatureThunk } = generateThunkAndSlice(
    'getUserEmailSignature',
    endpoint.getUserEmailSignature,
    methods.GET
);
const { request: updateUserEmailSignatureThunk } = generateThunkAndSlice(
    'updateUserEmailSignature',
    endpoint.updateUserEmailSignature,
    methods.PUT
);
const { request: removeUserEmailSignatureThunk } = generateThunkAndSlice(
    'removeUserEmailSignature',
    endpoint.removeUserEmailSignature,
    methods.DELETE
);

const { slice: getDefaultSignature, request: getDefaultSignatureThunk } = generateThunkAndSlice(
    'getDefaultSignature',
    endpoint.getDefaultSignature,
    methods.GET
);
const { request: setDefaultSignatureThunk } = generateThunkAndSlice(
    'setDefaultSignature',
    endpoint.setDefaultSignature,
    methods.PUT
);
const { request: duplicateJobThunk } = generateThunkAndSlice('duplicateJob', endpoint.duplicateJob, methods.POST);

export {
    createTemplateDocThunk,
    getTemplateDoc,
    getTemplateDocThunk,
    deleteTemplateDocThunk,
    updateTemplateDocThunk,
    bulkDeleteTemplateDocThunk,
    getTemplateTask,
    getTemplateTaskThunk,
    createTemplateTaskThunk,
    deleteTemplateTaskThunk,
    updateTemplateTaskThunk,
    addAttachmentTemplateTaskThunk,
    removeAttachmentTemplateTaskThunk,
    removeChecklistTemplateTaskThunk,
    createChecklistTemplateTaskThunk,
    createChecklistItemTemplateTaskThunk,
    getTemplateTaskById,
    getTemplateTaskByIdThunk,
    createUserEmailSignatureThunk,
    getUserEmailSignature,
    getUserEmailSignatureThunk,
    updateUserEmailSignatureThunk,
    removeUserEmailSignatureThunk,
    getDefaultSignature,
    getDefaultSignatureThunk,
    setDefaultSignatureThunk,
    duplicateJobThunk
};
