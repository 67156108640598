import React from 'react';

export const PngFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#949BA5" />
            <path
                d="M7.55469 19.25V22H4.72656V10.7969H10.5469L12.0547 12.125V17.9219L10.5469 19.25H7.55469ZM7.55469 13.6328V16.4141H9.20312V13.6328H7.55469ZM16.0859 17.1719V22H13.3359V10.7969H16.0703L17.9141 15.3906V10.7969H20.6641V21.9844H17.9766L16.0859 17.1719ZM27.7656 22H23.4297L21.9453 20.6875V12.125L23.4297 10.7969H27.7656L29.2734 12.125V14.4297H26.5547V13.5078H24.7734V19.2656H26.5547V18.0625H25.6094V15.7109H29.2734V20.6875L27.7656 22Z"
                fill="white"
            />
        </svg>
    );
};
