import React from 'react';
import { Loader } from 'components/common/Loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader, setSideLoader } from 'store/global/globalReducer';
import {
    businessEmployeeArchiveThunk,
    changeEmpStatusThunk,
    fetchRegisteredEmployeesThunk
} from 'store/settings/team/team';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { EyeIcon } from 'utills/svgs/EyeIcon';
import ConfirmationModal from 'utills/confirmationModal';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_ERROR } from 'utills/globalVars';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { LockClosedIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import useRoleGuard from 'Hooks/useRoleGuard';
import { cn } from 'utills/tailwindUtil';
import ListView from 'utills/svgs/ListView';
import KanbanView from 'utills/svgs/KanbanView';
import { GridProfiles } from 'components/molecules/grids/GridProfiles';
import { TransferData } from './transfer-data/TransferData';
import DefaultSwitch from 'components/atoms/inputs/DefaultSwitch';
const RegisteredTeam = ({
    handleMember,
    handleEditMember,
    resendPasseord,
    registeredUpdated,
    searchTerm,
    teamType
}) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.global);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [business_employee_id, setBusinessEmployeeId] = useState(false);
    const [user_id, setUserId] = useState('');
    const [team, setTeam] = useState([]);
    const [transfer, setTrasfer] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const { hasPermissions } = useRoleGuard();
    const [dataView, setDataView] = useState('list');

    const viewMember = (business_employee_id) => {
        handleMember(business_employee_id);
    };

    const _handleDelete = (target, emp) => {
        // setIsConfirmationOpen(false);
        dispatch(setSideLoader(true));
        dispatch(
            businessEmployeeArchiveThunk({
                business_employee_id: emp?.business_employee?.business_employee_id,
                from_employee_id: emp?.user_id,
                to_employee_id: target?.user_id
            })
        )
            .then((response) => {
                if (response.payload) {
                    // const newTeam = team?.filter(
                    //     (member) => member.business_employee?.business_employee_id !== business_employee_id
                    // );
                    // setTeam(newTeam);
                    getEmps();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setSideLoader(false);
            });
    };
    const _deleteUser = (business_employee_id) => {
        if (hasPermissions('Team', ['admin'])) {
            // setIsConfirmationOpen(true);
            setBusinessEmployeeId(business_employee_id);
        } else {
            toastHandler('You dont have permission to perform this action', ERROR_TYPE_ERROR);
        }
    };

    const getEmps = () => {
        dispatch(setLoader(true));
        dispatch(fetchRegisteredEmployeesThunk({ status: teamType === 'inactive' ? 'inactive' : 'active' }))
            .then((response) => {
                if (response.payload) {
                    setTeam(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        getEmps();
    }, [registeredUpdated, teamType]);

    const filteredTeam = team?.filter((member) => {
        return (
            member?.first_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            member?.last_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            member?.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            member?.phone_number?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            member?.business_group_name?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
    });

    const handleResendPass = (userid) => {
        if (hasPermissions('Team', ['admin'])) {
            setBusinessEmployeeId(false);
            setUserId(userid);
            setIsConfirmationOpen(true);
        } else {
            toastHandler('You dont have permission to perform this action', ERROR_TYPE_ERROR);
        }
    };

    const moveToActive = () => {
        setIsConfirmationOpen(false);
        const payload = {
            status: 'active',
            business_employee_id: business_employee_id
        };

        dispatch(changeEmpStatusThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setBusinessEmployeeId(null);

                    getEmps();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    return (
        <div className="md:px-10 mt-10 px-5 relative ">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                title={user_id && !business_employee_id ? 'Resend Password' : 'Confirmation'}
                description={
                    user_id && !business_employee_id
                        ? 'Are you sure you want to resend the password'
                        : 'Are you sure you want to reactivate this employee'
                }
                onConfirm={() => {
                    if (user_id && !business_employee_id) {
                        setIsConfirmationOpen(false);
                        setUserId('');
                        resendPasseord(user_id);
                    } else {
                        moveToActive();
                    }
                }}
                customIcon={<QuestionMarkCircleIcon className="w-12 h-12 " />}
            />
            {isLoading && <Loader />}

            {transfer && (
                <TransferData
                    open={transfer}
                    setOpen={setTrasfer}
                    employee={selectedEmployee}
                    onConfirm={() => getEmps()}
                />
            )}

            <div className="flex justify-end items-center">
                {' '}
                <span className="isolate inline-flex rounded-lg shadow-sm">
                    <button
                        type="button"
                        className={cn(
                            'relative inline-flex items-center rounded-l-lg  px-4 py-3 h-[3rem] text-sm font-semibold text-gray-900 ring-1 ring-inset ring-thirdbg focus:z-10',
                            dataView === 'list' ? 'bg-thirdbg  !text-white' : 'bg-white !text-gray-800'
                        )}
                        onClick={() => setDataView('list')}
                    >
                        <ListView className={cn('w-6 h-5', dataView === 'list' ? '!fill-white' : '!fill-black')} />
                    </button>
                    <button
                        type="button"
                        className={cn(
                            'relative -ml-px inline-flex items-center rounded-r-lg bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-thirdbg focus:z-10',
                            dataView === 'kanban' ? 'bg-thirdbg  !text-white' : 'bg-white !text-gray-800'
                        )}
                        onClick={() => setDataView('kanban')}
                    >
                        <KanbanView
                            className={cn('w-6 h-5', dataView === 'kanban' ? 'stroke-white' : 'stroke-gray-600')}
                        />
                    </button>
                </span>
            </div>

            {dataView === 'list' ? (
                <div className="">
                    <div className="px-4 sm:px-6 rounded-lg bg-white lg:px-8">
                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-0"
                                                >
                                                    Employee
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Contact No
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Role Group
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {team &&
                                                filteredTeam?.map((person) => (
                                                    <tr key={person?.email}>
                                                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-md sm:pl-0">
                                                            <div className="flex items-center">
                                                                <div>
                                                                    {' '}
                                                                    <DefaultProfileViewer
                                                                        image={person?.image}
                                                                        width="50px"
                                                                        height="50px"
                                                                        name={
                                                                            person?.first_name + ' ' + person?.last_name
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="ml-4">
                                                                    <div className="font-medium text-gray-900">
                                                                        {person?.first_name} {person?.last_name}
                                                                    </div>
                                                                    <div className="mt-1 text-gray-500">
                                                                        {person?.email}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                            <div className="text-gray-900">{person?.phone_number}</div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                            <span className="inline-flex items-center rounded-md bg-thirdbg/10 px-2 py-1 text-xs font-medium text-thirdbg ring-1 ring-inset ">
                                                                {person.business_group_name || '--'}
                                                            </span>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                            <div className="flex-1 flex gap-2 items-center ">
                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() =>
                                                                        viewMember(
                                                                            person?.business_employee
                                                                                ?.business_employee_id
                                                                        )
                                                                    }
                                                                >
                                                                    <EyeIcon />
                                                                </div>

                                                                {person.business_group_name &&
                                                                    teamType === 'inactive' && (
                                                                        <DefaultSwitch
                                                                            checked={false}
                                                                            onChange={
                                                                                (_e) => {
                                                                                    setIsConfirmationOpen(true);
                                                                                    setBusinessEmployeeId(
                                                                                        person?.business_employee
                                                                                            ?.business_employee_id
                                                                                    );
                                                                                    setUserId(null);
                                                                                }
                                                                                // moveToActive(
                                                                                //     person?.business_employee
                                                                                //         ?.business_employee_id
                                                                                // )
                                                                            }
                                                                        />
                                                                    )}
                                                                {person?.business_group_name &&
                                                                    teamType !== 'inactive' && (
                                                                        <div
                                                                            className="cursor-pointer"
                                                                            onClick={() =>
                                                                                handleEditMember(
                                                                                    person?.business_employee
                                                                                        ?.business_employee_id
                                                                                )
                                                                            }
                                                                        >
                                                                            <BgEditIcon />
                                                                        </div>
                                                                    )}
                                                                {person?.business_group_name &&
                                                                    teamType !== 'inactive' && (
                                                                        <div
                                                                            className="cursor-pointer"
                                                                            onClick={() =>
                                                                                handleResendPass(person?.user_id)
                                                                            }
                                                                        >
                                                                            <LockClosedIcon className="w-5 h-5 text-black" />
                                                                        </div>
                                                                    )}
                                                                {person?.business_group_name &&
                                                                    teamType !== 'inactive' && (
                                                                        <div
                                                                            className="cursor-pointer"
                                                                            onClick={() =>
                                                                                // deleteUser(
                                                                                //     person?.business_employee
                                                                                //         ?.business_employee_id
                                                                                // )
                                                                                {
                                                                                    setTrasfer(!transfer);
                                                                                    setSelectedEmployee(person);
                                                                                }
                                                                            }
                                                                        >
                                                                            <BgDeleteIcon />
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="my-10">
                    <GridProfiles users={filteredTeam} onClick={(emp, id) => viewMember(emp, id)} />
                </div>
            )}
        </div>
    );
};
export default RegisteredTeam;
