import React from 'react';

export const DocFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.2222 0H3.77778C1.7 0 0 1.7 0 3.77778V30.2222C0 32.3 1.7 34 3.77778 34H30.2222C32.3 34 34 32.3 34 30.2222V3.77778C34 1.7 32.3 0 30.2222 0ZM12.2778 16.0556C12.2778 17.5667 10.9556 18.8889 9.44444 18.8889H7.55556V22.6667H4.72222V11.3333H9.44444C10.9556 11.3333 12.2778 12.6556 12.2778 14.1667V16.0556ZM21.7222 19.8333C21.7222 21.3444 20.4 22.6667 18.8889 22.6667H14.1667V11.3333H18.8889C20.4 11.3333 21.7222 12.6556 21.7222 14.1667V19.8333ZM29.2778 14.1667H26.4444V16.0556H29.2778V18.8889H26.4444V22.6667H23.6111V11.3333H29.2778V14.1667ZM17 14.1667H18.8889V19.8333H17V14.1667ZM7.55556 14.1667H9.44444V16.0556H7.55556V14.1667Z"
                fill="#198DCF"
            />
            <rect x="4" y="9" width="26" height="16" rx="4" fill="#198DCF" />
            <path
                d="M11.9688 21.6875L10.4609 23H4.64062V11.7969H10.4609L11.9688 13.125V21.6875ZM7.46875 20.1719H9.11719V14.6328H7.46875V20.1719ZM18.9103 23H14.5744L13.09 21.6875V13.125L14.5744 11.7969H18.9103L20.4181 13.125V21.6875L18.9103 23ZM15.9181 20.2656H17.5822V14.5078H15.9181V20.2656ZM27.3597 23H23.0238L21.5394 21.6875V13.125L23.0238 11.7969H27.3597L28.8675 13.125V15.9609H26.0159V14.6328H24.3675V20.1719H26.0159V18.8047H28.8675V21.6875L27.3597 23Z"
                fill="white"
            />
        </svg>
    );
};
