import React from 'react';
import { CallBurger } from 'utills/svgs/CallBurger';
import './css/team.css';
import { SearchInput } from 'components/molecules/SearchInput';
import { MultiSwitch } from 'components/atoms/multi-switch/MultiSwitch';

const TeamOperations = ({ toggleAddTeam, searchByname, setTeam, team }) => {
    return (
        <div>
            <div className="md:px-10 px-5 mt-3 relative">
                <div className="flex justify-between flex-wrap items-center gap-5">
                    <SearchInput atChange={(e) => searchByname(e.target.value)} width="300px" placeholder="Search" />

                    <div>
                        <div className="w-[450px]">
                            <MultiSwitch
                                items={[
                                    { title: 'Invited', selected: team === 'invited' },
                                    { title: 'Registered', selected: team === 'registered' },
                                    { title: 'Inactive', selected: team === 'inactive' }
                                ]}
                                onClick={(value) => {
                                    setTeam(value.toLowerCase());
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <div
                            onClick={toggleAddTeam}
                            className=" ann-btn rounded-xl  !text-[15px] md:text-[17px] lg:text-[17px] xl:text-[18px] font-[400] font-fira px-7 cursor-pointer flex gap-2 items-center "
                        >
                            <div>
                                <CallBurger />
                            </div>
                            <div>Invite Team Member</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamOperations;
