import React from 'react';

export const MdbFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#B695F8" />
            <path
                d="M6.3125 15.8438V22H3.80469V10.7969H7.65625L8.89062 15.8438L10.0469 10.7969H13.9766V22H11.4688V15.8438L9.96094 22H7.83594L6.3125 15.8438ZM22.5859 20.6875L21.0781 22H15.2578V10.7969H21.0781L22.5859 12.125V20.6875ZM18.0859 13.6328V19.1719H19.7344V13.6328H18.0859ZM31.1953 20.6875L29.6875 22H23.8672V10.7969H29.6875L31.1953 12.125V15.7109L30.4453 16.3828L31.1953 17.0391V20.6875ZM26.6953 17.8047V19.6797H28.3438V17.8047H26.6953ZM26.6953 13.0859V14.9609H28.3438V13.0859H26.6953Z"
                fill="white"
            />
        </svg>
    );
};
