import React from 'react';

export const JPEGFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#9D7980" />
            <path
                d="M10.5469 22H6.21094L4.72656 20.6875V17.8047H7.55469V19.1719H9.20312V10.7969H12.0547V20.6875L10.5469 22ZM16.1641 19.25V22H13.3359V10.7969H19.1562L20.6641 12.125V17.9219L19.1562 19.25H16.1641ZM16.1641 13.6328V16.4141H17.8125V13.6328H16.1641ZM27.7656 22H23.4297L21.9453 20.6875V12.125L23.4297 10.7969H27.7656L29.2734 12.125V14.4297H26.5547V13.5078H24.7734V19.2656H26.5547V18.0625H25.6094V15.7109H29.2734V20.6875L27.7656 22Z"
                fill="white"
            />
        </svg>
    );
};
