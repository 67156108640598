import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import EmailInput from 'components/atoms/Input/EmailInput';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { InputField } from 'components/atoms/inputs/InputField';
import { TFNInputField } from 'components/atoms/inputs/TFNInputField';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import dayjs from 'dayjs';
import { PickAddress } from 'global-components/PickAddress';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { searchClientsThunk } from 'store/client';
import { validatePhoneNumber } from 'utills/baseValidations';
import { validateEmailValue, validateIndividualsObjects, validateTFN } from 'utills/dataValidation';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { dateMinus12Years } from 'utills/moment';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { NewPlusIcon } from 'utills/svgs/NewPlusIcon';

const TreasurerDetailSubForm = ({ setCurrentStep, treasurerList, setTreasurerList, defaultTreasurerObject }) => {
    const dispatch = useDispatch();

    const handleRemoveEntry = (index) => {
        const newInputs = [...treasurerList];
        newInputs.splice(index, 1);
        setTreasurerList(newInputs);
    };
    const handleAddEntry = () => {
        setTreasurerList([...treasurerList, defaultTreasurerObject]);
    };

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleEntityValue = (value, targetProperty, index) => {
        setTreasurerList((pre) => {
            return pre.map((prevObj, preInd) => {
                return {
                    ...prevObj,
                    [targetProperty]: preInd === index ? value : prevObj?.[targetProperty]
                };
            });
        });
    };

    const handleComboxboxEntity = (obj = null, index, isFirstLoad = false) => {
        setTreasurerList((pre) => {
            return pre.map((prevObj, preInd) => {
                if (preInd === index) {
                    if (isFirstLoad) {
                        if (!obj) {
                            return {
                                ...prevObj,
                                isFirstLoad: false,
                                disabled: {
                                    first_name: false,
                                    last_name: false,
                                    tfn: false,
                                    phone_number: false,
                                    email_address: false,
                                    dob: false,
                                    treasurer_status: false,
                                    current_address: false
                                }
                            };
                        }

                        return {
                            ...prevObj,
                            isFirstLoad: false
                        };
                    }

                    if (obj?.business_client_id === prevObj?.business_client_id) {
                        return {
                            ...prevObj
                        };
                    }
                    return {
                        ...prevObj,
                        business_client_id: obj ? obj?.business_client_id : '',
                        first_name: obj ? obj?.first_name : '',
                        last_name: obj ? obj?.last_name : '',
                        tfn: obj ? obj?.tfn : '',
                        phone_number: obj ? obj?.phone_number : '',
                        email_address: obj ? obj?.email : '',
                        dob: obj ? obj?.date_of_birth : '',
                        treasurer_status: 'Inactive',
                        current_address: obj ? obj?.residential_address : '',
                        disabled: {
                            first_name: obj && obj?.first_name && obj?.first_name !== '' ? true : false,
                            last_name: obj && obj?.last_name && obj?.last_name !== '' ? true : false,
                            tfn: obj && obj?.tfn && obj?.tfn !== '' ? true : false,
                            phone_number: obj && obj?.phone_number && obj?.phone_number !== '' ? true : false,
                            email_address: obj && obj?.email && obj?.email !== '' ? true : false,
                            dob: obj && obj?.date_of_birth && obj?.date_of_birth !== '' ? true : false,
                            treasurer_status:
                                obj && obj?.treasurer_status && obj?.treasurer_status !== '' ? true : false,
                            current_address:
                                obj && obj?.residential_address && obj?.residential_address !== '' ? true : false
                        }
                    };
                }
                return prevObj;
            });
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let hasError = false;
        const emailSet = new Set();

        for (const [index, tresure] of treasurerList.entries()) {
            const validationErrors = validateIndividualsObjects(tresure);
            if (validationErrors?.length > 0) {
                validationErrors.forEach((error) => {
                    toastHandler(error + ' at ' + (index + 1), TOAST_TYPE_ERROR);
                });
                hasError = true;
                break;
            }

            if (tresure?.email_address) {
                if (!validateEmailValue(tresure?.email_address)) {
                    hasError = true;
                    toastHandler(`Invalid Email at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                }
            }

            if (tresure?.email_address) {
                if (emailSet.has(tresure.email_address)) {
                    hasError = true;
                    toastHandler(`Duplicate email found at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                } else {
                    emailSet.add(tresure.email_address);
                }
            }

            if (tresure?.phone_number?.length > 4) {
                if (!validatePhoneNumber(tresure?.phone_number)) {
                    hasError = true;
                    break;
                }
            }

            if (tresure?.tfn) {
                if (!validateTFN(tresure?.tfn)) {
                    hasError = true;
                    toastHandler(`Invalid TFN at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                }
            }
        }

        if (hasError) {
            return;
        }

        setCurrentStep(3);
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="table-title font-poppins font-semibold">Treasurer Details</div>
                <div onClick={handleAddEntry} className="cursor-pointer">
                    <NewPlusIcon className="w-8 h-8" />
                </div>
            </div>

            <div>
                <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                >
                    {treasurerList?.map((treasurerObj, treasurerObjIndex) => (
                        <div className=" !bg-dimThird p-5  rounded-xl mt-5  " key={'treasurerList' + treasurerObjIndex}>
                            <div
                                onClick={() => handleRemoveEntry(treasurerObjIndex)}
                                className="cursor-pointer w-fit ml-auto flex justify-end items-center"
                            >
                                <label className="invisible"> f</label>
                                <BgDeleteIcon />
                            </div>

                            <div className=" default-forms grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-5  rounded-xl ">
                                <div className="">
                                    <label className="inputLabel">Treasurer</label>

                                    <ComboboxComponentSSR
                                        fetchDataFunction={(payload) =>
                                            fetchReferencesListFun({
                                                ...payload,
                                                type: 'individual'
                                            })
                                        }
                                        defaultSelected={treasurerObj?.business_client_id}
                                        removePreviousSelected
                                        onRemove={(_e) => {
                                            handleComboxboxEntity(null, treasurerObjIndex);
                                        }}
                                        filterSelectedValues={treasurerList || []}
                                        filterSelectedValueTargetElement={'business_client_id'}
                                        onChange={(res) => {
                                            const obj = res[0];
                                            handleComboxboxEntity(obj, treasurerObjIndex, treasurerObj?.isFirstLoad);
                                        }}
                                        targetProperty="business_client_id"
                                        placeholder={'Search by Name/Reference No.'}
                                        limit={1}
                                        sx="rounded-xl ring-gray-400 "
                                        showNameOnly
                                    />
                                </div>

                                <div className="">
                                    <InputField
                                        label={'First Name'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'first_name', treasurerObjIndex);
                                        }}
                                        name={'First Name'}
                                        inputValue={treasurerObj.first_name}
                                        placeholder={'First Name'}
                                        classes="mt-2 bg-transparent"
                                        disabled={treasurerObj?.disabled?.first_name}
                                    />
                                </div>
                                <div className="">
                                    <InputField
                                        label={'Last Name'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'last_name', treasurerObjIndex);
                                        }}
                                        name={'Last Name'}
                                        inputValue={treasurerObj.last_name}
                                        placeholder={'Last Name'}
                                        classes="mt-2 bg-transparent"
                                        disabled={treasurerObj?.disabled?.last_name}
                                    />
                                </div>
                                <div className="">
                                    <TFNInputField
                                        label={'TFN'}
                                        maxLength={9}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'tfn', treasurerObjIndex);
                                        }}
                                        name={'TFN'}
                                        inputValue={treasurerObj.tfn}
                                        placeholder={'TFN'}
                                        classes="mt-2 bg-transparent"
                                        disabled={treasurerObj?.disabled?.tfn}
                                    />
                                </div>
                                <div className="">
                                    <PhoneInputComponent
                                        value={treasurerObj.phone_number}
                                        title="Phone Number"
                                        placeholder="Enter Phone Number"
                                        titleSx=""
                                        sx="!rounded-xl bg-transparent"
                                        bg={'!bg-transparent'}
                                        atChange={(phone) => {
                                            handleEntityValue(phone, 'phone_number', treasurerObjIndex);
                                        }}
                                        isDisabled={treasurerObj?.disabled?.phone_number}
                                    />
                                </div>
                                <div className="">
                                    <EmailInput
                                        label={'Email Address'}
                                        onChange={(value) => {
                                            handleEntityValue(value, 'email_address', treasurerObjIndex);
                                        }}
                                        value={treasurerObj.email_address}
                                        placeholder="Email Address"
                                        classes="mt-2 bg-transparent"
                                        disabled={treasurerObj?.disabled?.email_address}
                                    />
                                </div>
                                <div className="">
                                    <div>
                                        <label>Date of Birth</label>
                                    </div>
                                    <DatePick
                                        maxDate={dayjs(dateMinus12Years, 'DD-MM-YYYY')}
                                        iconSet={{
                                            right: CalendarSmallIco
                                        }}
                                        onDateChange={(date) => {
                                            handleEntityValue(date, 'dob', treasurerObjIndex);
                                        }}
                                        placeholder="DD-MM-YYYY"
                                        format="DD-MM-YYYY"
                                        value={treasurerObj?.dob ? dayjs(treasurerObj.dob, 'DD-MM-YYYY') : ''}
                                        sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full hover:bg-[#FAF9F6] !bg-transparent"
                                        isDisabled={treasurerObj?.disabled?.dob}
                                    />
                                </div>

                                <div className="">
                                    <PickAddress
                                        id={'residential-autocomplete' + treasurerObjIndex}
                                        address={treasurerObj?.current_address}
                                        setAddress={(address) => {
                                            handleEntityValue(address, 'current_address', treasurerObjIndex);
                                        }}
                                        onSelect={(place) => {
                                            handleEntityValue(
                                                place.formatted_address,
                                                'current_address',
                                                treasurerObjIndex
                                            );
                                        }}
                                        label="Physical Address"
                                        placeholder="Physical Address"
                                        disabled={treasurerObj?.disabled?.current_address}
                                        classes="bg-transparent"
                                    />
                                </div>

                                <div className="">
                                    <div className="">
                                        <label className="inputLabel">Treasurer Status</label>
                                        <DefaultDoubleSwitch
                                            items={[
                                                {
                                                    title: 'Active',
                                                    selected: treasurerObj?.treasurer_status === 'Active'
                                                },
                                                {
                                                    title: 'Inactive',
                                                    selected: treasurerObj?.treasurer_status === 'Inactive'
                                                }
                                            ]}
                                            onClick={(value) => {
                                                if (value) {
                                                    handleEntityValue(value, 'treasurer_status', treasurerObjIndex);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="flex justify-center mt-24 items-center">
                        <div className="flex gap-8 items-center">
                            <Button
                                title="Back"
                                classes={
                                    'border-blueish text-blueish border-2 h-[3rem] rounded-lg buttons-font-lighter font-fira px-14'
                                }
                                atClick={() => setCurrentStep(1)}
                                type="button"
                            />

                            <Button
                                title={'Next'}
                                classes={'ann-btn rounded-lg px-14 buttons-font-lighter font-fira'}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TreasurerDetailSubForm;
