/* eslint-disable no-prototype-builtins */
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { Button } from 'components/atoms/buttons/Button';
import { AccountNumberInput } from 'components/atoms/inputs/AccountNumberInput';
import { BsbInput } from 'components/atoms/inputs/BsbInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { InputField } from 'components/atoms/inputs/InputField';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import { CheckboxChip } from 'components/molecules/CheckboxChip';
import { PickAddress } from 'global-components/PickAddress';
import isValidABN from 'is-valid-abn';
import React, { useEffect, useState } from 'react';
import { toastHandler } from 'responseHanlder';
import { ALL_ClIENT_TYPES, BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS, USER_TYPE } from 'utills/globalVars';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import ComboboxComponent from 'components/atoms/Combobox';
import dayjs from 'dayjs';
import EmailInput from 'components/atoms/Input/EmailInput';
import { filterEmptyObject, formatDobToString, validateEmailValue, validateTFN } from 'utills/dataValidation';
import PresidentDetailSubForm from './sub-forms/association-steps/PresidentDetailSubForm';
import TreasurerDetailSubForm from './sub-forms/association-steps/TreasurerDetailSubForm';
import SecretaryDetailSubForm from './sub-forms/association-steps/SecretaryDetailSubForm';
import {
    addManualClientThunk,
    checkTfnAbnAcnThunk,
    updateClientProfileImageThunk,
    updateManualClientThunk
} from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import SearchableComboboxSSR from 'components/molecules/comboboxes/SearchableComboboxSSR';
import { getformsfieldsOptionsThunk } from 'store/form';
import TradingNames from './common/TradingNames';
import { base64ToBlob } from 'utills/filesHandlers';
import { validateAbn, validateBSB, validatePhoneNumber } from 'utills/baseValidations';
import { generateId } from 'utills/uid';
import { RelatedEntities } from './common/RelatedEntities';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import DefaultHint from 'components/atoms/DefaultHint';
import { STATE_TERRITORY } from './constants/addClient.constants';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';

export const AssociationInvite = ({
    setOpen,

    displayName,
    client,
    clientRefNo,
    handleReload,
    ownerProfile,
    currentStep,
    setCurrentStep,
    profileImage
}) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);

    const [addClientFormObj, setAddClientFormObj] = useState(null);

    const [phoneNo, setPhoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [postalAddress, setPostalAddress] = useState('');
    const [sameAddress, setSameAddress] = useState(false);

    const [accountHolderName, setAccountHolderName] = useState('');
    const [bsb, setBsb] = useState('');
    const [accountNumber, setAccountNumber] = useState('');

    const [abn, setAbn] = useState('');
    const [abnError, setAbnError] = useState(false);

    const [tfn, setTfn] = useState('');
    const [tfnError, setTfnError] = useState(false);

    const [weblink, setWeblink] = useState('');

    const [accountant, setAccountant] = useState('');
    const [registeredGst, setRegisteredGst] = useState('No');
    const [principleBusinessActivity, setPrincipleBusinessActivity] = useState('');

    const [activityStatementDate, setActivityStatementDate] = useState('');
    const [textFormDate, setTextFormDate] = useState('');

    const [retriveFromAto, setRetriveFromAto] = useState('Monthly');
    const [refund, setRefund] = useState('');
    const [clientAtoStatus, setClientAtoStatus] = useState('Active');

    const [clientVerified, setClientVerified] = useState('No');
    const [verificationMethod, setVerificationMethod] = useState('Document Check');

    const [dateVerified, setDateVerified] = useState('');
    const [verifiedBy, setVerifiedBy] = useState('');
    const [verifiedNotes, setVerifiedNotes] = useState('');
    const [tradings, setTradings] = useState([{ value: '' }]);

    const [ethicalClearanceStatus, setEthicalClearanceStatus] = useState(null);
    const [formerFirmName, setFormerFirmName] = useState(null);
    const [formerAccountentName, setFormerAccountentName] = useState(null);
    const [formerAccountentEmail, setFormerAccountentEmail] = useState(null);

    const [stateOrTerritory, setStateOrTerritory] = useState('');

    const [objective, setObjective] = useState('');

    const ethicalClearanceStatusArray = [
        { label: 'Pending', value: 'clearance pending' },
        { label: 'Granted', value: 'clearance granted' },
        { label: 'Rejected', value: 'clearance rejected' }
    ];

    const defaultPresidentDetails = {
        business_client_id: '',
        bcr_id: '',
        first_name: '',
        last_name: '',
        tfn: '',
        phone_number: '',
        email_address: '',
        dob: '',
        president_status: 'Inactive',
        current_address: '',
        disabled: {
            first_name: false,
            last_name: false,
            tfn: false,
            phone_number: false,
            email_address: false,
            dob: false,
            president_status: false,
            current_address: false
        }
    };
    const [presidentDetails, setPresidentDetails] = useState({ ...defaultPresidentDetails });

    const defaultTreasurerObject = {
        business_client_id: '',
        first_name: '',
        last_name: '',
        tfn: '',
        phone_number: '',
        email_address: '',
        dob: '',
        treasurer_status: 'Inactive',
        current_address: '',
        disabled: {
            first_name: false,
            last_name: false,
            tfn: false,
            phone_number: false,
            email_address: false,
            dob: false,
            treasurer_status: false,
            current_address: false
        }
    };
    const [treasurerList, setTreasurerList] = useState([defaultTreasurerObject]);

    const defaultSecretaryDetails = {
        business_client_id: '',
        bcr_id: '',
        first_name: '',
        last_name: '',
        tfn: '',
        phone_number: '',
        email_address: '',
        dob: '',
        secretary_status: 'Inactive',
        current_address: '',
        disabled: {
            first_name: false,
            last_name: false,
            tfn: false,
            phone_number: false,
            email_address: false,
            dob: false,
            status: false,
            current_address: false
        }
    };
    const [secretaryDetails, setSecretaryDetails] = useState(defaultSecretaryDetails);

    const defaultRelatedEntitiesObj = {
        fields: [
            {
                value: '',
                label: 'Entity',
                placeholder: 'Search by Name/Reference No.',
                id: generateId(),
                type: 'combobox',
                name: 'business_client_id',
                targetProperty: 'display_name'
            },
            {
                value: '',
                label: 'Display Name',
                placeholder: 'Enter Display Name',
                id: generateId(),
                name: 'display_name',
                type: 'text',
                disabled: false
            },

            {
                value: '',
                label: 'Designation',
                placeholder: 'Select Designation',
                id: generateId(),
                name: 'designation',
                options: [
                    { label: 'Partner' },
                    { label: 'Shareholder' },
                    { label: 'Trustee' },
                    { label: 'Unit Holder' },
                    { label: 'Beneficiary' }
                ],
                type: 'dropdown'
            },

            {
                value: '',
                label: 'Number of Units',
                placeholder: 'Enter number of units',
                id: generateId(),
                name: 'no_of_unit',
                type: 'number',
                is_conditional: false,
                data_name: 'Unit Holder'
            },

            {
                value: '',
                label: 'Partnership percentage',
                placeholder: 'Enter Partnership percentage',
                id: generateId(),
                name: 'partnership_percentage',
                type: 'number',
                is_conditional: false,
                data_name: 'Partner'
            },

            {
                value: '',
                label: 'Number of Shares',
                placeholder: 'Enter Number of Shares',
                id: generateId(),
                name: 'percentage_of_shares',
                type: 'number',
                is_conditional: false,
                data_name: 'Share Holder'
            },
            {
                value: '',
                label: 'Start date',
                placeholder: 'Enter start date',
                name: 'start_date',
                id: generateId(),
                type: 'date'
            },
            {
                value: 'No',
                options: ['Yes', 'No'],
                label: 'Is this designation currently in effect?',
                placeholder: '',
                id: generateId(),
                name: 'currently_effective',
                type: 'switch'
            },
            {
                value: '',
                label: 'End date',
                placeholder: 'Enter end date',
                id: generateId(),
                name: 'end_date',
                type: 'date'
            }
        ],
        link_profile: false,
        bcr_id: null
    };
    const [entities, setEntities] = useState([defaultRelatedEntitiesObj]);

    useEffect(() => {
        if (client) {
            setEmail(client?.email);
            setPhoneNo(client?.phone_number);
            setWeblink(client?.website);
            setAddress(client?.residential_address);
            setPostalAddress(client?.postal_address);
            setSameAddress(client?.is_postal_same_as_residential);
            setAccountHolderName(client?.account_holdername);
            setBsb(client?.bsb_code);
            setAccountNumber(client?.bank_account_number);
            setTfn(client?.tfn);
            setAbn(client?.abn);
            setAccountant(client?.accountant && client?.accountant?.user_id);
            setRegisteredGst(client?.registered_for_gst ? 'Yes' : 'No');
            setActivityStatementDate(client?.activity_statement_due_date);
            setTextFormDate(client?.tax_form_due_date);
            setRetriveFromAto(client?.ato_retrieval_time_span);
            setClientAtoStatus(client?.ato_client_status);
            setClientVerified(client?.is_client_verified ? 'Yes' : 'No');
            setVerificationMethod(client?.client_verification_method);
            setDateVerified(client?.date_verified);
            setVerifiedBy(client?.verified_by?.user_id);
            setVerifiedNotes(client?.notes);
            setRefund(client?.fee_from_refund ? 'Refund' : '');
            setTradings(client?.trading_name ? client?.trading_name.map((v) => ({ value: v })) : [{ value: '' }]);
            setPrincipleBusinessActivity(client?.business_occupation);

            setEthicalClearanceStatus(client?.ethical_cleanance_status ?? null);
            setFormerFirmName(client?.former_accounting_firmname ?? null);
            setFormerAccountentName(client?.former_accounting_name ?? null);
            setFormerAccountentEmail(client?.former_accounting_email_address ?? null);
            setStateOrTerritory(client?.state ?? '');
            setObjective(client?.objectives ?? '');

            if (client?.association_president_details) {
                const newData = updateObjectEntries(
                    { ...client?.association_president_details, isFirstLoad: true },
                    defaultPresidentDetails
                );
                setPresidentDetails(newData);
            }
            if (client.related_references?.length > 0) {
                let relatedRefList = [];
                client.related_references.map((responseObj, resInd) => {
                    const newApptList = defaultRelatedEntitiesObj?.fields?.map((obj, ind) => {
                        return {
                            ...obj,
                            id: generateId() + ind + resInd,
                            value:
                                obj.type === 'switch'
                                    ? responseObj?.[obj.name] === 'Yes'
                                        ? 'Yes'
                                        : 'No'
                                    : responseObj?.[obj.name],
                            is_conditional: responseObj?.[obj.name] ? true : false,
                            disabled:
                                responseObj?.business_client_id &&
                                responseObj?.display_name &&
                                obj.name === 'display_name'
                                    ? true
                                    : false
                        };
                    });
                    relatedRefList.push({
                        fields: newApptList,
                        link_profile: responseObj.link_to_profile,
                        bcr_id: responseObj.bcr_id
                    });
                });

                setEntities(relatedRefList);
            }

            if (client.association_treasurer_details) {
                const newTreasurerList = client?.association_treasurer_details?.map((obj) => {
                    return updateObjectEntries(
                        {
                            ...obj,
                            bcr_id: obj?.bcr_id,
                            isFirstLoad: true
                        },
                        defaultTreasurerObject
                    );
                });
                setTreasurerList(newTreasurerList);
            }

            if (client?.association_secretary_details) {
                const newData = updateObjectEntries(
                    { ...client?.association_secretary_details, isFirstLoad: true },
                    defaultSecretaryDetails
                );
                setSecretaryDetails(newData);
            }
        } else {
            setDateVerified(dayjs());
            setVerifiedBy(ownerProfile?.user_id);
        }
    }, [client]);

    const updateObjectEntries = (obj, defaultObj, showConsole = false) => {
        if (showConsole) {
            console.info('rehman obj', obj);
        }
        const updatedDisabled = {};

        if (obj.hasOwnProperty('business_client_id') && obj?.business_client_id !== '') {
            for (const key in defaultObj.disabled) {
                updatedDisabled[key] =
                    obj.hasOwnProperty(key) && obj[key] !== '' && obj[key] !== null && obj[key] !== undefined;
            }

            if (updatedDisabled.hasOwnProperty('email')) {
                updatedDisabled.email_address =
                    obj.hasOwnProperty('email') &&
                    obj['email'] !== '' &&
                    obj[email] !== null &&
                    obj[email] !== undefined;
            }
        }

        const newObj = {
            ...defaultObj,
            ...obj,
            disabled: {
                ...defaultObj.disabled,
                ...updatedDisabled
            }
        };
        return newObj;
    };

    useEffect(() => {
        if (!employees) {
            dispatch(fetchRegisteredEmployeesThunk());
        }
    }, [employees]);

    const onSelect = (place) => {
        setAddress(place.formatted_address);
    };
    const onSelectPostal = (place) => {
        setPostalAddress(place.formatted_address);
    };

    const handleAbnValidation = (e) => {
        let { value } = e.target;

        value = value.replace(/[^0-9]/g, '');
        if (value.length > 11) {
            value = value.slice(0, 11);
        }

        if (value?.length < 1) {
            setAbnError(false);
            setAbn(value);
            return;
        }

        const abnNumber = value.replace(/\s/g, '');
        const updatedABN = abnNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3-$4');
        if (!isValidABN(updatedABN)) {
            setAbnError(true);
        } else {
            setAbnError(false);
        }
        setAbn(value.replace(/\s/g, ''));
    };

    const handleTfnValidation = (e) => {
        let { value } = e.target;

        value = value.replace(/[^0-9]/g, '');

        if (value.length > 9) {
            value = value.slice(0, 9);
        }
        setTfn(value);

        if (validateTFN(value)) {
            setTfnError(false);
        } else {
            setTfnError(true);
        }
    };

    const checkDuplicateFromDB = async (name, value) => {
        if (name) {
            return await dispatch(
                checkTfnAbnAcnThunk({
                    business_id: business_id,
                    [name]: value
                })
            ).then((res) => {
                if (res?.payload && res?.payload?.found) {
                    toastHandler(`${name?.toUpperCase()} number is already exist`, TOAST_TYPE_ERROR);
                    return false;
                } else {
                    return true;
                }
            });
        }
    };

    const returnPayload = () => {
        return {
            client_category: 'Association',
            client_ref_no: clientRefNo ?? '',
            display_name: displayName,
            business_occupation: principleBusinessActivity,
            trading_name: tradings.filter((obj) => filterEmptyObject(obj)).map((obj) => obj.value),

            state: stateOrTerritory,

            objectives: objective,
            email: email,
            phone_number: phoneNo,
            website: weblink,
            residential_address: address,
            postal_address: sameAddress ? address : postalAddress,
            is_postal_same_as_residential: sameAddress,
            account_holdername: accountHolderName,
            bsb_code: bsb,
            bank_account_number: accountNumber,
            tfn: tfn,
            abn: abn,
            accountant: accountant,
            registered_for_gst: registeredGst === 'Yes' ? true : false,
            activity_statement_due_date: activityStatementDate
                ? typeof activityStatementDate === 'string'
                    ? activityStatementDate
                    : dayjs(activityStatementDate).format('DD-MM-YYYY')
                : '',
            tax_form_due_date: textFormDate
                ? typeof textFormDate === 'string'
                    ? textFormDate
                    : dayjs(textFormDate).format('DD-MM-YYYY')
                : '',

            ato_retrieval_time_span: retriveFromAto,
            fee_from_refund: refund ? true : false,
            ato_client_status: clientAtoStatus,
            is_client_verified: clientVerified === 'Yes' ? true : false,
            client_verification_method: verificationMethod,
            date_verified: dateVerified
                ? typeof dateVerified === 'string'
                    ? dateVerified
                    : dayjs(dateVerified).format('DD-MM-YYYY')
                : '',
            verified_by: clientVerified === 'Yes' ? verifiedBy : '',
            notes: verifiedNotes,
            spouse_reference: '',
            spouse_first_name: '',
            spouse_last_name: '',
            trust_structure: '',

            ethical_cleanance_status: ethicalClearanceStatus,
            former_accounting_firmname: formerFirmName,
            former_accounting_name: formerAccountentName,
            former_accounting_email_address: formerAccountentEmail,
            //

            related_references: addClientFormObj?.related_references ? addClientFormObj?.related_references : [],
            partner_details: addClientFormObj?.partner_details ? addClientFormObj?.partner_details : [],
            director_details: addClientFormObj?.director_details ? addClientFormObj?.director_details : [],
            shareholder_details: addClientFormObj?.shareholder_details ? addClientFormObj?.shareholder_details : [],
            public_officer_details: addClientFormObj?.public_officer_details
                ? addClientFormObj?.public_officer_details
                : [],
            secretary_details: addClientFormObj?.secretary_details ? addClientFormObj?.secretary_details : [],
            trustees: addClientFormObj?.trustees ? addClientFormObj?.trustees : [],
            settlor_details: addClientFormObj?.settlor_details ? addClientFormObj?.settlor_details : [],
            appointer_details: addClientFormObj?.appointer_details ? addClientFormObj?.appointer_details : [],
            upholder_details: addClientFormObj?.upholder_details ? addClientFormObj?.upholder_details : [],
            association_president_details: addClientFormObj?.association_president_details
                ? addClientFormObj?.association_president_details
                : [],
            association_treasurer_details: addClientFormObj?.association_treasurer_details
                ? addClientFormObj?.association_treasurer_details
                : []
        };
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if ((!client && !displayName) || (client && client?.client_type === 'offline_client')) {
            if (!displayName) {
                return toastHandler('Display name is required', TOAST_TYPE_ERROR);
            }
        }

        if (displayName?.length < 4) {
            return toastHandler('Display name must be greater then 3 characters', TOAST_TYPE_ERROR);
        }

        if ((!client && phoneNo?.length < 7) || (client && client?.client_type === 'offline_client')) {
            if (phoneNo.length < 7) {
                return toastHandler('Phone number is required', TOAST_TYPE_ERROR);
            }
        }

        if (email) {
            if (!validateEmailValue(email)) {
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            }
        }

        if (formerAccountentEmail) {
            if (!validateEmailValue(formerAccountentEmail)) {
                return toastHandler(`invalid Former Accountant Email `, TOAST_TYPE_ERROR);
            }
        }

        if (abn) {
            const validateAbnV = validateAbn(abn);
            if (!validateAbnV) {
                return toastHandler('Invalid ABN number', TOAST_TYPE_ERROR);
            }
            if (!client && validateAbnV && (await checkDuplicateFromDB('abn', abn)) === false) {
                return false;
            }
        }

        if (tfn) {
            const validateTfnV = validateTFN(tfn);
            if (!validateTfnV) {
                return toastHandler('Invalid TFN number', TOAST_TYPE_ERROR);
            }
            if (!client && validateTfnV && (await checkDuplicateFromDB('tfn', tfn)) === false) {
                return false;
            }
        }

        if (bsb) {
            if (!validateBSB(bsb)) {
                return toastHandler('Invalid BSB number', TOAST_TYPE_ERROR);
            }
        }
        if (phoneNo) {
            if (!validatePhoneNumber(phoneNo)) {
                return false;
            }
        }

        if (objective) {
            const wordsArray = objective.split(/\s+/).filter((word) => word.length > 0);
            if (wordsArray?.length > 5000) {
                return toastHandler('Objective should not exceed 5000 words', TOAST_TYPE_ERROR);
            }
        }

        const formPayload = returnPayload();

        setCurrentStep(1);

        setAddClientFormObj(formPayload);
    };

    const handleSelectChange = (customerArray) => {
        const obj = customerArray[0];
        setVerifiedBy(obj?.user_id);
    };

    const onSubmitForm = async () => {
        if ((!client && !displayName) || (client && client?.client_type === 'offline_client')) {
            if (!displayName) {
                return toastHandler('Display name is required', TOAST_TYPE_ERROR);
            }
        }

        if (displayName?.length < 4) {
            return toastHandler('Display name must be greater then 3 characters', TOAST_TYPE_ERROR);
        }

        if ((!client && phoneNo?.length < 7) || (client && client?.client_type === 'offline_client')) {
            if (phoneNo.length < 7) {
                return toastHandler('Phone number is required', TOAST_TYPE_ERROR);
            }
        }

        if (email) {
            if (!validateEmailValue(email)) {
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            }
        }

        if (abn) {
            const validateAbnV = validateAbn(abn);
            if (!validateAbnV) {
                return toastHandler('Invalid ABN number', TOAST_TYPE_ERROR);
            }
            if (!client && validateAbnV && (await checkDuplicateFromDB('abn', abn)) === false) {
                return false;
            }
        }

        if (tfn) {
            const validateTfnV = validateTFN(tfn);
            if (!validateTfnV) {
                return toastHandler('Invalid TFN number', TOAST_TYPE_ERROR);
            }
            if (!client && validateTfnV && (await checkDuplicateFromDB('tfn', tfn)) === false) {
                return false;
            }
        }

        if (bsb) {
            if (!validateBSB(bsb)) {
                return toastHandler('Invalid BSB number', TOAST_TYPE_ERROR);
            }
        }
        if (phoneNo) {
            if (!validatePhoneNumber(phoneNo)) {
                return false;
            }
        }

        if (!email) {
            return toastHandler('Email is required', TOAST_TYPE_ERROR);
        }

        delete presidentDetails.disabled;
        delete secretaryDetails.disabled;

        let related_entities = [];
        entities?.forEach((entity) => {
            const newEntity = {
                business_client_id: entity.fields[0]?.value || '',
                display_name: entity.fields[1].value,
                designation: entity.fields[2].value,

                ...(entity.fields[3].is_conditional && { no_of_unit: entity.fields[3].value }),
                ...(entity.fields[4].is_conditional && { partnership_percentage: entity.fields[4].value }),
                ...(entity.fields[5].is_conditional && { percentage_of_shares: entity.fields[5].value }),
                start_date: entity.fields[6].value,
                end_date: entity.fields[8].value,
                currently_effective: entity.fields[7].value,
                link_to_profile: entity.link_profile,
                bcr_id: entity?.bcr_id ? entity?.bcr_id : ''
            };

            if (filterEmptyObject(newEntity, ['currently_effective', 'bcr_id', 'link_to_profile'])) {
                related_entities.push(newEntity);
            }
        });

        const addClientFormObj = returnPayload();

        const newClient = {
            ...addClientFormObj,
            related_references: related_entities,
            association_president_details: filterEmptyObject(presidentDetails) ? presidentDetails : null,
            association_treasurer_details: treasurerList
                ? treasurerList
                      .filter((obj) => filterEmptyObject(obj))
                      .map((obj) => {
                          delete obj.disabled;
                          return obj;
                      })
                : [],
            association_secretary_details: filterEmptyObject(secretaryDetails) ? secretaryDetails : null
        };
        const formattedDOBObjects = formatDobToString(newClient);
        if (client) {
            dispatch(updateManualClientThunk({ ...formattedDOBObjects, business_client_id: client.business_client_id }))
                .then((response) => {
                    if (response.payload?.business_client_id) {
                        if (profileImage) {
                            handleUpdateProfile(response.payload.business_client_id);
                        } else {
                            setOpen(false);
                            handleReload();
                            toastHandler('Client updated successfully', TOAST_TYPE_SUCCESS);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        } else {
            dispatch(addManualClientThunk(formattedDOBObjects))
                .then((response) => {
                    if (response.payload?.business_client_id) {
                        if (profileImage) {
                            handleUpdateProfile(response.payload.business_client_id);
                        } else {
                            setOpen(false);
                            handleReload();
                            toastHandler('Client added successfully', TOAST_TYPE_SUCCESS);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    if (!profileImage) {
                        dispatch(setLoader(false));
                    }
                });
        }
    };

    const handleUpdateProfile = (id) => {
        const mimeType = profileImage.match(/data:([^;]+);base64,/)[1];

        const blob = base64ToBlob(profileImage);
        const formData = new FormData();

        formData.append('business_client_id', id);
        formData.append('image', blob, `image.${mimeType.split('/')[1]}`);
        formData.append('uploaded_by', USER_TYPE);

        dispatch(setLoader(true));

        dispatch(updateClientProfileImageThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setOpen(false);
                    handleReload();
                    setCurrentStep(0);
                    toastHandler('Client added successfully', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const fetchBusinessOccupationOptions = async (payload) => {
        const payloadNew = {
            ...payload,
            type: 'business-industry-occupation'
        };
        return await dispatch(getformsfieldsOptionsThunk(payloadNew));
    };
    return (
        <div>
            {currentStep === 0 ? (
                <form onSubmit={handleSubmit}>
                    <div className="border  rounded-xl mt-5 ">
                        <div className="py-5 px-3 rounded-tl-xl rounded-tr-xl bg-dimThird table-title font-poppins font-semibold">
                            Association Details
                        </div>

                        <div className="p-5">
                            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                <div>
                                    <div>
                                        <div>
                                            <label>Email Address</label>
                                        </div>
                                        <div className="mt-2 ">
                                            <EmailInput
                                                onChange={(value) => setEmail(value)}
                                                onError={() => {}}
                                                showRandomEmail
                                                placeholder="Enter email"
                                                required
                                                value={email}
                                                disabled={
                                                    !client
                                                        ? false
                                                        : client?.client_type !== 'offline_client' &&
                                                          client?.client_type !== 'added-from-xero' &&
                                                          client?.client_type !== 'offline_entity'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <PhoneInputComponent
                                        title="Phone Number"
                                        placeholder="Enter Phone Number"
                                        titleSx=""
                                        sx="!rounded-xl"
                                        atChange={(phone) => {
                                            setPhoneNo(phone);
                                        }}
                                        value={phoneNo}
                                        isDisabled={
                                            !client
                                                ? false
                                                : client?.client_type !== 'offline_client' &&
                                                  client?.client_type !== 'added-from-xero' &&
                                                  client?.client_type !== 'offline_entity'
                                                ? true
                                                : false
                                        }
                                    />
                                </div>

                                <div>
                                    <PickAddress
                                        id="residential-autocomplete"
                                        address={address}
                                        setAddress={setAddress}
                                        onSelect={onSelect}
                                        label="Physical Address"
                                        placeholder="Enter Physical Address"
                                        disabled={
                                            !client
                                                ? false
                                                : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                <div>
                                    <div>
                                        <PickAddress
                                            id="postal-autocomplete"
                                            address={!sameAddress ? postalAddress : address}
                                            setAddress={setPostalAddress}
                                            onSelect={onSelectPostal}
                                            label="Postal Address"
                                            placeholder="Enter postal address"
                                            disabled={
                                                sameAddress
                                                    ? true
                                                    : !client
                                                    ? false
                                                    : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="default-forms">
                                    <div>
                                        <label className="!flex items-center gap-2 mb-2 text-ellipsis">
                                            Association Registration State or Territory{' '}
                                            <DefaultHint
                                                hindSx={{
                                                    className: '!w-6 !h-6'
                                                }}
                                            >
                                                Please select the state or territory of registration to proceed with the
                                                registration process.
                                            </DefaultHint>
                                        </label>
                                    </div>

                                    <div className="mt-2 ">
                                        <SimpleSelectMenu
                                            disabled={
                                                !client
                                                    ? false
                                                    : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                    ? true
                                                    : false
                                            }
                                            placeholder="Select"
                                            targetProperty="label"
                                            selectedValue={stateOrTerritory}
                                            valuePropertyName="label"
                                            optionsData={STATE_TERRITORY || []}
                                            sx="rounded-xl py-3 ring-gray-400 bg-[#fff] !font-normal cursor-pointer"
                                            showChipOnly={false}
                                            onChangeValue={(obj) => setStateOrTerritory(obj.label)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 flex gap-2 items-center buttons-font-lighter font-poppins">
                                <div>
                                    <input
                                        type="checkbox"
                                        className="rounded-[3px]"
                                        id="postalAddress"
                                        onChange={(e) => setSameAddress(e.target.checked)}
                                        checked={sameAddress}
                                        disabled={
                                            !client
                                                ? false
                                                : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                ? true
                                                : false
                                        }
                                    />
                                </div>{' '}
                                <label className="cursor-pointer" htmlFor="postalAddress">
                                    Postal Address same as Physical Address
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="border  rounded-xl mt-5">
                        <div className="bg-dimThird  rounded-tl-xl rounded-tr-xl">
                            <div className="py-5 px-3 table-title font-poppins font-semibold">Business Information</div>
                        </div>
                        <div className="p-5">
                            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                <div>
                                    <div>
                                        <label>TFN</label>
                                    </div>
                                    <div className="mt-2 ">
                                        <input
                                            type="text"
                                            placeholder="Enter TFN number"
                                            name="tfnNumber"
                                            value={tfn}
                                            onChange={handleTfnValidation}
                                            className={`px-3 mt-2 ${tfnError && tfn ? 'add-error-border' : ''} ${
                                                !client || ALL_ClIENT_TYPES.includes(client?.client_type)
                                                    ? ''
                                                    : 'bg-gray-200 !border-none !cursor-no-drop'
                                            }`}
                                            disabled={
                                                !client
                                                    ? false
                                                    : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {tfnError && (
                                            <div className="text-sm text-red-500">
                                                {tfnError && tfn ? <span> Invalid TFN Number </span> : ''}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div>
                                            <label>ABN</label>
                                        </div>
                                        <div className="mt-2 ">
                                            <input
                                                type="text"
                                                placeholder="Enter ABN number"
                                                name="abnNumber"
                                                value={abn}
                                                onChange={handleAbnValidation}
                                                className={`px-3 mt-2  ${abnError && abn ? 'add-error-border' : ''} ${
                                                    !client || ALL_ClIENT_TYPES.includes(client?.client_type)
                                                        ? ''
                                                        : 'bg-gray-200 !border-none !cursor-no-drop'
                                                }`}
                                                disabled={
                                                    !client
                                                        ? false
                                                        : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {abnError && (
                                                <div className="text-sm text-red-500">
                                                    {abnError && abn ? <span> Invalid ABN Number </span> : ''}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label>Principle Business Activity</label>
                                    </div>
                                    <div>
                                        <SearchableComboboxSSR
                                            fetchDataFunction={fetchBusinessOccupationOptions}
                                            defaultSelected={principleBusinessActivity ? principleBusinessActivity : ''}
                                            removePreviousSelected
                                            placeholder="Select"
                                            inputsx="!font-normal !text-sm"
                                            sx="!rounded-xl  !font-normal !ring-gray-400 mt-2 !flex"
                                            showNameOnly
                                            onChange={(obj) => {
                                                setPrincipleBusinessActivity(obj[0]);
                                            }}
                                            limit={1}
                                        />
                                    </div>
                                </div>
                                <div className="lg:col-span-3">
                                    <TradingNames values={tradings} onChange={(e) => setTradings(e)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border  rounded-xl mt-5">
                        <div className="bg-dimThird  rounded-tl-xl rounded-tr-xl">
                            <div className="py-5 px-3 table-title font-poppins font-semibold">Financial Detail</div>
                        </div>

                        <div className="p-5">
                            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                <div>
                                    <div>
                                        <div>
                                            {' '}
                                            <div>
                                                <label>Account Holder Name</label>
                                            </div>
                                            <InputField
                                                placeholder={`Enter Account Holder Name`}
                                                inputValue={accountHolderName}
                                                maxLength={50}
                                                classes={'mt-2'}
                                                allowSpaces={true}
                                                onChange={(e) => setAccountHolderName(e.target.value)}
                                                disabled={
                                                    !client
                                                        ? false
                                                        : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label>BSB Number</label>
                                    </div>
                                    <div className="mt-2 ">
                                        <BsbInput
                                            onChange={(value) => setBsb(value)}
                                            placeholder={'Enter BSB Number'}
                                            value={bsb}
                                            disabled={
                                                !client
                                                    ? false
                                                    : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <label>Bank Account Number</label>
                                    </div>
                                    <div className="mt-2 ">
                                        <AccountNumberInput
                                            onChange={(value) => setAccountNumber(value)}
                                            placeholder={'Enter Bank Account Number'}
                                            value={accountNumber}
                                            disabled={
                                                !client
                                                    ? false
                                                    : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border  rounded-xl mt-5">
                        <div className="bg-dimThird  rounded-tl-xl rounded-tr-xl">
                            <div className="py-5 px-3 table-title font-poppins font-semibold">Objective</div>
                        </div>

                        <div className="default-forms p-5">
                            <div>
                                <label> Objective or purpose of this association</label>
                            </div>

                            <DefaultTextArea
                                onChange={(val) => setObjective(val)}
                                placeholder={'Enter object'}
                                value={objective}
                                maxCount={5000}
                                sx={'mt-3'}
                            />
                        </div>
                    </div>

                    <div className="border  rounded-xl mt-5">
                        <div className="bg-dimThird  rounded-tl-xl rounded-tr-xl">
                            <div className="py-5 px-3 table-title font-poppins font-semibold">Compliance</div>
                        </div>

                        <div className="p-5">
                            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 ">
                                <div className="default-forms">
                                    <div>
                                        <label>Accountant</label>
                                    </div>

                                    <ComboboxComponent
                                        data={employees}
                                        placeholder={'Select Accountant'}
                                        defaultSelected={employees && accountant}
                                        onChange={(list) => {
                                            const obj = list[0];
                                            setAccountant(obj?.user_id);
                                        }}
                                        targetProperty="user_id"
                                        limit={1}
                                        showNameOnly
                                    />
                                </div>
                                <div>
                                    <div className="buttons-font default-forms">
                                        <label>Fee From Refund</label>
                                    </div>
                                    <div className="mt-2">
                                        <CheckboxChip
                                            value="Refund"
                                            selectedValue={refund}
                                            onChange={(value) => setRefund(refund ? '' : value)}
                                            p="pr-24"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="buttons-font default-forms">
                                        <label>Client ATO Status</label>
                                    </div>
                                    <div className="">
                                        <DefaultDoubleSwitch
                                            items={[
                                                { title: 'Active', selected: clientAtoStatus === 'Active' },
                                                { title: 'Inactive', selected: clientAtoStatus === 'Inactive' }
                                            ]}
                                            onClick={(value) => setClientAtoStatus(value)}
                                        />
                                    </div>
                                </div>
                                <div className="default-forms">
                                    <div>
                                        <label>Prepare Tax Form</label>
                                    </div>

                                    <div className="flex gap-2 items-center">
                                        <div className="w-full  grid grid-cols-7  buttons-font-lighter font-poppins">
                                            <div className="flex gap-2 items-center col-span-2">
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        className="!rounded-[3px] !w-[18px] !h-[18px]"
                                                        checked={textFormDate ? true : false}
                                                    />
                                                </div>{' '}
                                                <div>Due Date</div>
                                            </div>
                                            <div className="col-span-5">
                                                <DatePick
                                                    iconSet={{
                                                        right: CalendarSmallIco
                                                    }}
                                                    onDateChange={(date) => {
                                                        setTextFormDate(date);
                                                    }}
                                                    placeholder="DD-MM-YYYY"
                                                    value={textFormDate ? dayjs(textFormDate, 'DD-MM-YYYY') : ''}
                                                    sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="default-forms">
                                    <div>
                                        <label>Are you registered for GST?</label>
                                    </div>
                                    <div>
                                        {' '}
                                        <DefaultDoubleSwitch
                                            items={[
                                                { title: 'Yes', selected: registeredGst === 'Yes' },
                                                { title: 'No', selected: registeredGst === 'No' }
                                            ]}
                                            onClick={(value) => setRegisteredGst(value)}
                                        />
                                    </div>
                                </div>

                                <div></div>
                            </div>
                            {registeredGst === 'Yes' && (
                                <div>
                                    {' '}
                                    <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-5 mt-5 default-forms">
                                        <div>
                                            <div>
                                                <label>Prepare Activity Statement Due Date</label>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <div className="mt-2  w-full  grid grid-cols-7 gap-3 buttons-font-lighter font-poppins">
                                                    <div className="flex gap-3 items-center col-span-2 ">
                                                        {' '}
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                className="!rounded-[3px] !w-[18px] !h-[18px]"
                                                                checked={activityStatementDate ? true : false}
                                                            />
                                                        </div>{' '}
                                                        <div>Due Date</div>
                                                    </div>
                                                    <div className="col-span-5">
                                                        <DatePick
                                                            iconSet={{
                                                                right: CalendarSmallIco
                                                            }}
                                                            onDateChange={(date) => {
                                                                setActivityStatementDate(date);
                                                            }}
                                                            placeholder="DD-MM-YYYY"
                                                            value={
                                                                activityStatementDate
                                                                    ? dayjs(activityStatementDate, 'DD-MM-YYYY')
                                                                    : ''
                                                            }
                                                            sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div></div>
                                    </div>
                                    <div className="flex gap-3 items-center flex-wrap">
                                        <div>
                                            {' '}
                                            <div className="mt-5 buttons-font">
                                                <label>Automatically Retrieve from ATO</label>
                                            </div>
                                            <div className="flex gap-3 items-center flex-wrap mt-3">
                                                <CheckboxChip
                                                    value="Monthly"
                                                    selectedValue={retriveFromAto}
                                                    onChange={(value) => setRetriveFromAto(value)}
                                                    p="pr-24"
                                                />
                                                <CheckboxChip
                                                    value="Quarterly"
                                                    selectedValue={retriveFromAto}
                                                    onChange={(value) => setRetriveFromAto(value)}
                                                    p="pr-24"
                                                />

                                                <CheckboxChip
                                                    value="Annually"
                                                    selectedValue={retriveFromAto}
                                                    onChange={(value) => setRetriveFromAto(value)}
                                                    p="pr-24"
                                                />
                                                <CheckboxChip
                                                    value="Opt-out"
                                                    selectedValue={retriveFromAto}
                                                    onChange={(value) => setRetriveFromAto(value)}
                                                    p="pr-24"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 ">
                                <div>
                                    <div className="buttons-font default-forms">
                                        <label className="">Ethical Clearance Status</label>
                                    </div>
                                    <SimpleSelectMenu
                                        placeholder="Select Ethical Clearance Status"
                                        targetProperty="label"
                                        selectedValue={ethicalClearanceStatus}
                                        valuePropertyName="value"
                                        optionsData={ethicalClearanceStatusArray || []}
                                        sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                                        showChipOnly={false}
                                        onChangeValue={(obj) => setEthicalClearanceStatus(obj?.value)}
                                    />
                                </div>
                                <div className="default-forms">
                                    <InputField
                                        label="Former Accounting Firm Name"
                                        inputValue={formerFirmName}
                                        maxLength={100}
                                        allowSpaces
                                        onChange={(e) => {
                                            setFormerFirmName(e.target.value);
                                        }}
                                        name="former_accounting_firmname"
                                        placeholder="Enter Firm Name"
                                        classes="mt-2"
                                    />
                                </div>
                                <div className="default-forms">
                                    <InputField
                                        label="Former Accountant Name"
                                        inputValue={formerAccountentName}
                                        maxLength={100}
                                        allowSpaces
                                        onChange={(e) => {
                                            setFormerAccountentName(e.target.value);
                                        }}
                                        name="former_accounting_name"
                                        placeholder="Enter Account Name"
                                        classes="mt-2"
                                    />
                                </div>
                                <div className="default-forms">
                                    <div>
                                        <label>Former Account Email</label>
                                    </div>
                                    <div className="mt-2 ">
                                        <EmailInput
                                            onChange={(value) => setFormerAccountentEmail(value)}
                                            placeholder="Former Account Email"
                                            value={formerAccountentEmail}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border  rounded-xl mt-5">
                        <div className="bg-dimThird  rounded-tl-xl rounded-tr-xl">
                            <div className="py-5 px-3 table-title font-poppins font-semibold">Client Verification</div>
                        </div>
                        <div className="p-5">
                            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                <div>
                                    <div>
                                        <label>Verified</label>
                                    </div>

                                    <DefaultDoubleSwitch
                                        items={[
                                            { title: 'Yes', selected: clientVerified === 'Yes' },
                                            { title: 'No', selected: clientVerified === 'No' }
                                        ]}
                                        onClick={(value) => setClientVerified(value)}
                                    />
                                </div>
                                {clientVerified === 'Yes' && (
                                    <div className="md:col-span-2">
                                        <div>
                                            <label>Verification Method</label>
                                        </div>

                                        <div className="flex gap-3 items-center flex-wrap mt-2">
                                            <CheckboxChip
                                                value="Document Check"
                                                selectedValue={verificationMethod}
                                                onChange={(value) => setVerificationMethod(value)}
                                                classes={' !rounded-[3px] !w-[16px] !h-[16px]'}
                                            />
                                            <CheckboxChip
                                                value="Verified in Person"
                                                selectedValue={verificationMethod}
                                                onChange={(value) => setVerificationMethod(value)}
                                                classes={' !rounded-[3px] !w-[16px] !h-[16px]'}
                                            />

                                            <CheckboxChip
                                                value="Verified Online"
                                                selectedValue={verificationMethod}
                                                onChange={(value) => setVerificationMethod(value)}
                                                classes={' !rounded-[3px] !w-[16px] !h-[16px]'}
                                            />

                                            <CheckboxChip
                                                value="Other"
                                                selectedValue={verificationMethod}
                                                onChange={(value) => setVerificationMethod(value)}
                                                classes={' !rounded-[3px] !w-[16px] !h-[16px]'}
                                                p="pr-24"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                            {clientVerified === 'Yes' && (
                                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                    <div>
                                        <div>
                                            <label>Date Verified</label>
                                        </div>
                                        <div>
                                            <DatePick
                                                iconSet={{
                                                    right: CalendarSmallIco
                                                }}
                                                onDateChange={(date) => {
                                                    setDateVerified(date);
                                                }}
                                                placeholder="Select Date"
                                                value={dateVerified ? dayjs(dateVerified, 'DD-MM-YYYY') : dayjs()}
                                                sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6] mt-2"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label>Verified By</label>
                                        </div>

                                        <ComboboxComponent
                                            data={employees}
                                            defaultSelected={
                                                employees &&
                                                (client ? client?.verified_by?.user_id : ownerProfile?.user_id)
                                            }
                                            onChange={(e) => handleSelectChange(e)}
                                            targetProperty="user_id"
                                            limit={1}
                                            showNameOnly
                                        />
                                    </div>

                                    <div>
                                        <div>
                                            <label>Notes</label>
                                        </div>

                                        <textarea
                                            onChange={(e) => setVerifiedNotes(e.target.value)}
                                            value={verifiedNotes}
                                            className="w-full mt-2 rounded-xl border border-secondarybg h-[150px] "
                                            placeholder="Notes"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-center items-center mb-10 mt-16 gap-5">
                        <Button
                            title="Next"
                            classes="ann-btn buttons-font-lighter font-fira px-20 rounded-xl "
                            buttonType="submit"
                        />
                    </div>
                </form>
            ) : currentStep === 1 ? (
                <PresidentDetailSubForm
                    handleCreate={onSubmitForm}
                    presidentDetails={presidentDetails}
                    setPresidentDetails={setPresidentDetails}
                    setCurrentStep={setCurrentStep}
                />
            ) : currentStep === 2 ? (
                <TreasurerDetailSubForm
                    handleCreate={onSubmitForm}
                    setCurrentStep={setCurrentStep}
                    treasurerList={treasurerList}
                    setTreasurerList={setTreasurerList}
                    defaultTreasurerObject={defaultTreasurerObject}
                />
            ) : currentStep === 3 ? (
                <SecretaryDetailSubForm
                    handleCreate={onSubmitForm}
                    secretaryDetails={secretaryDetails}
                    setSecretaryDetails={setSecretaryDetails}
                    setCurrentStep={setCurrentStep}
                    onSubmitForm={() => setCurrentStep(4)}
                />
            ) : currentStep === 4 ? (
                <RelatedEntities
                    client={client}
                    addClientFormObj={addClientFormObj}
                    setCurrentStep={setCurrentStep}
                    entities={entities}
                    setEntities={setEntities}
                    onBack={() => setCurrentStep(3)}
                    onNext={() => onSubmitForm()}
                />
            ) : (
                ''
            )}
        </div>
    );
};
