/* eslint-disable react/no-children-prop */
import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import DefaultHint from 'components/atoms/DefaultHint';
import EmailInput from 'components/atoms/Input/EmailInput';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { InputField } from 'components/atoms/inputs/InputField';
import { NumberInput } from 'components/atoms/inputs/NumberInput';
import { PrimaryCheckbox } from 'components/atoms/inputs/PrimaryCheckbox';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import dayjs from 'dayjs';
import { PickAddress } from 'global-components/PickAddress';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { deleteClientRefThunk, searchClientsThunk } from 'store/client';
import { validatePhoneNumber } from 'utills/baseValidations';
import { validateEmailValue, validateIndividualsObjects } from 'utills/dataValidation';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';

const optlst = [
    { label: 'New South Wales' },
    { label: 'Victoria' },
    { label: 'Queensland' },
    { label: 'South Australia' },
    { label: 'Western Australia' },
    { label: 'Australian Capital Territory' },
    { label: 'Northern Territory' }
];

export const SettlorDetail = ({ setCurrentStep, settlorDetails, setSettlorDetails }) => {
    const dispatch = useDispatch();

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleEntityValue = (value, targetProperty) => {
        setSettlorDetails((prev) => ({ ...prev, [targetProperty]: value }));
        return false;
    };

    const removeBCRIdFun = () => {
        if (settlorDetails?.bcr_id && settlorDetails?.business_client_id !== '') {
            dispatch(deleteClientRefThunk({ bcr_id: settlorDetails.bcr_id }));
        }
        return true;
    };

    const handleComboxboxEntity = (obj = null, isFirstLoad = false) => {
        setSettlorDetails((prevData) => {
            return isFirstLoad === true
                ? { ...prevData, isFirstLoad: false }
                : prevData?.business_client_id === obj?.business_client_id
                ? { ...prevData }
                : {
                      ...prevData,
                      business_client_id: obj ? obj?.business_client_id : '',
                      bcr_id: obj ? obj?.bcr_id : '',
                      first_name: obj ? obj?.first_name : '',
                      last_name: obj ? obj?.last_name : '',
                      phone_number: obj ? obj?.phone_number : '',
                      email_address: obj ? obj?.email : '',
                      residential_address: obj ? obj?.residential_address : '',
                      disabled: {
                          first_name: obj && obj?.first_name && obj?.first_name !== '' ? true : false,
                          last_name: obj && obj?.last_name && obj?.last_name !== '' ? true : false,
                          phone_number: obj && obj?.phone_number && obj?.phone_number !== '' ? true : false,
                          email_address: obj && obj?.email && obj?.email !== '' ? true : false,
                          residential_address:
                              obj && obj?.residential_address && obj?.residential_address !== '' ? true : false
                      }
                  };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validateIndividualsObjects(settlorDetails);

        if (validationErrors?.length > 0) {
            validationErrors?.forEach((error) => {
                toastHandler(error, TOAST_TYPE_ERROR);
            });
            return false;
        }

        let hasError = false;

        if (settlorDetails?.email_address) {
            if (!validateEmailValue(settlorDetails?.email_address)) {
                hasError = true;
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            } else {
                hasError = false;
            }
        }

        if (settlorDetails?.phone_number?.length > 4) {
            if (!validatePhoneNumber(settlorDetails?.phone_number)) {
                hasError = true;
                return false;
            } else {
                hasError = false;
            }
        }

        if (hasError) {
            return;
        }

        setCurrentStep(3);
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="table-title font-poppins font-semibold">Settlor Details</div>
            </div>
            <div>
                <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                >
                    <div className="mt-5">
                        <div className=" default-forms grid md:grid-cols-2 lg:grid-cols-3 gap-5  rounded-xl ">
                            <div className="w-[100%]">
                                <label className="inputLabel">Settlor</label>

                                <ComboboxComponentSSR
                                    fetchDataFunction={(payload) =>
                                        fetchReferencesListFun({
                                            ...payload,
                                            type: 'individual'
                                        })
                                    }
                                    defaultSelected={settlorDetails.business_client_id}
                                    removePreviousSelected
                                    onRemove={(_e) => {
                                        removeBCRIdFun();
                                        handleComboxboxEntity(null, false);
                                    }}
                                    filterSelectedValues={[settlorDetails] || []}
                                    filterSelectedValueTargetElement={'business_client_id'}
                                    onChange={(res) => {
                                        const obj = res[0];
                                        handleComboxboxEntity(obj, settlorDetails?.isFirstLoad);
                                    }}
                                    targetProperty="business_client_id"
                                    placeholder={'Search by Name/Reference No.'}
                                    limit={1}
                                    sx="rounded-xl ring-gray-400 "
                                    showNameOnly
                                />
                            </div>

                            <div className="w-[100%]">
                                <InputField
                                    label={'First Name'}
                                    maxLength={30}
                                    allowSpaces={true}
                                    onChange={(e) => {
                                        handleEntityValue(e.target.value, 'first_name');
                                    }}
                                    name={'First Name'}
                                    inputValue={settlorDetails.first_name}
                                    placeholder={'First Name'}
                                    classes="mt-2 bg-transparent"
                                    disabled={settlorDetails?.disabled?.first_name}
                                />
                            </div>
                            <div className="w-[100%]">
                                <InputField
                                    label={'Last Name'}
                                    maxLength={30}
                                    allowSpaces={true}
                                    onChange={(e) => {
                                        handleEntityValue(e.target.value, 'last_name');
                                    }}
                                    name={'Last Name'}
                                    inputValue={settlorDetails.last_name}
                                    placeholder={'Last Name'}
                                    classes="mt-2 bg-transparent"
                                    disabled={settlorDetails?.disabled?.last_name}
                                />
                            </div>

                            <div className="w-[100%]">
                                <PhoneInputComponent
                                    value={settlorDetails.phone_number}
                                    title="Phone Number"
                                    placeholder="Enter Phone Number"
                                    titleSx=""
                                    sx="!rounded-xl bg-transparent"
                                    bg={'!bg-transparent'}
                                    atChange={(phone) => {
                                        handleEntityValue(phone, 'phone_number');
                                    }}
                                    isDisabled={settlorDetails?.disabled?.phone_number}
                                />
                            </div>
                            <div className="w-[100%]">
                                <EmailInput
                                    label={'Email Address'}
                                    onChange={(value) => {
                                        handleEntityValue(value, 'email_address');
                                    }}
                                    placeholder={'Email Address'}
                                    name={'Email Address'}
                                    value={settlorDetails.email_address}
                                    classes="mt-2 bg-transparent"
                                    disabled={settlorDetails?.disabled?.email_address}
                                />
                            </div>

                            <div className="w-[100%]">
                                <PickAddress
                                    id={`residential-autocomplete-${'34jib'}`}
                                    address={settlorDetails?.residential_address}
                                    setAddress={(address) => {
                                        if (settlorDetails?.residential_address !== address) {
                                            handleEntityValue(address, 'residential_address');
                                        }
                                    }}
                                    onSelect={(place) => {
                                        if (settlorDetails?.residential_address !== place.formatted_address) {
                                            handleEntityValue(place.formatted_address, 'residential_address');
                                        }
                                        return;
                                    }}
                                    label="Residential Address"
                                    placeholder="Residential Address"
                                    classes="bg-transparent"
                                    disabled={settlorDetails?.disabled?.residential_address}
                                />
                            </div>
                            <div className="w-[100%]">
                                <div>
                                    <label className="!flex items-center justify-between gap-2">
                                        Registration State or Territory{' '}
                                        <DefaultHint
                                            sx={'-top-4 transform -translate-y-full '}
                                            children={
                                                'Please select the state or territory of registration to proceed with the Trust registration process.'
                                            }
                                        />
                                    </label>
                                </div>
                                <SimpleSelectMenu
                                    placeholder="Select"
                                    targetProperty="label"
                                    selectedValue={settlorDetails?.company_state}
                                    valuePropertyName="label"
                                    optionsData={optlst || []}
                                    sx="rounded-xl mt-2 py-3 ring-gray-400 bg-[#fff] !font-normal cursor-pointer"
                                    showChipOnly={false}
                                    onChangeValue={(obj) => {
                                        handleEntityValue(obj?.label, 'company_state');
                                    }}
                                />
                            </div>
                            <div className="w-[100%]">
                                <div>
                                    <label className="!flex items-center justify-between gap-2">
                                        What date will the deed be established?{' '}
                                        <DefaultHint
                                            sx={'-top-4 transform -translate-y-full '}
                                            children={
                                                "Please enter the deed establishment date to help us complete the necessary information.This date will be recorded as the official date of the deed's creation."
                                            }
                                        />
                                    </label>
                                </div>
                                <DatePick
                                    maxDate={dayjs()}
                                    iconSet={{
                                        right: CalendarSmallIco
                                    }}
                                    onDateChange={(date) => {
                                        handleEntityValue(
                                            date ? dayjs(date).format('DD-MM-YYYY') : '',
                                            'deed_establish_date'
                                        );
                                    }}
                                    placeholder="DD-MM-YYYY"
                                    format="DD-MM-YYYY"
                                    value={
                                        settlorDetails?.deed_establish_date
                                            ? dayjs(settlorDetails?.deed_establish_date, 'DD-MM-YYYY')
                                            : ''
                                    }
                                    sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full hover:bg-[#FAF9F6] !bg-transparent"
                                />
                            </div>
                            <div className="w-[100%]">
                                <div>
                                    <label className="!flex items-center justify-between gap-2">
                                        Settled Sum{' '}
                                        <DefaultHint
                                            sx={'-top-4 transform -translate-y-full '}
                                            children={
                                                'Please enter the settled sum to help us complete the necessary information.This amount represents the initial statement of the trust.If the default amount of $10 is acceptable,you can leave it as $10.'
                                            }
                                        />
                                    </label>
                                </div>
                                <NumberInput
                                    value={settlorDetails.setteled_sum}
                                    onChange={(e) => {
                                        handleEntityValue(e, 'setteled_sum');
                                    }}
                                    acceptZero
                                    placeholder={'Enter Settled Sum'}
                                    name={'Settled Sum'}
                                    sx="mt-2 bg-transparent"
                                    disabled={settlorDetails?.disabled?.setteled_sum}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 flex gap-2 items-center buttons-font-lighter font-poppins">
                        <PrimaryCheckbox
                            id={'link_profile'}
                            name={'link_profile'}
                            onchange={(e) => {
                                handleEntityValue(e.target.checked, 'link_profile');
                            }}
                            htmlFor="link_profile"
                            checked={settlorDetails?.link_profile && settlorDetails.link_profile !== '' ? true : false}
                            label="I would like to link the profile"
                        />
                    </div>

                    {/*  */}
                    <div className="flex justify-center mt-24 items-center">
                        <div className="flex gap-8 items-center">
                            <Button
                                title="Back"
                                classes={
                                    'border-blueish text-blueish border-2 h-[3rem] rounded-lg buttons-font-lighter font-fira px-14'
                                }
                                atClick={() => setCurrentStep(1)}
                                type="button"
                            />

                            <Button
                                title={'Next'}
                                classes={'ann-btn rounded-lg px-14 buttons-font-lighter font-fira'}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
