import React from 'react';
import { useEffect, useState } from 'react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { cn } from 'utills/tailwindUtil';
import { CheckIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { SmallLoader } from 'components/common/SmallLoader';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import { CompoboxInput } from '../CompoboxInput';

const SearchableComboboxSSR = ({
    selectedValue,
    defaultSelected,
    onChange,
    limit = 1,
    sx,
    placeholder,
    showNameOnly,
    disabled,
    inputsx,
    removePreviousSelected,
    fetchDataFunction,
    nullable = true,
    allowSelectCustomValue = false,
    targetResponseProperty = 'list',
    nestedResponseProperty = '',
    displayContent = null,
    displayCustomInputValue = null,
    parseStrFromObj = false,
    showCancelBtn = false,
    targetProperty,
    forceReload = false
}) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const [query, setQuery] = useState(null);
    const [page, setPage] = useState(1);
    const [listData, setListData] = useState(null);
    const [hasMoreContent, setHasMoreContent] = useState(true);

    useEffect(() => {
        getAllData();
    }, [page]);

    useEffect(() => {
        if (query !== null) {
            reloadDataPage();
        }
    }, [query]);

    useEffect(() => {
        if (forceReload) {
            reloadDataPage();
        }
    }, [forceReload]);
    const handlePageChange = () => {
        setPage(page + 1);
    };

    const getAllData = async (hasReset = false) => {
        const payload = {
            page: page,
            search_term: query ?? ''
        };
        setHasMoreContent(true);

        try {
            const response = await fetchDataFunction(payload);
            if (response.payload) {
                const returnArr = nestedResponseProperty
                    ? response.payload[targetResponseProperty][nestedResponseProperty]
                    : response.payload[targetResponseProperty];

                const parsedStrs = parseStrFromObj ? returnArr?.map((item) => item[targetProperty]) : returnArr;
                const countNum = nestedResponseProperty
                    ? response.payload[targetResponseProperty].count
                    : response.payload.count;

                const cloneData = [...(!hasReset ? listData || [] : []), ...parsedStrs];

                setListData(cloneData);
                setHasMoreContent(cloneData.length >= countNum ? false : true);
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const reloadDataPage = () => {
        setPage(1);
        getAllData(true);
        setListData(null);
    };

    useEffect(() => {
        if (selectedValue === '') {
            setSelectedItems([]);
        }
    }, [selectedValue]);

    useEffect(() => {
        if (defaultSelected) {
            handleDefaultSelect(defaultSelected);
        }
    }, [defaultSelected]);

    const handleDefaultSelect = async (defaultSelected = '') => {
        if (defaultSelected && defaultSelected !== '') {
            const dummyState = [...(removePreviousSelected ? [] : selectedItems), defaultSelected];
            setSelectedItems(dummyState);

            setQuery('');
        } else {
            setSelectedItems([]);

            setQuery('');
        }
    };

    const handleReset = () => {
        setSelectedItems([]);
        onChange([]);
        setQuery(null);
    };

    return (
        <Combobox
            as="div"
            disabled={disabled}
            value={selectedItems?.length > 0 ? selectedItems[0] : null}
            onChange={(e) => {
                if (e) {
                    setSelectedItems([e]);
                    onChange([e]);
                } else {
                    handleReset();
                }
            }}
            nullable={nullable}
        >
            <div className="relative">
                <div
                    className={cn(
                        '!rounded-xl border-0 shadow-sm ring-1  ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500 px-2 mt-2',
                        sx || '',
                        !allowSelectCustomValue && selectedItems.length > 0 && !showNameOnly ? 'py-2' : '',
                        disabled ? '!bg-gray-200 !border-none !cursor-no-drop' : ''
                    )}
                >
                    <div className="w-full">
                        <CompoboxInput
                            atChange={(event) => setQuery(event.target.value)}
                            placeholder={placeholder}
                            selectedItems={selectedItems}
                            inputsx={inputsx}
                            showDisplayValue
                            displayCustomInputValue={displayCustomInputValue}
                        />
                        {selectedItems.length > 0 && showCancelBtn ? (
                            <div
                                className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none cursor-pointer"
                                onClick={() => handleReset()}
                            >
                                <XCircleIcon className="h-5 w-5 text-[#979797]" />
                            </div>
                        ) : (
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon className="h-5 w-5 text-[#979797]" aria-hidden="true" />
                            </Combobox.Button>
                        )}
                    </div>
                </div>

                <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-hidden hover:overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <InfiniteScrollComp
                        height={215}
                        next={handlePageChange}
                        dataLength={(listData?.length === 0 ? (hasMoreContent ? 1 : 0) : listData?.length) || 0}
                        hasMore={listData?.length !== 0 ? hasMoreContent : false}
                        loader={
                            <div className="flex items-center justify-center py-2">
                                <SmallLoader scale={125} />
                            </div>
                        }
                    >
                        {allowSelectCustomValue && query && query !== '' && (
                            <>
                                <Combobox.Option
                                    value={query}
                                    className={({ active }) =>
                                        cn(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-third !text-white' : 'text-gray-900'
                                        )
                                    }
                                    onClick={() => {
                                        if (limit && selectedItems.length === limit) {
                                            return false;
                                        } else {
                                            const dummyState = [...selectedItems, query];
                                            setSelectedItems(dummyState);
                                            onChange(dummyState);
                                            setQuery('');
                                        }
                                    }}
                                >
                                    {({ active, selected }) =>
                                        displayContent ? (
                                            displayContent(query, active, selected)
                                        ) : (
                                            <>
                                                <span className={cn('block truncate', selected && 'font-semibold')}>
                                                    {query || 'undefined'}
                                                </span>

                                                {selected && (
                                                    <span
                                                        className={cn(
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                            active ? 'text-white' : 'text-third'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                )}
                                            </>
                                        )
                                    }
                                </Combobox.Option>
                            </>
                        )}
                        {listData && listData?.length > 0 && (
                            <>
                                {listData.map((obj, ind) => (
                                    <div key={'item_fileter1' + ind}>
                                        <Combobox.Option
                                            value={obj}
                                            className={({ active }) =>
                                                cn(
                                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                                    active ? 'bg-third !text-white' : 'text-gray-900'
                                                )
                                            }
                                            onClick={() => {
                                                if (limit && selectedItems.length === limit) {
                                                    return false;
                                                } else {
                                                    const dummyState = [...selectedItems, obj];
                                                    setSelectedItems(dummyState);
                                                    onChange(dummyState);
                                                    setQuery('');
                                                }
                                            }}
                                        >
                                            {({ active, selected }) =>
                                                displayContent ? (
                                                    displayContent(obj, active, selected)
                                                ) : (
                                                    <>
                                                        <span
                                                            className={cn(
                                                                'block truncate',
                                                                selected && 'font-semibold'
                                                            )}
                                                        >
                                                            {obj || 'undefined'}
                                                        </span>

                                                        {selected && (
                                                            <span
                                                                className={cn(
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                    active ? 'text-white' : 'text-third'
                                                                )}
                                                            >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        )}
                                                    </>
                                                )
                                            }
                                        </Combobox.Option>
                                    </div>
                                ))}
                            </>
                        )}
                    </InfiniteScrollComp>
                </Combobox.Options>
            </div>
        </Combobox>
    );
};

export default SearchableComboboxSSR;
