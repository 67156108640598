import { UsersIcon } from '@heroicons/react/24/outline';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import React from 'react';
import { getProfileName } from 'utills/globalVars';
import { JobsCIcon } from 'utills/svgs/JobsCIcon';
import { TasksCIcon } from 'utills/svgs/TasksCIcon';
import { ThinLineIcon } from 'utills/svgs/ThinLineIcon';

export const EmpCard = ({ emp, targetTasks, targetJobs, targetClients }) => {
    return (
        <div className="bg-linear text-white rounded-xl p-5 w-full lg:w-[250px] ">
            <div className="flex justify-center mt-2 items-center">
                <DefaultProfileViewer image={emp?.image} width="70px" height="70px" name={getProfileName(emp)} />
            </div>

            <div className="text-center mt-4">
                <PrimaryParagraph> {getProfileName(emp)} </PrimaryParagraph>
            </div>
            <div className="text-center ">
                <SecondaryParagraph> {emp?.email}</SecondaryParagraph>
            </div>

            <div className=" mt-5   flex justify-center items-center">
                <span className="overflow-hidden !w-[180px]">
                    <ThinLineIcon />
                </span>
            </div>

            <div className="mt-4">
                <div className="flex gap-3 justify-between w-full items-center">
                    <div className="flex gap-2 items-center">
                        <JobsCIcon />
                        <SecondaryParagraph sx="text-[#898989]">Jobs</SecondaryParagraph>
                    </div>
                    <div>
                        <PrimaryParagraph> {targetJobs ?? 0}</PrimaryParagraph>
                    </div>
                </div>
                <div className="flex gap-3 mt-3 justify-between w-full items-center">
                    <div className="flex gap-2 items-center">
                        <TasksCIcon />

                        <SecondaryParagraph sx="text-[#898989]">Tasks</SecondaryParagraph>
                    </div>
                    <div>
                        <PrimaryParagraph> {targetTasks ?? 0} </PrimaryParagraph>
                    </div>
                </div>
                <div className="flex gap-3 mt-3 justify-between w-full items-center">
                    <div className="flex gap-2 items-center">
                        <span className="border border-[#898989] p-1 rounded-[5px] ">
                            {' '}
                            <UsersIcon className="text-[#898989] w-5 h-5" />
                        </span>

                        <SecondaryParagraph sx="text-[#898989]">Clients</SecondaryParagraph>
                    </div>
                    <div>
                        <PrimaryParagraph> {targetClients ?? 0} </PrimaryParagraph>
                    </div>
                </div>
            </div>
        </div>
    );
};
