import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponent from 'components/atoms/Combobox';
import { H3 } from 'components/atoms/typography/headings/H3';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { getEmployeeStatsThunk } from 'store/settings/team/team';
import { BUSINESS_ID, getProfileName, TOAST_TYPE_ERROR } from 'utills/globalVars';
// import { JobsCIcon } from 'utills/svgs/JobsCIcon';
// import { TasksCIcon } from 'utills/svgs/TasksCIcon';

export const SelectEmp = ({
    emp,
    setCondition,
    selectedTarget,
    setSelectedTarget,
    setTargetTasks,
    setTargetClients,
    _targetTasks,
    _targetJobs,
    setTargetJobs
}) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);
    const { data: _loginData } = useSelector((state) => state.login);

    const handleSelect = (person) => {
        dispatch(setLoader(true));
        dispatch(getEmployeeStatsThunk({ employee_id: emp?.user_id, business_id }))
            .then((response) => {
                if (response.payload) {
                    setSelectedTarget(person);
                    setTargetTasks(response.payload.tasks);
                    setTargetJobs(response.payload.jobs);
                    setTargetClients(response.payload.client);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return (
        <div>
            <H3 sx="font-fira !text-[#222222]"> Transfer Data</H3>

            <SecondaryParagraph sx="text-secondarybg mt-4">
                Select person to whom you want to transfer job and tasks.
            </SecondaryParagraph>

            <div className="mt-5">
                {' '}
                <PrimaryLabel> Select Employee </PrimaryLabel>
                <ComboboxComponent
                    data={employees?.filter((item) => item?.user_id !== emp?.user_id)}
                    // data={employees?.filter((item) => item?.user_id !== loginData?.user_id)}
                    removePreviousSelected
                    hideSelectedValues
                    placeholder={'Search Manager by Name or Reference Number'}
                    onChange={(list) => {
                        const obj = list[0];

                        handleSelect(obj);
                    }}
                    targetProperty="user_id"
                />
            </div>
            {selectedTarget && (
                <div className="mt-4 bg-linear rounded-xl text-white p-4 ">
                    <div className="flex gap-3 items-center">
                        <DefaultProfileViewer
                            image={selectedTarget?.image}
                            alt="emp"
                            width={'70px'}
                            height={'70px'}
                            name={getProfileName(selectedTarget)}
                        />

                        <div>
                            <PrimaryParagraph>{getProfileName(selectedTarget)} </PrimaryParagraph>
                            <SecondaryParagraph sx="    pt-2">{selectedTarget?.email} </SecondaryParagraph>
                        </div>
                    </div>
                    {/* 
                    <div className="pl-[80px] w-full flex justify-between items-center mt-5 ">
                        <div className="flex gap-3 items-center">
                            <JobsCIcon />

                            <div>
                                <SecondaryParagraph sx="text-[#898989]">Total Jobs</SecondaryParagraph>
                                <PrimaryParagraph> {targetJobs ?? 0}</PrimaryParagraph>
                            </div>
                        </div>
                        <div className="flex gap-3 items-center">
                            <TasksCIcon />

                            <div>
                                <SecondaryParagraph sx="text-[#898989]">Total Tasks</SecondaryParagraph>
                                <PrimaryParagraph> {targetTasks ?? 0} </PrimaryParagraph>
                            </div>
                        </div>
                    </div> */}
                </div>
            )}
            <div className="mt-16 flex justify-center items-center w-full">
                <Button
                    title="Next"
                    atClick={() => {
                        if (!selectedTarget) {
                            return toastHandler('Please select an employee', TOAST_TYPE_ERROR);
                        }
                        setCondition(2);
                    }}
                    classes="ann-btn px-16 rounded-xl"
                />
            </div>
        </div>
    );
};
