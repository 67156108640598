import { Button } from 'components/atoms/buttons/Button';
import { H3 } from 'components/atoms/typography/headings/H3';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import React from 'react';
import { getProfileName } from 'utills/globalVars';
import { EmployeeDataIcon } from 'utills/svgs/EmployeeDataIcon';
import { TransferArrowsIcon } from 'utills/svgs/TransferArrowsIcon';

export const FinalizeData = ({ onConfirm, selectedTarget }) => {
    return (
        <div>
            <div className="mt-8 flex gap-3 justify-between items-center">
                <EmployeeDataIcon />
                <TransferArrowsIcon />
                <EmployeeDataIcon />
            </div>

            <div className="mt-5">
                <H3 sx="font-fira !text-[#222222] text-center">Assigned</H3>

                <PrimaryParagraph sx=" mt-2 text-center">
                    Jobs, Clients and Tasks Successfully Assigned to {getProfileName(selectedTarget)}
                </PrimaryParagraph>
            </div>

            <div className="flex justify-center items-center mt-16 ">
                <Button title="Ok!" atClick={() => onConfirm()} classes="ann-btn px-16 rounded-xl" />
            </div>
        </div>
    );
};
