import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import EmailInput from 'components/atoms/Input/EmailInput';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { InputField } from 'components/atoms/inputs/InputField';
import { TFNInputField } from 'components/atoms/inputs/TFNInputField';
import SearchableComboboxSSR from 'components/molecules/comboboxes/SearchableComboboxSSR';
import dayjs from 'dayjs';
import { PickAddress } from 'global-components/PickAddress';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { deleteClientRefThunk, searchClientsThunk } from 'store/client';
import { getformsfieldsOptionsThunk } from 'store/form';
import { validatePhoneNumber } from 'utills/baseValidations';
import { validateEmailValue, validateIndividualsObjects, validateTFN } from 'utills/dataValidation';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { dateMinus12Years } from 'utills/moment';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { NewPlusIcon } from 'utills/svgs/NewPlusIcon';
import { cn } from 'utills/tailwindUtil';

const MemberDetailsSubForm = ({ setCurrentStep, membersList, setMembersList, defaultMembersObject, onSubmitForm }) => {
    const dispatch = useDispatch();

    const handleRemoveEntry = (index, bcr_id) => {
        if (bcr_id) {
            dispatch(deleteClientRefThunk({ bcr_id: bcr_id }));
        }
        const newInputs = [...membersList];
        newInputs.splice(index, 1);
        setMembersList(newInputs);
    };
    const handleAddEntry = () => {
        setMembersList([...membersList, defaultMembersObject]);
    };

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleEntityValue = (value, targetProperty, index) => {
        setMembersList((pre) => {
            return pre.map((prevObj, preInd) => {
                return {
                    ...prevObj,
                    [targetProperty]: preInd === index ? value : prevObj?.[targetProperty]
                };
            });
        });
    };

    const handleComboxboxEntity = (obj = null, index, isFirstLoad = false) => {
        setMembersList((pre) => {
            return pre.map((prevObj, preInd) => {
                if (preInd === index) {
                    if (isFirstLoad) {
                        if (!obj) {
                            return {
                                ...prevObj,
                                member_occupation: '',
                                isFirstLoad: false,
                                disabled: {
                                    first_name: false,
                                    last_name: false,
                                    tfn: false,
                                    phone_number: false,
                                    email_address: false,
                                    dob: false,
                                    residential_address: false,
                                    member_occupation: false
                                }
                            };
                        }

                        return {
                            ...prevObj,
                            isFirstLoad: false
                        };
                    }

                    if (obj?.business_client_id === prevObj?.business_client_id) {
                        return {
                            ...prevObj
                        };
                    }

                    return {
                        ...prevObj,
                        business_client_id: obj ? obj?.business_client_id : '',
                        first_name: obj ? obj?.first_name : '',
                        last_name: obj ? obj?.last_name : '',
                        tfn: obj ? obj?.tfn : '',
                        phone_number: obj ? obj?.phone_number : '',
                        email_address: obj ? obj?.email : '',
                        dob: obj ? obj?.date_of_birth : '',
                        residential_address: obj ? obj?.residential_address : '',
                        member_occupation: obj ? obj?.work_profession ?? obj?.member_occupation : '',
                        disabled: {
                            first_name: obj && obj?.first_name && obj?.first_name !== '' ? true : false,
                            last_name: obj && obj?.last_name && obj?.last_name !== '' ? true : false,
                            tfn: obj && obj?.tfn && obj?.tfn !== '' ? true : false,
                            phone_number: obj && obj?.phone_number && obj?.phone_number !== '' ? true : false,
                            email_address: obj && obj?.email && obj?.email !== '' ? true : false,
                            dob: obj && obj?.date_of_birth && obj?.date_of_birth !== '' ? true : false,
                            residential_address:
                                obj && obj?.residential_address && obj?.residential_address !== '' ? true : false,
                            member_occupation: obj && obj?.work_profession && obj?.work_profession !== '' ? true : false
                        }
                    };
                }
                return prevObj;
            });
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let hasError = false;
        const emailSet = new Set();

        for (const [index, member] of membersList.entries()) {
            const validationErrors = validateIndividualsObjects(member);
            if (validationErrors?.length > 0) {
                validationErrors.forEach((error) => {
                    toastHandler(error + ' at ' + (index + 1), TOAST_TYPE_ERROR);
                });
                hasError = true;
                break;
            }

            if (member?.email_address) {
                if (!validateEmailValue(member?.email_address)) {
                    hasError = true;
                    toastHandler(`Invalid Email at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                }
            }

            if (member?.email_address) {
                if (emailSet.has(member.email_address)) {
                    hasError = true;
                    toastHandler(`Duplicate email found at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                } else {
                    emailSet.add(member.email_address);
                }
            }

            if (member?.phone_number?.length > 4) {
                if (!validatePhoneNumber(member?.phone_number)) {
                    hasError = true;
                    break;
                }
            }

            if (member?.tfn) {
                if (!validateTFN(member?.tfn)) {
                    hasError = true;
                    toastHandler(`Invalid TFN at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                }
            }
        }

        if (hasError) {
            return;
        }

        if (onSubmitForm) {
            onSubmitForm();
        }
    };

    const fetchBusinessOccupationOptions = async (payload) => {
        const payloadNew = {
            ...payload,
            type: 'business-industry-occupation'
        };
        return await dispatch(getformsfieldsOptionsThunk(payloadNew));
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="table-title font-poppins font-semibold">Member Details</div>
                <div onClick={handleAddEntry} className="cursor-pointer">
                    <NewPlusIcon className="w-8 h-8" />
                </div>
            </div>
            <form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                }}
            >
                <div>
                    {membersList?.map((memberObj, memberObjIndex) => (
                        <div className=" !bg-dimThird p-5  rounded-xl mt-5  " key={'membersList' + memberObjIndex}>
                            <div
                                onClick={() => handleRemoveEntry(memberObjIndex, memberObj?.bcr_id)}
                                className="cursor-pointer w-fit ml-auto flex justify-end items-center"
                            >
                                <label className="invisible"> f</label>
                                <BgDeleteIcon />
                            </div>

                            <div className=" default-forms grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-5  rounded-xl ">
                                <div className="">
                                    <label className="inputLabel">Member</label>

                                    <ComboboxComponentSSR
                                        fetchDataFunction={(payload) =>
                                            fetchReferencesListFun({
                                                ...payload,
                                                type: 'individual'
                                            })
                                        }
                                        defaultSelected={memberObj.business_client_id}
                                        removePreviousSelected
                                        onRemove={(_e) => {
                                            handleComboxboxEntity(null, memberObjIndex);
                                        }}
                                        filterSelectedValues={membersList || []}
                                        filterSelectedValueTargetElement={'business_client_id'}
                                        onChange={(res) => {
                                            const obj = res[0];
                                            handleComboxboxEntity(obj, memberObjIndex, memberObj?.isFirstLoad);
                                        }}
                                        targetProperty="business_client_id"
                                        placeholder={'Search by Name/Reference No.'}
                                        limit={1}
                                        showNameOnly
                                        sx="rounded-xl ring-gray-400 "
                                    />
                                </div>

                                <div className="">
                                    <InputField
                                        label={'First Name'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'first_name', memberObjIndex);
                                        }}
                                        name={'First Name'}
                                        inputValue={memberObj.first_name}
                                        placeholder={'First Name'}
                                        classes="mt-2 bg-transparent"
                                        disabled={memberObj?.disabled?.first_name}
                                    />
                                </div>
                                <div className="">
                                    <InputField
                                        label={'Last Name'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'last_name', memberObjIndex);
                                        }}
                                        name={'Last Name'}
                                        inputValue={memberObj.last_name}
                                        placeholder={'Last Name'}
                                        classes="mt-2 bg-transparent"
                                        disabled={memberObj?.disabled?.last_name}
                                    />
                                </div>
                                <div className="">
                                    <TFNInputField
                                        label={'TFN'}
                                        maxLength={9}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'tfn', memberObjIndex);
                                        }}
                                        name={'TFN'}
                                        inputValue={memberObj.tfn}
                                        placeholder={'TFN'}
                                        classes="mt-2 bg-transparent"
                                        disabled={memberObj?.disabled?.tfn}
                                    />
                                </div>
                                <div className="">
                                    <PhoneInputComponent
                                        value={memberObj.phone_number}
                                        title="Phone Number"
                                        placeholder="Enter Phone Number"
                                        titleSx=""
                                        sx="!rounded-xl bg-transparent"
                                        bg={'!bg-transparent'}
                                        atChange={(phone) => {
                                            handleEntityValue(phone, 'phone_number', memberObjIndex);
                                        }}
                                        isDisabled={memberObj?.disabled?.phone_number}
                                    />
                                </div>
                                <div className="">
                                    <EmailInput
                                        label={'Email Address'}
                                        onChange={(e) => {
                                            handleEntityValue(e, 'email_address', memberObjIndex);
                                        }}
                                        name={'Email Address'}
                                        value={memberObj.email_address}
                                        placeholder={'Email Address'}
                                        classes="mt-2 bg-transparent"
                                        disabled={memberObj?.disabled?.email_address}
                                    />
                                </div>

                                <div className="">
                                    <div>
                                        <label>Date of Birth</label>
                                    </div>
                                    <DatePick
                                        iconSet={{
                                            right: CalendarSmallIco
                                        }}
                                        maxDate={dayjs(dateMinus12Years, 'DD-MM-YYYY')}
                                        onDateChange={(date) => {
                                            handleEntityValue(date, 'dob', memberObjIndex);
                                        }}
                                        placeholder="DD-MM-YYYY"
                                        format="DD-MM-YYYY"
                                        value={memberObj?.dob ? dayjs(memberObj.dob, 'DD-MM-YYYY') : ''}
                                        sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full hover:bg-[#FAF9F6] !bg-transparent"
                                        isDisabled={memberObj?.disabled?.dob}
                                    />
                                </div>
                                <div className="">
                                    <PickAddress
                                        id={'residential-autocomplete' + memberObjIndex}
                                        address={memberObj?.residential_address}
                                        setAddress={(address) => {
                                            handleEntityValue(address, 'residential_address', memberObjIndex);
                                        }}
                                        onSelect={(place) => {
                                            handleEntityValue(
                                                place.formatted_address,
                                                'residential_address',
                                                memberObjIndex
                                            );
                                        }}
                                        label="Physical Address"
                                        placeholder="Enter Physical Address"
                                        classes="bg-transparent"
                                        disabled={memberObj?.disabled?.residential_address}
                                    />
                                </div>
                                <div>
                                    <div>
                                        <label className="!flex items-center gap-2 mb-2">Member Occupation</label>
                                    </div>
                                    <div className="mt-2 ">
                                        <SearchableComboboxSSR
                                            fetchDataFunction={fetchBusinessOccupationOptions}
                                            defaultSelected={memberObj?.member_occupation}
                                            selectedValue={memberObj?.member_occupation}
                                            removePreviousSelected
                                            allowSelectCustomValue
                                            placeholder="Select"
                                            inputsx="!font-normal !text-sm"
                                            sx={cn(
                                                '!rounded-xl !font-normal !ring-gray-400 mt-2 !flex',
                                                memberObj?.disabled?.member_occupation ? '!ring-0' : ''
                                            )}
                                            showNameOnly
                                            onChange={(obj) => {
                                                handleEntityValue(obj[0], 'member_occupation', memberObjIndex);
                                            }}
                                            limit={1}
                                            disabled={memberObj?.disabled?.member_occupation}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="flex justify-center mt-24 items-center">
                        <div className="flex gap-8 items-center">
                            <Button
                                title="Back"
                                classes={
                                    'border-blueish text-blueish border-2 h-[3rem] rounded-lg buttons-font-lighter font-fira px-14'
                                }
                                atClick={() => setCurrentStep(1)}
                                type="button"
                            />

                            <Button
                                title={'Next'}
                                classes={'ann-btn rounded-lg px-14 buttons-font-lighter font-fira'}
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MemberDetailsSubForm;
