import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { cn } from 'utills/tailwindUtil';

const InfiniteScrollComp = ({
    children,
    next,
    dataLength,
    hasMore,
    loader,
    height,
    className,
    scrollThreshold,
    scrollableTarget,
    endMessageContent
}) => {
    return (
        <InfiniteScroll
            dataLength={dataLength}
            next={next}
            hasMore={hasMore}
            loader={loader}
            className={className}
            height={height ? height : 400}
            scrollThreshold={scrollThreshold}
            scrollableTarget={scrollableTarget}
            style={{ width: '100%' }}
            endMessage={
                endMessageContent ? (
                    endMessageContent
                ) : (
                    <div
                        className={cn(
                            'mx-auto my-[10px] text-center !bg-white py-2 rounded-lg !shadow-sm  ring-1 ring-gray-200 px-1',
                            'py-2 rounded-lg'
                        )}
                        style={{ width: 'calc(100% - 10px)' }}
                    >
                        <h3 className="text-gray-800 text-sm font-semibold">No More Content</h3>
                    </div>
                )
            }
        >
            {children}
        </InfiniteScroll>
    );
};

export default InfiniteScrollComp;
