import React from 'react';

export const RtfFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#9CACA3" />
            <path
                d="M7.53906 18.5469V22H4.71094V10.7969H10.5312L12.0391 12.125V17.2188L11.0156 18.125L12.7109 22H9.875L8.35938 18.5469H7.53906ZM7.53906 13.6328V15.7109H9.50781V13.6328H7.53906ZM18.4531 22H15.625V13.6484H13.3516V10.7969H20.6797V13.6484H18.4531V22ZM24.7891 17.8047V22H21.9609V10.7969H29.2891V13.6484H24.7891V14.9609H27.7969V17.8047H24.7891Z"
                fill="white"
            />
        </svg>
    );
};
