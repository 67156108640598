/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState } from 'react';
import { BluePencil } from 'utills/svgs/BluePencil';
import ProgressBar from '@ramonak/react-progress-bar';
import { ChecklistIcon } from 'utills/svgs/ChecklistIcon';
import { LeftIcon } from 'utills/svgs/LeftIcon';
import { OrangeCalendar } from 'utills/svgs/OrangeCalendar';
import { OrangeEmail } from 'utills/svgs/OrangeEmail';
import { OrangeHash } from 'utills/svgs/OrangeHash';
import { OrangeTel } from 'utills/svgs/OrangeTel';
import { OrangeUserIcon } from 'utills/svgs/OrangeUserIcon';
import { Plus } from 'utills/svgs/Plus';
import './css/taskDetail.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import {
    archiveTaskFileThunk,
    createChecklistItemThunk,
    getActivityThunk,
    getAllTaskStatusesThunk,
    getAllTasks,
    getSingleTaskThunk,
    getTaskLogsThunk,
    markAsCompleteTaskThunk,
    removeEmployeeFromTaskThunk,
    updateChecklistItemThunk,
    updateTaskThunk,
    uploadTaskFileThunk
} from 'store/workspace/workspaceTasks';
import {
    BUSINESS_ID,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS,
    USER_TYPE,
    all_files_types,
    handleDownload,
    taskPriorityOptions
} from 'utills/globalVars';
import { convertDateFormatToLocal, formateDate } from 'utills/moment';
import { EmployeesModal } from './EmployeesModal';
import { toastHandler } from 'responseHanlder';
import { setLoader, setSideLoader } from 'store/global/globalReducer';
import { dynamicWidthgenerator } from 'utills/dynamicWidthGenerator';
import Activity from './Activity';
import cloneDeep from 'lodash/cloneDeep';
import { RoundedTick } from 'utills/svgs/RoundedTick';
import { useTaskBarColor } from './hooks/useTaskBarColor';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import Slideover from 'components/atoms/SlideOver';
import { NoDataFound } from 'components/common/NoDataFound';
import Badge from 'components/atoms/Badge';
import useRoleGuard from 'Hooks/useRoleGuard';
import CommandPalettes from 'components/atoms/CommandPalettes';
import { Button } from 'components/atoms/buttons/Button';
import AvatarGroup from 'components/atoms/AvatarGroup';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import moment from 'moment';
import { DefaultLogs } from 'components/molecules/DefaultLogs';
import { UseDownloadLogsPdf } from 'Hooks/Files/UseDownloadLogsPdf';
import { endpoint } from 'apiEndpoints';
import { Loader } from 'components/common/Loader';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';
import { Truncate } from 'components/molecules/Truncate';
import { TaskHistory } from './TaskHistory';
import { generateId } from 'utills/uid';
import { Chip } from 'components/atoms/buttons/Chip';
import MediumIcon from 'utills/svgs/MediumIcon';
import { Link } from 'react-router-dom';
import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { FileCardPreview } from 'components/atoms/FileCardPreview/FileCardPreview';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { MoveCard } from './MoveCard';
import { DragFile } from 'components/atoms/inputs/DragFile';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';

const TaskDetails = ({ toggleTaskDetails, handleTasksUpdate, open, setOpen }) => {
    const [_shareTask, setShareTask] = useState(false);
    const [_checkLoading, setCheckLoading] = useState(false);
    const [showTaskLogs, setShowTaskLogs] = useState(false);
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [isZoomed, setIsZoomed] = useState(false);

    const [openpdf, setOpenpdf] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const { task: taskItem } = useSelector((state) => state.global);
    const [task, setTask] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const { data } = useSelector((state) => state.getAllTaskStatuses);
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);
    const { data: loginData } = useSelector((state) => state.login);
    const { isLoading } = useSelector((state) => state.global);
    const { data: tasks } = useSelector((state) => state.getAllTasks);

    const [selectedImage, setSelectedImage] = useState('');
    const [_selectedPdf, setSelectedPdf] = useState('');

    const { handleDownloadLogsPdf } = UseDownloadLogsPdf();

    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]);
    const [count, setCount] = useState(null);
    const [term, setTerm] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [filteredLogs, setfilteredLogs] = useState([]);
    const [filterPage, setFilterPage] = useState(1);
    const [filterCount, setFilterCount] = useState(1);

    const [allAssignees, setAllAssignees] = useState([]);
    const [viewList, setViewList] = useState(false);

    const [allowAction, setAllowAction] = useState(false);
    const { hasPermissions } = useRoleGuard();

    useEffect(() => {
        if (task) {
            if (hasPermissions('Tasks', ['admin']) || loginData?.user_id === task?.created_by?.user_id) {
                setAllowAction(true);
            }
        }
    }, [task]);

    const toggleTaskLogs = () => {
        setShowTaskLogs(!showTaskLogs);
    };

    const [showAddEmp, setShowAddEmp] = useState(false);
    const toggleShowAddEmp = () => {
        setShowAddEmp(!showAddEmp);
    };
    const toggleModal = () => {
        if (!hasPermissions('Tasks', ['write', 'admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        setShowModal(!showModal);
    };
    const fetchActivity = () => {
        dispatch(setSideLoader(true));
        dispatch(
            getActivityThunk({
                task_id: taskItem?.task_id
            })
        ).finally(() => {
            dispatch(setSideLoader(false));
        });
    };
    const fetchEmployees = () => {
        dispatch(fetchRegisteredEmployeesThunk()).finally(() => {});
    };

    const getStatuses = () => {
        dispatch(getAllTaskStatusesThunk({ business_id })).finally(() => {});
    };

    useEffect(() => {
        if (hasPermissions('Tasks', ['read', 'write', 'admin'])) {
            fetchActivity();
        }
    }, []);
    useEffect(() => {
        if (!employees && hasPermissions('Tasks', ['read', 'write', 'admin'])) {
            fetchEmployees();
        }
    }, [employees]);
    useEffect(() => {
        if (!data && hasPermissions('Tasks', ['read', 'write', 'admin'])) {
            getStatuses();
        }
    }, [data]);

    const getSingleTask = () => {
        const payload = {
            task_id: taskItem.task_id,
            business_id
        };
        dispatch(setLoader(true));
        dispatch(getSingleTaskThunk(payload))
            .then((response) => {
                if (response.payload) {
                    const taskObj = cloneDeep(response.payload);
                    taskObj.checklists = taskObj?.checklists?.map((checklist) => {
                        return {
                            ...checklist,
                            checkListItems:
                                checklist?.checkListItems?.length > 0
                                    ? [...checklist?.checkListItems]?.sort((a, b) => {
                                          if (a?.sequence == null && b?.sequence == null) return 0;
                                          if (a?.sequence == null) return 1;
                                          if (b?.sequence == null) return -1;
                                          return Number(a?.sequence) - Number(b?.sequence);
                                      })
                                    : []
                        };
                    });
                    setTask(taskObj);
                    setShareTask(response.payload.share ? response.payload.share : false);
                    setAllAssignees(response.payload.employees || []);
                }
            })

            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleRemnoveAssigee = (employee_id) => {
        if (employee_id) {
            const payload = {
                task_id: taskItem.task_id,
                employee_id: employee_id
            };
            dispatch(setLoader(true));
            dispatch(removeEmployeeFromTaskThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        const newList = allAssignees.filter((user) => user?.user_id !== employee_id);
                        setAllAssignees(newList || []);
                        toastHandler('Successfully Removed!', 'success');
                    }
                    dispatch(setLoader(false));
                })

                .finally(() => {
                    getStatuses();
                });
        }
    };

    useEffect(() => {
        if (open && hasPermissions('Tasks', ['read', 'write', 'admin'])) {
            getSingleTask();
        }
    }, [open]);

    const updateItem = (task_checklist_item_id, value, e, index, listIndex) => {
        const newTask = cloneDeep(task);
        const checked = e.target.checked;
        const payload = {
            task_checklist_item_id,
            value,
            is_completed: checked
        };

        setCheckLoading(true);
        newTask.checklists[listIndex].checkListItems[index].is_completed = checked;
        setTask(newTask);

        dispatch(updateChecklistItemThunk(payload))
            .then((response) => {
                if (response.payload) {
                    newTask.checklists[listIndex].checkListItems[index].is_completed =
                        response.payload?.is_completed || checked;
                    const newTaskArray = tasks?.map((obj) => {
                        if (obj.task_status_id === newTask?.task_status_id) {
                            return {
                                ...obj,
                                tasks: obj.tasks.map((taskObj) => {
                                    if (taskObj?.task_id === newTask?.task_id) {
                                        return {
                                            ...taskObj,
                                            checklist: newTask.checklists
                                        };
                                    }
                                    return taskObj;
                                })
                            };
                        }
                        return obj;
                    });
                    setTask(newTask);
                    dispatch(getAllTasks.actions.handleUpdate(newTaskArray));
                }
            })

            .finally(() => {
                setCheckLoading(false);
                dispatch(setLoader(false));
            });
    };

    const handleChecklistProgress = () => {
        if (task) {
            let newChecklistItems = [];
            task?.checklists?.forEach((checklist) => {
                checklist?.checkListItems?.forEach((item) => {
                    newChecklistItems?.push(item);
                });
            });

            const total = newChecklistItems?.length;
            const completed = newChecklistItems?.filter((items) => items?.is_completed === true).length;
            const totalres = 100 / total;
            const result = completed * totalres;

            return Math.ceil(result);
        }
    };

    const [checklistIndex, _setListIndex] = useState(null);
    const [showAddItem, setShowAddItem] = useState(false);

    const [itemName, setItemName] = useState('');

    const handleSubmitItem = (task_checklist_id) => {
        const payload = {
            task_checklist_id,
            value: itemName,
            is_completed: false
        };

        dispatch(createChecklistItemThunk(payload)).then((response) => {
            if (response.payload) {
                setItemName('');
                setShowAddItem(false);
                getSingleTask();
            }
        });
    };
    const hideAddList = () => {
        setShowAddItem(false);
    };

    const [showTitleEditor, setShowTitleEditor] = useState(false);
    const [editTitle, setEditTitle] = useState('');

    const [showPNotesEditor, setShowPNotesEditor] = useState(false);
    const [editPNotes, setEditPNotes] = useState('');
    const togglePNotesEditor = () => {
        setShowPNotesEditor(!showPNotesEditor);
    };

    const [showDescEditor, setShowDescEditor] = useState(false);
    const [editDesc, setEditDesc] = useState('');

    const updateTaskDef = () => {
        const payload = {
            task_id: task?.task_id,
            title: editTitle,
            description: editDesc,
            personal_note: editPNotes,
            business_id
        };

        dispatch(updateTaskThunk(payload)).then((response) => {
            if (response.payload) {
                setShowTitleEditor(false);
                setShowDescEditor(false);
                setShowPNotesEditor(false);
                getSingleTask();
                handleTasksUpdate();
            }
        });
    };

    useEffect(() => {
        if (task && hasPermissions('Tasks', ['read', 'write', 'admin'])) {
            setEditTitle(task?.title);
            setEditPNotes(task?.personal_note);
            setEditDesc(task?.description?.replace(/<[^>]*>/g, ''));
        }
    }, [task]);

    const [uploadFiles, setUploadFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);

    const [filesloader, setFilesLoader] = useState(false);

    const removeFile = (index) => {
        if (!hasPermissions('Tasks', ['admin', 'write'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        const newArr = [...uploadFiles];
        const newPreview = [...uploadingFiles];

        const uploadingFile = newPreview[index];
        const file = newArr[index];

        const newIndex = newArr.indexOf(file);
        const uploadingIndex = newPreview?.indexOf(uploadingFile);

        newArr.splice(newIndex, 1);
        newPreview?.splice(uploadingIndex, 1);

        setUploadingFiles([...newPreview]);
        setUploadFiles([...newArr]);
    };

    const uploadTaskFiles = () => {
        if (!hasPermissions('Tasks', ['admin', 'write'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }

        const formData = new FormData();
        formData.append('task_id', task?.task_id);
        formData.append('uploaded_by', USER_TYPE);

        if (uploadFiles) {
            uploadFiles.forEach((file) => {
                formData.append('task_files', file);
            });
        }
        setFilesLoader(true);
        dispatch(uploadTaskFileThunk(formData))
            .then((response) => {
                if (response.payload) {
                    handleTasksUpdate();

                    getSingleTask();
                    setUploadFiles([]);
                    setUploadingFiles([]);
                }
            })
            .finally(() => {
                setFilesLoader(false);
            });
    };

    const removeTaskFile = (task_files_id) => {
        const payload = {
            task_id: task?.task_id,
            task_files_id
        };

        dispatch(archiveTaskFileThunk(payload)).then((response) => {
            if (response?.payload) {
                handleTasksUpdate();

                getSingleTask();
            }
        });
    };

    const markAsCompleted = () => {
        if (!hasPermissions('Tasks', ['admin', 'write'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        if (task?.completed_at) {
            return;
        }

        let hasIncompleteItem = false;
        task.checklists.forEach((checkList) => {
            const hasFalse = checkList.checkListItems.some((item) => item.is_completed === false);
            hasIncompleteItem = hasFalse;
        });
        if (hasIncompleteItem) {
            return toastHandler('Mark All Checklist', TOAST_TYPE_ERROR);
        }
        const payload = {
            task_id: task?.task_id
        };
        dispatch(setLoader(true));
        dispatch(markAsCompleteTaskThunk(payload))
            .then((response) => {
                toastHandler('Mark as Completed successfully', TOAST_TYPE_SUCCESS);
                if (response.payload) {
                    getSingleTask();
                    handleTasksUpdate();
                    toggleTaskDetails();
                }
                dispatch(setLoader(false));
            })

            .finally((_e) => {
                dispatch(setLoader(false));
            });
    };

    const modalRefone = useRef(null);
    const modalReftwo = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRefone.current && !modalRefone.current.contains(event.target)) {
                updateTaskDef();
            }
        }

        document?.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRefone, editTitle, editDesc, editPNotes]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalReftwo.current && !modalReftwo.current.contains(event.target)) {
                updateTaskDef();
            } else {
                //
            }
        }

        document?.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalReftwo, editTitle, editDesc, editPNotes]);

    const { color } = useTaskBarColor(task || {});

    const fetchLogs = () => {
        const payload = {
            task_id: task?.task_id,
            page: term || dateFrom || dateTo ? filterPage : page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            business_id,
            filter: {
                text: term,
                from_date: dateFrom && moment(new Date(dateFrom)).format('DD-MM-YYYY'),
                end_date: dateTo && moment(new Date(dateTo)).format('DD-MM-YYYY')
            }
        };

        dispatch(setSideLoader(true));
        dispatch(getTaskLogsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    if (dateFrom || dateTo || term) {
                        setFilterCount(response?.payload?.count);
                        const newLogs = [...filteredLogs, ...response.payload.logs];
                        setfilteredLogs(newLogs);
                    } else {
                        setCount(response?.payload?.count);
                        if (page > 1) {
                            const newLogs = [...logs, ...response.payload.logs];
                            setLogs(newLogs);
                        } else {
                            const newLogs = response.payload.logs;
                            setLogs(newLogs);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handleMore = () => {
        const totalPages = Math.ceil(count / Number(PAGINATION_PAGE_SIZE));
        const togleFilterPages = Math.ceil(filterCount / Number(PAGINATION_PAGE_SIZE));

        if (dateFrom || dateTo || term) {
            if (filterPage < togleFilterPages) {
                setFilterPage(filterPage + 1);
            }
            return;
        }
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handleDownloadActivity = () => {
        const payload = {
            task_id: task?.task_id,
            business_id: business_id
        };
        handleDownloadLogsPdf(payload, endpoint.downloadTaskDetailsLogsPdf);
    };
    const getName = (obj) => {
        return obj?.first_name && obj?.first_name !== ''
            ? obj?.first_name + ' ' + (obj?.last_name || '')
            : obj?.display_name;
    };

    const getClickProfileLink = (customer = null) => {
        if (customer && customer?.business_client_id !== '') {
            return '/app/user/profile/' + customer?.business_client_id;
        } else {
            return false;
        }
    };

    const handlePreviewFile = (file) => {
        // Check if `file` is a valid File object before creating the URL
        if (file instanceof File || file instanceof Blob) {
            const url = URL.createObjectURL(file);

            // Check the type of the file for previewing
            if (file.type.includes('jpg') || file.type.includes('png') || file.type.includes('jpeg')) {
                setSelectedImage(url); // Set image preview
            } else if (file.type.includes('application/pdf')) {
                setSelectedPdf(url); // Set PDF preview
            }
        } else {
            console.error('Invalid file object passed to handlePreviewFile.');
        }
    };

    const onRemoveFile = (index) => {
        const newFiles = [...uploadFiles];
        newFiles.splice(index, 1);
        setUploadFiles(newFiles);
    };

    return (
        <>
            <Slideover open={open} setOpen={setOpen} size={'max-w-[1500px]'}>
                <RoleBasedGuard module={'Tasks'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                    {openpdf && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpenpdf} open={openpdf} /> : ''}
                    {selectedImage && <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} />}
                    {isLoading && <Loader />}

                    {showTaskLogs && (
                        <DefaultLogs
                            toggleLogs={toggleTaskLogs}
                            fetchLogs={fetchLogs}
                            logs={dateFrom || dateTo || term ? filteredLogs : logs}
                            count={count}
                            pageSize={PAGINATION_PAGE_SIZE}
                            page={page}
                            handleMore={handleMore}
                            term={term}
                            setTerm={setTerm}
                            dateFrom={dateFrom}
                            setDateFrom={setDateFrom}
                            dateTo={dateTo}
                            setDateTo={setDateTo}
                            setFilterPage={setFilterPage}
                            filterPage={filterPage}
                            filterCount={filterCount}
                            setfilteredLogs={setfilteredLogs}
                            open={showTaskLogs}
                            setOpen={setShowTaskLogs}
                            downloadActivity={handleDownloadActivity}
                        />
                    )}

                    <div className="text-3xl px-5 font-fira font-semibold">Task Details</div>

                    <div className="flex justify-end items-center mx-5 gap-3 "></div>

                    <div className="m-5 relative">
                        <div className="lg:grid lg:grid-cols-3 gap-5">
                            <div className="col-span-2">
                                <div className="task-detail-main-card default-forms bg-white  rounded-xl p-5 ">
                                    {!showTitleEditor ? (
                                        <div className="flex gap-2 items-center justify-between">
                                            {' '}
                                            <div className="xl-title text-blueish font-poppins break-words">
                                                {task?.title}
                                            </div>{' '}
                                            <Chip
                                                title={task?.priority}
                                                iconSet={{
                                                    leftIcon:
                                                        taskPriorityOptions?.find((obj) => obj.label === task?.priority)
                                                            ?.icon || MediumIcon
                                                }}
                                                classes={'flex items-center gap-1 bg-gray-100 px-3 py-1 '}
                                            />
                                        </div>
                                    ) : (
                                        <div ref={modalRefone} className=" buttons-font-lighter">
                                            <div>
                                                {' '}
                                                <input
                                                    value={editTitle}
                                                    onChange={(e) => setEditTitle(e.target.value)}
                                                    type="text"
                                                />{' '}
                                            </div>
                                            <div></div>
                                        </div>
                                    )}
                                    {!showDescEditor ? (
                                        <div className=" buttons-font-lighter flex gap-2 w-full relative mt-4">
                                            <div className="flex-1 w-[80%] break-all !text-black">
                                                {task?.description && (
                                                    <Truncate text={task?.description} maxLength={450} />
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div ref={modalReftwo}>
                                            <div>
                                                {' '}
                                                <DefaultTextArea
                                                    placeholder="Description..."
                                                    className="add-item-area p-3"
                                                    onChange={(e) => setEditDesc(e)}
                                                    cols="30"
                                                    rows="10"
                                                    value={editDesc}
                                                />
                                            </div>
                                            <div className="flex gap-2 justify-end items-center">
                                                <div></div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="task-detail-main-card p-5 rounded-xl mt-5">
                                    {task?.checklists?.length > 0 ? (
                                        <div>
                                            {' '}
                                            <div>
                                                {' '}
                                                <div className="flex gap-2 items-center">
                                                    <div>
                                                        {' '}
                                                        <ChecklistIcon />
                                                    </div>
                                                    <div>
                                                        <label className="text-lg font-semibold font-poppins text-blueish">
                                                            Checklists
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {' '}
                                                <div className="my-2 mt-3 add-ann-form   gap-2 flex items-center">
                                                    {' '}
                                                    <div className="w-[96%]">
                                                        {' '}
                                                        <ProgressBar
                                                            completed={handleChecklistProgress()}
                                                            bgColor="#b695f8"
                                                            height="7px"
                                                            width="100%"
                                                            animateOnRender={true}
                                                            isLabelVisible={false}
                                                        />
                                                    </div>
                                                    <div className="assigned-people-detail w-[4%] small-text">
                                                        {handleChecklistProgress()}%
                                                    </div>
                                                </div>{' '}
                                            </div>
                                            {task?.checklists.map((checklist, listIndex) => (
                                                <div className=" text-black mt-3 " key={checklist.task_checklist_id}>
                                                    <div className="mt-3">
                                                        <div>
                                                            <div className="flex justify-between items-center">
                                                                {' '}
                                                                <div className="checklist-title text-lg  font-[500]">
                                                                    {checklist?.title}{' '}
                                                                </div>
                                                            </div>

                                                            {checklist?.checkListItems &&
                                                                checklist?.checkListItems?.map((check, index) => (
                                                                    <div
                                                                        key={
                                                                            'checklistItem' +
                                                                            check?.task_checklist_id +
                                                                            check?.task_checklist_item_id
                                                                        }
                                                                        className="flex justify-between items-center"
                                                                    >
                                                                        <div className="flex gap-3 mt-5  task-check items-center">
                                                                            <div>
                                                                                <input
                                                                                    onChange={(e) => {
                                                                                        if (
                                                                                            !hasPermissions('Tasks', [
                                                                                                'admin',
                                                                                                'write'
                                                                                            ])
                                                                                        ) {
                                                                                            return toastHandler(
                                                                                                'You do not have permission to perform this action.',
                                                                                                TOAST_TYPE_ERROR
                                                                                            );
                                                                                        }
                                                                                        updateItem(
                                                                                            check.task_checklist_item_id,
                                                                                            check.value,
                                                                                            e,
                                                                                            index,
                                                                                            listIndex
                                                                                        );
                                                                                    }}
                                                                                    type="checkbox"
                                                                                    checked={!!check.is_completed}
                                                                                    id={
                                                                                        'task_check' +
                                                                                        check.task_checklist_item_id
                                                                                    }
                                                                                    className=" !rounded-[5px] h-[20px] w-[20px] !border-[#979797] "
                                                                                />
                                                                            </div>
                                                                            <label
                                                                                htmlFor={
                                                                                    'task_check' +
                                                                                    check.task_checklist_item_id
                                                                                }
                                                                                className={`buttons-font-lighter font-poppins cursor-pointer ${
                                                                                    check.is_completed && 'line-through'
                                                                                }`}
                                                                            >
                                                                                {' '}
                                                                                {check?.value}{' '}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>

                                                        {showAddItem && allowAction && checklistIndex === listIndex ? (
                                                            <div className="mt-2 add-ann-form">
                                                                <div>
                                                                    {' '}
                                                                    <input
                                                                        onChange={(e) => setItemName(e.target.value)}
                                                                        type="text"
                                                                        placeholder="Item Name"
                                                                    />{' '}
                                                                </div>
                                                                <div className="flex items-center gap-3 mt-3">
                                                                    <button
                                                                        onClick={() =>
                                                                            handleSubmitItem(
                                                                                checklist.task_checklist_id
                                                                            )
                                                                        }
                                                                        className="bg-linear text-white font-fira buttons-font-lighter px-8 h-[2.5rem] rounded-xl "
                                                                    >
                                                                        Add
                                                                    </button>
                                                                    <button
                                                                        className="checklist-cancel-btn  font-fira buttons-font-lighter rounded-xl px-5 h-[2.5rem]"
                                                                        onClick={() => hideAddList()}
                                                                    >
                                                                        Cancel
                                                                    </button>{' '}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <>
                                            <NoDataFound message="No Checklist found!" height="20vh" />
                                        </>
                                    )}
                                </div>

                                <div className="task-detail-main-card mt-3 text-black p-5 rounded-xl">
                                    <div className="flex gap-5 items-center flex-wrap ">
                                        {task?.attachments?.map((file, index) => (
                                            <FileCardPreview
                                                url={file?.url}
                                                key={index}
                                                name={file?.name}
                                                size={''}
                                                extension={
                                                    file?.url?.includes('.')
                                                        ? file?.url?.split('.')?.pop()?.toLowerCase()
                                                        : file?.url.split('/').pop().toLowerCase()
                                                }
                                                onClick={() => {
                                                    if (file?.url?.toLowerCase()?.endsWith('.pdf')) {
                                                        setPdfUrl(file?.url);
                                                        setOpenpdf(true);
                                                    } else if (
                                                        file?.url?.toLowerCase()?.endsWith('.jpg') ||
                                                        file?.url?.toLowerCase()?.endsWith('.jpeg') ||
                                                        file?.url?.toLowerCase()?.endsWith('.png')
                                                    ) {
                                                        setSelectedImage(file?.url);
                                                    } else {
                                                        handleDownload(file);
                                                    }
                                                }}
                                                onRemove={() => removeTaskFile(file?.task_files_id)}
                                            />
                                        ))}
                                    </div>
                                    <div className="mt-3">
                                        {/* {console.info(uploadingFiles, 'uploadingFiles')} */}
                                        <div className="flex items-center flex-wrap gap-2">
                                            {uploadingFiles.map((image, index) => (
                                                <div key={generateId() + index}>
                                                    <div className="flex items-start gap-3 my-3 flex-wrap">
                                                        <Badge
                                                            leftIcon={
                                                                <div>
                                                                    <GenerateFileIcon
                                                                        url={uploadFiles[index]?.type}
                                                                        showImage
                                                                        imgPrperties={{
                                                                            url: uploadFiles[index],
                                                                            type: uploadFiles[index].type,
                                                                            onClick: () => {
                                                                                setIsZoomed(true);
                                                                            }
                                                                        }}
                                                                        sx={
                                                                            'thumbnail-image cursor-pointer !w-10 !h-10 bg-white rounded-md mr-2'
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                            text={uploadFiles[index]?.name?.slice(0, 7)}
                                                            sx="text-white bg-third rounded-md !px-3 !text-md"
                                                            onCancel={() => removeFile(index)}
                                                        />
                                                        {isZoomed && (
                                                            <ZoomImage
                                                                src={image}
                                                                alt="snow"
                                                                onClose={() => setIsZoomed(false)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {allowAction && (
                                            <>
                                                <div className="flex gap-2 flex-col items-start w-full flex-wrap mt-5">
                                                    {/* <Button title={'Add an attachment'} classes={' px-5 py-2 rounded-xl'} /> */}
                                                    {/* <Button
                                                        title="Add an attachment"
                                                        classes={
                                                            'bg-blueish text-white !text-sm font-[400] !px-5 !py-2 !rounded-xl'
                                                        }
                                                        atClick={() => {
                                                            console.error();
                                                        }}
                                                    /> */}

                                                    {/* <DefaultLightBgCard title={'Upload Attachments'} sx="w-full"> */}
                                                    <div className="default-forms w-full">
                                                        <label className="mb-2"> Upload Attachment</label>
                                                        <DragFile
                                                            selectedFiles={uploadFiles}
                                                            atChange={(file) => {
                                                                setUploadFiles([...file, ...uploadFiles]);
                                                            }}
                                                            accept={all_files_types}
                                                            isMultiple={true}
                                                            hideSelectedFiles={true}
                                                            dragMessage="Drag files here to upload, or browse for files"
                                                            dropMessage="Drop the selected files in this box... "
                                                            // message="Only Pdf and jpg files are allowed"
                                                            message="This file type is not allowed"
                                                        />

                                                        <div className="mt-5 flex gap-5 items-center flex-wrap">
                                                            {uploadFiles?.map((file, index) => (
                                                                <FileCardPreview
                                                                    url={file?.name}
                                                                    key={index}
                                                                    showImage={true}
                                                                    name={file?.name}
                                                                    size={file?.size}
                                                                    extension={
                                                                        file?.type?.includes('.')
                                                                            ? file?.type
                                                                                  ?.split('.')
                                                                                  ?.pop()
                                                                                  ?.toLowerCase()
                                                                            : file?.type?.split('/').pop().toLowerCase()
                                                                    }
                                                                    imgPrperties={{
                                                                        url: file,
                                                                        type: file?.type,
                                                                        sx: ''
                                                                    }}
                                                                    onClick={() => handlePreviewFile(file)}
                                                                    onRemove={() => onRemoveFile(index)}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {/* </DefaultLightBgCard> */}

                                                    {/* <label htmlFor="files-input">
                                                <div className="ann-btn rounded-xl cursor-pointer  buttons-font-lighter font-fira flex justify-center items-center px-5 py-2 ">
                                                    Add an attachment
                                                </div>
                                            </label> */}
                                                    <div className="mt-3 flex justify-end items-center w-full">
                                                        {uploadFiles?.length > 0 && (
                                                            <button
                                                                disabled={!!filesloader}
                                                                onClick={uploadTaskFiles}
                                                                className="ann-btn buttons-font font-fira rounded-md px-5 py-2 flex gap-2 items-center"
                                                            >
                                                                Upload {filesloader && <SmallLoaderWhite />}
                                                            </button>
                                                        )}
                                                    </div>
                                                    {/* <input
                                                id="files-input"
                                                type="file"
                                                accept="images/*"
                                                multiple
                                                onChange={(e) => handleFilesChange(e)}
                                                style={{ display: 'none' }}
                                            /> */}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="task-detail-main-card mt-5 text-black p-5 rounded-xl">
                                    <div className="flex justify-between items-center">
                                        <div className="flex gap-2 items-center">
                                            <div>
                                                <label className="text-lg font-semibold">Compliance Notes</label>
                                            </div>
                                        </div>
                                        <div> </div>
                                    </div>

                                    <div className="mt-3">
                                        {!showPNotesEditor ? (
                                            <div className="detail-personal_note buttons-font-lighter flex gap-2 w-full relative mt-4">
                                                <div className="flex-1 w-[80%] break-all">
                                                    {task?.personal_note && (
                                                        <Truncate text={task?.personal_note} maxLength={250} />
                                                    )}
                                                </div>
                                                {allowAction && hasPermissions('Tasks', ['write', 'admin']) && (
                                                    <div className=" cursor-pointer" onClick={togglePNotesEditor}>
                                                        {' '}
                                                        <BluePencil />
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div ref={modalReftwo}>
                                                <div>
                                                    <DefaultTextArea
                                                        placeholder="Description..."
                                                        className="add-item-area p-3"
                                                        onChange={(e) => setEditPNotes(e)}
                                                        value={editPNotes}
                                                    />{' '}
                                                </div>
                                                <div className="flex gap-2 justify-end items-center">
                                                    <div></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <Activity task={task} fetchActivity={fetchActivity} />
                                </div>
                            </div>
                            <div className="lg:col-span-1 lg:mt-0 mt-5 ">
                                <div className="add-ann-form text-black task-detail-main-card p-5 w-72 md:w-full rounded-xl relative">
                                    <div className="flex justify-between items-center">
                                        <label className="text-lg font-semibold font-poppins">Assigned To</label>
                                        {task?.job && (
                                            <Link
                                                to={`/job-details/${task?.job?.job_id}`}
                                                className="bg-[#b695f8]/10 text-thirdbg rounded-lg font-poppins text-sm px-5 p-2"
                                            >
                                                Job Ref : {task?.job?.ref_no || '--'}
                                            </Link>
                                        )}
                                    </div>
                                    <div className="mt-4 task-assigned-people ">
                                        <div className="task-images-wrapper items-center relative">
                                            <div className="task-images-stack  ">
                                                <div className="assigned-images-wrapper">
                                                    <AvatarGroup
                                                        list={allAssignees}
                                                        limit={4}
                                                        targetProperty={'image'}
                                                        onClick={() => setViewList(true)}
                                                    />
                                                    {allowAction && (
                                                        <label>
                                                            {' '}
                                                            <button
                                                                onClick={toggleShowAddEmp}
                                                                className="add-assigned-btn"
                                                            >
                                                                <Plus />
                                                            </button>
                                                        </label>
                                                    )}
                                                </div>
                                                {showAddEmp && allowAction && (
                                                    <EmployeesModal
                                                        handleTasksUpdate={handleTasksUpdate}
                                                        getSingleTask={getSingleTask}
                                                        toggleShowAddEmp={toggleShowAddEmp}
                                                        employees={employees}
                                                        task_id={task?.task_id}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-2 mt-6">
                                        {' '}
                                        {}
                                        <ProgressBar
                                            completed={dynamicWidthgenerator(task?.start_date, task?.end_date)}
                                            bgColor={color}
                                            height="7px"
                                            width="100%"
                                            animateOnRender={true}
                                            isLabelVisible={false}
                                        />
                                    </div>
                                    <div className="progress-date x-small-text flex justify-between items-center ">
                                        <div>{task?.start_date ? formateDate(task?.start_date) : '--'}</div>
                                        <div>{task?.end_date ? formateDate(task?.end_date) : '--'}</div>
                                    </div>

                                    <div className="assigned-details-people mt-5  text-black">
                                        <div className="flex gap-3 items-center">
                                            <div>
                                                <OrangeUserIcon />
                                            </div>
                                            <div>
                                                <label className="buttons-font text-blueish font-poppins">
                                                    Created By
                                                </label>
                                                <div className="assigned-people-detail x-small-text font-poppins">
                                                    {task?.created_by?.first_name} {task?.created_by?.last_name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex gap-3 mt-4 items-center">
                                            <div>
                                                <OrangeCalendar />
                                            </div>
                                            <div>
                                                <label className="buttons-font font-poppins">Created On</label>
                                                <div className="assigned-people-detail  x-small-text font-poppins">
                                                    {convertDateFormatToLocal(
                                                        task?.created_at,
                                                        'DD-MM-YYYYTHH:mm:ssZ',
                                                        'MMM, DD, YYYY hh:mm A'
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex gap-3 mt-3 items-center">
                                            <div>
                                                <OrangeCalendar />
                                            </div>
                                            <div>
                                                <label className="buttons-font font-poppins">Updated On</label>
                                                <div className="assigned-people-detail x-small-text font-poppins">
                                                    {convertDateFormatToLocal(
                                                        task?.updated_at,
                                                        'DD-MM-YYYYTHH:mm:ssZ',
                                                        'MMM, DD, YYYY hh:mm A'
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex gap-3 mt-4 items-center">
                                            <div>
                                                <OrangeUserIcon />
                                            </div>
                                            <div>
                                                <label className="buttons-font font-poppins text-blueish">
                                                    Client Name
                                                </label>
                                                <Link to={getClickProfileLink(task?.customer)}>
                                                    <div className="assigned-people-detail x-small-text">
                                                        {getName(task?.customer)}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="flex gap-3 mt-4 items-center">
                                            <div>
                                                <OrangeEmail />
                                            </div>
                                            <div>
                                                <label className="buttons-font font-poppins text-blueish">
                                                    Client Email
                                                </label>
                                                <Link to={getClickProfileLink(task?.customer)}>
                                                    <div className="assigned-people-detail x-small-text">
                                                        {task?.customer?.email ?? '_ _'}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="flex gap-3 mt-4 items-center">
                                            <div>
                                                <OrangeTel />
                                            </div>
                                            <div>
                                                <label className="buttons-font font-poppins text-blueish">
                                                    Client Phone No
                                                </label>
                                                <Link to={getClickProfileLink(task?.customer)}>
                                                    <div className="assigned-people-detail x-small-text">
                                                        {task?.customer?.phone_number ?? '_ _'}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="flex gap-3 mt-4 items-center">
                                            <div>
                                                <OrangeHash />
                                            </div>
                                            <div>
                                                <label className="buttons-font font-poppins text-blueish">
                                                    Client Reference No
                                                </label>
                                                <Link to={getClickProfileLink(task?.customer)}>
                                                    <div className="assigned-people-detail x-small-text">
                                                        {task?.reference_number ?? '_ _ '}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="flex gap-3 mt-4 items-center truncate">
                                            <div>
                                                <OrangeHash />
                                            </div>
                                            <div>
                                                <label className="buttons-font font-poppins text-blueish">
                                                    Task ID
                                                </label>
                                                <div className="assigned-people-detail x-small-text truncate">
                                                    {task?.task_ref_no ?? '_ _ '}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-10 flex gap-5 flex-col justify-center lg:px-12  text-center">
                                        <button
                                            onClick={toggleModal}
                                            className="ann-btn px-10 rounded-xl buttons-font-lighter font-fira  justify-center py-2 flex gap-2 items-center "
                                        >
                                            <span>
                                                <LeftIcon />{' '}
                                            </span>{' '}
                                            <span>Change Status</span>
                                        </button>

                                        <button
                                            onClick={markAsCompleted}
                                            className="ann-btn  px-10 justify-center  buttons-font-lighter font-fira rounded-xl  py-2 flex items-center gap-2"
                                        >
                                            <span>
                                                <RoundedTick />
                                            </span>{' '}
                                            {!task?.completed_at && <span> Mark as Completed</span>}
                                            {task?.completed_at && <span>Completed</span>}
                                        </button>
                                    </div>
                                    {showModal && (
                                        <MoveCard
                                            toggleModal={toggleModal}
                                            data={
                                                data &&
                                                data.filter((obj) => obj?.task_status_id !== task?.task_status_id)
                                            }
                                            handleTasksUpdate={handleTasksUpdate}
                                            task_id={task?.task_id}
                                            task={task}
                                            toggleTaskDetails={toggleTaskDetails}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {task?.task_id && <TaskHistory taskId={task?.task_id} />}

                    <CommandPalettes
                        open={viewList}
                        setOpen={setViewList}
                        list={allAssignees}
                        actionButton={(event) => (
                            <Button
                                title={'Remove Assignee'}
                                atClick={() => {
                                    handleRemnoveAssigee(event?.user_id);
                                }}
                                classes={'bg-linear w-full text-white buttons-font-lighter rounded-lg py-2 font-fira'}
                            />
                        )}
                    />
                </RoleBasedGuard>
            </Slideover>
        </>
    );
};

export default TaskDetails;
