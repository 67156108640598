import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { InputField } from 'components/atoms/inputs/InputField';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import dayjs from 'dayjs';
import { PickAddress } from 'global-components/PickAddress';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteClientRefThunk, searchClientsThunk } from 'store/client';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
// import { TFNInputField } from '../../../../../atoms/inputs/TFNInputField';
import { EMAIL_LENGTH } from 'utills/constants/validation-constants/validation.constants';
import { dateMinus12Years } from 'utills/moment';

const PublicOfficers = ({ setCurrentStep, publicOfficer, setPublicOfficer, handlepublicOfficerNext }) => {
    const dispatch = useDispatch();

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleEntityValue = (value, targetProperty) => {
        setPublicOfficer((prev) => ({
            ...prev,
            [targetProperty]: value
        }));
    };

    const removeBCRIdFun = () => {
        if (publicOfficer?.bcr_id && publicOfficer?.business_client_id !== '') {
            dispatch(deleteClientRefThunk({ bcr_id: publicOfficer.bcr_id }));
        }
        return true;
    };

    const handleComboxboxEntity = (obj = null, isFirstLoad = false) => {
        setPublicOfficer((prevPublicOfficer) => {
            return isFirstLoad === true
                ? { ...prevPublicOfficer, isFirstLoad: false }
                : prevPublicOfficer?.business_client_id === obj?.business_client_id
                ? { ...prevPublicOfficer }
                : {
                      ...prevPublicOfficer,
                      business_client_id: obj ? obj?.business_client_id : '',
                      bcr_id: obj ? obj?.bcr_id : '',
                      first_name: obj ? obj?.first_name : '',
                      last_name: obj ? obj?.last_name : '',

                      phone_number: obj ? obj?.phone_number : '',
                      email_address: obj ? obj?.email : '',
                      dob: obj ? obj?.date_of_birth : '',
                      officer_status: 'Inactive',
                      residential_address: obj ? obj?.residential_address : '',
                      disabled: {
                          first_name: obj && obj?.first_name && obj?.first_name !== '' ? true : false,
                          last_name: obj && obj?.last_name && obj?.last_name !== '' ? true : false,

                          phone_number: obj && obj?.phone_number && obj?.phone_number !== '' ? true : false,
                          email_address: obj && obj?.email && obj?.email !== '' ? true : false,
                          dob: obj && obj?.date_of_birth && obj?.date_of_birth !== '' ? true : false,
                          officer_status: obj && obj?.officer_status && obj?.officer_status !== '' ? true : false,
                          residential_address:
                              obj && obj?.residential_address && obj?.residential_address !== '' ? true : false
                      }
                  };
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };
    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="table-title font-poppins font-semibold">Public Officer Details</div>
            </div>

            <form onSubmit={(e) => handlepublicOfficerNext(e)} onKeyDown={handleKeyDown}>
                <div>
                    <div className="mt-5">
                        <div className=" default-forms grid md:grid-cols-2 lg:grid-cols-3 gap-5  rounded-xl ">
                            <div className="w-[100%]">
                                <label className="inputLabel">Public Officer</label>

                                <ComboboxComponentSSR
                                    fetchDataFunction={(payload) =>
                                        fetchReferencesListFun({
                                            ...payload,
                                            type: 'individual'
                                        })
                                    }
                                    defaultSelected={publicOfficer.business_client_id}
                                    removePreviousSelected
                                    onRemove={(e) => {
                                        console.info(e);
                                        removeBCRIdFun();
                                        handleComboxboxEntity(null, false);
                                    }}
                                    filterSelectedValues={[publicOfficer] || []}
                                    filterSelectedValueTargetElement={'business_client_id'}
                                    onChange={(res) => {
                                        const obj = res[0];
                                        handleComboxboxEntity(obj, publicOfficer?.isFirstLoad);
                                    }}
                                    targetProperty="business_client_id"
                                    placeholder={'Search by Name/Reference No.'}
                                    limit={1}
                                    sx="rounded-xl ring-gray-400 "
                                    showNameOnly
                                />
                            </div>

                            <div className="w-[100%]">
                                <InputField
                                    label={'First Name'}
                                    maxLength={30}
                                    allowSpaces={true}
                                    onChange={(e) => {
                                        handleEntityValue(e.target.value, 'first_name');
                                    }}
                                    name={'First Name'}
                                    inputValue={publicOfficer.first_name}
                                    placeholder={'First Name'}
                                    classes="mt-2 bg-transparent"
                                    disabled={publicOfficer?.disabled?.first_name}
                                />
                            </div>
                            <div className="w-[100%]">
                                <InputField
                                    label={'Last Name'}
                                    maxLength={30}
                                    allowSpaces={true}
                                    onChange={(e) => {
                                        handleEntityValue(e.target.value, 'last_name');
                                    }}
                                    name={'Last Name'}
                                    inputValue={publicOfficer.last_name}
                                    placeholder={'Last Name'}
                                    classes="mt-2 bg-transparent"
                                    disabled={publicOfficer?.disabled?.last_name}
                                />
                            </div>
                            {/* <div className="w-[100%] !hidden">
                                <TFNInputField
                                    label={'TFN'}
                                    maxLength={10}
                                    name={'TFN'}
                                    inputValue={publicOfficer.tfn}
                                    placeholder={'TFN'}
                                    classes="mt-2 bg-transparent"
                                    disabled={publicOfficer?.disabled?.tfn}
                                    onChange={(e) => {
                                        handleEntityValue(e.target.value, 'tfn');
                                    }}
                                />
                            </div> */}
                            <div className="w-[100%]">
                                <PhoneInputComponent
                                    value={publicOfficer.phone_number}
                                    title="Phone Number"
                                    placeholder="Enter Phone Number"
                                    titleSx=""
                                    sx="!rounded-xl bg-transparent"
                                    bg={'!bg-transparent'}
                                    atChange={(phone) => {
                                        handleEntityValue(phone, 'phone_number');
                                    }}
                                    isDisabled={publicOfficer?.disabled?.phone_number}
                                />
                            </div>
                            <div className="w-[100%]">
                                <div>
                                    <label>Email Address</label>
                                </div>

                                <input
                                    label={'Email Address'}
                                    type="email"
                                    maxLength={EMAIL_LENGTH}
                                    onChange={(e) => {
                                        handleEntityValue(e.target.value, 'email_address');
                                    }}
                                    name={'Email Address'}
                                    value={publicOfficer.email_address}
                                    placeholder={'Email Address'}
                                    className={`mt-2 bg-transparent w-full px-3 py-2 border ${
                                        publicOfficer?.disabled?.email_address &&
                                        '!bg-gray-200 !border-none !cursor-no-drop '
                                    } rounded  focus:outline-none !ring-0 !border !border-secondarybg`}
                                    disabled={publicOfficer?.disabled?.email_address}
                                />
                            </div>
                            <div className="w-[100%]">
                                <div>
                                    <label>Date of Birth</label>
                                </div>
                                <DatePick
                                    maxDate={dayjs(dateMinus12Years, 'DD-MM-YYYY')}
                                    iconSet={{
                                        right: CalendarSmallIco
                                    }}
                                    onDateChange={(date) => {
                                        handleEntityValue(date, 'dob');
                                    }}
                                    placeholder="DD-MM-YYYY"
                                    format="DD-MM-YYYY"
                                    value={publicOfficer?.dob ? dayjs(publicOfficer.dob, 'DD-MM-YYYY') : ''}
                                    sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full hover:bg-[#FAF9F6]"
                                    isDisabled={publicOfficer?.disabled?.dob}
                                />
                            </div>

                            <div className="w-[100%]">
                                <PickAddress
                                    id="residential-autocomplete"
                                    address={publicOfficer?.residential_address}
                                    setAddress={(address) => {
                                        handleEntityValue(address, 'residential_address');
                                    }}
                                    onSelect={(place) => {
                                        handleEntityValue(place.formatted_address, 'residential_address');
                                    }}
                                    label="Physical Address"
                                    placeholder="Physical Address"
                                    classes="bg-transparent"
                                    disabled={publicOfficer?.disabled?.residential_address}
                                />
                            </div>

                            <div className="w-[100%]">
                                <div className="">
                                    <label className="inputLabel">Public Officer Status</label>
                                    <DefaultDoubleSwitch
                                        items={[
                                            {
                                                title: 'Active',
                                                selected: publicOfficer?.officer_status === 'Active'
                                            },
                                            {
                                                title: 'Inactive',
                                                selected: publicOfficer?.officer_status === 'Inactive'
                                            }
                                        ]}
                                        onClick={(value) => {
                                            if (value) {
                                                handleEntityValue(value, 'officer_status');
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center mt-24 items-center">
                        <div className="flex gap-8 items-center">
                            <Button
                                title="Back"
                                classes={
                                    'border-blueish text-blueish border-2 h-[3rem] rounded-lg buttons-font-lighter font-fira px-14'
                                }
                                atClick={() => setCurrentStep(2)}
                                type="button"
                            />

                            <Button
                                title={'Next'}
                                type={'submit'}
                                classes={'ann-btn rounded-lg px-14 buttons-font-lighter font-fira'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PublicOfficers;
