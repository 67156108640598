import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';
import { authEmailSendOtpThunk } from 'store/auth/slices';

const { request: getPermissionsThunk } = generateThunkAndSlice('getPermissions', endpoint.getPermissions, methods.POST);

const { slice: getAllBusinessModules, request: getAllBusinessModulesThunk } = generateThunkAndSlice(
    'getAllBusinessModules',
    endpoint.getAllBusinessModules,
    methods.POST
);
const { request: fetchAllBusinessGroupsThunk } = generateThunkAndSlice(
    'fetchAllBusinessGroups',
    endpoint.fetchAllBusinessGroups,
    methods.POST
);

const { request: sendEmployeeInviteThunk } = generateThunkAndSlice(
    'sendEmployeeInvite',
    endpoint.sendEmployeeInvite,
    methods.POST
);

const { request: fetchInvitedEmployeesThunk } = generateThunkAndSlice(
    'fetchInvitedEmployees',
    endpoint.fetchInvitedEmployees,
    methods.POST
);

const { slice: fetchRegisteredEmployeesForTask, request: fetchRegisteredEmployeesForTaskThunk } = generateThunkAndSlice(
    'fetchRegisteredEmployeesForTask',
    endpoint.fetchRegisteredEmployeesForTask,
    methods.POST
);
const { slice: fetchRegisteredEmployees, request: fetchRegisteredEmployeesThunk } = generateThunkAndSlice(
    'fetchRegisteredEmployees',
    endpoint.fetchRegisteredEmployees,
    methods.POST
);
const { slice: fetchRegisteredEmployeesForUser, request: fetchRegisteredEmployeesForUserThunk } = generateThunkAndSlice(
    'fetchRegisteredEmployeesForUser',
    endpoint.fetchRegisteredEmployees,
    methods.POST
);

const { request: revokeEmployeeInviteThunk } = generateThunkAndSlice(
    'revokeEmployeeInvite',
    endpoint.revokeEmployeeInvite,
    methods.POST
);

const { request: businessEmployeeArchiveThunk } = generateThunkAndSlice(
    'businessEmployeeArchive',
    endpoint.businessEmployeeArchive,
    methods.POST
);

const { slice: fetchBusinessEmployee, request: fetchBusinessEmployeeThunk } = generateThunkAndSlice(
    'fetchBusinessEmployee',
    endpoint.fetchBusinessEmployee,
    methods.POST
);

const { request: updateBusinessEmployeeThunk } = generateThunkAndSlice(
    'updateBusinessEmployee',
    endpoint.updateBusinessEmployee,
    methods.POST
);

const { slice: fetchAllPermissions, request: fetchAllPermissionsThunk } = generateThunkAndSlice(
    'fetchAllPermissions',
    endpoint.fetchAllPermissions,
    methods.POST
);

const { request: addRoleGroupThunk } = generateThunkAndSlice('addRoleGroup', endpoint.addRoleGroup, methods.POST);

const { request: updateRoleGroupThunk } = generateThunkAndSlice('addRoleGroup', endpoint.updateRoleGroup, methods.POST);

const { slice: fetchRoleGroups, request: fetchRoleGroupsThunk } = generateThunkAndSlice(
    'fetchRoleGroups',
    endpoint.fetchRoleGroups,
    methods.POST
);

const { request: updateBusinesGroupPermissionStatusThunk } = generateThunkAndSlice(
    'updateBusinesGroupPermissionStatus',
    endpoint.updateBusinesGroupPermissionStatus,
    methods.POST
);

const { request: updateBusinessGroupstatusThunk } = generateThunkAndSlice(
    'updateBusinessGroupstatus',
    endpoint.updateBusinessGroupstatus,
    methods.POST
);

const { request: acceptInviteThunk } = generateThunkAndSlice('acceptInvite', endpoint.acceptInvite, methods.POST);
const { request: blockDeviceThunk } = generateThunkAndSlice('blockDevice', endpoint.blockDevice, methods.POST);
const { slice: authPermissions, request: authPermissionsThunk } = generateThunkAndSlice(
    'authPermissions',
    endpoint.authPermissions,
    methods.POST
);
const { slice: getEmployeeStats, request: getEmployeeStatsThunk } = generateThunkAndSlice(
    'getEmployeeStats',
    endpoint.getEmployeeStats,
    methods.GET
);

const { request: resetPasswordOfEmployeeThunk } = generateThunkAndSlice(
    'resetPasswordOfEmployee',
    endpoint.resetPasswordOfEmployee,
    methods.POST
);

const { request: fetchSingleRoleGroupThunk } = generateThunkAndSlice(
    'fetchSingleRoleGroup',
    endpoint.fetchSingleRoleGroup,
    methods.POST
);

const { request: changeEmpStatusThunk } = generateThunkAndSlice(
    'changeEmpStatus',
    endpoint.changeEmpStatus,
    methods.POST
);

export {
    blockDeviceThunk,
    getPermissionsThunk,
    getAllBusinessModules,
    getAllBusinessModulesThunk,
    fetchAllBusinessGroupsThunk,
    sendEmployeeInviteThunk,
    fetchInvitedEmployeesThunk,
    fetchRegisteredEmployeesThunk,
    fetchRegisteredEmployeesForUser,
    fetchRegisteredEmployeesForUserThunk,
    fetchRegisteredEmployeesForTask,
    fetchRegisteredEmployeesForTaskThunk,
    revokeEmployeeInviteThunk,
    businessEmployeeArchiveThunk,
    fetchBusinessEmployee,
    fetchBusinessEmployeeThunk,
    updateBusinessEmployeeThunk,
    fetchAllPermissions,
    fetchAllPermissionsThunk,
    addRoleGroupThunk,
    updateRoleGroupThunk,
    fetchRoleGroupsThunk,
    fetchRoleGroups,
    updateBusinesGroupPermissionStatusThunk,
    updateBusinessGroupstatusThunk,
    acceptInviteThunk,
    authPermissions,
    authPermissionsThunk,
    authEmailSendOtpThunk,
    fetchRegisteredEmployees,
    resetPasswordOfEmployeeThunk,
    fetchSingleRoleGroupThunk,
    getEmployeeStats,
    getEmployeeStatsThunk,
    changeEmpStatusThunk
};
