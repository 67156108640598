import React from 'react';

export const HeifFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#D54267" />
            <path
                d="M7.43066 18.3291V22H4.95605V12.1973H7.43066V15.8408H8.87305V12.1973H11.3682V22H8.87305V18.3291H7.43066ZM14.9639 18.3291V19.5049H18.9014V22H12.4893V12.1973H18.9014V14.6924H14.9639V15.8408H17.5957V18.3291H14.9639ZM22.5107 12.1973V22H20.0225V12.1973H22.5107ZM28.7246 22H24.9307L23.6318 20.8516V13.3594L24.9307 12.1973H28.7246L30.0439 13.3594V15.8408H27.5488V14.6787H26.1064V19.5254H27.5488V18.3291H30.0439V20.8516L28.7246 22Z"
                fill="white"
            />
        </svg>
    );
};
