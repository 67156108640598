import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import EmailInput from 'components/atoms/Input/EmailInput';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { InputField } from 'components/atoms/inputs/InputField';
import { TFNInputField } from 'components/atoms/inputs/TFNInputField';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import dayjs from 'dayjs';
import { PickAddress } from 'global-components/PickAddress';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { deleteClientRefThunk, searchClientsThunk } from 'store/client';
import { validatePhoneNumber } from 'utills/baseValidations';
import { validateEmailValue, validateIndividualsObjects, validateTFN } from 'utills/dataValidation';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { dateMinus12Years } from 'utills/moment';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';

const PresidentDetailSubForm = ({ presidentDetails, setPresidentDetails, setCurrentStep }) => {
    const dispatch = useDispatch();

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleEntityValue = (value, targetProperty) => {
        setPresidentDetails((pre) => ({
            ...pre,
            [targetProperty]: value
        }));
    };

    const removeBCRIdFun = () => {
        if (presidentDetails?.bcr_id && presidentDetails?.business_client_id !== '') {
            dispatch(deleteClientRefThunk({ bcr_id: presidentDetails.bcr_id }));
        }

        return true;
    };

    const handleComboxboxEntity = (obj = null, isFirstLoad = false) => {
        setPresidentDetails((prevData) => {
            return isFirstLoad
                ? { ...prevData, isFirstLoad: false }
                : prevData?.business_client_id === obj?.business_client_id
                ? { ...prevData }
                : {
                      ...prevData,
                      business_client_id: obj ? obj?.business_client_id : '',
                      bcr_id: obj ? obj?.bcr_id : '',
                      first_name: obj ? obj?.first_name : '',
                      last_name: obj ? obj?.last_name : '',
                      tfn: obj ? obj?.tfn : '',
                      phone_number: obj ? obj?.phone_number : '',
                      email_address: obj ? obj?.email : '',
                      dob: obj ? obj?.date_of_birth : '',
                      president_status: 'Inactive',
                      current_address: obj ? obj?.residential_address : '',
                      disabled: {
                          first_name: obj && obj?.first_name && obj?.first_name !== '' ? true : false,
                          last_name: obj && obj?.last_name && obj?.last_name !== '' ? true : false,
                          tfn: obj && obj?.tfn && obj?.tfn !== '' ? true : false,
                          phone_number: obj && obj?.phone_number && obj?.phone_number !== '' ? true : false,
                          email_address: obj && obj?.email && obj?.email !== '' ? true : false,
                          dob: obj && obj?.date_of_birth && obj?.date_of_birth !== '' ? true : false,
                          president_status: obj && obj?.president_status && obj?.president_status !== '' ? true : false,
                          current_address:
                              obj && obj?.residential_address && obj?.residential_address !== '' ? true : false
                      }
                  };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validateIndividualsObjects(presidentDetails);

        if (validationErrors?.length > 0) {
            validationErrors?.forEach((error) => {
                toastHandler(error, TOAST_TYPE_ERROR);
            });
            return false;
        }

        let hasError = false;

        if (presidentDetails?.email_address) {
            if (!validateEmailValue(presidentDetails?.email_address)) {
                hasError = true;
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            } else {
                hasError = false;
            }
        }

        if (presidentDetails?.phone_number?.length > 4) {
            if (!validatePhoneNumber(presidentDetails?.phone_number)) {
                hasError = true;
                return false;
            } else {
                hasError = false;
            }
        }

        if (presidentDetails?.tfn) {
            if (!validateTFN(presidentDetails?.tfn)) {
                hasError = true;
                return toastHandler(`invalid TFN `, TOAST_TYPE_ERROR);
            } else {
                hasError = false;
            }
        }

        if (hasError) {
            return;
        }

        setCurrentStep(2);
    };
    return (
        <div>
            <form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                }}
            >
                <div className="flex justify-between items-center">
                    <div className="table-title font-poppins font-semibold">President Details</div>
                </div>
                <div>
                    <div className="mt-5">
                        <div className=" default-forms grid lg:grid-cols-3  md:grid-cols-2 gap-5  rounded-xl ">
                            <div className="w-[100%]">
                                <label className="inputLabel">President</label>

                                <ComboboxComponentSSR
                                    fetchDataFunction={(payload) =>
                                        fetchReferencesListFun({
                                            ...payload,
                                            type: 'individual'
                                        })
                                    }
                                    defaultSelected={presidentDetails.business_client_id}
                                    removePreviousSelected
                                    onRemove={(_e) => {
                                        removeBCRIdFun();
                                        handleComboxboxEntity(null, false);
                                    }}
                                    filterSelectedValues={[presidentDetails] || []}
                                    filterSelectedValueTargetElement={'business_client_id'}
                                    onChange={(res) => {
                                        const obj = res[0];
                                        handleComboxboxEntity(obj, presidentDetails.isFirstLoad);
                                    }}
                                    targetProperty="business_client_id"
                                    placeholder={'Search by Name/Reference No.'}
                                    limit={1}
                                    sx="rounded-xl ring-gray-400"
                                    showNameOnly
                                />
                            </div>

                            <div className="w-[100%]">
                                <InputField
                                    label={'First Name'}
                                    maxLength={30}
                                    allowSpaces={true}
                                    onChange={(e) => {
                                        handleEntityValue(e.target.value, 'first_name');
                                    }}
                                    name={'First Name'}
                                    inputValue={presidentDetails.first_name}
                                    placeholder={'First Name'}
                                    classes="mt-2 bg-transparent"
                                    disabled={presidentDetails?.disabled?.first_name}
                                />
                            </div>
                            <div className="w-[100%]">
                                <InputField
                                    label={'Last Name'}
                                    maxLength={30}
                                    allowSpaces={true}
                                    onChange={(e) => {
                                        handleEntityValue(e.target.value, 'last_name');
                                    }}
                                    name={'Last Name'}
                                    inputValue={presidentDetails.last_name}
                                    placeholder={'Last Name'}
                                    classes="mt-2 bg-transparent"
                                    disabled={presidentDetails?.disabled?.last_name}
                                />
                            </div>
                            <div className="w-[100%]">
                                <TFNInputField
                                    label={'TFN'}
                                    maxLength={9}
                                    allowSpaces={true}
                                    onChange={(e) => {
                                        handleEntityValue(e.target.value, 'tfn');
                                    }}
                                    name={'TFN'}
                                    inputValue={presidentDetails.tfn}
                                    placeholder={'TFN'}
                                    classes="mt-2 bg-transparent"
                                    disabled={presidentDetails?.disabled?.tfn}
                                />
                            </div>
                            <div className="w-[100%]">
                                <PhoneInputComponent
                                    value={presidentDetails.phone_number}
                                    title="Phone Number"
                                    placeholder="Enter Phone Number"
                                    titleSx=""
                                    sx="!rounded-xl bg-transparent"
                                    bg={'!bg-transparent'}
                                    atChange={(phone) => {
                                        handleEntityValue(phone, 'phone_number');
                                    }}
                                    isDisabled={presidentDetails?.disabled?.phone_number}
                                />
                            </div>
                            <div className="w-[100%]">
                                <EmailInput
                                    label={'Email Address'}
                                    onChange={(value) => {
                                        handleEntityValue(value, 'email_address');
                                    }}
                                    placeholder="Email Address"
                                    value={presidentDetails.email_address}
                                    classes="mt-2 bg-transparent"
                                    disabled={presidentDetails?.disabled?.email_address}
                                />
                            </div>
                            <div className="">
                                <div>
                                    <label>Date of Birth</label>
                                </div>
                                <DatePick
                                    maxDate={dayjs(dateMinus12Years, 'DD-MM-YYYY')}
                                    iconSet={{
                                        right: CalendarSmallIco
                                    }}
                                    onDateChange={(date) => {
                                        handleEntityValue(date, 'dob');
                                    }}
                                    placeholder="DD-MM-YYYY"
                                    format="DD-MM-YYYY"
                                    value={presidentDetails?.dob ? dayjs(presidentDetails.dob, 'DD-MM-YYYY') : ''}
                                    sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full hover:bg-[#FAF9F6] !bg-transparent"
                                    isDisabled={presidentDetails?.disabled?.dob}
                                />
                            </div>

                            <div className="w-[100%]">
                                <PickAddress
                                    id="residential-autocomplete"
                                    address={presidentDetails?.current_address}
                                    setAddress={(address) => {
                                        handleEntityValue(address, 'current_address');
                                    }}
                                    onSelect={(place) => {
                                        handleEntityValue(place.formatted_address, 'current_address');
                                    }}
                                    label="Physical Address"
                                    placeholder="Physical Address"
                                    classes="bg-transparent"
                                    disabled={presidentDetails?.disabled?.current_address}
                                />
                            </div>

                            <div className="w-[100%]">
                                <div className="">
                                    <label className="inputLabel">President Status</label>
                                    <DefaultDoubleSwitch
                                        items={[
                                            {
                                                title: 'Active',
                                                selected: presidentDetails?.president_status === 'Active'
                                            },
                                            {
                                                title: 'Inactive',
                                                selected: presidentDetails?.president_status === 'Inactive'
                                            }
                                        ]}
                                        onClick={(value) => {
                                            if (value) {
                                                handleEntityValue(value, 'president_status');
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center mt-24 items-center">
                        <div className="flex gap-8 items-center">
                            <Button
                                title="Back"
                                classes={
                                    'border-blueish text-blueish border-2 h-[3rem] rounded-lg buttons-font-lighter font-fira px-14'
                                }
                                atClick={() => setCurrentStep(0)}
                                type="button"
                            />
                            <Button
                                title={'Next'}
                                classes={'ann-btn rounded-lg px-14 buttons-font-lighter font-fira'}
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PresidentDetailSubForm;
