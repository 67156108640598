import React from 'react';

export const EmployeeDataIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="137"
            height="137"
            viewBox="0 0 137 137"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="136.029" height="136.029" rx="8" fill="#0A1E46" />
            <path
                d="M112.614 114.541H13.8267C13.34 114.541 13.0156 114.169 13.0156 113.611C13.0156 113.054 13.34 112.682 13.8267 112.682H112.614C113.1 112.682 113.425 113.054 113.425 113.611C113.425 114.169 113.1 114.541 112.614 114.541Z"
                fill="white"
            />
            <path
                d="M112.614 102.641H13.8267C13.34 102.641 13.0156 102.269 13.0156 101.711C13.0156 101.153 13.34 100.781 13.8267 100.781H112.614C113.1 100.781 113.425 101.153 113.425 101.711C113.425 102.269 113.1 102.641 112.614 102.641Z"
                fill="white"
            />
            <g clipPath="url(#clip0_25014_8609)">
                <path
                    d="M41.8037 14.7756C38.4327 15.2943 35.4198 16.8995 33.0613 19.4062C30.4682 22.1598 29.1223 25.3455 28.9371 29.1611C28.6777 34.4954 31.2955 39.5951 35.8149 42.5463C38.0375 44.0034 40.1737 44.7443 42.7668 44.9542C47.5702 45.3246 51.6697 43.8182 54.979 40.4472C58.2142 37.1502 59.6589 33.0877 59.2638 28.4202C58.918 24.4194 57.1523 20.9743 54.09 18.3319C51.6697 16.2574 49.4471 15.2202 46.3724 14.7756C44.8907 14.5657 43.162 14.5534 41.8037 14.7756ZM47.0639 18.6529C49.0026 19.1468 50.4596 19.9989 52.0896 21.5671C54.2134 23.5798 55.2877 25.8024 55.5717 28.6795C55.9051 32.0876 54.8555 35.1993 52.5465 37.6689C51.1141 39.2 49.0026 40.4966 47.2121 40.9534C44.5573 41.6202 41.3962 41.3362 39.1242 40.2125C36.0248 38.6814 33.7281 35.8167 32.8884 32.4333C32.5674 31.1368 32.6044 28.2103 32.9625 26.8767C33.9133 23.2958 37.025 19.9742 40.4824 18.8505C42.3593 18.2454 45.1623 18.159 47.0639 18.6529Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.8"
                />
                <path
                    d="M30.5906 45.2251C28.7013 45.4227 26.7503 46.1388 25.2068 47.1761C21.3542 49.7939 18.8599 55.6468 18.2796 63.4261C17.9585 67.7973 18.2178 69.9582 19.3415 72.2178C19.9589 73.4773 21.132 74.8603 22.3297 75.7494C23.2435 76.4162 24.7746 77.1077 26.1206 77.4534L27.2319 77.7498H44.5191H61.8063L62.9177 77.4658C66.8196 76.4409 69.5485 73.8107 70.524 70.094C71.0303 68.18 70.9686 63.6113 70.3759 60.0057C69.0793 51.9795 65.7947 47.1637 60.5962 45.6573C59.4108 45.3115 57.287 45.0522 56.6572 45.1757C56.0522 45.2868 55.2125 45.7314 53.3109 46.9538C51.2611 48.275 50.9401 48.4479 49.4089 49.0036C47.6061 49.658 46.2602 49.9173 44.5932 49.9173C42.8645 49.9173 41.5309 49.6704 39.7775 49.053C38.1228 48.4603 38.0488 48.4232 35.4557 46.7809C34.0974 45.9166 33.0849 45.3609 32.6897 45.2498C31.9982 45.0769 31.9982 45.0769 30.5906 45.2251ZM33.801 50.1026C36.1225 51.5967 37.098 52.0782 39.1354 52.745C44.445 54.4861 49.8658 53.6464 54.9779 50.3001C57.287 48.7813 57.2376 48.806 58.2131 48.9295C62.6954 49.4975 65.4984 53.2636 66.6838 60.3391C67.1654 63.2532 67.3135 67.3157 66.9925 68.8592C66.4739 71.3411 64.7946 73.0946 62.2262 73.8231C61.4853 74.033 60.5098 74.0454 44.5191 74.0454C28.4914 74.0454 27.5653 74.033 26.7997 73.8231C24.9599 73.3045 23.367 72.045 22.6261 70.5262C21.984 69.2173 21.8235 68.1183 21.9222 65.4635C22.0087 62.784 22.1322 61.5368 22.5643 59.3512C22.8854 57.6472 23.6386 55.227 24.1696 54.1774C25.7625 51.0163 27.9234 49.2876 30.7264 48.9295C31.1833 48.8678 31.6031 48.8184 31.6525 48.806C31.7019 48.806 32.665 49.3864 33.801 50.1026Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.8"
                />
            </g>
            <defs>
                <clipPath id="clip0_25014_8609">
                    <rect width="63.2218" height="63.2218" fill="white" transform="translate(12.9688 14.5897)" />
                </clipPath>
            </defs>
        </svg>
    );
};
