import React from 'react';
import { Fragment, useState } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { UsersIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { cn } from 'utills/tailwindUtil';
import { Loader } from 'components/common/Loader';
import { useSelector } from 'react-redux';

import { generateRandomProfileLink, getProfileName } from 'utills/globalVars';

export default function CommandPalettes({
    open,
    setOpen,
    actionButton,
    list = [],
    showSearch = true,
    profileTopRightChip = false
}) {
    const [query, setQuery] = useState('');
    const { isLoading } = useSelector((state) => state.global);

    const filteredPeople =
        query === ''
            ? []
            : list.filter((person) => {
                  return ['first_name', 'display_name'].some((key) =>
                      person?.[key]?.toLowerCase()?.includes(query?.toLowerCase())
                  );
              });

    return (
        <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
            <Dialog as="div" className="relative z-[999]" onClose={setOpen}>
                {isLoading && <Loader />}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                            <Combobox>
                                {({ activeOption }) => (
                                    <>
                                        {showSearch && (
                                            <div className="relative">
                                                <MagnifyingGlassIcon
                                                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-[#979797]"
                                                    aria-hidden="true"
                                                />
                                                <Combobox.Input
                                                    className="h-12 w-full border-0 bg-transparent !pl-10 pr-4 text-gray-900 placeholder:text-[#979797] focus:ring-0 sm:text-sm"
                                                    placeholder="Search..."
                                                    onChange={(event) => setQuery(event?.target?.value)}
                                                />
                                            </div>
                                        )}

                                        {(query === '' || filteredPeople.length > 0) && (
                                            <Combobox.Options
                                                as="div"
                                                static
                                                hold
                                                className="flex transform-gpu divide-x divide-gray-100"
                                            >
                                                <div
                                                    className={cn(
                                                        'max-h-104 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                                                        activeOption && 'sm:h-104'
                                                    )}
                                                >
                                                    {query === '' && (
                                                        <h2 className="mb-4 mt-2 text-md font-semibold text-gray-500">
                                                            Recent Added
                                                        </h2>
                                                    )}
                                                    <div className="-mx-2 text-md text-gray-700">
                                                        {(query === '' ? list : filteredPeople).map((person) => (
                                                            <Combobox.Option
                                                                as="div"
                                                                key={person.user_id}
                                                                value={person}
                                                                className={({ active }) =>
                                                                    cn(
                                                                        'flex cursor-default select-none items-center rounded-md p-2',
                                                                        active && 'bg-gray-100 text-gray-900'
                                                                    )
                                                                }
                                                            >
                                                                {({ active }) => (
                                                                    <>
                                                                        <img
                                                                            src={
                                                                                person.image ??
                                                                                generateRandomProfileLink(
                                                                                    getProfileName(person)
                                                                                )
                                                                            }
                                                                            alt=""
                                                                            className="h-8 w-8 flex-none rounded-full"
                                                                        />
                                                                        <span className="ml-3 flex-auto truncate">
                                                                            {getProfileName(person)}
                                                                        </span>
                                                                        {active && (
                                                                            <ChevronRightIcon
                                                                                className="ml-3 h-5 w-5 flex-none text-[#979797]"
                                                                                aria-hidden="true"
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))}
                                                    </div>
                                                </div>

                                                {activeOption && (
                                                    <div className="hidden h-104 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                                                        {profileTopRightChip && (
                                                            <span className="absolute top-3 right-5">
                                                                {profileTopRightChip(activeOption)}
                                                            </span>
                                                        )}

                                                        <div className="flex-none p-6 text-center">
                                                            <img
                                                                src={
                                                                    activeOption.image ??
                                                                    generateRandomProfileLink(
                                                                        getProfileName(activeOption)
                                                                    )
                                                                }
                                                                alt=""
                                                                className="mx-auto h-16 w-16 rounded-full"
                                                            />
                                                            <h2 className="mt-3 text-md font-semibold text-gray-900">
                                                                {getProfileName(activeOption)}
                                                            </h2>
                                                            <p className="text-sm leading-6 text-gray-500">
                                                                {activeOption.user_type === 'employee'
                                                                    ? 'Employee'
                                                                    : 'Business'}
                                                            </p>
                                                            <p className="text-sm leading-6 text-gray-500">
                                                                {activeOption.client_category}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-auto flex-col justify-between p-6">
                                                            <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                                                <dt className="col-end-1 font-semibold text-gray-900">
                                                                    Phone
                                                                </dt>
                                                                <dd>{activeOption.phone_number}</dd>

                                                                <dt className="col-end-1 font-semibold text-gray-900">
                                                                    Email
                                                                </dt>
                                                                <dd className="truncate">
                                                                    <a
                                                                        href={`mailto:${activeOption.email}`}
                                                                        className="text-[#b695f8] underline"
                                                                    >
                                                                        {activeOption.email}
                                                                    </a>
                                                                </dd>
                                                            </dl>
                                                            {actionButton && actionButton(activeOption)}
                                                        </div>
                                                    </div>
                                                )}
                                            </Combobox.Options>
                                        )}

                                        {query !== '' && filteredPeople.length === 0 && (
                                            <div className="px-6 py-14 text-center text-sm sm:px-14">
                                                <UsersIcon
                                                    className="mx-auto h-6 w-6 text-[#979797]"
                                                    aria-hidden="true"
                                                />
                                                <p className="mt-4 font-semibold text-gray-900">No people found</p>
                                                <p className="mt-2 text-gray-500">
                                                    We couldn’t find anything with that term. Please try again.
                                                </p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Combobox>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
