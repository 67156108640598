/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import AppUserProfilePage from '../../components/appusers-dash/app-user-profile/AppUserProfilePage';
import Forms from '../../components/appusers-dash/forms/Forms';

import PreviewForm from '../../components/appusers-dash/forms/dynamic-form/PreviewForm';
import { FormDetails } from 'components/appusers-dash/forms/form-details/FormDetails';
import { EditCustomerForm } from 'components/appusers-dash/forms/form-details/EditCustomerForm';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Attachments } from 'components/appusers-dash/forms/added-forms/Attachments';
import { UpdateForm } from 'components/settings/form-settings/UpdateForm';

import { Terms } from 'components/appusers-dash/forms/dynamic-form/Terms';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { formHandler } from 'store/global/globalReducer';
import { handleRemoveQueryParams } from 'utills/globalVars';
import { fetchSingleFormFbThunk } from 'store/form';

const AppUserProfile = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const form = queryParams.get('form_id');

    const navigate = useNavigate();
    const [showCompanyRegistration, setShowCompanyRegistration] = useState(false);
    const [showIE, setShowIE] = useState(false);
    const [showForms, setShowForms] = useState(form ? true : false);
    const [showEntityForm, setShowEntityForm] = useState(false);
    const [showTrustRegistration, setShowTrustRegistration] = useState(false);
    const [showRentalDeduction, setShowRentalDeduction] = useState(false);
    const [showAbnDeduction, setShowAbnDeduction] = useState(false);
    const [showTfnDeduction, setShowTfnDeduction] = useState(false);
    const [showAddedForms, setShowAddedForms] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showPreviewForm, setShowPreviewForm] = useState(false);
    const [showEditCustomerForm, setShowEditCustomerForm] = useState(false);
    const [showAttchments, setShowAttachments] = useState(false);
    const [showFormDetails, setShowFormDetails] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);

    const [submissionsUpdate, setSubmissionUpdate] = useState(false);

    const [showTerms, setShowTerms] = useState(false);

    const businessFormDataiId = queryParams.get('businessFormDataId');
    const [allowFirst, setAllowFirst] = useState(true);
    useEffect(() => {
        if (businessFormDataiId && allowFirst) {
            dispatch(
                fetchSingleFormFbThunk({
                    business_form_data_id: businessFormDataiId,
                    business_client_id: id
                })
            ).then((res) => {
                if (res?.payload) {
                    dispatch(formHandler(res?.payload));
                    // togglePreviewForm();
                    setAllowFirst(false);
                }
            });
        }
    }, [businessFormDataiId]);

    useEffect(() => {
        if (businessFormDataiId && !showPreviewForm && !allowFirst) {
            handleRemoveQueryParams(navigate);
        }
    }, [businessFormDataiId, showPreviewForm, allowFirst]);

    const toggleTerms = () => {
        setShowTerms(!showTerms);
    };

    const toggleSubmissionsUpdate = () => {
        setSubmissionUpdate(!submissionsUpdate);
    };

    const handleUpdateForm = () => {
        setShowUpdateForm(!showUpdateForm);
    };
    const toggleAttachments = () => {
        setShowAttachments(!showAttchments);
    };
    const toggleFormDetails = () => {
        setShowFormDetails(!showFormDetails);
    };

    const togglePreviewForm = () => {
        setShowPreviewForm(!showPreviewForm);
    };

    const toggleAddedForms = () => {
        setShowForms(false);
        setShowIE(false);
        setShowEntityForm(false);
        setShowTrustRegistration(false);
        setShowRentalDeduction(false);
        setShowAbnDeduction(false);
        setShowTfnDeduction(false);
        setShowCreateForm(false);
        setShowAddedForms(!showAddedForms);
    };

    const toggleTfnDeduction = () => {
        setShowForms(false);
        setShowIE(false);
        setShowEntityForm(false);
        setShowTrustRegistration(false);
        setShowRentalDeduction(false);
        setShowAbnDeduction(false);
        setShowTfnDeduction(!showTfnDeduction);
    };

    const toggleAbnDeduction = () => {
        setShowForms(false);
        setShowIE(false);
        setShowEntityForm(false);
        setShowTrustRegistration(false);
        setShowRentalDeduction(false);
        setShowAbnDeduction(!showAbnDeduction);
    };

    const toggleRentalDeduction = () => {
        setShowForms(false);
        setShowIE(false);
        setShowEntityForm(false);
        setShowTrustRegistration(false);
        setShowRentalDeduction(!showRentalDeduction);
    };

    const toggleEntityForm = () => {
        setShowForms(false);
        setShowIE(false);
        setShowEntityForm(!showEntityForm);
    };

    const toggleForms = () => {
        setShowForms(false);
        setShowIE(false);
        setShowEntityForm(false);
        setShowTrustRegistration(false);
        setShowRentalDeduction(false);
        setShowAbnDeduction(false);
        setShowTfnDeduction(false);
        setShowCreateForm(false);
        setShowAddedForms(false);
        setShowCompanyRegistration(false);
        setShowForms(!showForms);
    };

    const toggleEditForm = () => {
        setShowEditCustomerForm(!showEditCustomerForm);
    };
    const directory = 'Forms &gt; Submited  Forms &gt;';

    const [term, setTerm] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [count, setCount] = useState(0);

    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [selectedDate, setSelectedDate] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <div>
            <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                {' '}
                <div>
                    {showForms && (
                        <Forms
                            toggleAddedForms={toggleAddedForms}
                            toggleEntityForm={toggleEntityForm}
                            toggleForms={toggleForms}
                            toggleFormDetails={toggleFormDetails}
                            togglePreviewForm={togglePreviewForm}
                            handleUpdateForm={handleUpdateForm}
                        />
                    )}
                    {showFormDetails && (
                        <FormDetails
                            toggleFormDetails={toggleFormDetails}
                            id={id}
                            toggleEditForm={toggleEditForm}
                            togglePreviewForm={togglePreviewForm}
                            toggleAttachments={toggleAttachments}
                            submissionsUpdate={submissionsUpdate}
                        />
                    )}
                    {showUpdateForm && (
                        <UpdateForm
                            handleUpdateForm={handleUpdateForm}
                            toggleSubmissionsUpdate={toggleSubmissionsUpdate}
                        />
                    )}
                    {showEditCustomerForm && (
                        <EditCustomerForm
                            toggleSubmissionsUpdate={toggleSubmissionsUpdate}
                            toggleEditForm={toggleEditForm}
                            id={id}
                        />
                    )}
                    {showAttchments && <Attachments toggleAttachments={toggleAttachments} />}
                    {showPreviewForm && (
                        <PreviewForm
                            togglePreviewForm={togglePreviewForm}
                            directory={directory}
                            toggleTerms={toggleTerms}
                        />
                    )}
                    {showTerms && <Terms toggleTerms={toggleTerms} />}
                    <AppUserProfilePage
                        toggleTfnDeduction={toggleTfnDeduction}
                        toggleForms={toggleForms}
                        toggleRentalDeduction={toggleRentalDeduction}
                        toggleAbnDeduction={toggleAbnDeduction}
                    />{' '}
                </div>
            </RoleBasedGuard>
        </div>
    );
};

export default AppUserProfile;
