import React from 'react';

export const PptFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#0C7785" />
            <path
                d="M7.55469 19.25V22H4.72656V10.7969H10.5469L12.0547 12.125V17.9219L10.5469 19.25H7.55469ZM7.55469 13.6328V16.4141H9.20312V13.6328H7.55469ZM16.1641 19.25V22H13.3359V10.7969H19.1562L20.6641 12.125V17.9219L19.1562 19.25H16.1641ZM16.1641 13.6328V16.4141H17.8125V13.6328H16.1641ZM27.0469 22H24.2188V13.6484H21.9453V10.7969H29.2734V13.6484H27.0469V22Z"
                fill="white"
            />
        </svg>
    );
};
