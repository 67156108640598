import React, { useRef, useState } from 'react';
import { cn } from 'utills/tailwindUtil';
import '../css/atoms.css';

import { TooltipChip } from '../tooltipChip/TooltipChip';
import { useSelector } from 'react-redux';
export const Button = ({
    title,
    atClick,
    classes,
    buttonType,
    key,
    iconSet,
    style,
    isloading,
    disabled = false,
    iconInvert,
    tooltip,
    groupSx,
    tooltipDirection
}) => {
    const parentRef = useRef();
    const [hoveredIndex, setHoveredIndex] = useState(false);
    const { isLoading } = useSelector((state) => state.global);

    return (
        <div
            ref={parentRef}
            onMouseEnter={() => setHoveredIndex(true)}
            onMouseLeave={() => setHoveredIndex(null)}
            className={cn('group relative ', groupSx ? groupSx : '')}
        >
            {tooltip && (
                <TooltipChip
                    text={tooltip}
                    direction={tooltipDirection}
                    parentRef={parentRef}
                    isVisible={hoveredIndex}
                />
            )}
            <button
                key={'button_key_' + key}
                disabled={isLoading ? true : disabled}
                type={buttonType}
                style={style}
                onClick={atClick}
                className={`
                    'px-8 rounded-md hover:opacity-75 hover:!shadow-md duration-50 transition ease-in-out' ${classes} ${
                    isLoading || disabled ? 'opacity-50 !cursor-not-allowed' : ''
                } `}
            >
                {iconSet?.leftIcon && <span className={`${iconInvert ? 'invert' : ''}`}>{<iconSet.leftIcon />}</span>}
                {iconSet?.leftIconHTML && iconSet.leftIconHTML}

                {title && (
                    <span className="flex gap-2 items-center text-center place-content-center">
                        {title} {isloading && <iconSet.loader />}
                    </span>
                )}

                {iconSet?.rightIcon && <span className={`${iconInvert ? 'invert' : ''}`}>{<iconSet.rightIcon />}</span>}
            </button>
        </div>
    );
};
