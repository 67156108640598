import React from 'react';

export const PptxFIle = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#4FCB8E" />
            <path
                d="M5.82178 18.7656V21H3.52393V11.8975H8.25293L9.47803 12.9766V17.6865L8.25293 18.7656H5.82178ZM5.82178 14.2017V16.4614H7.16113V14.2017H5.82178ZM12.8169 18.7656V21H10.519V11.8975H15.248L16.4731 12.9766V17.6865L15.248 18.7656H12.8169ZM12.8169 14.2017V16.4614H14.1562V14.2017H12.8169ZM21.6592 21H19.3613V14.2144H17.5142V11.8975H23.4683V14.2144H21.6592V21ZM26.8071 21H24.5093V17.5532L25.7725 16.4487L24.522 15.3442V11.8975H26.8389V14.3413L27.499 14.9316L28.1592 14.3413V11.8975H30.4761V15.3442L29.2129 16.4487L30.4761 17.5532V21H28.1719V18.5688L27.499 17.9722L26.8071 18.5688V21Z"
                fill="white"
            />
        </svg>
    );
};
