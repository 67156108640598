export const JOBS_FILTERS = [
    'search',
    'filter by group',
    'filter by priority',
    'filter by status',
    'filter by job type',
    'filter by employee',
    'filter by client',
    'From Date - To Date'
];
export const CLIENTS_FILTERS = [
    'search',
    'filter by group',
    'filter by date',
    'filter by type',
    'filter by category',
    'filter by accountant'
];
export const CLIENTS_COLS = [
    'client',
    'tfn',
    'reference no',
    'phone',
    'address',
    'client type',
    'client category',
    'date connected'
];
export const TASKS_FILTERS = [
    'search',
    'filter by emplyee',
    'filter by client',
    'filter by assigned by',
    'filter by client ref no',
    'filter by task status',
    'filter by task type',
    'order by date',
    'order by from date',
    'order by to date',
    'filter by task priority',
    'filter by pending tasks'
];
export const JOBS_TABLE_COLS = [
    'job ref no',
    'job',
    'priority',
    'status',
    'clients',
    'created date',
    'start date',
    'due date',
    'manager'
];
