import { getFileIcon } from 'Hooks/Files/getFileIcon';
import React from 'react';
import { formatFileSize } from 'utills/filesHandlers';

import { SideTimes } from 'utills/svgs/SideTimes';

// const getFileIcon = (url, sx = '', showImage = false, imgPrperties) => {
//     if (
//         showImage &&
//         (imgPrperties.type.includes('jpg') || imgPrperties.type.includes('png') || imgPrperties.type.includes('jpeg'))
//     ) {
//         return (
//             <img
//                 src={typeof imgPrperties.url === 'string' ? imgPrperties.url : URL.createObjectURL(imgPrperties.url)}
//                 alt={'alt' + url}
//                 onClick={imgPrperties?.onClick ? imgPrperties.onClick : () => {}}
//                 className={imgPrperties.sx}
//             />
//         );
//     }
//     const fileExtension = url?.includes('.')
//         ? url?.split('.')?.pop()?.toLowerCase()
//         : url.split('/').pop().toLowerCase();

//     switch (fileExtension) {
//         case 'pdf':
//             return <PdfFile className={sx} />;

//         case 'msword':
//         case 'doc':
//         case 'docx':
//             return <DocFile className={sx} />;

//         case 'jpeg':
//         case 'jpg':
//             return <JPEGFile className={sx} />;

//         case 'vnd.ms-excel':
//         case 'xlsx':
//         case 'xls':
//         case 'csv':
//             return <ExcelFile className={sx} />;
//         case 'png':
//             return <PngFile className={sx} />;

//         case 'heic':
//             return <HeicFile className={sx} />;
//         case 'webp':
//             return <WebpFile className={sx} />;
//         case 'avif':
//             return <AvifFile className={sx} />;

//         case 'ms-outlook':
//         case 'rfc822':
//         case 'msg':
//         case 'octet-stream':
//             return <MsgFile className={sx} />;
//         default:
//             return (
//                 <div className={'bg-third rounded-md p-1 ' + sx}>
//                     <FilesIcon className={'w-[85%] h-auto m-auto stroke-white'} />
//                 </div>
//             );
//     }
// };

export const FileCardPreview = ({
    url,
    sx,
    showImage,
    imgPrperties,
    onClick,
    name,
    size,
    extension,
    onRemove,
    hideRemove,
    file_cx
}) => {
    const icon = getFileIcon(url, sx, showImage, imgPrperties);

    return (
        <div className={`${file_cx} w-[280px] h-[210px] rounded-xl relative overflow-hidden border cursor-pointer `}>
            <div className="cursor-pointer absolute right-1 top-1" onClick={onRemove}>
                {hideRemove ? '' : <SideTimes />}
            </div>
            <div
                onClick={onClick}
                className="bg-[#E6F2E2] overflow-hidden flex justify-center items-center   w-full h-[80%]"
            >
                {icon}
            </div>
            <div className="bg-[#F8F8FE] rounded-xl  absolute bottom-0 w-full  flex gap-2 items-center p-2">
                <div>{getFileIcon(url, sx)}</div>
                <div className="flex flex-col w-full flex-nowrap overflow-hidden">
                    <div className="text-sm text-nowrap truncate">{name?.slice(0, 16)}</div>
                    <div className="flex gap-2 items-center text-[#666] text-sm truncate overflow-hidden">
                        {size && (
                            <div className="truncate">
                                <span>●</span> {formatFileSize(size / 1024)}
                            </div>
                        )}
                        <div className="truncate">
                            <span>●</span> {extension}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
