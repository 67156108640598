/* eslint-disable no-extra-boolean-cast */
import React, { useState, useRef, useEffect } from 'react';
import { DatePick } from 'components/atoms/inputs/DatePick';
import dayjs from 'dayjs';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { cn } from 'utills/tailwindUtil';
import SimpleSelectMenu from '../SimpleSelectMenu';
import { useSelector } from 'react-redux';
import { Truncate } from 'components/molecules/Truncate';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import DraftEditorNew from 'components/molecules/DraftEditorNew';

const EditableText = ({
    text,
    placeholder,
    onUpdate,
    componentName = 'textarea',
    children,
    sx,
    options,
    minDate,
    maxDate,
    getObject,
    autoOpen = true,
    permissions,
    disabled = false
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentObj, setCurrentObj] = useState(null);
    const [currentText, setCurrentText] = useState(' ');
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const { isLoading } = useSelector((state) => state.global);

    const { hasPermissions } = useRoleGuard();

    useEffect(() => {
        setCurrentText(text);
    }, [text]);

    const handleClickOutside = (e) => {
        if (typeof currentText === 'string') {
            if (currentText?.trim() !== '') {
                if (componentName === 'dropdown' || componentName === 'date') {
                    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                        setIsEditing(false);
                        onUpdate(!!getObject ? currentObj : currentText);
                    }
                } else {
                    if (inputRef.current && !inputRef.current.contains(e.target)) {
                        if (currentText !== text) {
                            setIsEditing(false);

                            onUpdate(!!getObject ? currentObj : currentText);
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isEditing, currentText]);

    const handleChange = (e) => {
        setCurrentText(e.target.value);
    };

    const toggleEdit = () => {
        setIsEditing(true);
    };

    return (
        <div className={cn('default-forms w-full', sx)}>
            {(isEditing || currentText === '' || currentText === null) && !isLoading ? (
                componentName === 'textarea' ? (
                    <textarea
                        ref={inputRef}
                        value={currentText}
                        onChange={handleChange}
                        onBlur={() => setIsEditing(false)}
                        rows={4}
                        placeholder={placeholder ? placeholder : ''}
                        autoFocus
                        maxLength={5000}
                        className="w-full scrollbar-hide border-secondarybg rounded-lg focus:!outline-none  focus:!ring-0 focus:!border-secondarybg"
                    />
                ) : componentName === 'date' ? (
                    <div>
                        <DatePick
                            iconSet={{
                                right: CalendarSmallIco
                            }}
                            maxDate={maxDate ? dayjs(maxDate, 'DD-MM-YYYY') : ''}
                            minDate={minDate ? dayjs(minDate, 'DD-MM-YYYY') : ''}
                            onDateChange={(date) => {
                                if (date) {
                                    setCurrentText(date);
                                    onUpdate(date);
                                }
                                setIsEditing(false);
                            }}
                            placeholder="DD-MM-YYYY"
                            value={currentText ? dayjs(currentText, 'DD/MM/YYYY') : ''}
                            sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                            onOpenChange={(open) => {
                                setIsEditing(open);
                            }}
                            autoOpen={autoOpen}
                        />
                    </div>
                ) : componentName === 'dropdown' ? (
                    <div ref={dropdownRef} className="w-[200px]">
                        <SimpleSelectMenu
                            ref={inputRef}
                            onBlur={() => setIsEditing(false)}
                            placeholder="Select "
                            targetProperty="label"
                            selectedValue={currentText}
                            valuePropertyName="label"
                            optionsData={options || []}
                            sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                            showChipOnly={false}
                            onChangeValue={(obj) => {
                                setCurrentText(obj.label);
                                if (getObject) {
                                    setCurrentObj(obj);
                                }
                            }}
                        />
                    </div>
                ) : componentName === 'editor' && isEditing ? (
                    <div ref={inputRef}>
                        {/* <DraftEditor
                            onChange={(val) => {
                                if (val?.replace(/<\/?[^>]+(>|$)/g, '')?.trim() !== '') {
                                    setCurrentText(val);
                                }
                            }}
                            initialValue={currentText}
                            maxWords={10000}
                            required={true}
                            placeholder="Enter description"
                        /> */}
                        <DraftEditorNew
                            onChange={(val) => {
                                setCurrentText(val);
                                setIsEditing(false);
                                onUpdate(val);
                            }}
                            initialValue={currentText}
                            required={true}
                            placeholder="Enter description"
                            extraConfigs={{
                                toolbarAdaptive: false,
                                askBeforePasteHTML: false,
                                askBeforePasteFromWord: false,
                                buttons: [
                                    'bold',
                                    'italic',
                                    'underline',
                                    'strikethrough',
                                    'eraser',
                                    'ul',
                                    'ol',
                                    'font',
                                    'fontsize',
                                    'paragraph',
                                    'lineHeight',
                                    'superscript',
                                    'subscript',
                                    '|',
                                    'cut',
                                    'copy',
                                    'paste',
                                    'copyformat',
                                    '|',
                                    'hr',
                                    'link',
                                    '|',
                                    'indent',
                                    'outdent',
                                    'left'
                                ],
                                height: 400
                            }}
                        />
                    </div>
                ) : (
                    <input
                        ref={inputRef}
                        type="text"
                        value={currentText}
                        placeholder={placeholder ?? ''}
                        onChange={handleChange}
                        onBlur={() => {
                            if (currentText?.trim() === '') {
                                setCurrentText(text);
                            }
                            setIsEditing(false);
                        }}
                        autoFocus
                    />
                )
            ) : (
                <>
                    {children ? (
                        <div
                            onClick={() => {
                                if (permissions) {
                                    if (!hasPermissions(permissions?.module, permissions?.permissions)) {
                                        return toastHandler(
                                            'You dont have permission to do this action.',
                                            TOAST_TYPE_ERROR
                                        );
                                    }
                                }
                                if (!disabled) {
                                    toggleEdit();
                                }
                            }}
                        >
                            {children}
                        </div>
                    ) : (
                        <span
                            onClick={() => {
                                if (permissions) {
                                    if (!hasPermissions(permissions?.module, permissions?.permissions)) {
                                        return toastHandler(
                                            'You dont have permission to do this action.',
                                            TOAST_TYPE_ERROR
                                        );
                                    }
                                }
                                if (!disabled) {
                                    toggleEdit();
                                }
                            }}
                            className="break-all"
                        >
                            {componentName === 'textarea' ? (
                                <Truncate text={currentText} maxLength={1500} />
                            ) : (
                                currentText || '--'
                            )}
                        </span>
                    )}
                </>
            )}
        </div>
    );
};

export default EditableText;
