import React, { useEffect, useState } from 'react';
import { CollapsArrow } from 'utills/svgs/CollapsArrow';

export const CollapsDivComponent = ({
    _h_one = 'max-h-[60px]',
    h_two = 'max-h-[300px]',
    title,
    titleSx,
    classes,
    children,
    childSx,
    extra = '',
    titleIcon = '',
    _buttons,
    defaultClose = false,
    ArrowIcon = <CollapsArrow />,

    extraSx = '',
    headSx = '',
    disabled = false,
    forceClose
}) => {
    const [collapsed, setCollapsed] = useState(defaultClose ?? false);

    useEffect(() => {
        if (forceClose) {
            setCollapsed(true);
        }
    }, [forceClose]);

    return (
        <div className={`bg-white ${classes} transition-all duration-500 ease-in-out rounded-xl shadow-md`}>
            <div className={`flex justify-between items-center ${headSx}  `}>
                <div className={`${titleIcon && 'flex gap-3 items-center'}`}>
                    {titleIcon && titleIcon}
                    <h3
                        onClick={() => !disabled && setCollapsed(!collapsed)}
                        className={`text-lg ${titleSx} font-[500] font-poppins cursor-pointer`}
                    >
                        {title}
                    </h3>
                </div>
                <div className={` ${extraSx} flex items-center justify-end gap-5`}>
                    {extra}
                    <div
                        onClick={() => !disabled && setCollapsed(!collapsed)}
                        className={`cursor-pointer ${
                            disabled && 'opacity-25 cursor-not-allowed'
                        } transition-transform duration-300 ${collapsed ? 'rotate-180' : ''}`}
                    >
                        {ArrowIcon}
                    </div>
                </div>
            </div>

            <div
                className={`transition-[max-height] overflow-y-hidden     hover:overflow-y-auto duration-500 ease-in-out overflow-hidden ${
                    collapsed ? 'max-h-[0px] ' : h_two
                }`}
            >
                <div className={`${childSx}`}>{children}</div>
            </div>
        </div>
    );
};
