import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import EmailInput from 'components/atoms/Input/EmailInput';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { InputField } from 'components/atoms/inputs/InputField';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import dayjs from 'dayjs';
import { PickAddress } from 'global-components/PickAddress';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteClientRefThunk, searchClientsThunk } from 'store/client';
import { filterEmptyObject, validateEmailValue, validateIndividualsObjects } from 'utills/dataValidation';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { NewPlusIcon } from 'utills/svgs/NewPlusIcon';
import { validatePhoneNumber } from 'utills/baseValidations';
import { validateTFN } from '../../../../../../utills/dataValidation';
import { toastHandler } from '../../../../../../responseHanlder';
import { TOAST_TYPE_ERROR } from '../../../../../../utills/globalVars';
import { TFNInputField } from './../../../../../atoms/inputs/TFNInputField';
import { NumberInput } from 'components/atoms/inputs/NumberInput';
import { dateMinus12Years } from 'utills/moment';

const DirectorDetailsSubForm = ({
    setCurrentStep,
    directorsList,
    setDirectorsList,
    defaultDirectorObject,
    addClientFormObj,
    setAddClientFormObj
}) => {
    const dispatch = useDispatch();

    const handleRemoveEntry = (index, bcr_id = null) => {
        if (bcr_id) {
            dispatch(deleteClientRefThunk({ bcr_id: bcr_id }));
        }
        const newInputs = [...directorsList];
        newInputs.splice(index, 1);
        setDirectorsList(newInputs);
    };
    const handleAddEntry = () => {
        setDirectorsList([...directorsList, defaultDirectorObject]);
    };

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleEntityValue = (value, targetProperty, index) => {
        setDirectorsList((pre) => {
            return pre.map((prevObj, preInd) => {
                return {
                    ...prevObj,
                    [targetProperty]: preInd === index ? value : prevObj?.[targetProperty]
                };
            });
        });
    };

    const handleComboxboxEntity = (obj = null, index, isFirstLoad = false) => {
        setDirectorsList((pre) => {
            return pre.map((prevObj, preInd) => {
                if (preInd === index) {
                    if (isFirstLoad) {
                        if (!obj) {
                            return {
                                ...prevObj,
                                isFirstLoad: false,
                                disabled: {
                                    first_name: false,
                                    last_name: false,
                                    tfn: false,
                                    phone_number: false,
                                    email_address: false,
                                    dob: false,
                                    director_status: false,
                                    residential_address: false
                                }
                            };
                        }

                        return {
                            ...prevObj,
                            isFirstLoad: false
                        };
                    }
                    if (obj?.business_client_id === prevObj?.business_client_id) {
                        return {
                            ...prevObj
                        };
                    }
                    return {
                        ...prevObj,
                        business_client_id: obj ? obj?.business_client_id : '',
                        first_name: obj ? obj?.first_name : '',
                        last_name: obj ? obj?.last_name : '',
                        tfn: obj ? obj?.tfn : '',
                        phone_number: obj ? obj?.phone_number : '',
                        email_address: obj ? obj?.email : '',
                        director_identification_number: obj ? obj?.director_identification_number : '',
                        dob: obj ? obj?.date_of_birth : '',
                        place_of_birth: obj ? obj?.place_of_birth : '',
                        director_status: 'Inactive',
                        residential_address: obj ? obj?.residential_address : '',
                        disabled: {
                            first_name: obj && obj?.first_name && obj?.first_name !== '' ? true : false,
                            last_name: obj && obj?.last_name && obj?.last_name !== '' ? true : false,
                            tfn: obj && obj?.tfn && obj?.tfn !== '' ? true : false,
                            phone_number: obj && obj?.phone_number && obj?.phone_number !== '' ? true : false,
                            email_address: obj && obj?.email && obj?.email !== '' ? true : false,
                            director_status: obj && obj?.director_status && obj?.director_status !== '' ? true : false,
                            dob: obj && obj?.date_of_birth && obj?.date_of_birth !== '' ? true : false,
                            residential_address:
                                obj && obj?.residential_address && obj?.residential_address !== '' ? true : false
                        }
                    };
                }
                return prevObj;
            });
        });
    };

    const handleNext = (e) => {
        e.preventDefault();

        let directors = [];
        directorsList?.forEach((director) => {
            const newDirector = {
                business_client_id: director?.business_client_id || '',
                first_name: director?.first_name,
                last_name: director?.last_name,
                email_address: director?.email_address,
                phone_number: director?.phone_number ?? '',
                director_identification_number: director?.director_identification_number,
                director_status: director?.director_status,
                tfn: director?.tfn,
                dob: director?.dob,
                place_of_birth: director?.place_of_birth,
                residential_address: director?.residential_address,
                bcr_id: director?.bcr_id ? director?.bcr_id : ''
            };

            if (filterEmptyObject(newDirector)) {
                directors?.push(newDirector);
            }
        });

        let hasError = false;
        const emailSet = new Set();

        for (let index = 0; index < directors?.length; index++) {
            const director = directors[index];
            const validationErrors = validateIndividualsObjects(director);

            if (validationErrors?.length > 0) {
                validationErrors?.forEach((error) => {
                    toastHandler(error + ' at ' + (index + 1), TOAST_TYPE_ERROR);
                });
                hasError = true;
                break;
            }

            if (director?.email_address) {
                if (!validateEmailValue(director?.email_address)) {
                    hasError = true;
                    toastHandler(`Invalid Email at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                }
            }

            if (director?.email_address) {
                if (emailSet.has(director.email_address)) {
                    hasError = true;
                    toastHandler(`Duplicate email found at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                } else {
                    emailSet.add(director.email_address);
                }
            }

            if (director?.phone_number?.length > 4) {
                if (!validatePhoneNumber(director?.phone_number)) {
                    hasError = true;
                    break;
                }
            }

            if (director?.tfn?.length > 1) {
                if (!validateTFN(director?.tfn)) {
                    hasError = true;
                    toastHandler('Invalid TFN number', TOAST_TYPE_ERROR);
                    break;
                }
            }
        }

        if (hasError) {
            return;
        }
        setAddClientFormObj({
            ...addClientFormObj,
            director_details: directors
        });
        setCurrentStep(2);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };
    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="table-title font-poppins font-semibold">Director Details</div>
                <div onClick={handleAddEntry} className="cursor-pointer">
                    <NewPlusIcon className="w-8 h-8" />
                </div>
            </div>

            <form onSubmit={handleNext} onKeyDown={handleKeyDown}>
                <div>
                    {directorsList?.map((directorObj, directorObjIndex) => (
                        <div className=" !bg-dimThird p-5  rounded-xl mt-5  " key={'directorsList' + directorObjIndex}>
                            <div className="flex justify-end items-center">
                                <span
                                    className="cursor-pointer w-fit ml-auto"
                                    onClick={() => handleRemoveEntry(directorObjIndex, directorObj?.bcr_id)}
                                >
                                    <label className="invisible"> f</label>
                                    <BgDeleteIcon />
                                </span>
                            </div>

                            <div className=" default-forms grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5  rounded-xl ">
                                <div className="">
                                    <label className="inputLabel">Director</label>

                                    <ComboboxComponentSSR
                                        fetchDataFunction={(payload) =>
                                            fetchReferencesListFun({
                                                ...payload,
                                                type: 'individual'
                                            })
                                        }
                                        defaultSelected={directorObj?.business_client_id}
                                        removePreviousSelected
                                        onRemove={(_e) => {
                                            handleComboxboxEntity(null, directorObjIndex);
                                        }}
                                        filterSelectedValues={directorsList || []}
                                        filterSelectedValueTargetElement={'business_client_id'}
                                        onChange={(res) => {
                                            const obj = res[0];
                                            handleComboxboxEntity(obj, directorObjIndex, directorObj.isFirstLoad);
                                        }}
                                        targetProperty="business_client_id"
                                        placeholder={'Search by Name/Reference No.'}
                                        limit={1}
                                        sx="rounded-xl ring-gray-400 "
                                        showNameOnly
                                    />
                                </div>

                                <div className="">
                                    <InputField
                                        label={'First Name'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'first_name', directorObjIndex);
                                        }}
                                        name={'First Name'}
                                        inputValue={directorObj.first_name}
                                        placeholder={'First Name'}
                                        classes="mt-2 bg-transparent"
                                        disabled={directorObj?.disabled?.first_name}
                                    />
                                </div>
                                <div className="">
                                    <InputField
                                        label={'Last Name'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'last_name', directorObjIndex);
                                        }}
                                        name={'Last Name'}
                                        inputValue={directorObj.last_name}
                                        placeholder={'Last Name'}
                                        classes="mt-2 bg-transparent"
                                        disabled={directorObj?.disabled?.last_name}
                                    />
                                </div>
                                <div className="">
                                    <TFNInputField
                                        label={'TFN'}
                                        maxLength={10}
                                        name={'TFN'}
                                        inputValue={directorObj.tfn}
                                        placeholder={'TFN'}
                                        classes="mt-2 bg-transparent"
                                        disabled={directorObj?.disabled?.tfn}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'tfn', directorObjIndex);
                                        }}
                                    />
                                </div>
                                <div className="">
                                    <PhoneInputComponent
                                        value={directorObj.phone_number}
                                        title="Phone Number"
                                        placeholder="Enter Phone Number"
                                        titleSx=""
                                        sx="!rounded-xl bg-transparent"
                                        bg={'!bg-transparent'}
                                        atChange={(phone) => {
                                            handleEntityValue(phone, 'phone_number', directorObjIndex);
                                        }}
                                        isDisabled={directorObj?.disabled?.phone_number}
                                    />
                                </div>
                                <div className="">
                                    <EmailInput
                                        label={'Email Address'}
                                        onChange={(e) => {
                                            handleEntityValue(e, 'email_address', directorObjIndex);
                                        }}
                                        name={'Email Address'}
                                        value={directorObj.email_address}
                                        placeholder={'Email Address'}
                                        classes="mt-2 bg-transparent"
                                        disabled={directorObj?.disabled?.email_address}
                                    />
                                </div>

                                <div className="">
                                    <NumberInput
                                        label={'Director Identification Number'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e, 'director_identification_number', directorObjIndex);
                                        }}
                                        name={'Director Identification Number'}
                                        value={directorObj.director_identification_number}
                                        placeholder={'Director Identification Number'}
                                        sx="mt-2 bg-transparent"
                                        disabled={directorObj?.disabled?.director_identification_number}
                                    />
                                </div>

                                <div className="">
                                    <div>
                                        <label>Date of Birth</label>
                                    </div>
                                    <DatePick
                                        maxDate={dayjs(dateMinus12Years, 'DD-MM-YYYY')}
                                        iconSet={{
                                            right: CalendarSmallIco
                                        }}
                                        onDateChange={(date) => {
                                            handleEntityValue(date, 'dob', directorObjIndex);
                                        }}
                                        placeholder="DD-MM-YYYY"
                                        format="DD-MM-YYYY"
                                        value={directorObj?.dob ? dayjs(directorObj.dob, 'DD-MM-YYYY') : ''}
                                        sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full hover:bg-[#FAF9F6] !bg-transparent"
                                        isDisabled={directorObj?.disabled?.dob}
                                    />
                                </div>

                                <div className="">
                                    <PickAddress
                                        id={'place_of_birth-autocomplete' + directorObjIndex}
                                        address={directorObj?.place_of_birth}
                                        setAddress={(address) => {
                                            handleEntityValue(address, 'place_of_birth', directorObjIndex);
                                        }}
                                        onSelect={(place) => {
                                            handleEntityValue(
                                                place.formatted_address,
                                                'place_of_birth',
                                                directorObjIndex
                                            );
                                        }}
                                        label="Place of Birth"
                                        placeholder="Place of Birth"
                                        classes="bg-transparent"
                                    />
                                </div>
                                <div className="">
                                    <PickAddress
                                        id={'residential-autocomplete' + directorObjIndex}
                                        address={directorObj?.residential_address}
                                        setAddress={(address) => {
                                            handleEntityValue(address, 'residential_address', directorObjIndex);
                                        }}
                                        onSelect={(place) => {
                                            handleEntityValue(
                                                place.formatted_address,
                                                'residential_address',
                                                directorObjIndex
                                            );
                                        }}
                                        label="Physical Address"
                                        placeholder="Physical Address"
                                        classes="bg-transparent"
                                        disabled={directorObj?.disabled?.residential_address}
                                    />
                                </div>

                                <div className="">
                                    <div className="">
                                        <label className="inputLabel">Director Status</label>
                                        <DefaultDoubleSwitch
                                            items={[
                                                {
                                                    title: 'Active',
                                                    selected: directorObj?.director_status === 'Active'
                                                },
                                                {
                                                    title: 'Inactive',
                                                    selected: directorObj?.director_status === 'Inactive'
                                                }
                                            ]}
                                            onClick={(value) => {
                                                if (value) {
                                                    handleEntityValue(value, 'director_status', directorObjIndex);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="flex justify-center mt-24 items-center">
                        <div className="flex gap-8 items-center">
                            <Button
                                title="Back"
                                classes={
                                    'border-blueish text-blueish border-2 h-[3rem] rounded-lg buttons-font-lighter font-fira px-14'
                                }
                                atClick={() => setCurrentStep(0)}
                                buttonType="button"
                            />

                            <Button
                                title={'Next'}
                                buttonType="submit"
                                classes={'ann-btn rounded-lg px-14 buttons-font-lighter font-fira'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default DirectorDetailsSubForm;
