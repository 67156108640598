import { Button } from 'components/atoms/buttons/Button';
import { H3 } from 'components/atoms/typography/headings/H3';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import React, { useEffect, useState } from 'react';
import { EmpCard } from './EmpCard';
import { DataTransfer } from 'utills/svgs/DataTransfer';
import { useDispatch } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { businessEmployeeArchiveThunk, getEmployeeStatsThunk } from 'store/settings/team/team';
import { BUSINESS_ID } from 'utills/globalVars';
export const PreviewEmps = ({ setCondition, selectedTarget, emp, targetJobs, targetTasks, targetClients }) => {
    const dispatch = useDispatch();
    const [jobs, setJobs] = useState(0);
    const [tasks, setTasks] = useState(0);
    const [clients, setClients] = useState(0);
    const business_id = localStorage.getItem(BUSINESS_ID);

    const [loading, setLoading] = useState(false);

    const handleSelect = () => {
        dispatch(setLoader(true));
        dispatch(getEmployeeStatsThunk({ employee_id: selectedTarget?.user_id, business_id }))
            .then((response) => {
                if (response.payload) {
                    setTasks(response.payload.tasks);
                    setJobs(response.payload.jobs);
                    setClients(response.payload.client);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        handleSelect();
    }, []);

    const handleDelete = () => {
        setLoading(true);
        dispatch(
            businessEmployeeArchiveThunk({
                business_employee_id: emp?.business_employee?.business_employee_id,
                from_employee_id: emp?.user_id,
                to_employee_id: selectedTarget?.user_id
            })
        )
            .then((response) => {
                if (response.payload) {
                    // const newTeam = team?.filter(
                    //     (member) => member.business_employee?.business_employee_id !== business_employee_id
                    // );
                    // setTeam(newTeam);
                    setCondition(3);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="">
            <H3 sx="font-fira !text-[#222222]"> Transfer Data</H3>

            <SecondaryParagraph sx="text-secondarybg mt-4">
                All assigned jobs, tasks, and clients will be transferred to the selected employee. Once the transfer is
                complete, the original employee will be permanently removed from the system.
            </SecondaryParagraph>

            <div className="lg:flex gap-5   justify-between mt-5">
                <EmpCard emp={emp} targetJobs={targetJobs} targetTasks={targetTasks} targetClients={targetClients} />
                <div
                    className={`my-5  flex justify-center items-center lg:my-0  lg:self-center   ${
                        loading && 'animate-bounce'
                    }`}
                >
                    {' '}
                    <DataTransfer />
                </div>
                <EmpCard emp={selectedTarget} targetJobs={jobs} targetTasks={tasks} targetClients={clients} />
            </div>

            <div className="mt-12 flex justify-center gap-3 items-center w-full">
                <Button
                    title="Back"
                    atClick={() => setCondition(1)}
                    classes="border-blueish border min-h-[3rem]  px-16 text-blueish font-[500] font-fira rounded-xl"
                />
                <Button
                    title="Transfer"
                    atClick={() => handleDelete()}
                    classes="ann-btn px-14 rounded-xl"
                    disabled={loading}
                />
            </div>
        </div>
    );
};
