import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { InputField } from 'components/atoms/inputs/InputField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import {
    addManualClientThunk,
    deleteClientRefThunk,
    searchClientsThunk,
    updateClientProfileImageThunk,
    updateManualClientThunk
} from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS, USER_TYPE } from 'utills/globalVars';
import cloneDeep from 'lodash/cloneDeep';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import dayjs from 'dayjs';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { base64ToBlob } from 'utills/filesHandlers';
import { Loader } from 'components/common/Loader';
import { RelatedEntities } from '../common/RelatedEntities';
import { filterEmptyObject } from 'utills/dataValidation';
import { validateStartEndDate } from 'utills/baseValidations';
import { dateMinus12Years } from 'utills/moment';

export const IndividualAddSubForm = ({
    setOpen,
    addClientFormObj,
    setIndividualOpen,
    client,
    handleReload,
    setCurrentStep,
    profileImage,
    entities,
    setEntities,
    default_options
}) => {
    const dispatch = useDispatch();

    const [showNewSpouse, _setShowNewSpouse] = useState(false);
    const [newSpouseFirstName, setNewSpouseFirstName] = useState('');

    const [spouseGender, setSpouseGender] = useState({
        value: 'Male',
        disabled: false
    });
    const [spouseDob, setSpouseDob] = useState({
        value: '',
        disabled: false
    });
    const [spouse_first_name, set_spouse_first_name] = useState({
        value: '',
        disabled: false
    });
    const [spouse_last_name, set_spouse_last_name] = useState({
        value: '',
        disabled: false
    });

    const [_spouseSelected, setSpouseSelected] = useState(false);
    const [spouseTaxIncome, setSpouseTaxIncome] = useState('');
    const [isFullYear, setIsFullYear] = useState('No');
    const [spouseDateFrom, setSpouseDateFrom] = useState('');
    const [spouseDateTo, setSpouseDateTo] = useState('');
    const [spouseDiedThisYear, setSpouseDiedThisYear] = useState('No');
    const [numberOfDependents, setNumberOfDependents] = useState('');

    const [newSpouseLastName, setNewSpouseLastName] = useState('');
    const [taxableIncome, setTaxableIncome] = useState('');
    const [linkSpouseProfile, setLinkSpouseProfile] = useState(false);
    const { isLoading } = useSelector((state) => state.global);

    const [auResidencyStatus, setAuResidencyStatus] = useState('');

    const [clientData, _setClientData] = useState(cloneDeep(client));
    const [selectedSpouse, setSelectedSpouse] = useState(null);
    const [spouseBCR_ID, setSpouseBCR_ID] = useState(null);

    useEffect(() => {
        setSpouseBCR_ID(client?.spouse?.bcr_id || null);
    }, [client]);

    const handleUpdateProfile = (id) => {
        const mimeType = profileImage.match(/data:([^;]+);base64,/)[1];

        const blob = base64ToBlob(profileImage);
        const formData = new FormData();

        formData.append('business_client_id', id);
        formData.append('image', blob, `image.${mimeType.split('/')[1]}`);
        formData.append('uploaded_by', USER_TYPE);

        dispatch(setLoader(true));

        dispatch(updateClientProfileImageThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setIndividualOpen(false);

                    setOpen(false);
                    handleReload();
                    setCurrentStep(0);
                    toastHandler('Client added successfully', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleUpdateClient = (payload) => {
        //updateClientProfileImageThunk

        dispatch(setLoader(true));

        dispatch(updateManualClientThunk(payload))
            .then((response) => {
                if (response.payload) {
                    if (profileImage) {
                        handleUpdateProfile(client?.business_client_id);
                    } else {
                        setIndividualOpen(false);
                        setOpen(false);
                        handleReload();
                        toastHandler('Client updated successfully', TOAST_TYPE_SUCCESS);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                if (!profileImage) {
                    dispatch(setLoader(false));
                }
            });
    };

    const handleCreate = (newPayload) => {
        dispatch(setLoader(true));

        dispatch(addManualClientThunk(newPayload))
            .then((response) => {
                if (response.payload) {
                    if (profileImage) {
                        handleUpdateProfile(response?.payload?.business_client_id);
                    } else {
                        setIndividualOpen(false);

                        setOpen(false);
                        handleReload();
                        setCurrentStep(0);
                        toastHandler('Client added successfully', TOAST_TYPE_SUCCESS);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                if (!profileImage) {
                    dispatch(setLoader(false));
                }
            });
    };
    const handleSubmit = () => {
        let related_entities = [];
        entities?.forEach((entity) => {
            const newEntity = {
                business_client_id: entity.fields[0]?.value || '',
                display_name: entity.fields[1].value,
                designation: entity.fields[2].value,

                ...(entity.fields[3].is_conditional && { no_of_unit: entity.fields[3].value }),
                ...(entity.fields[4].is_conditional && { partnership_percentage: entity.fields[4].value }),
                ...(entity.fields[5].is_conditional && { percentage_of_shares: entity.fields[5].value }),
                start_date: entity.fields[6].value,
                end_date: entity.fields[8].value,
                currently_effective: entity.fields[7].value,
                link_to_profile: entity.link_profile,
                bcr_id: entity.bcr_id
            };

            if (filterEmptyObject(newEntity, ['currently_effective', 'bcr_id', 'link_to_profile'])) {
                related_entities.push(newEntity);
            }
        });

        const spouse = {
            business_client_id: selectedSpouse?.business_client_id || '',
            first_name: spouse_first_name.value,
            last_name: spouse_last_name.value,
            gender: spouseGender.value,
            date_of_birth:
                spouse_first_name.value !== '' && spouse_last_name?.value !== ''
                    ? spouseDob?.value
                        ? dayjs(spouseDob?.value).format('DD-MM-YYYY')
                        : ''
                    : '',
            taxable_income: spouse_first_name.value !== '' && spouse_last_name?.value !== '' ? spouseTaxIncome : '',
            spouse_for_full_fy: isFullYear,
            spouse_from_date: spouseDateFrom ? dayjs(spouseDateFrom).format('DD-MM-YYYY') : '',
            spouse_to_date: spouseDateTo ? dayjs(spouseDateTo).format('DD-MM-YYYY') : '',
            spouse_die_fy: spouseDiedThisYear,
            residency_status: auResidencyStatus,
            no_of_dependentant:
                spouse_first_name.value !== '' && spouse_last_name?.value !== '' ? numberOfDependents : '',
            link_to_profile: linkSpouseProfile,
            bcr_id: spouseBCR_ID ?? null
        };

        if (spouseDateFrom && spouseDateTo) {
            if (!validateStartEndDate(spouseDateFrom, spouseDateTo)) {
                return toastHandler(`Start date cannot be greater than end date in Spouse Reference`, TOAST_TYPE_ERROR);
            }
        }

        const newPayload = {
            ...addClientFormObj,

            spouse: filterEmptyObject(spouse, [
                'bcr_id',
                'spouse_for_full_fy',
                'no_of_dependentant',
                'spouse_die_fy',
                'link_to_profile',
                'gender'
            ])
                ? spouse
                : null,

            related_references: related_entities
        };

        if (client) {
            handleUpdateClient({ ...newPayload, business_client_id: client?.business_client_id });
        } else {
            handleCreate(newPayload);
        }
    };

    useEffect(() => {
        if (clientData) {
            if (client?.spouse) {
                const person = {
                    business_client_id: client?.spouse?.business_client_id,
                    first_name: client?.spouse?.first_name,
                    last_name: client?.spouse?.last_name,
                    gender: client?.gender,
                    date_of_birth: client?.spouse?.date_of_birth ? client?.spouse?.date_of_birth : ''
                };

                handleSpouseSelect(person, true);

                setSpouseTaxIncome(client?.spouse?.taxable_income);
                setNumberOfDependents(client?.spouse?.no_of_dependentant);
                setIsFullYear(client?.spouse?.spouse_for_full_fy ?? 'No');
                setSpouseDateFrom(client?.spouse?.spouse_from_date);
                setSpouseDateTo(client?.spouse?.spouse_to_date);
                setSpouseDiedThisYear(client?.spouse?.spouse_die_fy ?? 'No');
                setLinkSpouseProfile(client?.spouse?.link_to_profile);
                setAuResidencyStatus(client?.spouse?.residency_status);
            }
        }
    }, [clientData]);

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleSpouseSelect = (person, _isEdit) => {
        setSpouseSelected(true);

        setSelectedSpouse(person);

        if (person?.first_name) {
            set_spouse_first_name({ value: person?.first_name, disabled: person?.business_client_id ? true : false });
        }
        if (person?.last_name) {
            set_spouse_last_name({ value: person?.last_name, disabled: person?.business_client_id ? true : false });
        }
        if (person?.gender) {
            setSpouseGender({ value: person?.gender, disabled: person?.business_client_id ? true : false });
        }

        if (person?.date_of_birth) {
            setSpouseDob({ value: person?.date_of_birth, disabled: person?.business_client_id ? true : false });
        }
    };

    const handleRemoveSpouseSelect = () => {
        setSelectedSpouse(null);
        set_spouse_first_name({ disabled: false, value: '' });
        set_spouse_last_name({ disabled: false, value: '' });
        setSpouseGender({ disabled: false, value: 'Male' });
        setSpouseDob({ disabled: false, value: '' });
        setSpouseSelected(false);

        if (client && client?.spouse?.business_client_id && client?.spouse?.bcr_id) {
            dispatch(deleteClientRefThunk({ bcr_id: client?.spouse?.bcr_id })).then((res) => {
                if (res?.payload) {
                    setSpouseBCR_ID(null);
                }
            });
        }
    };

    return (
        <div>
            {isLoading && <Loader />}

            <div className="">
                <div className="mt-5 mb-5 table-title font-poppins font-semibold">Australian Residency Status</div>
                <div>
                    <div className="md:w-[420px] default-forms">
                        <div>
                            <div>
                                <label>Select Australian Residency Status</label>
                            </div>

                            <SimpleSelectMenu
                                placeholder="Select"
                                targetProperty="label"
                                selectedValue={auResidencyStatus}
                                valuePropertyName="label"
                                optionsData={
                                    [
                                        { label: 'Australian citizen' },
                                        { label: 'Australian permanent resident' },
                                        { label: 'Graduate visa' },
                                        { label: 'Other visa' },
                                        { label: 'Student visa' },
                                        { label: 'Working holiday visa' }
                                    ] || []
                                }
                                sx="rounded-xl mt-2 py-3 ring-gray-400 !font-normal cursor-pointer"
                                showChipOnly={false}
                                onChangeValue={(obj) => setAuResidencyStatus(obj.label)}
                            />
                        </div>
                    </div>
                </div>{' '}
                <div className="flex justify-between mt-10 items-center gap-5">
                    <div className=" table-title font-poppins font-semibold">Spouse Reference</div>
                </div>
                <div className=" gap-5 grid md:grid-cols-2 lg:grid-cols-3  default-forms mt-5">
                    <div className="  default-forms">
                        <div className="">
                            <label className="inputLabel">Spouse Reference Number/Name </label>

                            <ComboboxComponentSSR
                                fetchDataFunction={(payload) =>
                                    fetchReferencesListFun({
                                        ...payload,
                                        type: 'individual',
                                        ...(client ? { business_client_id: client?.business_client_id } : {})
                                    })
                                }
                                defaultSelected={
                                    (client?.spouse?.business_client_id && selectedSpouse?.business_client_id) || ''
                                }
                                removePreviousSelected
                                onRemove={(_e) => {
                                    handleRemoveSpouseSelect();
                                }}
                                onChange={(res) => {
                                    const obj = res[0];
                                    handleSpouseSelect(obj);
                                }}
                                targetProperty="business_client_id"
                                placeholder="Search by name/reference number/email"
                                limit={1}
                                sx="rounded-xl ring-gray-400"
                                showNameOnly
                            />
                        </div>
                    </div>
                    <div className="">
                        <InputField
                            label="First Name"
                            inputValue={spouse_first_name.value}
                            maxLength={30}
                            allowSpaces={false}
                            onChange={(e) => {
                                set_spouse_first_name({ ...spouse_first_name, value: e.target.value });
                            }}
                            name="First Name"
                            placeholder="Enter First Name"
                            required
                            classes="mt-2"
                            disabled={spouse_first_name.disabled ? true : false}
                        />
                    </div>

                    <div className="">
                        <InputField
                            label="Last Name"
                            inputValue={spouse_last_name.value}
                            maxLength={30}
                            allowSpaces={true}
                            onChange={(e) => {
                                set_spouse_last_name({ ...spouse_last_name, value: e.target.value });
                            }}
                            name="Last Name"
                            placeholder="Enter Last Name"
                            required
                            classes="mt-2"
                            disabled={spouse_last_name.disabled ? true : false}
                        />
                    </div>

                    <div className="">
                        <label className="inputLabel">Gender</label>
                        <DefaultDoubleSwitch
                            disabled={spouseGender.disabled ? true : false}
                            items={[
                                { title: 'Male', selected: spouseGender.value === 'Male' },
                                { title: 'Female', selected: spouseGender.value === 'Female' }
                            ]}
                            onClick={(value) => {
                                setSpouseGender({ ...spouseGender, value: value });
                            }}
                        />
                    </div>
                    <div className="">
                        <div>
                            <label>Date of Birth</label>
                        </div>
                        <DatePick
                            isDisabled={spouseDob.disabled ? true : false}
                            iconSet={{
                                right: CalendarSmallIco
                            }}
                            onDateChange={(date) => {
                                setSpouseDob({ ...spouseDob, value: date });
                            }}
                            maxDate={dayjs(dateMinus12Years, 'DD-MM-YYYY')}
                            placeholder="DD-MM-YYYY"
                            format="DD-MM-YYYY"
                            value={spouseDob?.value ? dayjs(spouseDob.value, 'DD-MM-YYYY') : ''}
                            sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                        />
                    </div>

                    <div className="">
                        <label>Taxable Income</label>
                        <input
                            className={`mt-2 `}
                            type="number"
                            value={spouseTaxIncome}
                            onChange={(e) => setSpouseTaxIncome(e.target.value)}
                            placeholder="Enter Taxable Income"
                        />
                    </div>
                    <div className="">
                        <label>Number of Dependents</label>
                        <input
                            className={`mt-2 `}
                            type="number"
                            value={numberOfDependents}
                            onChange={(e) => setNumberOfDependents(e.target.value)}
                            placeholder="Enter Number of Dependents"
                        />
                    </div>

                    <div className="">
                        <label className="inputLabel">Did you have a spouse for this full Financial Year?</label>
                        <DefaultDoubleSwitch
                            items={[
                                { title: 'Yes', selected: isFullYear === 'Yes' },
                                { title: 'No', selected: isFullYear === 'No' }
                            ]}
                            onClick={(value) => {
                                setIsFullYear(value);
                            }}
                        />
                    </div>

                    {isFullYear === 'Yes' ? (
                        ''
                    ) : (
                        <>
                            <div className="  ">
                                <label className="inputLabel">If you did not have spouse for the full year?</label>
                                <div className="md:flex items-center justify-between gap-5">
                                    <div className=" w-full">
                                        {' '}
                                        <DatePick
                                            maxDate={dayjs()}
                                            iconSet={{
                                                right: CalendarSmallIco
                                            }}
                                            onDateChange={(date) => {
                                                setSpouseDateFrom(date);
                                            }}
                                            placeholder="DD-MM-YYYY"
                                            value={spouseDateFrom ? dayjs(spouseDateFrom, 'DD-MM-YYYY') : ''}
                                            sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                        />
                                    </div>
                                    <div className=" w-full">
                                        <DatePick
                                            maxDate={dayjs()}
                                            iconSet={{
                                                right: CalendarSmallIco
                                            }}
                                            onDateChange={(date) => {
                                                setSpouseDateTo(date);
                                            }}
                                            placeholder="DD-MM-YYYY"
                                            value={spouseDateTo ? dayjs(spouseDateTo, 'DD-MM-YYYY') : ''}
                                            sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                        />
                                    </div>{' '}
                                </div>
                            </div>
                            <div className="">
                                <label className="inputLabel">
                                    Did your spouse die during the current financial year?
                                </label>
                                <DefaultDoubleSwitch
                                    items={[
                                        { title: 'Yes', selected: spouseDiedThisYear === 'Yes' },
                                        { title: 'No', selected: spouseDiedThisYear === 'No' }
                                    ]}
                                    onClick={(value) => {
                                        setSpouseDiedThisYear(value);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div>
                    {showNewSpouse && (
                        <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 default-forms">
                            <div>
                                <InputField
                                    label="First Name"
                                    maxLength={30}
                                    allowSpaces={true}
                                    onChange={(e) => {
                                        setNewSpouseFirstName(e.target.value);
                                    }}
                                    name="First Name"
                                    inputValue={newSpouseFirstName}
                                    placeholder="Enter First Name"
                                    required
                                    classes="mt-2"
                                />
                            </div>

                            <div>
                                <InputField
                                    label="Last Name"
                                    maxLength={30}
                                    allowSpaces={true}
                                    onChange={(e) => {
                                        setNewSpouseLastName(e.target.value);
                                    }}
                                    name="Last Name"
                                    inputValue={newSpouseLastName}
                                    placeholder="Enter Last Name"
                                    required
                                    classes="mt-2"
                                />
                            </div>

                            <div>
                                <label>Taxable Income</label>
                                <input
                                    className={`mt-2 `}
                                    type="number"
                                    value={taxableIncome}
                                    onChange={(e) => setTaxableIncome(e.target.value)}
                                    placeholder="Enter Taxable Income"
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-4 flex gap-2 items-center buttons-font-lighter font-poppins">
                    <div>
                        <input
                            type="checkbox"
                            className={`rounded-[3px]  scale-[1.1]  ${
                                selectedSpouse ? '' : '!bg-gray-200 border-none cursor-not-allowed'
                            } `}
                            onChange={(e) => setLinkSpouseProfile(e.target.checked)}
                            checked={linkSpouseProfile}
                            disabled={selectedSpouse ? false : true}
                        />
                    </div>{' '}
                    <div>I would like to link the profile</div>
                </div>
                <div className="mt-10">
                    <RelatedEntities
                        client={client}
                        addClientFormObj={addClientFormObj}
                        setCurrentStep={setCurrentStep}
                        entities={entities}
                        setEntities={setEntities}
                        onBack={() => {
                            setOpen(false);
                            setCurrentStep(0);
                        }}
                        onNext={handleSubmit}
                        designationOptions={default_options}
                        client_type="individual"
                    />
                </div>
            </div>
        </div>
    );
};
