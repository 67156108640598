import React from 'react';

import { CheckboxInput } from 'components/atoms/inputs/CheckboxInput';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatFileSize } from 'utills/filesHandlers';
import { formateDate } from 'utills/moment';
import { BgNotePencil } from 'utills/svgs/BgNotePencil';
import { EyeIcon } from 'utills/svgs/EyeIcon';
import '../css/appuserProfile.css';
import { BgDownloadIcon } from 'utills/svgs/BgDownloadIcon';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import ConfirmationModal from 'utills/confirmationModal';
import { NoDataFound } from 'components/common/NoDataFound';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFileThunk } from 'store/fileManager';
import { toastHandler } from 'responseHanlder';
import {
    getProfileName,
    LEGAL_DOCUMENT_FOLDER,
    PERMISSIONS_MESSAGE,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from 'utills/globalVars';

import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { generateId } from 'utills/uid';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import useRoleGuard from 'Hooks/useRoleGuard';
import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { IN_APP_VIEW_FORMATS } from './../../../../utills/globalVars';

export const FilesTable = ({ files, setFilesData, currentFolder, deleteIds, setDeleteIds }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { hasPermissions } = useRoleGuard();

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [managerId, setManagerId] = useState(null);
    const { data: loginData } = useSelector((state) => state.login);
    const storedInDicators = JSON.parse(localStorage.getItem('indicators'));
    const [isZoomed, setIsZoomed] = useState(false);
    const { data: customer } = useSelector((state) => state?.viewBusinessCustomerInfo);

    const handleDownloadClick = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'your-file-name.pdf';
        link.click();
    };

    const handleDelete = () => {
        setIsConfirmationOpen(false);

        dispatch(deleteFileThunk({ file_manager_id: [managerId] }))
            .then((response) => {
                if (response.payload) {
                    const findFiles = files?.filter((item) => item.file_manager_id !== managerId);
                    setFilesData(findFiles);
                    toastHandler('File Deleted Successfully', TOAST_TYPE_SUCCESS);
                }
            })
            .then((error) => {
                console.error(error);
            })
            .finally(() => {
                setManagerId(null);
            });
    };

    const deleteFile = (managerId) => {
        if (hasPermissions('Clients', ['write', 'admin'])) {
            setManagerId(managerId);
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    };

    const handleDeleteIds = (e, file_manager_id) => {
        if (e.target.checked) {
            const newIds = [...deleteIds];
            newIds.push(file_manager_id);
            setDeleteIds(newIds);
        } else {
            const newIds = [...deleteIds];
            const findId = newIds?.find((item) => item === file_manager_id);
            if (findId) {
                const index = newIds.indexOf(findId);
                newIds.splice(index, 1);
                setDeleteIds(newIds);
            }
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            let ids = [];
            const newFiles = files?.filter((file) => file?.final_doc === null);
            newFiles?.forEach((file) => {
                ids?.push(file?.file_manager_id);
            });

            setDeleteIds(ids);
        } else {
            setDeleteIds([]);
        }
    };

    useEffect(() => {
        const driverObj = driver({
            onDestroyed: () => {},
            showProgress: true,
            steps: [
                {
                    element: '.request-signature-indicate',

                    popover: {
                        title: 'signature-indicate',
                        description:
                            'In this section you can view your clients details. You can view there submited forms',
                        popoverClass: 'my-custom-popover-class '
                    }
                }
            ]
        });

        //remember to change this to  !loginData?.second_time_login && !storedInDicators?.file_manager
        if (!loginData?.second_time_login && !storedInDicators?.accountants_folder && files?.length > 0) {
            driverObj.drive();
        } else {
            driverObj.destroy();
        }
        return () => {
            driverObj.destroy();
        };
    }, []);

    const [previewFile, setPreviewFile] = useState(null);
    const handleViewFile = (file) => {
        if (file?.file_type !== 'application/pdf') {
            setIsZoomed(true);
            setPreviewFile(file);
        } else {
            navigate(`/pdf/viewer/${id}`, {
                state: {
                    url: file?.final_doc ? file?.final_doc : file?.url,
                    name: file?.name
                }
            });
        }
    };
    return (
        <div>
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />

            <div className="files-table-wrapper">
                {' '}
                <div className="files-table rounded-md p-5 mt-5">
                    <div className="grid primary-fonts grid-cols-8 gap-5">
                        <div className=" flex items-center gap-5 col-span-2">
                            <div className="files-table-label flex items-center">
                                <CheckboxInput
                                    atChange={(e) => handleSelectAll(e)}
                                    checked={
                                        deleteIds?.length > 0 &&
                                        deleteIds?.length === files?.filter((file) => file?.final_doc === null)?.length
                                            ? true
                                            : false
                                    }
                                />
                            </div>
                            Name
                        </div>
                        <div
                            className={` ${
                                currentFolder === LEGAL_DOCUMENT_FOLDER ? 'col-span-2' : ''
                            }  flex items-center  `}
                        >
                            Owner
                        </div>
                        <div className=" flex items-center">Created At</div>
                        <div
                            className={`${
                                currentFolder === LEGAL_DOCUMENT_FOLDER ? '' : 'col-span-2'
                            }  flex items-center`}
                        >
                            File Size
                        </div>

                        <div className={` ${currentFolder === LEGAL_DOCUMENT_FOLDER ? '' : 'col-span-1'} `}></div>
                        <div className=" flex items-center">Action</div>
                    </div>

                    <hr className="mt-2" />
                    <div>
                        {files?.length > 0 ? (
                            files?.map((file, index) => (
                                <div key={generateId()}>
                                    <div className="grid secondary-fonts grid-cols-8 gap-5 mt-2">
                                        <div className=" flex gap-5 items-center  col-span-2">
                                            <div>
                                                <CheckboxInput
                                                    atChange={(e) => handleDeleteIds(e, file?.file_manager_id)}
                                                    checked={deleteIds.includes(file?.file_manager_id)}
                                                />
                                            </div>
                                            <div className="manager-file-icon-wrapper1 p-2 ">
                                                <GenerateFileIcon url={file?.url} sx={'!w-[40px] !h-[40px]'} />
                                            </div>

                                            <span className="file-manager-file-name cursor-pointer break-all">
                                                {file ? file?.name : 'one'}
                                            </span>
                                        </div>
                                        <div
                                            className={`  ${
                                                currentFolder === LEGAL_DOCUMENT_FOLDER && 'col-span-2'
                                            } flex items-center `}
                                        >
                                            {file?.uploaded_by === 'employee' ? (
                                                <div className="flex gap-2 items-center">
                                                    <div>
                                                        <DefaultProfileViewer
                                                            image={file?.employee?.image}
                                                            atClick={() => {}}
                                                            width="40px"
                                                            height="40px"
                                                            name={
                                                                file?.employee?.first_name +
                                                                ' ' +
                                                                file?.employee?.last_name
                                                            }
                                                        />
                                                    </div>
                                                    <span>
                                                        {`${file?.employee?.first_name}  ${file?.employee?.last_name}`}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="flex gap-2 items-center">
                                                    {' '}
                                                    <div>
                                                        <DefaultProfileViewer
                                                            image={file?.customer?.image}
                                                            atClick={() => {}}
                                                            width="40px"
                                                            height="40px"
                                                            name={getProfileName(file?.customer)}
                                                        />
                                                    </div>
                                                    <span className="linear-font break-all">
                                                        {getProfileName(file?.customer)}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex items-center linear-font">
                                            {formateDate(file?.updated_at)}
                                        </div>
                                        <div className={`  flex items-center linear-font`}>
                                            {formatFileSize(file?.size)}
                                        </div>

                                        <div
                                            className={` ${
                                                currentFolder === LEGAL_DOCUMENT_FOLDER ? '' : 'col-span-2'
                                            }  flex items-center`}
                                        >
                                            {currentFolder !== LEGAL_DOCUMENT_FOLDER &&
                                            customer?.status === 'active' &&
                                            !file?.final_doc &&
                                            file?.file_type === 'application/pdf' ? (
                                                <button
                                                    onClick={() => {
                                                        if (file?.file_type !== 'application/pdf') {
                                                            toastHandler('Only PDF is allowed', TOAST_TYPE_ERROR);
                                                            return;
                                                        }
                                                        if (hasPermissions('Clients', ['write', 'admin'])) {
                                                            navigate(`/request/pdf/${id}`, { state: file });
                                                        } else {
                                                            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
                                                        }
                                                    }}
                                                    className={`bg-linear text-white small-text font-fira rounded-xl  text-md px-2 py-2  ${
                                                        index === 0 && 'request-signature-indicate'
                                                    }`}
                                                >
                                                    Request Signature
                                                </button>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="flex items-center flex-wrap gap-2 ">
                                            {IN_APP_VIEW_FORMATS.includes(file?.file_type) && (
                                                <div onClick={() => handleViewFile(file)} className=" cursor-pointer">
                                                    <EyeIcon className={' !w-[36px] !h-[36px]'} />
                                                </div>
                                            )}
                                            <div
                                                onClick={() =>
                                                    handleDownloadClick(file?.final_doc ? file?.final_doc : file?.url)
                                                }
                                                className="cursor-pointer"
                                            >
                                                <BgDownloadIcon />
                                            </div>
                                            {!file?.final_doc && (
                                                <div
                                                    onClick={() => deleteFile(file?.file_manager_id)}
                                                    className="cursor-pointer"
                                                >
                                                    <BgDeleteIcon />
                                                </div>
                                            )}

                                            {currentFolder === 'authorized' && (
                                                <div
                                                    onClick={() =>
                                                        navigate(`/sign/pdf/${file.customer_document_id}/${id}`, {
                                                            state: id
                                                        })
                                                    }
                                                >
                                                    {' '}
                                                    <BgNotePencil />{' '}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <hr className="mt-2" />
                                </div>
                            ))
                        ) : (
                            <NoDataFound message="No Data Found" />
                        )}
                    </div>

                    {isZoomed && previewFile && previewFile?.file_type !== 'application/pdf' ? (
                        <ZoomImage
                            src={previewFile?.final_doc ? previewFile?.final_doc : previewFile?.url}
                            alt="Profile"
                            onClose={() => {
                                setIsZoomed(false);
                                setPreviewFile(null);
                            }}
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};
