/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import ThreadListJumbo from './ThreadListJumbo';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import '../css/threads.css';
import { LinkIcon } from '../../../../utills/svgs/LinkIcon';
import { ThreeDotIcon } from '../../../../utills/svgs/ThreeDotIcon';
import { CommentIcon } from '../../../../utills/svgs/CommentIcon';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { handleEditThread, setLoader, setSideLoader } from 'store/global/globalReducer';
import {
    addCommentThunk,
    deleteThreadFileThunk,
    getAllCommentsThunk,
    getAllThreadsThunk
} from 'store/workspace/workspaceThreads';
import { generateId } from 'utills/uid';
import { formateDate, formateDateAndTime } from 'utills/moment';
import {
    BUSINESS_ID,
    ERROR_TYPE_ERROR,
    ERROR_TYPE_SUCCESS,
    handleDownload,
    PERMISSIONS_MESSAGE
} from 'utills/globalVars';
import { EyeIcon } from 'utills/svgs/EyeIcon';
import { ThreadsSkeleton } from '../threads-skeleton/ThreadsSkeleton';
import cloneDeep from 'lodash/cloneDeep';
import { toastHandler } from 'responseHanlder';
import { WhiteBgTimes } from 'utills/svgs/WhiteBgTimes';
import TruncateText from 'global-components/StringSlicer';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/20/solid';
import defaultUser from '../../../../files/default_user.png';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { MessagesIcon } from 'utills/svgs/MessagesIcon';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { NoDataFound } from 'components/common/NoDataFound';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import Badge from 'components/atoms/Badge';
import pdfIcon from '../../../../files/pdfIcon.svg';
import { downloadFile } from 'utills/filesHandlers';
import useRoleGuard from 'Hooks/useRoleGuard';
import { BgPlusIcon } from 'utills/svgs/BgPlusIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { FileCardPreview } from 'components/atoms/FileCardPreview/FileCardPreview';
const ThreadListPage = ({
    toggleMyNewThread,
    toggleStopThread,
    closeThreadHandler,
    threadsUpdated,
    toggleThreadUpdate,
    toggleEditThread
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: comments } = useSelector((state) => state.getAllComments);
    const { isLoading, sideLoader } = useSelector((state) => state.global);

    const [open, setOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const [showThreadModal, setShowThreadModal] = useState(false);
    const [modalIndex, setModalIndex] = useState(null);
    const [commentThreads, setCommentThreads] = useState([]);
    const [commentIndex, setCommentIndex] = useState(null);
    const [showComments, setShowComments] = useState(false);
    const [showImage, setShowImage] = useState('');

    const { hasPermissions } = useRoleGuard();

    const toggleThreadModal = (index) => {
        setModalIndex(index);
        setShowThreadModal(!showThreadModal);
    };

    const toggleComments = (thread_id, index, isComment) => {
        if (isComment) {
            //
        } else {
            setShowComments(!showComments);
            if (showComments) {
                return;
            }
        }
        setCommentIndex(index);

        const payload = {
            business_id,
            thread_id
        };

        dispatch(getAllCommentsThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        dispatch(setLoader(true));
        const payload = {
            business_id
        };
        dispatch(getAllThreadsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setCommentThreads(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, [dispatch, threadsUpdated]);

    const handleStopThread = (thread_id, createdBy) => {
        if (hasPermissions('Threads', ['write', 'admin'], createdBy)) {
            closeThreadHandler(thread_id);
            toggleStopThread();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const [commentInputs, setCommentInputs] = useState({});

    const [comment, setComment] = useState('');
    const [threadId, setThreadId] = useState(null);

    const handleCommentChange = (e, threadId) => {
        setComment(e.target.value);
        setThreadId(threadId);
        const newCommentInputs = { ...commentInputs };
        newCommentInputs[threadId] = e.target.value;
        setCommentInputs(newCommentInputs);
    };

    const handleComment = (thread_id, index) => {
        setShowComments(true);
        const text = commentInputs[thread_id];
        if (text === '') {
            toastHandler('Please write a comment', ERROR_TYPE_ERROR);
        }
        const payload = {
            business_id,
            thread_id,
            value: text
        };
        dispatch(setSideLoader(true));
        dispatch(addCommentThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Commented added', ERROR_TYPE_SUCCESS);
                    toggleComments(threadId, index, true);
                    setComment('');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const managerActions = [
        {
            title: 'Add Thread',
            classes: 'flex gap-4 items-center px-5 !rounded-xl ann-btn buttons-font-lighter',
            type: 'ann-btn',
            iconSet: { leftIcon: MessagesIcon },
            onAction: () => {
                toggleMyNewThread();
            }
        }
    ];

    const handleDelFile = (id, fileId, createdBy) => {
        if (!hasPermissions('Threads', ['write', 'admin'], createdBy)) {
            return toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }

        const payload = {
            thread_id: id,
            thread_file_id: fileId
        };

        dispatch(deleteThreadFileThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggleThreadUpdate();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    const editThread = (thread) => {
        if (hasPermissions('Threads', ['write', 'admin'], thread?.created_by_id)) {
            dispatch(handleEditThread(thread));
            toggleEditThread();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };
    return (
        <div>
            {open && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpen} open={open} /> : ''}
            <Jumbotron
                title="Threads "
                path={[
                    { title: 'Workspace', link: 'workspace' },
                    { title: 'Threads', link: 'threads' }
                ]}
                actionsRequired={true}
                actions={managerActions}
            />
            <div>
                <div>
                    <div className=" md:mx-10 mx-5 mt-2  ">
                        {!isLoading && commentThreads?.length < 1 && <NoDataFound message="No Thread Found!" />}
                        {isLoading ? (
                            <ThreadsSkeleton />
                        ) : (
                            <div>
                                {commentThreads &&
                                    commentThreads?.map((thread, index) => (
                                        <div key={index} className="">
                                            <div className=" shadow-md rounded-xl bg-white mt-5 p-5 relative ">
                                                <div className=" absolute right-5 top-3 flex items-center gap-4">
                                                    <div className="cursor-pointer" onClick={() => editThread(thread)}>
                                                        <BgEditIcon />
                                                    </div>
                                                    <Link
                                                        to={`/my/threads/details/${thread?.thread_id}`}
                                                        className="cursor-pointer"
                                                    >
                                                        <EyeIcon />
                                                    </Link>
                                                    <div>
                                                        {' '}
                                                        <DropdownMenu>
                                                            <DropdownMenuItem
                                                                onClick={() =>
                                                                    handleStopThread(
                                                                        thread?.thread_id,
                                                                        thread?.created_by_id
                                                                    )
                                                                }
                                                            >
                                                                <div className="flex items-center gap-2 cursor-pointer">
                                                                    <ArrowLeftIcon className="w-4 h-4 bg-transparent" />
                                                                    Stop Thread
                                                                </div>
                                                            </DropdownMenuItem>
                                                        </DropdownMenu>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="absolute top-3 left-3">
                                                        <DefaultProfileViewer
                                                            image={thread.profile_image}
                                                            width="50px"
                                                            height="50px"
                                                        />
                                                    </div>
                                                    <div className="ml-[60px] ">
                                                        <div className="buttons-font font-poppins">
                                                            {thread?.created_by}
                                                        </div>
                                                        <div className="x-small-text font-poppins text-secondarybg mt-1">
                                                            {formateDateAndTime(thread?.created_at)}
                                                        </div>
                                                        <div className="table-title font-poppins mt-3">
                                                            {thread?.title}
                                                        </div>
                                                        <div className=" break-words  buttons-font-lighter mt-2">
                                                            <TruncateText
                                                                index={index}
                                                                text={thread?.description}
                                                                maxLength={300}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {showImage && (
                                                    <ZoomImage
                                                        src={showImage}
                                                        alt="snow"
                                                        onClose={() => setShowImage(null)}
                                                    />
                                                )}

                                                {thread?.attachments?.length > 0 && (
                                                    <div className="ann-btns  mt-2 flex gap-2 flex-wrap ml-[60px]">
                                                        {thread?.attachments?.map((file, index) => (
                                                            <div key={'attachments_ud' + index}>
                                                                <FileCardPreview
                                                                    url={file?.url}
                                                                    key={index}
                                                                    // showImage={true}
                                                                    name={file?.name}
                                                                    // size={file?.size}
                                                                    hideRemove
                                                                    file_cx="!w-[180px] h-[180px] "
                                                                    sx="w-7 h-7"
                                                                    extension={
                                                                        file?.name?.includes('.')
                                                                            ? file?.name
                                                                                  ?.split('.')
                                                                                  ?.pop()
                                                                                  ?.toLowerCase()
                                                                            : file?.name.split('/').pop().toLowerCase()
                                                                    }
                                                                    imgPrperties={{
                                                                        url: file?.url,
                                                                        type: file?.file_type,
                                                                        sx: ''
                                                                    }}
                                                                    onClick={() => {
                                                                        if (
                                                                            [
                                                                                'image/png',
                                                                                'image/jpg',
                                                                                'image/jpeg'
                                                                            ].includes(file?.file_type)
                                                                        ) {
                                                                            setShowImage(file?.url);
                                                                        } else if (
                                                                            file?.file_type === 'application/pdf'
                                                                        ) {
                                                                            setPdfUrl(file?.url);
                                                                            setOpen(true);
                                                                        } else {
                                                                            handleDownload(file);
                                                                        }
                                                                    }}
                                                                />

                                                                {/* <div>
                                                                    <Badge
                                                                        onCancel={() =>
                                                                            handleDelFile(
                                                                                thread?.thread_id,
                                                                                att?.thread_file_id,
                                                                                thread?.created_by_id
                                                                            )
                                                                        }
                                                                        leftIcon={
                                                                            <img
                                                                                src={
                                                                                    att.file_type === 'image/jpeg' ||
                                                                                    att.file_type === 'image/png' ||
                                                                                    att.file_type === 'image/heic' ||
                                                                                    att.file_type === 'image/heif'
                                                                                        ? att.url
                                                                                        : pdfIcon
                                                                                }
                                                                                alt="alt"
                                                                                className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        att.file_type ===
                                                                                            'image/jpeg' ||
                                                                                        att.file_type === 'image/png' ||
                                                                                        att.file_type ===
                                                                                            'image/heic' ||
                                                                                        att.file_type === 'image/heif'
                                                                                    ) {
                                                                                        setShowImage(att.url);
                                                                                    } else {
                                                                                        if (
                                                                                            att?.url
                                                                                                ?.toLowerCase()
                                                                                                ?.endsWith('.pdf')
                                                                                        ) {
                                                                                            setPdfUrl(att?.url);
                                                                                            setOpen(true);
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        text={att.name.slice(0, 7)}
                                                                        sx="text-white bg-third rounded-md !px-3 !text-md"
                                                                    />
                                                                </div> */}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                                <hr className="mt-3" />

                                                <div className="my-thread-comment-btns text-md detail-comment-btn flex items-center justify-between p-2 mt-3">
                                                    <div>
                                                        <button
                                                            onClick={() => toggleComments(thread?.thread_id, index)}
                                                            className="flex bg-fifthbg  buttons-font-lighter font-poppins rounded-xl  gap-1 items-center px-2 py-1"
                                                        >
                                                            {' '}
                                                            <span>
                                                                <CommentIcon />
                                                            </span>{' '}
                                                            <span>Comments</span>{' '}
                                                        </button>
                                                    </div>
                                                    <div className="pr-5">
                                                        {showComments ? (
                                                            <div>
                                                                <div
                                                                    onClick={() =>
                                                                        toggleComments(thread?.thread_id, index)
                                                                    }
                                                                    className="cursor-pointer"
                                                                >
                                                                    {' '}
                                                                    <svg
                                                                        width="13"
                                                                        height="8"
                                                                        viewBox="0 0 20 11"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M0.418419 0.402728C0.976311 -0.134243 1.88083 -0.134243 2.43872 0.402728L10 7.68046L17.5613 0.402728C18.1192 -0.134243 19.0237 -0.134243 19.5816 0.402728C20.1395 0.939699 20.1395 1.8103 19.5816 2.34727L11.0102 10.5973C10.4523 11.1342 9.54774 11.1342 8.98985 10.5973L0.418419 2.34727C-0.139473 1.8103 -0.139473 0.939699 0.418419 0.402728Z"
                                                                            fill="#979797"
                                                                        />
                                                                    </svg>{' '}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                onClick={() => toggleComments(thread?.thread_id, index)}
                                                                className="cursor-pointer"
                                                            >
                                                                {' '}
                                                                <svg
                                                                    width="6"
                                                                    height="13"
                                                                    viewBox="0 0 11 20"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M0.402728 19.5816C-0.134243 19.0237 -0.134243 18.1192 0.402728 17.5613L7.68046 10L0.402727 2.43872C-0.134244 1.88083 -0.134244 0.976311 0.402727 0.418419C0.939698 -0.139475 1.8103 -0.139475 2.34727 0.418419L10.5973 8.98985C11.1342 9.54774 11.1342 10.4523 10.5973 11.0102L2.34727 19.5816C1.8103 20.1395 0.939699 20.1395 0.402728 19.5816Z"
                                                                        fill="#979797"
                                                                    />
                                                                </svg>{' '}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {comments && commentIndex === index && showComments ? (
                                                    <div>
                                                        <motion.div
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            transition={{ type: 'tween', duration: 0.5 }}
                                                            className="ml-[60px]"
                                                        >
                                                            {comments?.length > 0 ? (
                                                                <div>
                                                                    {' '}
                                                                    <hr className="mt-2" />
                                                                    {comments?.map((comment, index) => (
                                                                        <div key={'comments_' + index}>
                                                                            <div
                                                                                className="thread-comment p-3"
                                                                                key={generateId()}
                                                                            >
                                                                                <div className="flex gap-2">
                                                                                    <div>
                                                                                        <DefaultProfileViewer
                                                                                            image={
                                                                                                comment?.created_by_image
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div>
                                                                                        <div className="buttons-font font-poppins">
                                                                                            {comment?.created_by}
                                                                                        </div>
                                                                                        <div className="x-small-text font-poppins mt-1  text-secondarybg">
                                                                                            {formateDateAndTime(
                                                                                                comment?.created_at
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className=" mt-2">
                                                                                    <div className="buttons-font-lighter font-poppins break-words mt-1 pl-12">
                                                                                        <TruncateText
                                                                                            index={index}
                                                                                            text={comment?.value}
                                                                                            maxLength={200}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <hr className="mt-3" />
                                                                        </div>
                                                                    ))}{' '}
                                                                </div>
                                                            ) : (
                                                                <div className="flex justify-center gap-3 items-center buttons-font font-poppins py-5">
                                                                    <BgPlusIcon /> <span>Add your comment</span>
                                                                </div>
                                                            )}
                                                        </motion.div>

                                                        <hr className="mt-3 " />
                                                        <div className="mt-2 flex gap-3 items-center w-[50%] ml-[60px] ">
                                                            <div className="default-forms w-[90%]">
                                                                {' '}
                                                                <input
                                                                    type="text"
                                                                    key={index}
                                                                    value={
                                                                        threadId === thread?.thread_id ? comment : ''
                                                                    }
                                                                    placeholder="Comment"
                                                                    onChange={(e) =>
                                                                        handleCommentChange(e, thread.thread_id)
                                                                    }
                                                                    maxLength={200}
                                                                />
                                                            </div>
                                                            <div>
                                                                <button
                                                                    onClick={() =>
                                                                        handleComment(thread?.thread_id, index)
                                                                    }
                                                                    className={`ann-btn px-2 py-2 mt-1 rounded-xl `}
                                                                    disabled={sideLoader ? true : false}
                                                                >
                                                                    Comment
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThreadListPage;
