/* eslint-disable no-unused-vars */
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { Button } from 'components/atoms/buttons/Button';
import { AccountNumberInput } from 'components/atoms/inputs/AccountNumberInput';
import { BsbInput } from 'components/atoms/inputs/BsbInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';
import { InputField } from 'components/atoms/inputs/InputField';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import { CheckboxChip } from 'components/molecules/CheckboxChip';
import dayjs from 'dayjs';
import { PickAddress } from 'global-components/PickAddress';
import isValidABN from 'is-valid-abn';
import isValidACN from 'is-valid-acn';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toastHandler } from 'responseHanlder';
import {
    ALL_ClIENT_TYPES,
    BUSINESS_ID,
    defaultDesignationOpt_Ind,
    getUniqueDesignations,
    TOAST_TYPE_ERROR
} from 'utills/globalVars';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { NewPlusIcon } from 'utills/svgs/NewPlusIcon';
import { generateId } from 'utills/uid';
import { IndividualAddSubForm } from './sub-forms/IndividualAddSubForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import ComboboxComponent from 'components/atoms/Combobox';

import EmailInput from 'components/atoms/Input/EmailInput';
import { validateEmailValue, validateTFN } from 'utills/dataValidation';

import { getformsfieldsOptionsThunk } from 'store/form';
import SearchableComboboxSSR from 'components/molecules/comboboxes/SearchableComboboxSSR';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { validateAbn, validateBSB, validatePhoneNumber } from 'utills/baseValidations';
import { checkTfnAbnAcnThunk } from 'store/client';
import { CheckboxInput } from 'components/atoms/inputs/CheckboxInput';
import { dateMinus12Years } from 'utills/moment';

export const Individualinvite = ({
    setOpen,
    displayName,
    onDisplayName,
    onDisplayLastname,

    clientRefNo,
    displayNameFirst,
    displayLastname,
    client,
    handleReload,
    ownerProfile,
    addIndividualSubForm,
    setAddIndividualSubForm,
    setCurrentStep,
    currentStep,
    profileImage
}) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);

    const [addClientFormObj, setAddClientFormObj] = useState(null);
    const [bsbError, setBsbError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [title, setTitle] = useState('');

    const [refNo, setRefNo] = useState('');

    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');

    const [gender, setGender] = useState('Male');
    const [dob, setDob] = useState('');
    const [calculatedAge, setCalculatedAge] = useState(0);
    const [age, setAge] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [postalAddress, setPostalAddress] = useState('');
    const [residentialAddress, setResidentialAddress] = useState('');
    const [abn, setAbn] = useState('');
    const [abnError, setAbnError] = useState(false);
    const [acn, setAcn] = useState('');
    const [acnError, setAcnError] = useState(false);

    const [tfn, setTfn] = useState('');
    const [tfnError, setTfnError] = useState(false);
    const [weblink, setWeblink] = useState('');
    const [workProffession, setWorkProfession] = useState('');
    const [businessOccupation, setBusinessOccupation] = useState('');

    const [sameAddress, setSameAddress] = useState(false);
    const [accountHolderName, setAccountHolderName] = useState('');
    const [bsb, setBsb] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountant, setAccountant] = useState('');
    const [registeredGst, setRegisteredGst] = useState('No');

    const [activityStatementDate, setActivityStatementDate] = useState('');
    const [textFormDate, setTextFormDate] = useState('');

    const [retriveFromAto, setRetriveFromAto] = useState('Monthly');
    const [refund, setRefund] = useState('');
    const [clientAtoStatus, setClientAtoStatus] = useState('Active');

    const [clientVerified, setClientVerified] = useState('No');
    const [verificationMethod, setVerificationMethod] = useState('Document Check');

    const [dateVerified, setDateVerified] = useState('');
    const [verifiedBy, setVerifiedBy] = useState('');
    const [verifiedNotes, setVerifiedNotes] = useState('');

    const default_options = getUniqueDesignations(defaultDesignationOpt_Ind) ?? [];

    const [ethicalClearanceStatus, setEthicalClearanceStatus] = useState(null);
    const [formerFirmName, setFormerFirmName] = useState(null);
    const [formerAccountentName, setFormerAccountentName] = useState(null);
    const [formerAccountentEmail, setFormerAccountentEmail] = useState(null);

    const ethicalClearanceStatusArray = [
        { label: 'Pending', value: 'clearance pending' },
        { label: 'Granted', value: 'clearance granted' },
        { label: 'Rejected', value: 'clearance rejected' }
    ];

    const defaultRelatedEntitiesObj = {
        fields: [
            {
                value: '',
                label: 'Entity',
                placeholder: 'Search by Name/Reference No.',
                id: generateId(),
                type: 'combobox',
                name: 'business_client_id',
                targetProperty: 'display_name'
            },
            {
                value: '',
                label: 'Display Name',
                placeholder: 'Enter Display Name',
                id: generateId(),
                name: 'display_name',
                type: 'text',
                disabled: false
            },

            {
                value: '',
                label: 'Designation',
                placeholder: 'Select Designation',
                id: generateId(),
                name: 'designation',
                options: default_options,
                type: 'dropdown'
            },

            {
                value: '',
                label: 'Number of Units',
                placeholder: 'Enter number of units',
                id: generateId(),
                name: 'no_of_unit',
                type: 'number',
                is_conditional: false,
                data_name: 'Unit Holder'
            },

            {
                value: '',
                label: 'Partnership percentage',
                placeholder: 'Enter Partnership percentage',
                id: generateId(),
                name: 'partnership_percentage',
                type: 'number',
                is_conditional: false,
                data_name: 'Partner'
            },

            {
                value: '',
                label: 'Number of Shares',
                placeholder: 'Enter Number of Shares',
                id: generateId(),
                name: 'percentage_of_shares',
                type: 'number',
                is_conditional: false,
                data_name: 'Share Holder'
            },
            {
                value: '',
                label: 'Start date',
                placeholder: 'Enter start date',
                name: 'start_date',
                id: generateId(),
                type: 'date'
            },

            {
                value: 'No',
                options: ['Yes', 'No'],
                label: 'Is this designation currently in effect?',
                placeholder: '',
                id: generateId(),
                name: 'currently_effective',
                type: 'switch'
            },
            {
                value: '',
                label: 'End date',
                placeholder: 'Enter end date',
                id: generateId(),
                name: 'end_date',
                type: 'date'
            }
        ],
        link_profile: false,
        bcr_id: null
    };
    const [entities, setEntities] = useState([defaultRelatedEntitiesObj]);

    useEffect(() => {
        if (client) {
            setFirstname(client?.first_name);
            setLastname(client?.last_name);
            setTitle(client?.title);
            setGender(client?.gender ? client?.gender : 'Male');
            setDob(client?.date_of_birth);
            setCalculatedAge(client?.age);
            setEmail(client?.email);
            setPhoneNo(client?.phone_number);
            setWeblink(client?.website);
            setResidentialAddress(client?.residential_address);
            setPostalAddress(client?.postal_address);
            setSameAddress(client?.is_postal_same_as_residential);
            setAccountHolderName(client?.account_holdername);
            setBsb(client?.bsb_code);
            setAccountNumber(client?.bank_account_number);
            setTfn(client?.tfn);
            setAbn(client?.abn);
            setAccountant(client?.accountant && client?.accountant?.user_id);
            setRegisteredGst(client?.registered_for_gst ? 'Yes' : 'No');
            setActivityStatementDate(client?.activity_statement_due_date);
            setTextFormDate(client?.tax_form_due_date);
            setRetriveFromAto(client?.ato_retrieval_time_span);
            setClientAtoStatus(client?.ato_client_status);
            setClientVerified(client?.is_client_verified ? 'Yes' : 'No');
            setVerificationMethod(client?.client_verification_method);
            setDateVerified(client?.date_verified ? client?.date_verified : dayjs());
            setWorkProfession(client?.work_profession);
            setBusinessOccupation(client?.business_occupation);
            setRefund(client?.fee_from_refund ? 'Refund' : '');

            setVerifiedBy(client?.verified_by?.user_id);

            setVerifiedNotes(client?.notes);

            setEthicalClearanceStatus(client?.ethical_cleanance_status ?? null);
            setFormerFirmName(client?.former_accounting_firmname ?? null);
            setFormerAccountentName(client?.former_accounting_name ?? null);
            setFormerAccountentEmail(client?.former_accounting_email_address ?? null);

            if (client.related_references?.length > 0) {
                let relatedRefList = [];
                client.related_references.map((responseObj, resInd) => {
                    const newApptList = defaultRelatedEntitiesObj?.fields?.map((obj, ind) => {
                        return {
                            ...obj,
                            id: generateId() + ind + resInd,

                            value:
                                obj.type === 'switch'
                                    ? responseObj?.[obj.name] === 'Yes'
                                        ? 'Yes'
                                        : 'No'
                                    : responseObj?.[obj.name],
                            is_conditional: responseObj?.[obj.name] ? true : false,
                            disabled:
                                responseObj?.business_client_id &&
                                responseObj?.display_name &&
                                obj.name === 'display_name'
                                    ? true
                                    : false
                        };
                    });
                    relatedRefList.push({
                        fields: newApptList,
                        link_profile: responseObj.link_to_profile,
                        bcr_id: responseObj.bcr_id
                    });
                });

                setEntities(relatedRefList);
            }
        } else {
            setDateVerified(dayjs());
            setVerifiedBy(ownerProfile?.user_id);
        }
    }, [client]);

    useEffect(() => {
        dispatch(fetchRegisteredEmployeesThunk())
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    }, []);

    useEffect(() => {
        if (title) {
            if (title === 'Mr' || title === 'Master' || title === 'Dr') {
                setGender('Male');
            } else if (title === 'Mrs' || title === 'Miss' || title === 'Ms') {
                setGender('Female');
            }
        }
    }, [title]);
    const handleAbnValidation = (e) => {
        let { value } = e.target;

        value = value.replace(/[^0-9]/g, '');
        if (value.length > 11) {
            value = value.slice(0, 11);
        }

        if (value?.length < 1) {
            setAbnError(false);
            setAbn(value);
            return;
        }
        const abnNumber = value.replace(/\s/g, '');
        const updatedABN = abnNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3-$4');
        if (!isValidABN(updatedABN)) {
            setAbnError(true);
        } else {
            setAbnError(false);
        }
        setAbn(value.replace(/\s/g, ''));
    };

    const handleAcnValidation = (e) => {
        const { value } = e.target;

        if (!isValidACN(value)) {
            setAcnError(true);
        } else {
            setAcnError(false);
        }
        setAcn(value);
    };

    const handleTfnValidation = (e) => {
        let { value } = e.target;

        value = value.replace(/[^0-9]/g, '');

        if (value.length > 9) {
            value = value.slice(0, 9);
        }
        setTfn(value);

        if (validateTFN(value)) {
            setTfnError(false);
        } else {
            setTfnError(true);
        }
    };

    const onSelect = (place) => {
        setPostalAddress(place.formatted_address);
    };
    const onSelectR = (place) => {
        setResidentialAddress(place.formatted_address);
    };

    const handleCalculateAge = (value) => {
        if (value) {
            const newDate = moment(new Date(value)).format('DD-MM-YYYY');

            setDob(value);

            const birthDate = moment(new Date(value));
            const currentDate = moment();
            const age = currentDate.diff(birthDate, 'years');

            setCalculatedAge(age);
        } else {
            setCalculatedAge(0);
            setDob('');
        }
    };

    const _handlePhoneNo = (value) => {
        let isValid = false;

        const parsedPhoneNumber = parsePhoneNumberFromString(value);

        if (parsedPhoneNumber) {
            isValid = parsedPhoneNumber.isValid();
        } else {
            isValid = true;
        }

        return isValid;
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const checkDuplicateFromDB = async (name, value) => {
        if (name) {
            return await dispatch(
                checkTfnAbnAcnThunk({
                    business_id: business_id,
                    [name]: value
                })
            ).then((res) => {
                if (res?.payload && res?.payload?.found) {
                    toastHandler(`${name?.toUpperCase()} number is already exist`, TOAST_TYPE_ERROR);
                    return false;
                } else {
                    return true;
                }
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email) {
            if (!validateEmailValue(email)) {
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            }
        }

        if (formerAccountentEmail) {
            if (!validateEmailValue(formerAccountentEmail)) {
                return toastHandler(`invalid Former Accountant Email `, TOAST_TYPE_ERROR);
            }
        }

        if (phoneNo) {
            if (!validatePhoneNumber(phoneNo)) {
                return false;
            }
        }

        if (!client && !title) {
            if (!title) {
                return toastHandler('Title is required', TOAST_TYPE_ERROR);
            }
        }

        if ((!client && phoneNo.length < 7) || (client && client?.client_type === 'offline_client')) {
            if (phoneNo.length < 7) {
                return toastHandler('Phone number is required', TOAST_TYPE_ERROR);
            }
        }

        if (abn) {
            const validateAbnV = validateAbn(abn);
            if (!validateAbnV) {
                return toastHandler('Invalid ABN number', TOAST_TYPE_ERROR);
            }
            if (!client && validateAbnV && (await checkDuplicateFromDB('abn', abn)) === false) {
                return false;
            }
        }

        if (tfn) {
            const validateTfnV = validateTFN(tfn);
            if (!validateTfnV) {
                return toastHandler('Invalid TFN number', TOAST_TYPE_ERROR);
            }
            if (!client && validateTfnV && (await checkDuplicateFromDB('tfn', tfn)) === false) {
                return false;
            }
        }

        if (bsb) {
            if (!validateBSB(bsb)) {
                return toastHandler('Invalid BSB number', TOAST_TYPE_ERROR);
            }
        }

        if (clientVerified === 'Yes' && !verifiedBy) {
            return toastHandler('Client verified by is required', TOAST_TYPE_ERROR);
        }
        if (clientVerified === 'Yes' && !dateVerified) {
            return toastHandler('Client verification date is required', TOAST_TYPE_ERROR);
        }

        const formPayload = {
            client_category: 'Individual',
            client_ref_no: clientRefNo ?? '',
            display_name: `${displayName}`,
            first_name: firstName,
            last_name: lastName,
            title: title,
            gender: gender,

            date_of_birth: dob ? (typeof dob === 'string' ? dob : dayjs(dob).format('DD-MM-YYYY')) : '',
            age: parseInt(calculatedAge),
            email: email,
            phone_number: phoneNo,
            website: weblink,
            residential_address: residentialAddress,
            postal_address: sameAddress ? residentialAddress : postalAddress,
            is_postal_same_as_residential: sameAddress,
            account_holdername: accountHolderName,
            bsb_code: bsb,
            bank_account_number: accountNumber,
            tfn: tfn,
            abn: abn,
            acn: '',
            work_profession: workProffession ? workProffession : '',
            business_occupation: businessOccupation ? businessOccupation : '',
            accountant: accountant,
            registered_for_gst: registeredGst === 'Yes' ? true : false,

            activity_statement_due_date: activityStatementDate
                ? typeof activityStatementDate === 'string'
                    ? activityStatementDate
                    : dayjs(activityStatementDate).format('DD-MM-YYYY')
                : '',

            tax_form_due_date: textFormDate
                ? typeof textFormDate === 'string'
                    ? textFormDate
                    : dayjs(textFormDate).format('DD-MM-YYYY')
                : '',

            ato_retrieval_time_span: retriveFromAto,
            ato_client_status: clientAtoStatus,
            link_to_profile_for_spouse: '',
            fee_from_refund: refund ? true : false,
            is_client_verified: clientVerified === 'Yes' ? true : false,
            client_verification_method: verificationMethod,

            date_verified: dateVerified
                ? typeof dateVerified === 'string'
                    ? dateVerified
                    : dayjs(dateVerified).format('DD-MM-YYYY')
                : '',

            verified_by: clientVerified === 'Yes' ? verifiedBy : '',
            notes: verifiedNotes,
            taxable_income: '',
            spouse_reference: '',

            ethical_cleanance_status: ethicalClearanceStatus,
            former_accounting_firmname: formerFirmName,
            former_accounting_name: formerAccountentName,
            former_accounting_email_address: formerAccountentEmail,
            //
            spouse_first_name: '',
            spouse_last_name: '',
            related_references: [],
            partner_details: [],
            director_details: [],
            shareholder_details: [],
            public_officer_details: [],
            secretary_details: [],
            trust_structure: '',
            trustees: [],
            settlor_details: [],
            appointer_details: [],
            upholder_details: []
        };

        setAddClientFormObj(formPayload);
        setCurrentStep(1);

        setAddIndividualSubForm(true);
    };

    const handleSelectChange = (customerArray) => {
        const obj = customerArray[0];
        setVerifiedBy(obj?.user_id);
    };

    const handleFirstName = (value) => {
        setFirstname(value);
        onDisplayName(value + ' ' + lastName);
    };
    const handleLastName = (value) => {
        setLastname(value);
        onDisplayName(firstName + ' ' + value);
    };

    const fetchWagesOptions = async (payload) => {
        const payloadNew = {
            ...payload,
            type: 'salary-wages-occupation'
        };
        return await dispatch(getformsfieldsOptionsThunk(payloadNew));
    };

    const fetchBusinessOccupationOptions = async (payload) => {
        const payloadNew = {
            ...payload,
            type: 'business-industry-occupation'
        };
        return await dispatch(getformsfieldsOptionsThunk(payloadNew));
    };

    return (
        <div>
            {addIndividualSubForm ? (
                <IndividualAddSubForm
                    open={addIndividualSubForm}
                    setOpen={setAddIndividualSubForm}
                    setInvite={() => {}}
                    addClientFormObj={addClientFormObj}
                    setIndividualOpen={setOpen}
                    client={client}
                    handleReload={handleReload}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                    profileImage={profileImage}
                    entities={entities}
                    setEntities={setEntities}
                    default_options={default_options}
                />
            ) : (
                <div>
                    <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                        {' '}
                        <div className="border  rounded-xl mt-5 overflow-hidden">
                            {' '}
                            <div className="py-5 px-3 bg-dimThird table-title font-poppins font-semibold">
                                Primary Details
                            </div>
                            <div className="p-5">
                                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 default-forms">
                                    <div>
                                        <div>
                                            <label>Title</label>
                                        </div>

                                        <SimpleSelectMenu
                                            placeholder="Select"
                                            targetProperty="label"
                                            selectedValue={title}
                                            valuePropertyName="label"
                                            optionsData={
                                                [
                                                    { label: 'Mr' },
                                                    { label: 'Mrs' },
                                                    { label: 'Master' },
                                                    { label: 'Miss' },
                                                    { label: 'Ms' },
                                                    { label: 'Dr' }
                                                ] || []
                                            }
                                            sx="rounded-xl mt-2 py-3 ring-gray-400 !font-normal cursor-pointer"
                                            showChipOnly={false}
                                            onChangeValue={(obj) => setTitle(obj.label)}
                                            disabled={
                                                !client
                                                    ? false
                                                    : client?.client_type !== 'offline_client' &&
                                                      client?.client_type !== 'prospective_client' &&
                                                      client?.client_type !== 'active_client' &&
                                                      client?.client_type !== 'inactive_client' &&
                                                      client?.client_type !== 'added-from-xero'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="First Name"
                                            inputValue={firstName}
                                            maxLength={30}
                                            allowSpaces={true}
                                            onChange={(e) => {
                                                handleFirstName(e.target.value);
                                            }}
                                            name="First Name"
                                            placeholder="Enter First Name"
                                            required
                                            classes="mt-2"
                                            disabled={
                                                !client
                                                    ? false
                                                    : client?.client_type !== 'offline_client' &&
                                                      client?.client_type !== 'prospective_client' &&
                                                      client?.client_type !== 'active_client' &&
                                                      client?.client_type !== 'inactive_client' &&
                                                      client?.client_type !== 'added-from-xero'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>

                                    <div>
                                        <InputField
                                            label="Last Name"
                                            inputValue={lastName}
                                            maxLength={30}
                                            allowSpaces={true}
                                            onChange={(e) => {
                                                handleLastName(e.target.value);
                                            }}
                                            name="Last Name"
                                            placeholder="Enter Last Name"
                                            required
                                            classes="mt-2"
                                            disabled={
                                                !client
                                                    ? false
                                                    : client?.client_type !== 'offline_client' &&
                                                      client?.client_type !== 'prospective_client' &&
                                                      client?.client_type !== 'active_client' &&
                                                      client?.client_type !== 'inactive_client' &&
                                                      client?.client_type !== 'added-from-xero'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                    <div>
                                        <div>
                                            <label>Gender</label>
                                        </div>

                                        <div>
                                            {' '}
                                            <DefaultDoubleSwitch
                                                disabled={
                                                    !client
                                                        ? false
                                                        : client?.client_type !== 'offline_client' &&
                                                          client?.client_type !== 'prospective_client' &&
                                                          client?.client_type !== 'active_client' &&
                                                          client?.client_type !== 'inactive_client' &&
                                                          client?.client_type !== 'added-from-xero'
                                                        ? true
                                                        : false
                                                }
                                                items={[
                                                    { title: 'Male', selected: gender === 'Male' },
                                                    { title: 'Female', selected: gender === 'Female' }
                                                ]}
                                                onClick={(value) => {
                                                    if (
                                                        !client ||
                                                        client?.client_type === 'offline_client' ||
                                                        client?.client_type === 'active_client' ||
                                                        client?.client_type === 'prospective_client' ||
                                                        client?.client_type === 'inactive_client' ||
                                                        client?.client_type === 'added-from-xero'
                                                    ) {
                                                        setGender(value);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label>Date of Birth</label>
                                        </div>
                                        <DatePick
                                            iconSet={{
                                                right: CalendarSmallIco
                                            }}
                                            onDateChange={(date) => {
                                                handleCalculateAge(date);
                                            }}
                                            placeholder="DD-MM-YYYY"
                                            format="DD-MM-YYYY"
                                            value={dob ? dayjs(dob, 'DD-MM-YYYY') : ''}
                                            sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                            maxDate={dayjs(dateMinus12Years, 'DD-MM-YYYY')}
                                            isDisabled={
                                                !client
                                                    ? false
                                                    : client?.client_type !== 'offline_client' &&
                                                      client?.client_type !== 'prospective_client' &&
                                                      client?.client_type !== 'active_client' &&
                                                      client?.client_type !== 'inactive_client' &&
                                                      client?.client_type !== 'added-from-xero'
                                                    ? true
                                                    : false
                                            }
                                            isDob={true}
                                        />
                                    </div>

                                    <div>
                                        <div>
                                            <label>Age</label>
                                        </div>
                                        <div className="h-[3rem] mt-2 bg-[#E8E9EB] buttons-font-lighter font-poppins rounded-xl p-3">
                                            {calculatedAge}
                                        </div>
                                    </div>
                                </div>

                                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                    <div>
                                        <div>
                                            <label>Email</label>
                                        </div>
                                        <div className="mt-2 ">
                                            <EmailInput
                                                onChange={(value) => setEmail(value)}
                                                onError={(value) => setEmailError(value)}
                                                placeholder="Enter email"
                                                required
                                                value={email}
                                                showRandomEmail
                                                disabled={
                                                    !client
                                                        ? false
                                                        : client?.client_type !== 'offline_client' &&
                                                          client?.client_type !== 'added-from-xero'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <PhoneInputComponent
                                            value={phoneNo}
                                            title="Phone Number"
                                            placeholder="Enter Phone Number"
                                            titleSx=""
                                            sx="!rounded-xl"
                                            atChange={(phone) => {
                                                setPhoneNo(phone);
                                            }}
                                            required={true}
                                            isDisabled={
                                                !client
                                                    ? false
                                                    : client?.client_type !== 'offline_client' &&
                                                      client?.client_type !== 'added-from-xero'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <label>Website Link</label>
                                        </div>
                                        <div className="mt-2 ">
                                            <input
                                                type="url"
                                                className={`px-3 mt-2 ${
                                                    !client ||
                                                    client?.client_type === 'offline_client' ||
                                                    client?.client_type === 'prospective_client' ||
                                                    client?.client_type === 'active_client' ||
                                                    client?.client_type === 'inactive_client' ||
                                                    client?.client_type === 'added-from-xero'
                                                        ? ''
                                                        : 'bg-gray-200 !border-none !cursor-no-drop'
                                                }`}
                                                placeholder="Enter website link"
                                                name="link"
                                                value={weblink?.trim()}
                                                onChange={(e) => {
                                                    setWeblink(e.target.value);
                                                }}
                                                maxLength={200}
                                                disabled={
                                                    !client
                                                        ? false
                                                        : client?.client_type !== 'offline_client' &&
                                                          client?.client_type !== 'prospective_client' &&
                                                          client?.client_type !== 'active_client' &&
                                                          client?.client_type !== 'inactive_client' &&
                                                          client?.client_type !== 'added-from-xero'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                    <div>
                                        <PickAddress
                                            id="residential-autocomplete"
                                            address={residentialAddress}
                                            setAddress={setResidentialAddress}
                                            onSelect={onSelectR}
                                            label="Residential Address"
                                            placeholder="Enter Residential Address"
                                            name="Residential Address"
                                            disabled={
                                                !client
                                                    ? false
                                                    : client?.client_type !== 'offline_client' &&
                                                      client?.client_type !== 'prospective_client' &&
                                                      client?.client_type !== 'active_client' &&
                                                      client?.client_type !== 'inactive_client' &&
                                                      client?.client_type !== 'added-from-xero'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                    <div>
                                        <PickAddress
                                            id="postal-autocomplete"
                                            address={!sameAddress ? postalAddress : residentialAddress}
                                            setAddress={setPostalAddress}
                                            onSelect={onSelect}
                                            label="Postal Address"
                                            placeholder="Enter Postal Address"
                                            disabled={
                                                sameAddress
                                                    ? true
                                                    : !client
                                                    ? false
                                                    : client?.client_type !== 'offline_client' &&
                                                      client?.client_type !== 'active_client' &&
                                                      client?.client_type !== 'prospective_client' &&
                                                      client?.client_type !== 'inactive_client' &&
                                                      client?.client_type !== 'added-from-xero'
                                                    ? true
                                                    : false
                                            }
                                            name="Postal Address"
                                        />
                                    </div>
                                </div>

                                <div className="mt-4 flex gap-2 items-center buttons-font-lighter font-poppins">
                                    <div>
                                        <input
                                            type="checkbox"
                                            className="rounded-[3px]"
                                            onChange={(e) => setSameAddress(e.target.checked)}
                                            checked={sameAddress}
                                            disabled={
                                                !client
                                                    ? false
                                                    : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>{' '}
                                    <div>Postal Address same as Residential Address</div>
                                </div>
                            </div>
                        </div>
                        <div className="border   rounded-xl mt-5 ">
                            {' '}
                            <div className="bg-dimThird  rounded-tl-xl rounded-tr-xl ">
                                <div className="py-5 px-3 table-title font-poppins font-semibold">
                                    Business Information
                                </div>
                            </div>
                            <div className="p-5">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                    <div>
                                        <div>
                                            <label>TFN</label>
                                        </div>
                                        <div className="mt-2 ">
                                            <input
                                                type="text"
                                                placeholder="Enter TFN number"
                                                name="tfnNumber"
                                                value={tfn}
                                                onChange={handleTfnValidation}
                                                className={`px-3 mt-2 ${tfnError && tfn ? 'add-error-border' : ''} ${
                                                    !client || ALL_ClIENT_TYPES.includes(client?.client_type)
                                                        ? ''
                                                        : 'bg-gray-200 !border-none !cursor-no-drop'
                                                }`}
                                                disabled={
                                                    !client
                                                        ? false
                                                        : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {tfnError && (
                                                <div className="text-sm text-red-500">
                                                    {tfnError && tfn ? <span> Invalid TFN Number </span> : ''}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div>
                                        <label> Work Profession</label>

                                        <SearchableComboboxSSR
                                            fetchDataFunction={fetchWagesOptions}
                                            defaultSelected={workProffession ? workProffession : ''}
                                            removePreviousSelected
                                            allowSelectCustomValue
                                            placeholder="Select"
                                            inputsx="!font-normal !text-sm"
                                            sx="!rounded-xl  !font-normal !ring-gray-400 mt-2 !flex"
                                            showNameOnly
                                            onChange={(obj) => {
                                                setWorkProfession(obj[0]);
                                            }}
                                            limit={1}
                                        />
                                    </div>

                                    <div>
                                        <div>
                                            <div>
                                                <label>ABN</label>
                                            </div>
                                            <div className="mt-2 ">
                                                <input
                                                    type="text"
                                                    placeholder="Enter ABN number"
                                                    name="abnNumber"
                                                    value={abn}
                                                    className={`px-3 mt-2 ${
                                                        abnError && abn ? 'add-error-border' : ''
                                                    } ${
                                                        !client || ALL_ClIENT_TYPES.includes(client?.client_type)
                                                            ? ''
                                                            : 'bg-gray-200 !border-none !cursor-no-drop'
                                                    }`}
                                                    onChange={handleAbnValidation}
                                                    disabled={
                                                        !client
                                                            ? false
                                                            : !ALL_ClIENT_TYPES.includes(client?.client_type)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {abnError && (
                                                    <div className="text-sm text-red-500">
                                                        {abnError && abn ? <span> Invalid ABN Number </span> : ''}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label> Principle Business Occupation </label>

                                        <SearchableComboboxSSR
                                            fetchDataFunction={fetchBusinessOccupationOptions}
                                            defaultSelected={
                                                businessOccupation && businessOccupation?.length > 0
                                                    ? businessOccupation
                                                    : ''
                                            }
                                            removePreviousSelected
                                            allowSelectCustomValue
                                            placeholder="Select"
                                            inputsx="!font-normal !text-sm"
                                            sx="!rounded-xl  !font-normal !ring-gray-400 mt-2 !flex"
                                            showNameOnly
                                            onChange={(obj) => {
                                                console.info(obj, 'onChangeCalled');
                                                setBusinessOccupation(obj[0]);
                                            }}
                                            limit={1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border   rounded-xl mt-5">
                            {' '}
                            <div className="bg-dimThird  rounded-tl-xl rounded-tr-xl ">
                                <div className="py-5 px-3 table-title font-poppins font-semibold">Financial Detail</div>
                            </div>
                            <div className="p-5">
                                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                    <div>
                                        <div>
                                            <div>
                                                {' '}
                                                <div>
                                                    <label>Account Holder Name</label>
                                                </div>
                                                <InputField
                                                    placeholder={`Enter Account Holder Name`}
                                                    inputValue={accountHolderName}
                                                    maxLength={50}
                                                    classes={'mt-2'}
                                                    allowSpaces={true}
                                                    onChange={(e) => setAccountHolderName(e.target.value)}
                                                    disabled={
                                                        !client
                                                            ? false
                                                            : client?.client_type !== 'offline_client' &&
                                                              client?.client_type !== 'prospective_client' &&
                                                              client?.client_type !== 'active_client' &&
                                                              client?.client_type !== 'inactive_client' &&
                                                              client?.client_type !== 'added-from-xero'
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label>BSB Number</label>
                                        </div>
                                        <div className="mt-2">
                                            <BsbInput
                                                onChange={(value) => setBsb(value)}
                                                onError={(error) => setBsbError(error)}
                                                placeholder={'Enter BSB Number'}
                                                value={bsb}
                                                disabled={
                                                    !client
                                                        ? false
                                                        : client?.client_type !== 'offline_client' &&
                                                          client?.client_type !== 'prospective_client' &&
                                                          client?.client_type !== 'active_client' &&
                                                          client?.client_type !== 'inactive_client' &&
                                                          client?.client_type !== 'added-from-xero'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <label>Bank Account Number</label>
                                        </div>
                                        <div className="mt-2 ">
                                            <AccountNumberInput
                                                onChange={(value) => setAccountNumber(value)}
                                                placeholder={'Enter Bank Account Number'}
                                                value={accountNumber}
                                                disabled={
                                                    !client
                                                        ? false
                                                        : client?.client_type !== 'offline_client' &&
                                                          client?.client_type !== 'prospective_client' &&
                                                          client?.client_type !== 'active_client' &&
                                                          client?.client_type !== 'inactive_client' &&
                                                          client?.client_type !== 'added-from-xero'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border   rounded-xl mt-5">
                            <div className="bg-dimThird  rounded-tl-xl rounded-tr-xl">
                                <div className="py-5 px-3 table-title font-poppins font-semibold">Compliance</div>
                            </div>

                            <div className="p-5">
                                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 ">
                                    <div className="default-forms">
                                        <div>
                                            <label>Accountant</label>
                                        </div>

                                        <ComboboxComponent
                                            data={employees}
                                            placeholder={'Select Accountant'}
                                            defaultSelected={employees && accountant}
                                            onChange={(list) => {
                                                const obj = list[0];
                                                setAccountant(obj?.user_id);
                                            }}
                                            targetProperty="user_id"
                                            limit={1}
                                            showNameOnly
                                        />
                                    </div>

                                    <div>
                                        {' '}
                                        <div className=" buttons-font default-forms">
                                            <label>Fee From Refund</label>
                                        </div>
                                        <div className="mt-2">
                                            <CheckboxChip
                                                value="Refund"
                                                selectedValue={refund}
                                                onChange={(value) => setRefund(refund ? '' : value)}
                                                p="pr-24"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        {' '}
                                        <div className=" buttons-font default-forms">
                                            <label>Client ATO Status</label>
                                        </div>
                                        <div className="">
                                            <DefaultDoubleSwitch
                                                items={[
                                                    { title: 'Active', selected: clientAtoStatus === 'Active' },
                                                    { title: 'Inactive', selected: clientAtoStatus === 'Inactive' }
                                                ]}
                                                onClick={(value) => setClientAtoStatus(value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="default-forms">
                                        <div>
                                            <label>Prepare Tax Form</label>
                                        </div>

                                        <div className="flex gap-2 items-center">
                                            <div className="w-full  grid grid-cols-7 gap-3 buttons-font-lighter font-poppins">
                                                <div className="flex gap-3 items-center col-span-2">
                                                    {' '}
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            className="!rounded-[3px] !w-[18px] !h-[18px]"
                                                            checked={textFormDate ? true : false}
                                                        />
                                                    </div>{' '}
                                                    <div>Due Date</div>
                                                </div>
                                                <div className="col-span-5">
                                                    <DatePick
                                                        iconSet={{
                                                            right: CalendarSmallIco
                                                        }}
                                                        onDateChange={(date) => {
                                                            setTextFormDate(date);
                                                        }}
                                                        placeholder="DD-MM-YYYY"
                                                        value={textFormDate ? dayjs(textFormDate, 'DD-MM-YYYY') : ''}
                                                        sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="default-forms">
                                        <div>
                                            <label>Are you registered for GST?</label>
                                        </div>

                                        <div>
                                            <DefaultDoubleSwitch
                                                items={[
                                                    { title: 'Yes', selected: registeredGst === 'Yes' },
                                                    { title: 'No', selected: registeredGst === 'No' }
                                                ]}
                                                onClick={(value) => setRegisteredGst(value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {registeredGst === 'Yes' && (
                                    <div>
                                        {' '}
                                        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-5 mt-5 default-forms">
                                            <div>
                                                <div>
                                                    <label>Prepare Activity Statement Due Date</label>
                                                </div>

                                                <div className="flex gap-2 items-center">
                                                    <div className="mt-2  w-full  grid grid-cols-7 gap-3 buttons-font-lighter font-poppins">
                                                        <div className="flex gap-3 items-center col-span-2 ">
                                                            {' '}
                                                            <div>
                                                                <input
                                                                    type="checkbox"
                                                                    className="!rounded-[3px] !w-[18px] !h-[18px]"
                                                                />
                                                            </div>{' '}
                                                            <div>Due Date</div>
                                                        </div>
                                                        <div className="col-span-5">
                                                            <DatePick
                                                                iconSet={{
                                                                    right: CalendarSmallIco
                                                                }}
                                                                onDateChange={(date) => {
                                                                    setActivityStatementDate(date);
                                                                }}
                                                                placeholder="DD-MM-YYYY"
                                                                checked={activityStatementDate ? true : false}
                                                                value={
                                                                    activityStatementDate
                                                                        ? dayjs(activityStatementDate, 'DD-MM-YYYY')
                                                                        : ''
                                                                }
                                                                sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div></div>
                                        </div>
                                        <div className="flex gap-3 items-center flex-wrap">
                                            <div>
                                                {' '}
                                                <div className="mt-5 buttons-font">
                                                    <label>Automatically Retrieve from ATO</label>
                                                </div>
                                                <div className="flex gap-3 items-center flex-wrap mt-3">
                                                    <CheckboxChip
                                                        value="Monthly"
                                                        selectedValue={retriveFromAto}
                                                        onChange={(value) => setRetriveFromAto(value)}
                                                        p="pr-24"
                                                    />
                                                    <CheckboxChip
                                                        value="Quarterly"
                                                        selectedValue={retriveFromAto}
                                                        onChange={(value) => setRetriveFromAto(value)}
                                                        p="pr-24"
                                                    />

                                                    <CheckboxChip
                                                        value="Annually"
                                                        selectedValue={retriveFromAto}
                                                        onChange={(value) => setRetriveFromAto(value)}
                                                        p="pr-24"
                                                    />
                                                    <CheckboxChip
                                                        value="Opt-out"
                                                        selectedValue={retriveFromAto}
                                                        onChange={(value) => setRetriveFromAto(value)}
                                                        p="pr-24"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 ">
                                    <div>
                                        <div className="buttons-font default-forms">
                                            <label className="">Ethical Clearance Status</label>
                                        </div>
                                        <SimpleSelectMenu
                                            placeholder="Select Ethical Clearance Status"
                                            targetProperty="label"
                                            selectedValue={ethicalClearanceStatus}
                                            valuePropertyName="value"
                                            optionsData={ethicalClearanceStatusArray || []}
                                            sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                                            showChipOnly={false}
                                            onChangeValue={(obj) => setEthicalClearanceStatus(obj?.value)}
                                        />
                                    </div>
                                    <div className="default-forms">
                                        <InputField
                                            label="Former Accounting Firm Name"
                                            inputValue={formerFirmName}
                                            maxLength={100}
                                            allowSpaces
                                            onChange={(e) => {
                                                setFormerFirmName(e.target.value);
                                            }}
                                            name="former_accounting_firmname"
                                            placeholder="Enter Firm Name"
                                            classes="mt-2"
                                        />
                                    </div>
                                    <div className="default-forms">
                                        <InputField
                                            label="Former Accountant Name"
                                            inputValue={formerAccountentName}
                                            maxLength={100}
                                            allowSpaces
                                            onChange={(e) => {
                                                setFormerAccountentName(e.target.value);
                                            }}
                                            name="former_accounting_name"
                                            placeholder="Enter Account Name"
                                            classes="mt-2"
                                        />
                                    </div>
                                    <div className="default-forms">
                                        <div>
                                            <label>Former Account Email</label>
                                        </div>
                                        <div className="mt-2 ">
                                            <EmailInput
                                                onChange={(value) => setFormerAccountentEmail(value)}
                                                onError={(value) => {}}
                                                placeholder="Former Account Email"
                                                value={formerAccountentEmail}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border   rounded-xl mt-5">
                            <div className="bg-dimThird  rounded-tl-xl rounded-tr-xl">
                                <div className="py-5 px-3 table-title font-poppins font-semibold">
                                    Client Verification
                                </div>
                            </div>
                            <div className="p-5">
                                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
                                    <div>
                                        <div className="default-forms">
                                            <label>Verified</label>
                                        </div>

                                        <div>
                                            <DefaultDoubleSwitch
                                                items={[
                                                    { title: 'Yes', selected: clientVerified === 'Yes' },
                                                    { title: 'No', selected: clientVerified === 'No' }
                                                ]}
                                                onClick={(value) => setClientVerified(value)}
                                            />
                                        </div>
                                    </div>
                                    {clientVerified === 'Yes' && (
                                        <div className="md:col-span-2">
                                            <div className="default-forms">
                                                <label>Verification Method</label>
                                            </div>

                                            <div className="flex gap-3 items-center flex-wrap mt-2">
                                                <CheckboxChip
                                                    value="Document Check"
                                                    selectedValue={verificationMethod}
                                                    onChange={(value) => setVerificationMethod(value)}
                                                    classes={' !rounded-[3px] !w-[16px] !h-[16px]'}
                                                />
                                                <CheckboxChip
                                                    value="Verified in Person"
                                                    selectedValue={verificationMethod}
                                                    onChange={(value) => setVerificationMethod(value)}
                                                    classes={' !rounded-[3px] !w-[16px] !h-[16px]'}
                                                />

                                                <CheckboxChip
                                                    value="Verified Online"
                                                    selectedValue={verificationMethod}
                                                    onChange={(value) => setVerificationMethod(value)}
                                                    classes={' !rounded-[3px] !w-[16px] !h-[16px]'}
                                                />

                                                <CheckboxChip
                                                    value="Other"
                                                    selectedValue={verificationMethod}
                                                    onChange={(value) => setVerificationMethod(value)}
                                                    classes={' !rounded-[3px] !w-[16px] !h-[16px] '}
                                                    p="pr-24"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {clientVerified === 'Yes' && (
                                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 default-forms">
                                        <div>
                                            <div>
                                                <label>Date Verified</label>
                                            </div>
                                            <div className="mt-2">
                                                <DatePick
                                                    iconSet={{
                                                        right: CalendarSmallIco
                                                    }}
                                                    onDateChange={(date) => {
                                                        setDateVerified(date);
                                                    }}
                                                    placeholder="Select Date"
                                                    value={dateVerified ? dayjs(dateVerified, 'DD-MM-YYYY') : dayjs()}
                                                    sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>Verified By</label>
                                            </div>

                                            <ComboboxComponent
                                                data={employees}
                                                placeholder={'Select employee'}
                                                defaultSelected={
                                                    employees &&
                                                    (client ? client?.verified_by?.user_id : ownerProfile?.user_id)
                                                }
                                                onChange={(e) => handleSelectChange(e)}
                                                targetProperty="user_id"
                                                limit={1}
                                                showNameOnly
                                            />
                                        </div>

                                        <div>
                                            <div>
                                                <label>Notes</label>
                                            </div>

                                            <textarea
                                                onChange={(e) => setVerifiedNotes(e.target.value)}
                                                value={verifiedNotes}
                                                className="w-full mt-2 rounded-xl border border-secondarybg h-[150px] "
                                                placeholder="Notes"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-center items-center mb-10 mt-16">
                            <Button
                                title="Next"
                                classes="ann-btn buttons-font-lighter font-fira px-20 rounded-xl "
                                type="submit"
                            />
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};
