import moment from 'moment';
import 'moment-timezone';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);
dayjs.extend(duration);

export const convertDateFormatToLocal = (
    date,
    format = 'DD-MM-YYYYTHH:mm:ssZ',
    responseFormat = 'YYYY-MM-DDTHH:mm:ss.SSSS'
) => {
    const parsedDate = moment(date, format, true);
    if (!parsedDate.isValid()) {
        return 'Invalid date';
    }
    const formattedDate = parsedDate.format(responseFormat);
    return formattedDate;
};

export const DateFormatToLocal = ({
    date,
    format = 'DD/MM/YYYY hh:mm A',
    insertedFormat = null,
    addDays = null,
    showRecentDays = null
}) => {
    const userTimezone = moment.tz.guess();

    let localDate;
    if (insertedFormat) {
        localDate = moment.utc(date, insertedFormat).tz(userTimezone);
    } else {
        localDate = moment.utc(date).tz(userTimezone);
    }

    if (addDays) {
        localDate = localDate.add(addDays, 'days');
    }

    if (showRecentDays) {
        const today = moment().tz(userTimezone).startOf('day');
        const yesterday = moment().tz(userTimezone).subtract(1, 'day').startOf('day');

        if (localDate.isSame(today, 'day')) {
            return 'Today';
        } else if (localDate.isSame(yesterday, 'day')) {
            return 'Yesterday';
        }
    }

    const formattedDate = localDate.format(format);

    return formattedDate;
};

export const FormatDateWithRelativeTime = ({ date, format = 'D MMM YYYY, hh:mm A', insertedFormat = null }) => {
    const userTimezone = moment.tz.guess();

    let localDate;
    if (insertedFormat) {
        localDate = moment.utc(date, insertedFormat).tz(userTimezone);
    } else {
        localDate = moment.utc(date).tz(userTimezone);
    }

    const formattedDate = localDate.format(format);

    const relativeTime = localDate.fromNow();

    return `${formattedDate} (${relativeTime})`;
};
export const customDateFormat = (date, inputFormat, outPutFormat) => {
    if (date) {
        const dateValue = moment(date, inputFormat).format(outPutFormat);
        return dateValue;
    } else {
        return '';
    }
};

export const formatHumanReadableDate = (dateString) => {
    const date = moment(dateString);
    const today = moment();

    const minutesAgo = today.diff(date, 'minutes');
    if (minutesAgo < 1) {
        return 'Recently Added';
    } else if (minutesAgo < 60) {
        return `${minutesAgo} minutes ago`;
    }

    const hoursAgo = today.diff(date, 'hours');
    if (hoursAgo < 24) {
        return `${hoursAgo} hours ago`;
    }

    if (date.isSame(today, 'day')) {
        return 'today';
    }

    if (date.isSame(today.clone().subtract(1, 'day'), 'day')) {
        return 'yesterday';
    }

    return date.format('DD/MM/YYYY hh:mm') ?? '';
};

export const formatTimestamp = (timestamp) => {
    const date = moment(parseInt(timestamp));
    return date.format('MMMM, DD, YYYY');
};
export const dateFromating = (timestamp) => {
    return moment.utc(timestamp, 'DD-MM-YYYYTHH:mm:ss[Z]').format('DD-MM-YYYY');
};

export const formatTime = (timestamp) => {
    const date = moment(parseInt(timestamp));
    return date.format('hh:mm a');
};

export const formateDateAndTime = (timestamp, returnFormat = null) => {
    const date = moment(timestamp, 'DD-MM-YYYYTHH:mm:ss[Z]');
    if (!returnFormat) {
        return date.format('MMMM, DD, YYYY hh:mm a');
    } else {
        return date.format(returnFormat);
    }
};
export const formateSpecificDate = (timestamp) => {
    const date = moment(timestamp, 'YYYY-MM-DDTHH:mm:ss[Z]');
    return date.format('DD MMM, YYYY');
};
export const formateDateOnly = (timestamp, type = null) => {
    const date = moment(timestamp, type ? type : 'DD-MM-YYYYTHH:mm:ss[Z]');
    return date.format('DD MMM, YYYY');
};
export const currentDateAndTime = () => {
    const date = moment(moment(), 'DD-MM-YYYYTHH:mm:ss[Z]');
    return date.format('MMMM, DD, YYYY hh:mm:ss a');
};
export const formateDateTime = (timestamp) => {
    const date = moment(timestamp);
    return date.format('DD-MM-YYYY');
};

export const formateDate = (timestamp) => {
    const formattedDate = moment(timestamp, 'DD-MM-YYYY').format('DD MMM, YYYY');

    return formattedDate;
};

export const remainingDays = (targetDateStr) => {
    const targetDate = new Date(targetDateStr);
    const today = new Date();

    const timeDifference = targetDate - today;

    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    if (daysRemaining < 0) {
        return 'Overdue';
    } else if (daysRemaining <= 5) {
        return `Due in ${daysRemaining} days `;
    } else {
        const date = moment(targetDateStr);
        return date.format('MMM DD, YYYY');
    }
};
export function getDueStatusWithTime(start_date, start_time, end_date, end_time) {
    const startDateTime = moment(`${start_date} ${start_time}`, 'DD-MM-YYYY HH:mm:ss Z');
    const endDateTime = moment(`${end_date} ${end_time}`, 'DD-MM-YYYY HH:mm:ss Z');

    const localStartDateTime = startDateTime.local();
    const localEndDateTime = endDateTime.local();

    const today = moment();

    if (localEndDateTime.isBefore(today)) {
        return 'Overdue';
    } else if (localEndDateTime.diff(localStartDateTime, 'days') >= 5) {
        return localEndDateTime.format('DD-MM-YYYY');
    } else if (
        localEndDateTime.diff(localStartDateTime, 'days') >= 1 &&
        localEndDateTime.diff(localStartDateTime, 'days') < 5
    ) {
        return `Due in ${localEndDateTime.diff(localStartDateTime, 'days')} days`;
    } else if (
        localEndDateTime.diff(localStartDateTime, 'hours') > 0 &&
        localEndDateTime.diff(localStartDateTime, 'days') === 0
    ) {
        return `Due in ${localEndDateTime.diff(localStartDateTime, 'hours')} hours`;
    } else if (
        localEndDateTime.diff(localStartDateTime, 'minutes') > 0 &&
        localEndDateTime.diff(localStartDateTime, 'hours') === 0
    ) {
        return `Due in ${localEndDateTime.diff(localStartDateTime, 'minutes')} minutes`;
    } else {
        return 'Overdue';
    }
}
export function getDueCompletedStatus(end_date, end_time, completed_at) {
    const endDatetime = moment(
        moment(`${end_date} ${end_time}`, 'DD-MM-YYYY h:mm:ss A').format('YYYY-MM-DD HH:mm:ss'),
        'YYYY-MM-DD HH:mm:ss'
    );
    const completedDate = moment(moment(completed_at).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');

    if (completedDate.isBefore(endDatetime)) {
        return `Completed at: ${completedDate.format('YYYY-MM-DD HH:mm:ss')}`;
    } else {
        return 'Overdue';
    }
}
export function getDueStatus(start_date, end_date) {
    const today = moment();
    const endDate = moment(end_date, 'DD-MM-YYYY');
    const startDate = moment(start_date, 'DD-MM-YYYY');
    const daysDifference = endDate.diff(startDate, 'days');

    if (endDate.isBefore(today)) {
        return 'Overdue';
    } else if (endDate.isSame(startDate)) {
        return `Due in 1 day`;
    } else if (daysDifference < 5) {
        return `Due in ${daysDifference} days`;
    } else if (endDate.isAfter(today) && daysDifference >= 1) {
        return endDate.format('DD-MM-YYYY');
    } else {
        return 'Overdue';
    }
}

export function bitsToMegabytes(bits) {
    const megabytes = bits / 8 / 1024 / 1024;

    const roundedMegabytes = Math.round(megabytes * 100) / 100;

    return roundedMegabytes;
}

export function filterDataByDate(data, selectedDate) {
    if (!selectedDate) {
        return data;
    }
    const filterDate = moment(selectedDate, 'DD-MM-YYYY');
    const filteredData = data.filter((entry) => {
        const entryDate = moment(entry.created_at, 'DD-MM-YYYY');
        const isAfterOrOnSelectedDate = entryDate.isSameOrAfter(filterDate);
        return isAfterOrOnSelectedDate;
    });

    //

    return filteredData;
}

export function datePickerFormate(inputDateString) {
    const parsedDate = moment(inputDateString, 'DD-MM-YYYY');

    const formattedDateString = parsedDate.toString();

    return formattedDateString;
}

export function convertDateFormatToString(inputDate) {
    const dateObject = new Date(inputDate);

    if (isNaN(dateObject.getTime())) {
        return 'Invalid date';
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const month = months[dateObject.getMonth()];
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    const formattedHours = hours % 12 || 12;

    const result = `${month} ${day}, ${year} ${formattedHours}:${String(minutes).padStart(2, '0')} ${ampm}`;

    return result;
}

export const parseCreatedAt = (createdAtString) => {
    return moment(createdAtString, 'dddd, MMMM D, YYYY h:mm A');
};

export function convertDate(inputDate) {
    const parsedDate = moment(inputDate);

    const formattedDate = parsedDate.format('DD MMM, YYYY');

    return formattedDate;
}

export function calculateDays(start_date, end_date) {
    var start_date_components = start_date.split('-');
    var end_date_components = end_date.split('-');

    var start = new Date(start_date_components[2], start_date_components[1] - 1, start_date_components[0]);
    var end = new Date(end_date_components[2], end_date_components[1] - 1, end_date_components[0]);

    var time_difference = end.getTime() - start.getTime();

    var days_difference = Math.floor(time_difference / (1000 * 60 * 60 * 24));

    return days_difference;
}

export function calculateDaysFromToday(end_date) {
    var end_date_components = end_date.split('-');

    var today = new Date();
    var end = new Date(end_date_components[2], end_date_components[1] - 1, end_date_components[0]);

    var time_difference = end.getTime() - today.getTime();

    var days_difference = Math.floor(time_difference / (1000 * 60 * 60 * 24));

    return days_difference;
}

export function formatDefaultDate(inputDate) {
    return moment(inputDate, 'DD-MM-YYYYTHH:mm:ss[Z]').format('DD-MM-YYYY');
}

export const handleCalculateAge = (start, end) => {
    if (start && end) {
        const format = 'DD-MM-YYYY';

        const isStartFormatted = dayjs(start, format, true).isValid();
        const isEndFormatted = dayjs(end, format, true).isValid();

        const parsedStart = isStartFormatted ? dayjs(start, format) : dayjs(start);
        const parsedEnd = isEndFormatted ? dayjs(end, format) : dayjs(end);

        const ageInYears = parsedEnd.diff(parsedStart, 'year');
        const ageInMonths = parsedEnd.diff(parsedStart, 'month');

        if (ageInYears < 1) {
            return `${ageInMonths} months`;
        } else {
            return `${ageInYears} years`;
        }
    } else {
        return null;
    }
};

export const isFormattedDate = (date) => {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    return dateRegex.test(date);
};

export const formatDateIfNecessary = (date) => {
    return isFormattedDate(date) ? date : dayjs(date).format('DD-MM-YYYY');
};

export const convertDbDate = (dateString) => {
    return moment(dateString).format('DD-MM-YYYY');
};

export function isDatePastDue(dueDate) {
    const today = moment().startOf('day');
    const due = moment(dueDate, 'DD-MM-YYYY');

    return today.isAfter(due);
}

export const getIntervalDates = (start_date, repeat) => {
    const totalRepeats = {
        weekly: { value: 1, unit: 'weeks' },
        biweekly: { value: 2, unit: 'weeks' },
        monthly: { value: 1, unit: 'months' },
        quarterly: { value: 3, unit: 'months' },
        annually: { value: 12, unit: 'months' }
    };

    if (!totalRepeats[repeat]) {
        throw new Error('Invalid repeat interval');
    }

    const { value, unit } = totalRepeats[repeat];

    return moment(start_date, 'DD-MM-YYYY').add(value, unit).format('DD-MM-YYYY');
};

export const dateMinus12Years = moment().subtract(13, 'years').format('DD-MM-YYYY');
