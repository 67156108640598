import React from 'react';
import { Menu } from '@headlessui/react';
import { cn } from 'utills/tailwindUtil';

const DropdownMenuItem = ({ children, onClick }) => {
    return (
        <Menu.Item>
            {({ active }) => (
                <div className="group w-full">
                    <div
                        onClick={onClick}
                        className={cn(
                            'flex',
                            active
                                ? '!bg-third !text-gray-100 !group-hover:fill-white !group-hover:text-white !group-hover:stroke-white'
                                : '!text-gray-900 !flex ',
                            ' !flex !gap-1 !items-center  !px-4 py-2 text-sm !cursor-pointer '
                        )}
                    >
                        {children}
                    </div>
                </div>
            )}
        </Menu.Item>
    );
};

export default DropdownMenuItem;
