import { DefaultDialog } from 'components/molecules/DefaultDialog';
import React, { useState } from 'react';
import { PreviewEmps } from './PreviewEmps';
import { FinalizeData } from './FinalizeData';
import { SelectEmp } from './SelectEmp';
import { SideTimes } from 'utills/svgs/SideTimes';

export const TransferData = ({ open, setOpen, employee, onConfirm }) => {
    const [condition, setCondition] = useState(1);
    const [selectedTarget, setSelectedTarget] = useState(null);
    const [targetTasks, setTargetTasks] = useState(null);
    const [targetJobs, setTargetJobs] = useState(null);
    const [targetClients, setTargetClients] = useState(null);

    return (
        <DefaultDialog
            open={open}
            setOpen={setOpen}
            width={` ${condition === 2 ? '!max-w-[900px]' : '!max-w-[500px]'}  !min-h-[500px] !rounded-[26px] !p-6`}
        >
            <div className="flex justify-end items-center">
                <span className="cursor-pointer" onClick={() => setOpen(!open)}>
                    {' '}
                    <SideTimes />{' '}
                </span>
            </div>
            {condition === 1 ? (
                <SelectEmp
                    setCondition={setCondition}
                    emp={employee}
                    selectedTarget={selectedTarget}
                    setSelectedTarget={setSelectedTarget}
                    setTargetTasks={setTargetTasks}
                    setTargetJobs={setTargetJobs}
                    targetJobs={targetJobs}
                    targetTasks={targetTasks}
                    setTargetClients={setTargetClients}
                />
            ) : condition === 2 ? (
                <PreviewEmps
                    setCondition={setCondition}
                    selectedTarget={selectedTarget}
                    emp={employee}
                    targetJobs={targetJobs}
                    targetTasks={targetTasks}
                    targetClients={targetClients}
                />
            ) : condition === 3 ? (
                <FinalizeData
                    setCondition={setCondition}
                    selectedTarget={selectedTarget}
                    onConfirm={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                />
            ) : (
                ''
            )}
        </DefaultDialog>
    );
};
