/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { DatePicker } from 'antd';
import DatePicker from 'antd/es/date-picker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { cn } from 'utills/tailwindUtil';

dayjs.extend(customParseFormat);

export const DatePick = React.forwardRef(
    (
        {
            iconSet,
            onDateChange,
            placeholder,
            value,
            bg,
            sx,
            isDisabled,
            isNextDateRequired,
            width,
            border,
            isDob,
            minDate,
            maxDate,
            defaultValue,
            onOpenChange,
            autoOpen,
            ...otherProps
        },
        _ref
    ) => {
        const [selectedDate, setSelectedDate] = useState(null);
        const [inputValue, setInputValue] = useState('');
        const [isPickerOpen, setIsPickerOpen] = useState(false);

        useEffect(() => {
            const parsedDate = dayjs(value, 'DD-MM-YYYY');
            if (parsedDate.isValid()) {
                setSelectedDate(parsedDate);
                setInputValue(parsedDate.format('DD-MM-YYYY'));
            } else {
                setSelectedDate(null);
                setInputValue('');
            }
        }, [value]);

        useEffect(() => {
            if (defaultValue) {
                const parsedDate = dayjs(defaultValue, 'DD-MM-YYYY');
                if (parsedDate.isValid()) {
                    setSelectedDate(parsedDate);
                    setInputValue(parsedDate.format('DD-MM-YYYY'));
                } else {
                    setSelectedDate(null);
                    setInputValue('');
                }
            }
        }, [defaultValue]);

        useEffect(() => {
            if (autoOpen) {
                setIsPickerOpen(true);
            }
        }, [autoOpen]);

        const handleDateChange = (date, dateString) => {
            setSelectedDate(date);
            setInputValue(dateString);

            if (onDateChange) {
                onDateChange(date);
            }
        };

        const handleInputChange = (e) => {
            const value = e.target.value;
            setInputValue(value);

            if (dayjs(value, 'DD-MM-YYYY', true).isValid()) {
                const date = dayjs(value, 'DD-MM-YYYY');
                setSelectedDate(date);

                if (onDateChange) {
                    onDateChange(date);
                }
            } else {
                setSelectedDate(null);

                if (onDateChange) {
                    onDateChange(null);
                }
            }
        };

        const handleOpenChange = (open) => {
            setIsPickerOpen(open);
            if (onOpenChange) {
                onOpenChange(open);
            }
        };

        return (
            <div>
                <DatePicker
                    value={selectedDate}
                    format="DD/MM/YYYY"
                    suffixIcon={<iconSet.right />}
                    style={{
                        height: '3rem',
                        border: `1px solid ${border ? border : '#979797'}`,
                        fontFamily: 'poppins',
                        fontWeight: 'lighter',
                        borderRadius: '0.6rem',
                        backgroundColor: bg && bg,
                        width: width && width
                    }}
                    placeholder={placeholder || 'DD/MM/YYYY'}
                    onChange={handleDateChange}
                    onInput={(e) => handleInputChange(e)}
                    minDate={minDate && dayjs(minDate, 'YYYY-MM-DD')}
                    maxDate={maxDate && dayjs(maxDate, 'YYYY-MM-DD')}
                    disabled={isDisabled}
                    className={cn(
                        'custom-datepicker focus:!ring-0 focus-within:!shadow-none',
                        sx || '',
                        isDisabled ? '!bg-gray-200 !ring-0 !border-0' : ''
                    )}
                    open={isPickerOpen}
                    onOpenChange={handleOpenChange}
                    {...otherProps}
                />
            </div>
        );
    }
);
