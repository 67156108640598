/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { SearchIcon } from 'utills/svgs/SearchIcon';
import './css/molecules.css';

export const SearchInput = ({
    atChange,
    _value,
    placeholder,
    width,
    _inputBorder,
    name,
    classes,
    zIndex,
    defaultValue,
    sx = ''
}) => {
    const [inputValue, setInputValue] = useState(defaultValue ? defaultValue : '');
    const [text, setText] = useState('');

    useEffect(() => {
        const handleTypingStopped = () => {
            text &&
                atChange({
                    target: {
                        name: name ?? 'search_term',
                        value: text?.target?.value?.trim()
                    }
                });
        };

        const timeoutId = setTimeout(handleTypingStopped, 500);

        return () => clearTimeout(timeoutId);
    }, [inputValue]);

    useEffect(() => {
        if (defaultValue) {
            setText((e) => {
                if (e?.target?.value?.trim() !== defaultValue) {
                    return {
                        target: {
                            name: name ?? 'search_term',
                            value: defaultValue
                        }
                    };
                }
                return e;
            });
        }
    }, [defaultValue]);

    const handleChange = (e) => {
        setText(e);
        setInputValue(e.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.target.value !== '') {
            atChange({
                target: {
                    name: name ?? 'search_term',
                    value: event?.target?.value?.trim()
                }
            });
        }
    };

    const handlePaste = (e) => {
        const pastedValue = e.clipboardData.getData('text').trim();
        atChange({
            target: {
                name: name ?? 'search_term',
                value: pastedValue?.trim()
            }
        });
    };

    return (
        <div
            className={`default-search-input !text-sm !rounded-unset ${sx} `}
            style={{ width: width || '100%', height: '2.8rem', zIndex: zIndex && zIndex }}
        >
            <div className="default-search-icon pl-1.5">
                <SearchIcon className="w-4 h-4 " />
            </div>
            <input
                onChange={(e) => {
                    handleChange(e);
                }}
                onKeyDown={handleKeyDown}
                type="search"
                onPaste={handlePaste}
                value={text?.target?.value || ''}
                name={name ?? 'search_term'}
                title={'Type keyword and press enter to search'}
                placeholder={placeholder}
                className={`!text-sm !font-[400] pl-9 pr-5 w-full ${classes} h-full prebuilt-form-text placeholder:text-[#979797]  focus:ring-2 focus:ring-inset focus:ring-slate-300 focus:border-0 ring-0 border-secondarybg   ring-inset ring-slate-300 !rounded-full `}
            />
        </div>
    );
};
