import React from 'react';

export const GifFIle = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#00A6BF" />
            <path
                d="M12.7891 22H8.45312L6.96875 20.6875V12.125L8.45312 10.7969H12.7891L14.2969 12.125V14.4297H11.5781V13.5078H9.79688V19.2656H11.5781V18.0625H10.6328V15.7109H14.2969V20.6875L12.7891 22ZM18.4219 10.7969V22H15.5781V10.7969H18.4219ZM22.5312 17.8047V22H19.7031V10.7969H27.0312V13.6484H22.5312V14.9609H25.5391V17.8047H22.5312Z"
                fill="white"
            />
        </svg>
    );
};
