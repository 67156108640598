import React from 'react';

const ExcelFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#6683DF" />
            <path
                d="M7.54688 22H4.71875V17.7578L6.27344 16.3984L4.73438 15.0391V10.7969H7.58594V13.8047L8.39844 14.5312L9.21094 13.8047V10.7969H12.0625V15.0391L10.5078 16.3984L12.0625 17.7578V22H9.22656V19.0078L8.39844 18.2734L7.54688 19.0078V22ZM16.1875 10.7969V19.1719H20.6719V22H13.3438V10.7969H16.1875ZM23.4531 22L21.9531 20.6719V18.625H24.7969V19.3281H26.4297V17.4688H23.4531L21.9531 16.1562V12.125L23.4531 10.7969H27.7734L29.2812 12.125V14.1797H26.4297V13.4688H24.7969V15.2812H27.7734L29.2812 16.6094V20.6719L27.7734 22H23.4531Z"
                fill="white"
            />
        </svg>
    );
};

export default ExcelFile;
