import { Button } from 'components/atoms/buttons/Button';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import React, { useEffect, useRef, useState } from 'react';
import { DimmedDeleteIcon2 } from 'utills/svgs/DimmedDeleteIcon2';
import { MailIcon } from 'utills/svgs/MailIcon';
import { useDispatch, useSelector } from 'react-redux';
import { deleteThreadThunk, setThreadReadStatusThunk } from 'store/JobManager';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ConfirmationModal from 'utills/confirmationModal';
import { setLoader } from 'store/global/globalReducer';
import {
    BUSINESS_ID,
    getProfileName,
    handleRemoveQueryParams,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from 'utills/globalVars';
import { cn } from 'utills/tailwindUtil';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { getThreadDetailsThunk, setReplyObject } from 'store/custom-reducers/RelayRoomLeftBarReducer';
import { toastHandler } from 'responseHanlder';
import CreateTaskWithJob from 'components/job-manager-components/create-job/CreateTaskWithJob';
import { CreateJob } from 'components/job-manager-components/create-job/CreateJob';
import useRoleGuard from 'Hooks/useRoleGuard';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';
import { XMarkIcon } from '@heroicons/react/24/outline';
import RelayRoomThreadHandler from 'components/job-manager-components/job-manager-detail-components/RelayRoom/RelayRoomThreadHandler';
import EmailManagerThreadComponent from './EmailManagerThreadComponent';
import ExpanderIcon from 'utills/svgs/ExpanderIcon';

const EmailManagerThreadDetails = ({ sideProfile, setSideProfile, setForceUpdate, setPreSelectedData }) => {
    const dispatch = useDispatch();
    const { hasPermissions } = useRoleGuard();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const contentRef = useRef(null);

    const { email_type, threadDetailsObj, _replyObject } = useSelector((state) => state.getRelayRoomList);

    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;

    const business_id = localStorage.getItem(BUSINESS_ID);

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [threadList, setThreadList] = useState([]);
    const [threadData, setThreadData] = useState(null);

    const [open, setOpen] = useState(false);
    const [createJob, setCreateJob] = useState(false);

    const jobEmailId = searchParams.get('job_email_id');

    useEffect(() => {
        if (threadDetailsObj && threadDetailsObj?.job_email_id === jobEmailId) {
            setThreadData(threadDetailsObj);

            const mainObject = handleDriveObject(threadDetailsObj);
            let email_res_array = threadDetailsObj?.email_responces
                ? threadDetailsObj?.email_responces?.map((responseObj) => handleDriveObject(responseObj))
                : [];
            setThreadList([mainObject, ...email_res_array]);
        } else {
            if (threadDetailsObj) {
                getData(true);
            }
        }
    }, [threadDetailsObj, jobEmailId]);

    const handleDriveObject = (obj) => {
        const drivedObj = {
            job_email_responces_id: obj?.job_email_responces_id || null,
            job_email_id: obj?.job_email_id,
            name: obj?.from_detail?.name || getProfileName(obj?.from_detail) || obj?.from,
            image: obj?.from_detail?.logo || obj?.from_detail?.image,
            email: obj?.from_detail?.business_email || obj?.from_detail?.email || obj?.from,
            title: obj?.subject,
            message: obj?.body,
            created_at: obj?.created_at,
            updated_at: obj?.updated_at,
            is_favourite: obj?.is_favourite,
            status: obj?.status,
            attachments: obj?.attachments,
            bcc: obj?.bcc?.filter((obj) => obj !== ''),
            to: obj?.to?.filter((obj) => obj !== ''),
            cc: obj?.cc?.filter((obj) => obj !== ''),
            is_read: obj?.is_read,
            category: obj?.category,
            ref_no: obj?.ref_no,
            email_type: obj?.email_type,
            email_draft: obj?.email_draft,
            to_details: obj?.to_details,
            from_detail: obj?.from_detail
        };

        return drivedObj;
    };

    const [isloading, setIsloading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setIsloading(true);
        try {
            const jobEmailId = searchParams.get('job_email_id');
            await dispatch(getThreadDetailsThunk({ urlParams: jobEmailId }))
                ?.then((res) => {
                    if (res.payload) {
                        if (res.payload?.job_email_id && res.payload?.is_read !== 'true') {
                            markThreadAsRead(res.payload?.job_email_id);
                        }
                    }
                })
                .finally(() => {
                    setIsloading(false);
                });
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const markThreadAsRead = (job_email_id) => {
        const payloadData = {
            business_id,
            job_email_id,
            is_read: true
        };
        dispatch(setThreadReadStatusThunk(payloadData));
    };

    const handleDeleteThread = () => {
        if (threadData?.job_email_id) {
            setIsConfirmationOpen(false);
            dispatch(setLoader(true));
            dispatch(deleteThreadThunk({ business_id, ids: [threadData?.job_email_id] }))
                .then((response) => {
                    if (response.payload) {
                        handleRemoveQueryParams(navigate, ['activeTab']);
                        setForceUpdate(true);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };
    const handleMarkReadUnReadThread = () => {
        if (threadData?.job_email_id) {
            const is_read_status = !(threadData?.is_read === 'true' ? true : false);
            const payloadData = {
                business_id,
                job_email_id: threadData?.job_email_id,
                is_read: is_read_status?.toString()
            };
            dispatch(setLoader(true));
            dispatch(setThreadReadStatusThunk(payloadData))
                .then((res) => {
                    if (res.payload) {
                        toastHandler(
                            `The email has been marked as ${is_read_status ? 'read' : 'unread'} successfully.`,
                            TOAST_TYPE_SUCCESS
                        );
                        setThreadData({ ...threadData, is_read: is_read_status?.toString() });
                        setForceUpdate(true);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    return (
        <>
            {isloading && (
                <div className="m-5">
                    <SkeletonTable
                        showExtraRows
                        minLength={2}
                        columns={2}
                        showLogo
                        logoIndex={0}
                        sxLogo={'w-10 h-10'}
                    />{' '}
                </div>
            )}
            {!isloading && (
                <div className="h-full flex flex-col gap-2 justify-start">
                    <div ref={contentRef}>
                        <div className="flex justify-between items-center px-3 lg:px-5 py-6 border-b border-[#97979766]/40% hide-on-print">
                            <div className="flex-1 flex items-center flex-wrap gap-4">
                                <Button
                                    classes={'flex bg-transparent !rounded-full hover:!shadow-none'}
                                    tooltip={'Close'}
                                    iconSet={{ leftIconHTML: <XMarkIcon className={'w-7 h-7 fill-third'} /> }}
                                    atClick={() => {
                                        setPreSelectedData(null);
                                        handleRemoveQueryParams(navigate, ['activeTab']);
                                    }}
                                    tooltipDirection={'top'}
                                />
                                <span
                                    onClick={() => {
                                        if (!hasPermissions('Job', ['write', 'admin'])) {
                                            return toastHandler(
                                                'You dont have permission to do this action.',
                                                TOAST_TYPE_ERROR
                                            );
                                        }
                                        if (!disabled) {
                                            setIsConfirmationOpen(true);
                                        }
                                    }}
                                >
                                    <DimmedDeleteIcon2
                                        className={` ${
                                            disabled && '!cursor-not-allowed opacity-50'
                                        } w-5 cursor-pointer !fill-[#656565]`}
                                    />
                                </span>
                                {email_type !== 'send' && (
                                    <span
                                        onClick={() => {
                                            if (!hasPermissions('Job', ['write', 'admin'])) {
                                                return toastHandler(
                                                    'You dont have permission to do this action.',
                                                    TOAST_TYPE_ERROR
                                                );
                                            }

                                            if (!disabled) {
                                                handleMarkReadUnReadThread();
                                            }
                                        }}
                                    >
                                        <MailIcon
                                            className={cn(
                                                'w-5 cursor-pointer',
                                                threadData?.is_read === 'false' ? '!stroke-third' : '',
                                                disabled && '!cursor-not-allowed opacity-50'
                                            )}
                                        />
                                    </span>
                                )}
                            </div>
                            <div>
                                <Button
                                    classes={cn(
                                        'flex bg-primary text-white !rounded-full hover:!shadow-none p-2 ',
                                        sideProfile && 'rotate-180'
                                    )}
                                    tooltip={'Expand/Collaps'}
                                    iconSet={{ leftIconHTML: <ExpanderIcon className={'w-5 h-5'} /> }}
                                    atClick={() => {
                                        setSideProfile(!sideProfile);
                                    }}
                                    tooltipDirection={'top'}
                                />
                            </div>
                        </div>
                        <div className="flex justify-between items-center px-3 py-6 border-b border-[#97979766]/40%">
                            <div className="flex-1">
                                <PrimaryParagraph sx={'font-medium text-lg'}>
                                    {threadData?.subject || ''}
                                </PrimaryParagraph>
                            </div>
                        </div>

                        <div className="grid w-full divide-y border-[#97979766]/40% overflow-auto">
                            {threadList?.map((thread, ind) => (
                                <div className="px-3 py-6" key={'thred_index' + ind}>
                                    <EmailManagerThreadComponent thread={thread} handleReload={() => getData()} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="px-3 ">
                        <RelayRoomThreadHandler
                            handleReload={() => {
                                getData();
                                dispatch(setReplyObject(null));
                            }}
                        />
                    </div>
                </div>
            )}
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={() => handleDeleteThread()}
            />

            {open && <CreateTaskWithJob open={open} setOpen={setOpen} actionType={'select'} jobRefData={threadData} />}
            {createJob && (
                <CreateJob
                    title={'Create Job'}
                    open={createJob}
                    setOpen={(value) => {
                        setCreateJob(value);
                    }}
                    onAction={(_newJob) => {}}
                />
            )}
        </>
    );
};

export default EmailManagerThreadDetails;
