import React from 'react';
import { useEffect, useState } from 'react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { cn } from 'utills/tailwindUtil';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { XMarkIcon } from '@heroicons/react/24/outline';
import TooltipChipWrap from '../tooltipChip/TooltipChipWrap';
import { generateRandomProfileLink } from 'utills/globalVars';
import { CompoboxInput } from 'components/molecules/CompoboxInput';

const ComboboxComponent = ({
    data = [],
    selectedValue,
    defaultSelected,
    selectedData = '',
    restrictLastRemove = false,
    onChange,
    targetProperty,
    displayPropertyName,
    limit,
    sx,
    placeholder,
    _valuePropertyName,
    showNameOnly,
    disabled,
    inputsx,
    defaultSelectedValues,
    removePreviousSelected,
    onRemove,
    showTaskDetails,
    hideSelectedValues,
    maxDisplay = 1,
    hideImage = false
}) => {
    const [query, setQuery] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);

    let filteredPeople = [];
    if (data) {
        filteredPeople =
            query === ''
                ? data.filter(
                      (person) => !selectedItems?.find((item) => item?.[targetProperty] === person?.[targetProperty])
                  )
                : data.filter((person) => {
                      if (displayPropertyName) {
                          return (
                              person[displayPropertyName]?.toLowerCase().includes(query.toLowerCase()) &&
                              !selectedItems.find((item) => item?.[targetProperty] === person?.[targetProperty])
                          );
                      }
                      return (
                          person?.first_name?.toLowerCase().includes(query.toLowerCase()) &&
                          !selectedItems.find((item) => item?.[targetProperty] === person?.[targetProperty])
                      );
                  });
    }

    useEffect(() => {
        if (selectedValue === '') {
            setSelectedItems([]);
        }
    }, [selectedValue]);
    useEffect(() => {
        if (selectedData !== '') {
            setSelectedItems(selectedData);
        }
    }, [selectedData]);

    useEffect(() => {
        if (defaultSelected) {
            handleDefaultSelect(defaultSelected);
        }
    }, [defaultSelected]);

    useEffect(() => {
        if (defaultSelectedValues) {
            handleDefaultSelectValues(defaultSelectedValues);
        }
    }, [defaultSelectedValues]);

    const handleDefaultSelect = (defaultSelected = '') => {
        if (defaultSelected !== '') {
            const selectedObject = data?.find((person) => person?.[targetProperty] === defaultSelected);
            const dummyState = [...(removePreviousSelected ? [] : selectedItems)];
            if (selectedObject) {
                dummyState.push(selectedObject);
            }

            filterUniqueTargetId(dummyState);
            setQuery('');
        }
    };
    const handleDefaultSelectValues = (defaultSelectedArray = []) => {
        if (defaultSelectedArray.length > 0) {
            let dummyState = [...selectedItems];

            defaultSelectedArray.forEach((obj) => {
                const selectedObject = data.find((person) => person?.[targetProperty] === obj?.[targetProperty]);
                dummyState.push(selectedObject);
            });

            filterUniqueTargetId(dummyState);
            setQuery('');
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace' && query === '' && selectedItems.length > 0 && event?.target?.value === '') {
            event.preventDefault(); // Prevent default backspace action
            // Remove last selected item if input is empty
            const updatedState = selectedItems.slice(0, selectedItems.length - 1);
            setSelectedItems(updatedState);
            onChange(updatedState);
        }
        // if (event.key === 'Enter' && filteredPeople.length > 0) {
        //     if (limit && selectedItems.length === limit) {
        //         return false;
        //     } else {
        //         const dummyState = [...selectedItems, filteredPeople[0]];
        //         if (!hideSelectedValues) {
        //             filterUniqueTargetId(dummyState);
        //         } else {
        //             onChange([filteredPeople[0]]);
        //         }
        //         setQuery('');
        //         event.preventDefault();
        //     }
        // }
    };

    const getName = (obj) => {
        return obj?.first_name && obj?.first_name !== '' ? obj?.first_name + ' ' + obj?.last_name : obj?.display_name;
    };

    const filterUniqueTargetId = (dataArray) => {
        const uniqueData = dataArray.reduce((acc, current) => {
            const x = acc.find((item) => item?.[targetProperty] === current?.[targetProperty]);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        setSelectedItems(uniqueData);
        onChange(uniqueData);
    };

    const addItemToList = (item) => {
        if (limit && selectedItems.length === limit) {
            return false;
        } else {
            const dummyState = [...selectedItems, item];

            if (!hideSelectedValues) {
                filterUniqueTargetId(dummyState);
            } else {
                onChange([item]);
            }
            setQuery(''); // Clear input after selection
            return false;
        }
    };

    return (
        <Combobox
            as="div"
            disabled={disabled}
            onChange={(item) => {
                addItemToList(item);
            }}
            style={{ width: '-webkit-fill-available' }}
        >
            <div className="relative default-forms">
                <div
                    className={cn(
                        '!rounded-xl border-0  shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500 px-2 mt-2 overflow-hidden',
                        sx || '',
                        selectedItems?.length > 0 && !showNameOnly ? '' : '',
                        disabled ? '!bg-gray-200 !border-none !ring-0' : ''
                    )}
                >
                    {selectedItems && selectedItems.length > 0 && (
                        <div className="flex flex-wrap gap-2 py-[0.5rem] min-h-[3rem] w-fit">
                            {showNameOnly && (
                                <div className="flex items-center flex-wrap gap-2">
                                    {selectedItems.slice(0, maxDisplay).map((item, index) => (
                                        <div
                                            key={'item_display' + index}
                                            className="flex w-fit items-center bg-third rounded-lg px-2 mr-1 flex-nowrap py-1"
                                        >
                                            <div className="flex items-center">
                                                <TooltipChipWrap
                                                    sx="!p-0"
                                                    title={
                                                        <div className="overflow-y-auto max-h-[300px] max-w-[320px]">
                                                            <div className="flex flex-col gap-2 justify-center">
                                                                <div className="flex  items-center rounded-lg px-2 py-2 w-full justify-between">
                                                                    <div className="flex items-center">
                                                                        {item?.image && (
                                                                            <DefaultProfileViewer
                                                                                width={30}
                                                                                height={30}
                                                                                image={
                                                                                    item?.image ||
                                                                                    generateRandomProfileLink(
                                                                                        getName(item)
                                                                                    )
                                                                                }
                                                                                atClick={() => {}}
                                                                                name={getName(item)}
                                                                            />
                                                                        )}
                                                                        <div>
                                                                            <span className="ml-1 text-white text-sm">
                                                                                {displayPropertyName
                                                                                    ? item?.[displayPropertyName]
                                                                                    : getName(item)}
                                                                            </span>
                                                                            <br />
                                                                            {item?.email && (
                                                                                <span className="ml-1 text-white text-sm">
                                                                                    {item?.email}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    {restrictLastRemove &&
                                                                    selectedItems?.length === 1 ? (
                                                                        ''
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="ml-1 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                                            onClick={() => {
                                                                                if (onRemove) {
                                                                                    // if (

                                                                                    // ) {
                                                                                    //     return toastHandler(
                                                                                    //         'Atleast one option is required',
                                                                                    //         TOAST_TYPE_ERROR
                                                                                    //     );
                                                                                    // }

                                                                                    const getDeletedObj =
                                                                                        selectedItems.find(
                                                                                            (prevItem) =>
                                                                                                prevItem?.[
                                                                                                    targetProperty
                                                                                                ] ===
                                                                                                item?.[targetProperty]
                                                                                        );
                                                                                    onRemove(getDeletedObj);
                                                                                }

                                                                                const dummyState = selectedItems.filter(
                                                                                    (prevItem) =>
                                                                                        prevItem?.[targetProperty] !==
                                                                                        item?.[targetProperty]
                                                                                );
                                                                                filterUniqueTargetId(dummyState);
                                                                            }}
                                                                        >
                                                                            <XMarkIcon
                                                                                className="h-3 w-3"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    variant="soft"
                                                    placement="bottom"
                                                >
                                                    <span className="flex items-center text-white text-sm">
                                                        <div className="flex flex-col gap-2 justify-center">
                                                            <div className="flex  items-center bg-third rounded-lg w-full justify-between">
                                                                <div className="flex items-center">
                                                                    <div>
                                                                        <span className="ml-1 text-white text-sm">
                                                                            {displayPropertyName
                                                                                ? item?.[displayPropertyName]
                                                                                : getName(item)}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                {restrictLastRemove && selectedItems?.length === 1 ? (
                                                                    ''
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        className="ml-2 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                                        onClick={() => {
                                                                            if (onRemove) {
                                                                                const getDeletedObj =
                                                                                    selectedItems.find(
                                                                                        (prevItem) =>
                                                                                            prevItem?.[
                                                                                                targetProperty
                                                                                            ] === item?.[targetProperty]
                                                                                    );
                                                                                onRemove(getDeletedObj);
                                                                            }

                                                                            const dummyState = selectedItems.filter(
                                                                                (prevItem) =>
                                                                                    prevItem?.[targetProperty] !==
                                                                                    item?.[targetProperty]
                                                                            );
                                                                            filterUniqueTargetId(dummyState);
                                                                        }}
                                                                    >
                                                                        <XMarkIcon
                                                                            className="h-3 w-3"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </span>
                                                </TooltipChipWrap>
                                            </div>
                                        </div>
                                    ))}
                                    {/* {selectedItems > limit && selectedItems.length > 1 && ( */}
                                    {selectedItems && selectedItems?.length > maxDisplay && (
                                        <div className="flex w-fit items-center bg-third rounded-lg px-2 mr-1 flex-nowrap py-1">
                                            <div className="flex items-center">
                                                <TooltipChipWrap
                                                    title={
                                                        <div className="overflow-y-auto bg-white p-2 rounded-xl shadow-md max-h-[300px] max-w-[320px]">
                                                            <div className="flex flex-col gap-2 justify-center">
                                                                {selectedItems?.slice(maxDisplay).map((item, index) => (
                                                                    <div
                                                                        key={'item_fileter' + item.id + index}
                                                                        className="flex  items-center bg-third rounded-lg px-2 py-2 w-full justify-between"
                                                                    >
                                                                        <div className="flex items-center">
                                                                            {item?.image && (
                                                                                <DefaultProfileViewer
                                                                                    width={20}
                                                                                    height={20}
                                                                                    image={
                                                                                        item?.image ||
                                                                                        generateRandomProfileLink(
                                                                                            getName(item)
                                                                                        )
                                                                                    }
                                                                                    atClick={() => {}}
                                                                                    name={getName(item)}
                                                                                />
                                                                            )}
                                                                            <div>
                                                                                <span className="ml-1 text-white text-sm">
                                                                                    {displayPropertyName
                                                                                        ? item?.[displayPropertyName]
                                                                                        : getName(item)}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        {restrictLastRemove &&
                                                                        selectedItems?.length === 1 ? (
                                                                            ''
                                                                        ) : (
                                                                            <button
                                                                                type="button"
                                                                                className="ml-1 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                                                onClick={() => {
                                                                                    if (onRemove) {
                                                                                        const getDeletedObj =
                                                                                            selectedItems.find(
                                                                                                (prevItem) =>
                                                                                                    prevItem?.[
                                                                                                        targetProperty
                                                                                                    ] ===
                                                                                                    item?.[
                                                                                                        targetProperty
                                                                                                    ]
                                                                                            );
                                                                                        onRemove(getDeletedObj);
                                                                                    }

                                                                                    const dummyState =
                                                                                        selectedItems.filter(
                                                                                            (prevItem) =>
                                                                                                prevItem?.[
                                                                                                    targetProperty
                                                                                                ] !==
                                                                                                item?.[targetProperty]
                                                                                        );
                                                                                    filterUniqueTargetId(dummyState);
                                                                                }}
                                                                            >
                                                                                <XMarkIcon
                                                                                    className="h-3 w-3"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    }
                                                    variant="soft"
                                                    placement="bottom"
                                                    sx={'!bg-gray-100 !p-0'}
                                                    hideChip
                                                    groupSx={'w-full'}
                                                    useWithoutPortal
                                                >
                                                    <span className="flex items-center text-white text-sm">
                                                        {`+ ${selectedItems.length - maxDisplay} Selected`}
                                                    </span>
                                                </TooltipChipWrap>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {!showNameOnly &&
                                selectedItems?.map((item) => (
                                    <div key={`${item?.display_name}_item_show`}>
                                        <div className="flex w-fit items-center bg-third rounded-lg px-2 mr-4 py-2">
                                            <DefaultProfileViewer
                                                image={item?.image || generateRandomProfileLink(getName(item))}
                                                atClick={() => {}}
                                                name={getName(item)}
                                            />
                                            <div>
                                                <span className="ml-2 text-white">{getName(item)}</span>
                                                <TooltipChipWrap
                                                    title={item?.email || 'No Email'}
                                                    variant="soft"
                                                    placement="bottom"
                                                    sx={'!bg-gray-100 !text-gray-600 border'}
                                                    hideChip
                                                    groupSx={'w-full'}
                                                    useWithoutPortal
                                                >
                                                    <div className="ml-2 text-sm text-white max-w-[150px] text-ellipsis overflow-hidden">
                                                        {item?.email && item?.email?.slice(-30)}
                                                    </div>
                                                </TooltipChipWrap>
                                            </div>
                                            {restrictLastRemove && selectedItems?.length === 1 ? (
                                                ''
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="ml-1 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                    onClick={() => {
                                                        if (onRemove) {
                                                            const getDeletedObj = selectedItems.find(
                                                                (prevItem) =>
                                                                    prevItem?.[targetProperty] ===
                                                                    item?.[targetProperty]
                                                            );
                                                            onRemove(getDeletedObj);
                                                        }

                                                        const dummyState = selectedItems?.filter(
                                                            (prevItem) =>
                                                                prevItem?.[targetProperty] !== item?.[targetProperty]
                                                        );
                                                        filterUniqueTargetId(dummyState);
                                                    }}
                                                >
                                                    <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}

                    {selectedItems.length !== limit && (
                        <div className="flex !w-auto !flex-1">
                            <CompoboxInput
                                className={cn(
                                    'w-auto bg-transparent !py-1.5 !pl-1 pr-12 !m-[0px] !rounded-xl placeholder-[#979797] placeholder:text-[0.9rem] placeholder:font-[400] !font-[400] !text-[0.9rem] !border-0 focus:border-0 !ring-0 !focus:ring-[0px]',
                                    selectedItems.length > 0 ? 'max-w-[150px]' : '',
                                    inputsx ? inputsx : 'text-gray-900 !text-sm'
                                )}
                                atChange={(event) => setQuery(event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder={(selectedItems.length === 0 && placeholder) || ''}
                                selectedItems={selectedItems}
                                focusOnValues
                                query={query}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon className="h-5 w-5 text-[#979797]" aria-hidden="true" />
                            </Combobox.Button>
                        </div>
                    )}
                </div>

                {filteredPeople.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-hidden hover:overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredPeople.map((person, ind) => (
                            <div key={'item_fileter1' + person.id + ind}>
                                <Combobox.Option
                                    value={person}
                                    className={({ active }) =>
                                        cn(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-third !text-white' : 'text-gray-900'
                                        )
                                    }
                                >
                                    {({ active, _selected }) => (
                                        <>
                                            <div className="flex items-center">
                                                {!hideImage && (
                                                    <DefaultProfileViewer
                                                        image={
                                                            person?.image || generateRandomProfileLink(getName(person))
                                                        }
                                                        name={getName(person)}
                                                    />
                                                )}
                                                <div className="flex flex-col flex-1">
                                                    <span className={cn('ml-3 truncate')}>
                                                        {displayPropertyName
                                                            ? person[displayPropertyName]
                                                            : getName(person)}
                                                    </span>

                                                    {showTaskDetails ? (
                                                        <>
                                                            <div
                                                                className={cn(
                                                                    'flex items-center justify-between text-[12px] mt-1 gap-4 mx-3 flex-wrap text-gray-500',
                                                                    active && '!text-white'
                                                                )}
                                                            >
                                                                <span className={cn('truncate')}>
                                                                    Total Pending Tasks
                                                                </span>
                                                                <span className={cn('truncate font-semibold')}>
                                                                    {person?.totalTask || 0}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className={cn(
                                                                    'flex items-center justify-between text-[12px] gap-4 mx-3 flex-wrap text-gray-500',
                                                                    active && '!text-white'
                                                                )}
                                                            >
                                                                <span className={cn('truncate')}>
                                                                    Today&apos;s Pending Tasks
                                                                </span>
                                                                <span className={cn('truncate font-semibold')}>
                                                                    {person?.todayTask || 0}
                                                                </span>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <span className={cn('ml-3 truncate')}>{person?.email}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Combobox.Option>
                            </div>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
};

export default ComboboxComponent;
