import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import {
    getClientNominationThunk,
    moveClientThunk,
    sendClientInviteThunk,
    viewBusinessCustomerInfoThunk
} from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { BUSINESS_ID, ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE, TOAST_TYPE_SUCCESS } from 'utills/globalVars';

import ProfileCards from './profile-cards/ProfileCards';
import ProfileJumbo from './profile-jumbo/ProfileJumbo';
import ProfileModel from './profile-jumbo/ProfileModel';
import { getAllCompletedTasksThunk } from 'store/workspace/workspaceTasks';
import TaskDetails from 'components/workplace-content/tasks-pages/task-details/TaskDetails';
import { AddClient } from '../subcomponents/AddClient';
import useRoleGuard from 'Hooks/useRoleGuard';
import VerifyClientProfileModal from './manual-client/VerifyClientProfileModal';
import { Loader } from 'components/common/Loader';
import { SidebarLayout } from 'layouts/SidebarLayout';
import InviteClientsComponent from '../subcomponents/InviteClientsComponent';

const AppUserProfilePage = ({ toggleForms, toggleRentalDeduction, toggleAbnDeduction, toggleTfnDeduction }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);

    const [businessCustomer, setBusinessCustomerInfo] = useState(null);
    const { data: loginData } = useSelector((state) => state.login);

    const { hasPermissions } = useRoleGuard();

    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);
    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const business_id = localStorage.getItem(BUSINESS_ID);

    const { isLoading } = useSelector((state) => state.global);

    const [tableView, _setTableView] = useState('all');
    const [activeTab, setActiveTab] = useState('all');

    const [allPage, setAllPage] = useState(1);
    const [filteredPage, setFilteredPage] = useState(1);

    const [hasMoreContent, setHasMoreContent] = useState({
        all: true,
        filtered: false
    });
    const [taskListData, setTaskListData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);

    const [filters, setFilters] = useState({
        search_term: '',
        employee_id: '',
        start_date: '',
        end_date: '',
        business_client_id: '',
        task_type_id: '',
        task_status_id: '',
        reference_number: '',
        sort_by: ''
    });

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .then((response) => {
                setBusinessCustomerInfo(response.payload);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const fetchClientNominationDetails = () => {
        const payload = {
            business_client_id: id,
            business_id
        };
        dispatch(getClientNominationThunk(payload));
    };

    useEffect(() => {
        fetchCustomer();
        fetchClientNominationDetails();

        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        }

        document?.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef, id]);

    function toggleModal() {
        if (hasPermissions('Clients', ['write', 'admin'])) {
            setShowModal(!showModal);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    }

    const fetchClientTaskList = async (type, hasReset = false) => {
        let page;
        let dataSetter;
        let pageSetter;
        let stateData;

        switch (type) {
            case 'all':
                page = allPage;
                dataSetter = setTaskListData;
                stateData = taskListData;
                pageSetter = 'all';
                break;
            case 'filtered':
                page = filteredPage;
                dataSetter = setFilteredData;
                stateData = filteredData;
                pageSetter = 'filtered';
                break;
            default:
                return;
        }
        const payload = {
            business_id,
            filter: {
                ...filters,
                is_completed: '',
                business_client_id: businessCustomer?.business_client_id,
                page: page === 0 ? 1 : page,
                sort_by: 'DESC',
                page_size: 2
            }
        };
        setHasMoreContent({ ...hasMoreContent, [pageSetter]: true });
        try {
            const response = await dispatch(getAllCompletedTasksThunk(payload));
            if (response.payload) {
                const cloneData = [...(!hasReset ? stateData || [] : []), ...response.payload.rows];
                dataSetter(cloneData);
                setHasMoreContent({
                    ...hasMoreContent,
                    [pageSetter]: cloneData.length === response.payload.count ? false : true
                });
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    useEffect(() => {
        if (businessCustomer && hasPermissions('Tasks', ['read', 'write', 'admin'])) {
            if (businessCustomer?.business_client_id && businessCustomer?.business_client_id !== 'null') {
                fetchClientTaskList('all');
            } else {
                setTaskListData([]);
                setFilteredData([]);
            }
        }
    }, [businessCustomer, allPage]);

    useEffect(() => {
        const hasValue = Object.values(filters).some((value) => value !== '');

        if (hasValue) {
            if (businessCustomer?.business_client_id && businessCustomer?.business_client_id !== 'null') {
                fetchClientTaskList('filtered');
            } else {
                setTaskListData([]);
                setFilteredData([]);
            }
        } else {
            setActiveTab(tableView);
        }
    }, [filteredPage, filters]);

    const handlePageChange = () => {
        if (activeTab === 'all') {
            setAllPage(allPage + 1);
        } else if (activeTab === 'filtered') {
            setFilteredPage(filteredPage + 1);
        }
    };

    useEffect(() => {
        if (!taskListData) {
            fetchClientTaskList(businessCustomer?.business_client_id);
        }
    }, [taskListData]);

    const updateFilters = (newFilters) => {
        setFilters(newFilters);
        setFilteredPage(1);
        setFilteredData([]);
        setActiveTab('filtered');
    };

    const handleResetTable = () => {
        setAllPage(1);
        setActiveTab('all');
        fetchClientTaskList('all', true);
        setTaskListData(null);
    };

    const toggleTaskDetails = () => {
        setShowTaskDetails(!showTaskDetails);
    };

    const toggleClientEdit = () => {
        setEdit(!edit);
    };

    const handleSendInvite = () => {
        const payload = {
            first_name: businessCustomer?.first_name,
            last_name: businessCustomer?.last_name,
            email: businessCustomer?.email,
            phone_number: businessCustomer?.phone_number
        };

        dispatch(setLoader(true));
        dispatch(sendClientInviteThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setShowModal(false);
                    toastHandler('Invite sent!', TOAST_TYPE_SUCCESS);
                    fetchCustomer();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleMoveClient = () => {
        const payload = {
            employee_id: loginData?.user_id,
            business_client_id: businessCustomer?.business_client_id
        };
        dispatch(setLoader(true));
        dispatch(moveClientThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setShowModal(false);
                    fetchCustomer();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const [inviteClient, setInviteClient] = useState(false);

    return (
        <SidebarLayout>
            <div className="app-user-profile-page relative">
                {edit && (
                    <AddClient
                        open={edit}
                        setOpen={setEdit}
                        client={businessCustomer}
                        handleReload={() => fetchCustomer()}
                    />
                )}

                {isLoading && <Loader />}

                <VerifyClientProfileModal user={businessCustomer} handleReload={() => fetchCustomer()} />

                {showTaskDetails && (
                    <TaskDetails
                        open={showTaskDetails}
                        setOpen={setShowTaskDetails}
                        toggleTaskDetails={toggleTaskDetails}
                        handleTasksUpdate={() => {
                            setTaskListData(null);
                        }}
                    />
                )}
                <div>
                    <ProfileJumbo
                        toggleModal={toggleModal}
                        user={businessCustomer}
                        path
                        toggleClientEdit={toggleClientEdit}
                    />
                    {showModal ? (
                        <div ref={modalRef}>
                            {' '}
                            <ProfileModel
                                fetchCustomer={fetchCustomer}
                                user={businessCustomer}
                                setShowModal={setShowModal}
                                toggleClientEdit={toggleClientEdit}
                                handleSendInvite={handleSendInvite}
                                handleMoveClient={handleMoveClient}
                                setInviteClient={setInviteClient}
                            />
                        </div>
                    ) : (
                        ''
                    )}

                    {inviteClient && (
                        <InviteClientsComponent
                            open={inviteClient}
                            setOpen={setInviteClient}
                            handleAddClientAction={() => {}}
                            setModal={() => {}}
                            preFilledData={businessCustomer}
                        />
                    )}
                    <div>
                        <ProfileCards
                            user={businessCustomer}
                            toggleForms={toggleForms}
                            toggleRentalDeduction={toggleRentalDeduction}
                            toggleAbnDeduction={toggleAbnDeduction}
                            toggleTfnDeduction={toggleTfnDeduction}
                            tasks={activeTab === 'all' ? taskListData : filteredData}
                            handlePageChange={handlePageChange}
                            setFilters={updateFilters}
                            filters={filters}
                            hasMoreContent={hasMoreContent?.all}
                            handleResetTable={handleResetTable}
                            toggleTaskDetails={toggleTaskDetails}
                        />
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};

export default AppUserProfilePage;
