import HighIcon from './svgs/HighIcon';
import HighestIcon from './svgs/HighestIcon';
import LowIcon from './svgs/LowIcon';
import LowestIcon from './svgs/LowestIcon';
import MediumIcon from './svgs/MediumIcon';

export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const REDIRECT_URI = 'redirect_url';
export const TEMP_TOKEN = 'temp_token';
export const BUSINESS_ID = 'business_id';
export const AUTHORIZATION = 'Authorization';
export const ERROR_TYPE_SUCCESS = 'success';
export const ERROR_TYPE_ERROR = 'error';
export const TOAST_TYPE_ERROR = 'error';
export const TOAST_TYPE_SUCCESS = 'success';
export const DATE_FORMAT = 'dd-MM-yyyy';

export const LEGAL_DOCUMENT_FOLDER = 'legal-document';
export const ACCOUNTANTS_FOLDER = 'accountant';

//pagination
export const COUNT_PER_PAGE = [{ label: 10 }, { label: 25 }, { label: 50 }, { label: 100 }, { label: 200 }];

export const DOB = 'date-of-birth';
export const BSB = 'bsb';
export const SALARY_WAGES_OCCUPATION = 'salary-wages-occupation';
export const BUSINESS_INDUSTRY_OCCUPATION = 'business-industry-occupation';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SOCKET_URL = process.env.REACT_APP_BASE_URL_SOCKET;

// Job Details Activity Types;
export const ETHICAL_CLEARANCE = 'ethical_clearance';
export const SIGNATURE_DOCUMENT = 'signature_document';
export const REQUEST_DOCUMENT = 'requested_document';
export const RELAY_ROOM = 'relay_room';
export const JOB_ACTIVITY_CHAT = 'job_activity_chat';

export const CPK_TRIP = 'cpk_trip';
export const LB_TRIP = 'lb_trip';
export const LB_EXPENSE = 'lb_expense';
export const LB_TRIP_EXPENSE = 'lb_trip_expense';
export const CPK_TRIP_EXPENSE = 'cpk_trip_expense';

//socket events
export const EMAIL_VERIFICATION_EVENT = 'email_verification';
export const EMAIL_VERIFICATION_EVENT_NEW = 'is_email_verified';

export const USER_TYPE = 'employee';
export const HEADERLAYOUT = 'header';
export const PERMISSIONS_MESSAGE = 'You dont have permission to perform this action';
export const BUSINESS_EMAIL_REGEX =
    /^(?:(?!@(?:gmail\.|yahoo\.|hotmail\.|aol\.|live\.|outlook\.)).)*(?:[a-zA-Z0-9._%+-]+@(?!gmail\.|yahoo\.|hotmail\.|aol\.|live\.|outlook\.)(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/;

//https://api.Synkli.dev/api
//http://localhost:5000/api

export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'business_prod';

export const clientTypes = {
    active_client: 'Active',
    active_entity: 'All',
    inactive_client: 'Inactive',
    inactive_entity: 'All',
    prospective_client: 'Prospective',
    prospective_entity: 'All',
    offline_client: 'All',
    offline_entity: 'All',
    'added-from-xero': 'All'
};

export const CLIENT_CATEGORIES = {
    PARTNERSHIP: 'Partnership',
    COMPANY: 'Company',
    TRUST: 'Trust',
    INDIVIDUAL: 'Individual'
};

export const ALL_ClIENT_TYPES = [
    'active_entity',
    'inactive_entity',
    'prospective_entity',
    'offline_entity',
    'active_client',
    'inactive_client',
    'prospective_client',
    'offline_client',
    'added-from-xero'
];

export const taskPriorityOptions = [
    {
        label: 'Highest',
        icon: HighestIcon
    },
    {
        label: 'High',
        icon: HighIcon
    },
    {
        label: 'Normal',
        icon: MediumIcon
    },
    {
        label: 'Low',
        icon: LowIcon
    },
    {
        label: 'Lowest',
        icon: LowestIcon
    }
];
export const pendingTaskFilterOptions = [
    {
        label: "Today's Pending",
        value: 'today'
    },
    {
        label: 'All Pending',
        value: 'total'
    }
];
export const SORT_OPTIONS = [
    {
        title: 'Acsending'
    },
    {
        title: 'Descending'
    }
];
export const FCM_TOKEN = 'fcm_token';
export const CSRF_TOKEN = APP_ENVIRONMENT + 'x-csrf-token';
export const PLACES_API_KEY = process.env.REACT_APP_PLACES_API_KEY;
export const APP_RECAPCHA_KEY = process.env.REACT_APP_RECAPCHA_KEY;

export const colorSchemes = [
    { backgroundColor: '#b695f8', textColor: '#FFFFFF' },
    { backgroundColor: '#E6F2E2', textColor: '#000000' },
    { backgroundColor: '#BEBEBE', textColor: '#000000' },
    { backgroundColor: '#ffec3b', textColor: '#000000' },
    { backgroundColor: '#F8F0E7', textColor: '#000000 !important' },

    { backgroundColor: '#F9E8E8', textColor: '#000000' },

    { backgroundColor: '#795548', textColor: '#FFFFFF' },
    { backgroundColor: '#2A2A2A', textColor: '#FFFFFF' },
    { backgroundColor: '#EEF0F8', textColor: '#000000' },
    { backgroundColor: '#F14747', textColor: '#FFFFFF' }
];

export const heading = '';
export const completedTaskStatusId = '9d5b96c5-fb22-41da-8scd-020882f4fst5';
export const acceptImagesForDisplay = ['image/jpeg', 'image/jpg', 'image/JPG', 'image/png', 'image/PNG'];

export const playStoreLink = 'https://play.google.com/store/apps/details?id=com.twitter.android';
export const appStoreLink = 'https://itunes.apple.com/app/twitter/id333903271';

export const COMMON_TITLE_LIMIT = 100;

export const acceptImages = [
    'image/jpeg',
    'image/jpg',
    'image/JPG',
    'image/png',
    'image/PNG',
    'image/heic',
    'image/heif',
    'image/avif'
];

export const acceptImagesView = ['jpeg', 'jpg', 'JPG', 'png', 'PNG', 'heic', 'heif', 'avif'];
export const acceptFormate = [
    'image/jpeg',
    'image/jpg',
    'image/JPG',
    'image/png',
    'image/PNG',
    'image/heic',
    'image/heif',
    'application/pdf',

    'image/avif'
];
export const acceptFilesFormats = [
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/vnd.ms-access', // .mdb
    'application/vnd.ms-access', // .accdb
    'application/x-mspublisher', // .pub
    'application/onenote', // .one
    'application/vnd.ms-outlook', // .pst
    'application/vnd.ms-outlook', // .ost
    'application/vnd.visio', // .vsd
    'application/vnd.visio', // .vsdx
    'application/pdf', // .pdf
    'text/csv', // .csv
    'application/vnd.ms-outlook', // .msg
    'application/octet-stream', // .aba
    'application/octet-stream', // .qif
    'application/rtf', // .rtf,
    '.msg',
    'mdb',
    'pst'
];

export const IN_APP_VIEW_FORMATS = [
    'application/pdf',
    'image/jpeg',
    'image/jpg',
    'image/JPG',
    'image/png',
    'image/PNG'
];

export const workplaceAcceptFilesFormats = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/JPG',
    'image/PNG',
    'image/heic',
    'image/heif',
    'image/avif',
    'application/vnd.ms-outlook',
    'application/msg', // Outlook email format (.msg)
    'application/vnd.ms-excel',
    'application/xlsx',
    'application/xls',
    'application/msword',
    'application/pdf',
    'text/csv', // CSV
    'application/doc', // Word (.doc)
    'application/docx', // Word (.docx)
    'application/msword', // Word (.doc, .docx)
    'application/msword',
    '.msg',
    'qif',
    'heif',
    'heic',
    'mdb',
    'pst'
];
export const all_files_types = [
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/vnd.ms-access', // .mdb
    'application/vnd.ms-access', // .accdb (treated as Access database)
    'application/x-mspublisher', // .pub
    'application/onenote', // .one
    'application/vnd.ms-outlook', // .pst
    'application/vnd.ms-outlook', // .ost
    'application/vnd.visio', // .vsd
    'application/vnd.visio', // .vsdx
    'application/pdf', // .pdf
    'image/jpeg', // .jpg
    'image/jpeg', // .jpeg
    'image/png', // .png
    'text/csv', // .csv
    'image/heic', // .heic
    'application/vnd.ms-outlook', // .msg
    'application/octet-stream', // .aba (generic binary data for uncommon file formats)
    'application/octet-stream', // .qif (Quicken format)
    'image/jpeg', // .jpe
    'image/gif', // .gif
    'image/bmp', // .bmp
    'application/rtf', // .rtf
    'image/avif', // .avif
    'image/heif', // .heif
    'image/webp', // .webp, // .rtf,
    'application/msword',
    '.msg',
    'qif',
    'heif',
    'heic',
    'mdb',
    'pst',
    'xml'
];

export const non_image_file_types = [
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/vnd.ms-access', // .mdb
    'application/vnd.ms-access', // .accdb
    'application/x-mspublisher', // .pub
    'application/onenote', // .one
    'application/vnd.ms-outlook', // .pst
    'application/vnd.ms-outlook', // .ost
    'application/vnd.visio', // .vsd
    'application/vnd.visio', // .vsdx
    'application/pdf', // .pdf
    'text/csv', // .csv
    'application/vnd.ms-outlook', // .msg
    'application/octet-stream', // .aba
    'application/octet-stream', // .qif
    'application/rtf', // .rtf
    'application/msword',
    '.msg',
    'qif',
    'heif',
    'heic',
    'mdb',
    'pst'
];

//clients section
export const DEFAULT_CLIENT_ORDER = {
    email: '',
    tfn: '',
    client_ref_no: '',
    phone_number: '',
    residential_address: '',
    client_type: '',
    created_at: 'DESC'
};

export const PRIORITY_OPTIONS = [
    { label: 'High' },
    { label: 'Highest' },
    { label: 'Normal' },
    { label: 'Low' },
    { label: 'Lowest' }
];

// Jobs Manager
export const restrictedStatusArray = ['Closed', 'Cancelled', 'Completed'];

export const generateRandomProfileLink = (name) => {
    return `https://ui-avatars.com/api/?name=${name}&background=random`;
};

export const handleDownload = (att) => {
    if (att && att.url) {
        const link = document.createElement('a');
        link.href = att.url;
        link.setAttribute('download', 'file');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        console.error('Cannot download file: URL is missing');
    }
};

export const getProfileName = (obj) => {
    if (obj?.client_category) {
        return obj.client_category !== CLIENT_CATEGORIES.INDIVIDUAL
            ? obj?.display_name
            : obj?.first_name + ' ' + (obj?.last_name || '');
    } else {
        return obj?.first_name && obj?.first_name !== ''
            ? obj?.first_name + ' ' + (obj?.last_name || '')
            : obj?.display_name;
    }
};

export const handleRemoveQueryParams = (navigate, preventParams = []) => {
    const currentPath = window.location.pathname;
    const currentSearch = window.location.search;

    const urlParams = new URLSearchParams(currentSearch);

    const newUrlParams = new URLSearchParams();

    for (const [key, value] of urlParams.entries()) {
        if (preventParams.includes(key)) {
            newUrlParams.append(key, value);
        }
    }

    const newUrl = `${currentPath}${newUrlParams.toString() ? '?' + newUrlParams.toString() : ''}`;

    navigate(newUrl, { replace: true });
};

export const defaultDesignationOpt_Ind = {
    company: [{ label: 'Director' }, { label: 'Shareholder' }, { label: 'Public Officer' }, { label: 'Secretary' }],
    partnership: [{ label: 'Partner' }],
    unit_trust: [{ label: 'Trustee' }, { label: 'Unit Holder' }],
    family_trust: [{ label: 'Trustee' }, { label: 'Beneficiary' }, { label: 'Settlor' }, { label: 'Appointer' }],
    smsf: [{ label: 'Trustee' }, { label: 'Member' }],
    association: [{ label: 'President' }, { label: 'Secretary' }, { label: 'Treasurer' }]
};

export const defaultDesignationOpt_nonInd = {
    company: [{ label: 'Shareholder' }],
    partnership: [{ label: 'Partner' }],
    family_trust: [{ label: 'Trustee', activeOnly: 'company' }, { label: 'Beneficiary' }],
    unit_trust: [{ label: 'Trustee', activeOnly: 'company' }, { label: 'Unit Holder' }],
    smsf: [{ label: 'Trustee', activeOnly: 'company' }],
    association: []
};

export const getUniqueDesignations = (designationObj) => {
    const allDesignations = Object.values(designationObj).flat();

    const uniqueDesignations = Array.from(new Map(allDesignations.map((item) => [item.label, item])).values());

    return uniqueDesignations;
};

// --------------------------------------------------------------------------------------------
