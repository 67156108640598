import React from 'react';

export const OstFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#FFE0B6" />
            <path
                d="M10.5469 22H6.21094L4.72656 20.6875V12.125L6.21094 10.7969H10.5469L12.0547 12.125V20.6875L10.5469 22ZM7.55469 19.2656H9.21875V13.5078H7.55469V19.2656ZM14.8359 22L13.3359 20.6719V18.625H16.1797V19.3281H17.8125V17.4688H14.8359L13.3359 16.1562V12.125L14.8359 10.7969H19.1562L20.6641 12.125V14.1797H17.8125V13.4688H16.1797V15.2812H19.1562L20.6641 16.6094V20.6719L19.1562 22H14.8359ZM27.0469 22H24.2188V13.6484H21.9453V10.7969H29.2734V13.6484H27.0469V22Z"
                fill="white"
            />
        </svg>
    );
};
