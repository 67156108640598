import React from 'react';
import { useDispatch } from 'react-redux';
import { addToClientThunk, addToInactiveClientThunk } from 'store/client';
import { EditPencilIcon } from 'utills/svgs/EditPencilIcon';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

const ProfileModel = ({
    user,
    setShowModal,
    fetchCustomer,
    toggleClientEdit,
    handleSendInvite,
    handleMoveClient,
    setInviteClient
}) => {
    const dispatch = useDispatch();
    const handleAddToClient = (type) => {
        const payload = {
            business_client_id: user?.business_client_id
        };
        if (type === 'addToActive') {
            if (user?.business_client_id) {
                dispatch(addToClientThunk({ bc_ids: [user?.business_client_id] }))
                    .then(() => {})
                    .finally(() => {
                        fetchCustomer();
                        setShowModal(false);
                    });
            }

            return;
        }
        dispatch(addToInactiveClientThunk(payload))
            .then(() => {})
            .finally(() => {
                fetchCustomer();
                setShowModal(false);
            });
    };

    const handleSendInviteBySlider = () => {
        setInviteClient(true);
    };

    return (
        <div>
            <div className="dot-model  absolute right-12 top-20 text-black shadow-md rounded-md">
                <div
                    onClick={toggleClientEdit}
                    className="flex gap-2 items-center pr-16 pl-3  py-3 buttons-font font-poppins hover:bg-thirdbg dot-content  cursor-pointer "
                >
                    <div>
                        <div className="dot-svg-wrapper">
                            <EditPencilIcon />
                        </div>
                    </div>
                    <div>Edit </div>
                </div>

                {user?.client_category === 'Individual' && user?.client_type === 'offline_client' && (
                    <div
                        onClick={handleSendInvite}
                        className="flex gap-2 items-center px-4 py-3 buttons-font font-poppins hover:bg-thirdbg dot-content mt-2 cursor-pointer "
                    >
                        <div>
                            <div className="dot-svg-wrapper1">
                                <PaperAirplaneIcon className="w-5 h-5" />
                            </div>
                        </div>
                        <div>Send Invite</div>
                    </div>
                )}

                {user?.client_category !== 'Individual' &&
                    ['offline_entity', 'added-from-xero'].includes(user?.client_type) && (
                        <div
                            onClick={handleSendInviteBySlider}
                            className="flex gap-2 items-center px-4 py-3 buttons-font font-poppins hover:bg-thirdbg dot-content mt-2 cursor-pointer "
                        >
                            <div>
                                <div className="dot-svg-wrapper1">
                                    <PaperAirplaneIcon className="w-5 h-5" />
                                </div>
                            </div>
                            <div>Send Invite</div>
                        </div>
                    )}

                {user?.client_type === 'prospective_client' && (
                    <div>
                        <div
                            onClick={() => handleAddToClient('addToActive')}
                            className="flex gap-2 items-center px-4 py-3 buttons-font font-poppins hover:bg-thirdbg dot-content mt-2 cursor-pointer "
                        >
                            <div>
                                <div className="dot-svg-wrapper1">
                                    <PaperAirplaneIcon className="w-5 h-5" />
                                </div>
                            </div>
                            <div>Move to Active Clients</div>
                        </div>
                        <div
                            onClick={() => handleAddToClient('addToInActive')}
                            className="flex gap-2 items-center px-4 py-3 buttons-font font-poppins hover:bg-thirdbg dot-content mt-2 cursor-pointer "
                        >
                            <div>
                                <div className="dot-svg-wrapper1">
                                    <PaperAirplaneIcon className="w-5 h-5" />
                                </div>
                            </div>
                            <div>Move to Inactive Clients</div>
                        </div>
                    </div>
                )}

                {user?.client_type === 'active_client' && (
                    <div>
                        <div
                            onClick={handleMoveClient}
                            className="flex gap-2 items-center px-4 py-3 buttons-font font-poppins hover:bg-thirdbg dot-content mt-2 cursor-pointer "
                        >
                            <div>
                                <div className="dot-svg-wrapper1">
                                    <PaperAirplaneIcon className="w-5 h-5" />
                                </div>
                            </div>
                            <div>Move to Prospective Clients</div>
                        </div>
                        <div
                            onClick={() => handleAddToClient('addToInActive')}
                            className="flex gap-2 items-center px-4 py-3 buttons-font font-poppins hover:bg-thirdbg dot-content mt-2 cursor-pointer "
                        >
                            <div>
                                <div className="dot-svg-wrapper1">
                                    <PaperAirplaneIcon className="w-5 h-5" />
                                </div>
                            </div>
                            <div>Move to Inactive Clients</div>
                        </div>
                    </div>
                )}

                {user?.client_type === 'inactive_client' && (
                    <div>
                        <div
                            onClick={handleMoveClient}
                            className="flex gap-2 items-center px-4 py-3 buttons-font font-poppins hover:bg-thirdbg dot-content mt-2 cursor-pointer "
                        >
                            <div>
                                <div className="dot-svg-wrapper1">
                                    <PaperAirplaneIcon className="w-5 h-5" />
                                </div>
                            </div>
                            <div>Move to Prospective Clients</div>
                        </div>
                        <div
                            onClick={() => handleAddToClient('addToActive')}
                            className="flex gap-2 items-center px-4 py-3 buttons-font font-poppins hover:bg-thirdbg dot-content mt-2 cursor-pointer "
                        >
                            <div>
                                <div className="dot-svg-wrapper1">
                                    <PaperAirplaneIcon className="w-5 h-5" />
                                </div>
                            </div>
                            <div>Move to Active Clients</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileModel;
