import React, { useEffect, useState } from 'react';
import TeamContent from '../../components/team-components/TeamContent';
import TeamDetails from '../../components/team-components/team-sides/TeamDetails';
import AddTeam from '../../components/team-components/team-sides/AddTeam';
import InviteSuccess from '../../components/team-components/team-sides/InviteSuccess';
import AddRole from '../../components/team-components/team-sides/AddRole';
import { EditMember } from 'components/team-components/team-sides/EditMember';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_ERROR } from 'utills/globalVars';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { resetPasswordOfEmployeeThunk } from 'store/settings/team/team';
import { setSideLoader } from 'store/global/globalReducer';
import { useLocation } from 'react-router-dom';
import useRoleGuard from 'Hooks/useRoleGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';
const Team = () => {
    const [showTeamDetails, setShowTeamDetails] = useState(false);

    const [showAddTeam, setShowAddTeam] = useState(false);
    const [showInviteSuccess, setShowInviteSuccess] = useState(false);
    const [showAddRole, setShowAddRole] = useState(false);
    const [teamMember, setTeamMember] = useState(null);
    const [showEditMember, setShowEditMember] = useState(false);
    const [editId, setEditId] = useState(null);
    const [registeredUpdated, setRegisteredUpdated] = useState(false);
    const { hasPermissions } = useRoleGuard();

    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [inviteUpdated, setInviteUpdated] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryId = queryParams.get('business_employee_id');
    const [type, setType] = useState(false);

    useEffect(() => {
        if (queryId) {
            setId(queryId);
            setShowTeamDetails(true);
        }
    }, [queryId]);

    const toggleTeamDetails = () => {
        setShowTeamDetails(!showTeamDetails);
    };

    const handleMember = (id, type) => {
        if (type === 'inactive') {
            setType(true);
        } else {
            setType(false);
        }
        setId(id);
        setShowTeamDetails(true);
    };

    function handleTeamMember(member) {
        setTeamMember(member);
    }

    const toggleAddTeam = () => {
        setShowAddTeam(!showAddTeam);
    };

    const handleEditMember = (business_employee_id) => {
        if (hasPermissions('Team', ['admin'])) {
            setShowTeamDetails(false);
            setEditId(business_employee_id);
            setShowEditMember(!showEditMember);

            showEditMember && setRegisteredUpdated(!registeredUpdated);
        } else {
            toastHandler('You dont have permission to perform this action', ERROR_TYPE_ERROR);
        }
    };

    const resendPasseord = (user_id) => {
        if (hasPermissions('Team', ['admin'])) {
            dispatch(setSideLoader(true));
            dispatch(resetPasswordOfEmployeeThunk({ user_id })).finally(() => {
                dispatch(setSideLoader(false));
            });
        } else {
            toastHandler('You dont have permission to perform this action', ERROR_TYPE_ERROR);
        }
    };

    function toggleInviteSuccess() {
        setShowAddTeam(false);
        setShowInviteSuccess(!showInviteSuccess);
        !showAddTeam && setInviteUpdated(!inviteUpdated);
    }

    function toggleAddRole() {
        setShowAddRole(!showAddRole);
    }
    return (
        <SidebarLayout>
            <div>
                <div>
                    {showEditMember && (
                        <EditMember
                            handleEditMember={handleEditMember}
                            business_employee_id={editId}
                            open={showEditMember}
                            setOpen={setShowEditMember}
                        />
                    )}

                    {showTeamDetails && (
                        <TeamDetails
                            toggleTeamDetails={toggleTeamDetails}
                            id={id}
                            handleEditMember={handleEditMember}
                            open={showTeamDetails}
                            setOpen={setShowTeamDetails}
                            hideActions={type}
                        />
                    )}
                    {showAddTeam && (
                        <AddTeam
                            toggleAddTeam={toggleAddTeam}
                            toggleInviteSuccess={toggleInviteSuccess}
                            open={showAddTeam}
                            setOpen={setShowAddTeam}
                        />
                    )}

                    {showInviteSuccess && <InviteSuccess toggleInviteSuccess={toggleInviteSuccess} />}

                    {showAddRole && <AddRole toggleAddRole={toggleAddRole} teamMember={teamMember} />}

                    <div>
                        <RoleBasedGuard module={'Team'} permissions={['admin']} sx="h-[50vh]">
                            <TeamContent
                                toggleTeamDetails={toggleTeamDetails}
                                handleMember={handleMember}
                                toggleAddTeam={toggleAddTeam}
                                toggleInviteSuccess={toggleInviteSuccess}
                                toggleAddRole={toggleAddRole}
                                handleTeamMember={handleTeamMember}
                                inviteUpdated={inviteUpdated}
                                handleEditMember={handleEditMember}
                                resendPasseord={resendPasseord}
                                registeredUpdated={registeredUpdated}
                            />
                        </RoleBasedGuard>
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};

export default Team;
