import React, { useEffect, useRef, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import { cn } from 'utills/tailwindUtil';
import parsePhoneNumberFromString from 'libphonenumber-js';

const PhoneInputComponent = ({
    titleSx,
    title,
    sx,
    placeholder,
    id,
    wrapsx,
    atChange,
    value,
    isDisabled,
    required,
    bg
}) => {
    const [phoneNumber, setPhoneNumber] = useState(value || '');
    const prevValueRef = useRef(value);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        if (value) {
            if (prevValueRef.current !== value) {
                setPhoneNumber(value);
                validatePhoneNumber(value);
                prevValueRef.current = value;
            }
        } else {
            setPhoneNumber('');
        }
    }, [value]);

    const handleChange = (number) => {
        setPhoneNumber(number);
        validatePhoneNumber(number);
        atChange(number);
    };

    const validatePhoneNumber = (phone) => {
        setIsValid(true);

        const parsedPhoneNumber = parsePhoneNumberFromString(phone);

        if (!parsedPhoneNumber || !parsedPhoneNumber.nationalNumber) {
            return;
        }

        if (!parsedPhoneNumber) {
            setIsValid('Invalid phone number');
            return;
        }

        if (!parsedPhoneNumber.isValid()) {
            setIsValid('Phone number is not valid for the given country');
            return;
        }

        if (parsedPhoneNumber.nationalNumber.startsWith('0')) {
            setIsValid('Phone number should not start with 0 after the country code');
            return;
        }

        if (parsedPhoneNumber.countryCallingCode.startsWith('0')) {
            setIsValid('Country code should not start with 0');
            return;
        }

        const region = parsedPhoneNumber.country;
        const isValidForRegion = parsedPhoneNumber.isValid() && parsedPhoneNumber.country === region;
        if (!isValidForRegion) {
            setIsValid(`Phone number is not valid for the region ${region}`);
            return;
        }
    };

    return (
        <div className="default-forms">
            {title && (
                <label htmlFor={id || ''} className={cn('  ', titleSx ? titleSx : '')}>
                    {title}
                </label>
            )}
            <div className={cn('relative', title ? 'mt-2' : '', wrapsx ? wrapsx : '')}>
                <PhoneInput
                    defaultCountry="au"
                    value={phoneNumber}
                    placeholder={placeholder || ''}
                    forceDialCode={true}
                    disableFormatting
                    onChange={(phone) => handleChange(phone)}
                    disabled={isDisabled ? true : false}
                    required={required ?? false}
                    inputStyle={{
                        border: 'none',
                        fontWeight: '400 !important',
                        borderRadius: '0.7rem',
                        fontSize: '0.9rem',
                        color: 'black',
                        backgroundColor: isDisabled ? '#e5e7eb' : bg ? bg : '#fff'
                    }}
                    className={cn(
                        'default-phone-picker text-md',
                        'block w-full rounded-lg !font-[400]  border border-solid !border-[#979797] px-2 pl-4 py-[5.5px] !outline-none focus:ring-1 focus:ring-gray-600 focus:border-gray-600 sm:leading-6',
                        sx || '',
                        isDisabled ? '!bg-gray-200 !border-none !cursor-no-drop !text-black ' : '',
                        isValid !== true ? '!border-1 !border-red-500' : ''
                    )}
                />
                {isValid !== true && <div className="text-sm text-red-500">{isValid}</div>}
            </div>
        </div>
    );
};

export default PhoneInputComponent;
