/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { InputField } from 'components/atoms/inputs/InputField';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import dayjs from 'dayjs';
import { PickAddress } from 'global-components/PickAddress';
import { useDispatch } from 'react-redux';
import { deleteClientRefThunk, searchClientsThunk } from 'store/client';
import { capitalizeFirstLetter, convertHyphensToUnderscores, convertUnderscoresToSpaces } from 'utills/handleText';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { generateId, generateRandomEmail } from 'utills/uid';
import { NewPlusIcon } from 'utills/svgs/NewPlusIcon';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import cloneDeep from 'lodash/cloneDeep';
import { Button } from 'components/atoms/buttons/Button';
import {
    filterEmptyObject,
    validateEmailValue,
    validateIndividualsObjects,
    validateTFN
} from './../../../../../../utills/dataValidation';
import { toastHandler } from '../../../../../../responseHanlder';
import { getProfileName, TOAST_TYPE_ERROR } from '../../../../../../utills/globalVars';
import { validatePhoneNumber } from '../../../../../../utills/baseValidations';
import Input from 'components/atoms/Input';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { BgPlusIcon } from 'utills/svgs/BgPlusIcon';
import { EMAIL_LENGTH } from 'utills/constants/validation-constants/validation.constants';
import { COMPANY_CLIENT } from '../../constants/addClient.constants';
import { useGetDirectors } from 'Hooks/clients/useGetDirectors';
import { dateMinus12Years } from 'utills/moment';

export const AddPartnerModal = ({
    setCurrentStep,
    addClientFormObj,
    setAddClientFormObj,
    partners,
    setPartners,
    _handleAdd,
    text,
    onNext,
    onBack,
    returnKey = 'partner_details',
    returnType = 'partner_type',
    percentageKey = 'partnership_percentage',
    addPartnerObj,
    onSubmitForm,
    max_percentage = 100,
    max_percentage_toast_info = '100%',
    custom_toast_label,
    _client = null,
    isDob = true
}) => {
    const dispatch = useDispatch();

    const handlePartnerType = (type, index) => {
        const newPartners = [...partners];
        newPartners[index].partner_type = type;
        setPartners(newPartners);
    };

    const handleFieldValue = (value, fieldIndex, partnerIndex, partnerType) => {
        setPartners((prev) => {
            const newPartners = [...prev];
            newPartners[partnerIndex].fields[convertHyphensToUnderscores(partnerType)][fieldIndex].value = value;
            return newPartners;
        });
    };

    const fetchReferencesListFun = async (payload) => {
        const funresponse = await dispatch(searchClientsThunk(payload));
        return funresponse;
    };

    const handleComboxboxEntity = (person, fieldIndex, partnerIndex, partnerType, isCombobox) => {
        const newPartners = [...partners];

        newPartners[partnerIndex].fields[convertHyphensToUnderscores(partnerType)][fieldIndex].value = person;
        const fieldsToEdit = newPartners[partnerIndex].fields[convertHyphensToUnderscores(partnerType)];

        if (isCombobox) {
            if (person) {
                fieldsToEdit?.forEach((field) => {
                    if (field.type !== 'switch' && field.name !== 'partnership_percentage') {
                        const correspondingValue =
                            person?.[
                                field?.name === 'dob'
                                    ? 'date_of_birth'
                                    : field?.name === 'email_address'
                                    ? 'email'
                                    : field?.name
                            ];

                        if (correspondingValue) {
                            field.value = correspondingValue;
                        }
                        field.disabled = correspondingValue ? true : false;
                    }
                });
            } else {
                fieldsToEdit
                    ?.filter((item) => item.type !== 'switch')
                    .forEach((field) => {
                        field.value = '';
                        field.disabled = false;
                    });
            }
        }
        setPartners(newPartners);
    };
    const handleComboxboxEntityRemove = (fieldIndex, partnerIndex, partnerType) => {
        const newPartners = [...partners];
        newPartners[partnerIndex].fields[convertHyphensToUnderscores(partnerType)][fieldIndex].value = '';

        newPartners[partnerIndex].fields[convertHyphensToUnderscores(partnerType)]
            .filter((item) => item?.type !== 'switch')
            .forEach((field) => {
                field.value = '';
                field.disabled = false;
            });

        setPartners(newPartners);
    };
    const handleRemoveSubfieldVal = (type, partnerIndex, fieldIndex, i) => {
        setPartners((prev) => {
            const newPartners = [...prev];
            const directors_array =
                newPartners[partnerIndex].fields['Non_Individual'][0].fields[type][fieldIndex].value;

            newPartners[partnerIndex].fields['Non_Individual'][0].fields[type][fieldIndex].value =
                directors_array?.filter((_obj, ind) => ind !== i);

            return newPartners;
        });
    };

    const { handleGetDirectors } = useGetDirectors();

    const handleGetDirectorsFun = async (business_client_id = null) => {
        return await handleGetDirectors(business_client_id);
    };

    const handeSubFieldValue = async (
        value,
        type,
        partnerIndex,
        fieldIndex,
        isCombobox,
        i = null,
        isFirstLoad = false
    ) => {
        const duplicatedPartners = [...partners];
        let directors = [];
        if (
            duplicatedPartners[partnerIndex].fields['Non_Individual'][0]?.value === COMPANY_CLIENT &&
            value?.business_client_id &&
            !isFirstLoad
        ) {
            directors = await handleGetDirectorsFun(value?.business_client_id);
        }

        setPartners((prev) => {
            const newPartners = [...prev];

            if (i !== null) {
                console.info('value', value);
                console.info('newPartners', newPartners, partnerIndex, type, fieldIndex, i);

                newPartners[partnerIndex].fields['Non_Individual'][0].fields[type][fieldIndex].value[i] = value;
            } else {
                newPartners[partnerIndex].fields['Non_Individual'][0].fields[type][fieldIndex].value = value;
            }
            newPartners[partnerIndex].isFirstLoad = false;

            if (value && isCombobox) {
                const companyFields = newPartners[partnerIndex].fields['Non_Individual'][0]?.fields[type];

                //   const findDirectorField = companyFields?.find((item))

                // console.info(directors, 'directors++');
                companyFields?.forEach((field) => {
                    if (field.name === 'email_address' && value?.email) {
                        field.value = value?.email;
                        field.disabled = true;
                    }

                    if (field.name === 'display_name' && value?.display_name) {
                        field.value = value?.display_name;
                        field.disabled = true;
                    }
                    if (field.name === 'contact_director_name') {
                        field.value =
                            directors?.length > 0 ? directors?.map((item) => getProfileName(item)) : field.value;
                        field.disabled = false;
                    }
                    if (field.name === 'tfn' && value?.tfn) {
                        field.value = value?.tfn;
                        field.disabled = true;
                    }

                    if (field.name === 'phone_number' && value?.phone_number) {
                        field.value = value?.phone_number;
                        field.disabled = true;
                    }
                    if (field?.name === 'current_address' && value?.residential_address) {
                        field.value = value?.residential_address;
                        field.disabled = true;
                    }
                });
            } else if (isCombobox && !value) {
                // setIsFirstLoad(false);
                newPartners[partnerIndex].fields['Non_Individual'][0].fields[type]
                    .filter((item) => item.type !== 'switch')
                    .forEach((field) => {
                        field.value = field.type === 'multiple' ? [' '] : '';
                        field.disabled = false;
                    });
            }
            return newPartners;
        });
    };

    const updateIds = (partner) => {
        const newPartner = cloneDeep(partner);
        for (const key in newPartner?.fields) {
            const fields = newPartner.fields[key];
            fields?.forEach((field) => {
                field.id = generateId();
                field.disabled = false;
                field.value = field.type === 'switch' || field.type === 'multiple' ? field.value : '';
                if (field.fields) {
                    for (const key in field.fields) {
                        const fields = field.fields[key];
                        fields?.forEach((field) => {
                            field.id = generateId();
                            field.disabled = false;
                            field.value = field.type === 'switch' || field.type === 'multiple' ? field.value : '';
                        });
                    }
                }
            });
        }

        return newPartner;
    };
    const AddPartner = () => {
        const newPartners = [...partners];
        const formattedNewPartnerObj = updateIds(addPartnerObj);
        newPartners.push(formattedNewPartnerObj);
        setPartners([...newPartners]);
    };

    const returnSelectedPeople = () => {
        let selected = [];

        for (const partner of partners) {
            for (const key in partner?.fields) {
                const fields = partner?.fields[key];
                fields.forEach((field) => {
                    if (field.type === 'combobox') {
                        selected.push({ business_client_id: field?.value?.business_client_id });
                    }
                    if (field.fields) {
                        for (const key in field.fields) {
                            const fields = field.fields[key];
                            fields.forEach((field) => {
                                if (field.type === 'combobox') {
                                    selected.push({ business_client_id: field?.value?.business_client_id });
                                }
                            });
                        }
                    }
                });
            }
        }

        return selected.filter((item) => item.business_client_id !== undefined && item.business_client_id !== null);
    };

    const handleDeletePartner = (index, bcr_id = null) => {
        if (bcr_id) {
            dispatch(deleteClientRefThunk({ bcr_id: bcr_id }));
        }

        const newPartners = [...partners];
        newPartners.splice(index, 1);
        setPartners(newPartners);
    };

    const handleNext = (e, isDirect) => {
        e.preventDefault();

        let newPartners = [];

        partners.forEach((partner) => {
            let newpartner = {
                [returnType]:
                    partner.partner_type.toLocaleLowerCase() === 'individual'
                        ? 'individual'
                        : partner.fields.Non_Individual[0].value,
                bcr_id: partner?.bcr_id ? partner?.bcr_id : null,
                details: {}
            };

            if (partner?.partner_type.toLocaleLowerCase() === 'individual') {
                let newDetails = {};
                partner.fields[convertHyphensToUnderscores(partner.partner_type)].forEach((field) => {
                    newDetails[field.name] =
                        field.type === 'combobox'
                            ? typeof field?.value === 'object'
                                ? field?.value?.business_client_id ?? ''
                                : field?.value ?? ''
                            : field?.value ?? '';
                });

                newpartner.details = newDetails;
            } else {
                let newDetails = {};
                const selectedOption = partner.fields.Non_Individual[0].value;

                const fields = partner.fields.Non_Individual[0].fields[selectedOption];

                fields.forEach((field) => {
                    newDetails[field.name] =
                        field.type === 'combobox'
                            ? typeof field?.value === 'object'
                                ? field?.value?.business_client_id ?? ''
                                : field?.value ?? ''
                            : field?.value ?? '';
                });

                newpartner.details = newDetails;
            }

            if (filterEmptyObject(newpartner)) {
                newPartners.push(newpartner);
            }
        });

        let hasError = false;
        let total_percentage = 0;
        const emailSet = new Set();

        for (let index = 0; index < (newPartners?.length || 0); index++) {
            const partner = newPartners[index];

            if (partner?.details?.[percentageKey]) {
                total_percentage = total_percentage + parseFloat(partner?.details?.[percentageKey]);
            }

            if (partner?.[returnType] === 'individual') {
                const validationErrors = validateIndividualsObjects(partner?.details);
                if (validationErrors?.length > 0) {
                    validationErrors?.forEach((error) => {
                        toastHandler(error + ' at ' + (index + 1), TOAST_TYPE_ERROR);
                    });
                    hasError = true;
                    break;
                }
            }
            if (partner?.details?.email_address) {
                if (!validateEmailValue(partner?.details?.email_address)) {
                    hasError = true;
                    toastHandler(`invalid Email at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                } else {
                    hasError = false;
                }
            }

            if (partner?.[returnType] === 'individual') {
                if (partner?.details?.email_address) {
                    if (emailSet.has(partner?.details?.email_address)) {
                        hasError = true;
                        toastHandler(`Duplicate email found at ${index + 1}`, TOAST_TYPE_ERROR);
                        break;
                    } else {
                        hasError = false;
                        emailSet.add(partner?.details?.email_address);
                    }
                }
            }

            if (partner?.details?.phone_number?.length > 4) {
                if (!validatePhoneNumber(partner?.details?.phone_number)) {
                    hasError = true;

                    break;
                } else {
                    hasError = false;
                }
            }

            console.info(partner?.details, 'details');
            if (partner?.details?.tfn) {
                if (!validateTFN(partner?.details?.tfn)) {
                    hasError = true;
                    toastHandler(`invalid TFN at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                } else {
                    hasError = false;
                }
            }
        }

        if (total_percentage > max_percentage) {
            return toastHandler(
                `Total ${
                    max_percentage_toast_info?.includes('%')
                        ? custom_toast_label
                            ? custom_toast_label
                            : convertUnderscoresToSpaces(percentageKey)
                        : 'Partnership Amount'
                } cannot be greater than ${max_percentage_toast_info}`,
                TOAST_TYPE_ERROR
            );
        }

        if (hasError || total_percentage > max_percentage) {
            return false;
        } else {
            const newPayload = {
                ...addClientFormObj,
                [returnKey]: newPartners
            };
            setAddClientFormObj(newPayload);

            if (isDirect) {
                onSubmitForm(newPayload);

                return;
            }

            onNext ? onNext() : setCurrentStep(2);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <div className="p-5 default-forms">
            <div className="flex mb-6 justify-between items-center">
                <div className=" table-title font-poppins font-semibold">
                    {text?.heading ? text?.heading : 'Partner Details'}
                </div>
                <div className="cursor-pointer" onClick={() => AddPartner()}>
                    <NewPlusIcon />
                </div>
            </div>

            <form onSubmit={handleNext} onKeyDown={handleKeyDown}>
                {partners?.map((partner, partnerIndex) => (
                    <div
                        className="mt-5 bg-dimBlue rounded-xl p-5"
                        key={partnerIndex + partner?.fields?.Individual[0]?.id?.slice(0, partnerIndex + 1)}
                    >
                        <div className="flex justify-end items-center w-full">
                            <div
                                className="cursor-pointer"
                                onClick={() => handleDeletePartner(partnerIndex, partner?.bcr_id)}
                            >
                                <BgDeleteIcon />
                            </div>
                        </div>

                        <label>{text?.label ? text?.label : 'Is Partner an individual or Non-Individual?'} </label>
                        <div className="md:w-[350px]">
                            {' '}
                            <DefaultDoubleSwitch
                                items={[
                                    { title: 'Individual', selected: partner?.partner_type === 'Individual' },
                                    { title: 'Non-Individual', selected: partner?.partner_type === 'Non-Individual' }
                                ]}
                                onClick={(value) => {
                                    handlePartnerType(value, partnerIndex);
                                }}
                            />
                        </div>

                        <div className="flex gap-5 mt-5 items-start flex-wrap">
                            {partner.fields[convertHyphensToUnderscores(partner?.partner_type)]?.map(
                                (field, fieldIndex) => (
                                    <div
                                        className={`${
                                            partner.partner_type === 'Individual'
                                                ? 'w-full md:w-[100%] lg:w-[31%] xl:w-[23%]'
                                                : 'w-full'
                                        } `}
                                        key={'parent ' + field?.id + fieldIndex}
                                    >
                                        {field.type === 'combobox' && (
                                            <div className="">
                                                <label className="inputLabel">
                                                    {convertUnderscoresToSpaces(field.label)}
                                                </label>

                                                <ComboboxComponentSSR
                                                    fetchDataFunction={(payload) =>
                                                        fetchReferencesListFun({
                                                            ...payload,
                                                            type: 'individual'
                                                        })
                                                    }
                                                    defaultSelected={
                                                        field?.value
                                                            ? typeof field?.value === 'string'
                                                                ? field?.value
                                                                : field?.value?.business_client_id
                                                            : ''
                                                    }
                                                    removePreviousSelected
                                                    onRemove={(_e) => {
                                                        handleComboxboxEntityRemove(
                                                            fieldIndex,
                                                            partnerIndex,
                                                            partner?.partner_type,
                                                            true
                                                        );
                                                    }}
                                                    filterSelectedValues={returnSelectedPeople() || []}
                                                    filterSelectedValueTargetElement="business_client_id"
                                                    onChange={(res) => {
                                                        const obj = res[0];
                                                        handleComboxboxEntity(
                                                            obj,
                                                            fieldIndex,
                                                            partnerIndex,
                                                            partner?.partner_type,
                                                            true
                                                        );
                                                    }}
                                                    targetProperty="business_client_id"
                                                    placeholder={field.placeholder || ''}
                                                    limit={1}
                                                    sx="rounded-xl ring-gray-400"
                                                    showNameOnly
                                                />
                                            </div>
                                        )}

                                        {field?.type === 'tel' && (
                                            <div>
                                                <PhoneInputComponent
                                                    value={field.value}
                                                    title="Phone Number"
                                                    placeholder="Enter Phone Number"
                                                    titleSx=""
                                                    sx="!rounded-xl bg-transparent"
                                                    bg="#F8F8FE"
                                                    atChange={(phone) => {
                                                        handleFieldValue(
                                                            phone,
                                                            fieldIndex,
                                                            partnerIndex,
                                                            partner?.partner_type
                                                        );
                                                    }}
                                                    required={false}
                                                    isDisabled={field.disabled ? true : false}
                                                />
                                            </div>
                                        )}
                                        {field.type === 'dropdown' && (
                                            <div className="lg:w-[350px]">
                                                <div>
                                                    <label className="capitalize">{field?.label}</label>
                                                </div>
                                                <div className="mt-1">
                                                    <SimpleSelectMenu
                                                        placeholder="Select"
                                                        targetProperty="label"
                                                        selectedValue={
                                                            field.value === 'Association_Club'
                                                                ? 'Association/Club'
                                                                : field.value
                                                        }
                                                        valuePropertyName={field?.targetPropertyName || 'label'}
                                                        optionsData={field.options || []}
                                                        sx="rounded-xl mt-2 py-3 bg-transparent ring-gray-400 !font-normal cursor-pointer"
                                                        showChipOnly={false}
                                                        onChangeValue={(obj) => {
                                                            handleFieldValue(
                                                                obj.label === 'Association/Club'
                                                                    ? 'Association_Club'
                                                                    : field?.targetPropertyName
                                                                    ? obj?.[field?.targetPropertyName]
                                                                    : obj.label,
                                                                fieldIndex,
                                                                partnerIndex,
                                                                partner?.partner_type
                                                            );
                                                        }}
                                                        disabled={field.disabled ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {field.type === 'text' && (
                                            <InputField
                                                label={field?.label}
                                                maxLength={30}
                                                allowSpaces={true}
                                                onChange={(e) => {
                                                    handleFieldValue(
                                                        e.target.value,
                                                        fieldIndex,
                                                        partnerIndex,
                                                        partner?.partner_type
                                                    );
                                                }}
                                                name={field?.label}
                                                inputValue={field.value}
                                                placeholder={field?.placeholder}
                                                classes="mt-2 bg-transparent"
                                                disabled={field.disabled ? true : false}
                                            />
                                        )}

                                        {field.type === 'address' && (
                                            <div>
                                                <PickAddress
                                                    id={`residential-autocomplete-${field?.id + fieldIndex}`}
                                                    address={field.value}
                                                    setAddress={(address) => {
                                                        handleFieldValue(
                                                            address,
                                                            fieldIndex,
                                                            partnerIndex,
                                                            partner?.partner_type
                                                        );
                                                    }}
                                                    onSelect={(address) => {
                                                        handleFieldValue(
                                                            address.formatted_address,
                                                            fieldIndex,
                                                            partnerIndex,
                                                            partner?.partner_type
                                                        );
                                                    }}
                                                    label={field.label}
                                                    placeholder={field.placeholder}
                                                    name="Residential Address"
                                                    classes="bg-transparent "
                                                    disabled={field?.disabled ? true : false}
                                                />
                                            </div>
                                        )}

                                        {field.type === 'number' && (
                                            <div>
                                                <label className="inputLabel">{field.label}</label>
                                                <input
                                                    type="number"
                                                    step="any" // Allows floating numbers
                                                    disabled={field.disabled ? true : false}
                                                    placeholder={field?.placeholder}
                                                    value={field?.value}
                                                    onChange={(e) => {
                                                        let inputValue = e.target.value;

                                                        // Convert to float and ensure it's not less than 0
                                                        if (inputValue !== '' && parseFloat(inputValue) < 0) {
                                                            inputValue = '0';
                                                        }
                                                        handleFieldValue(
                                                            inputValue,
                                                            fieldIndex,
                                                            partnerIndex,
                                                            partner?.partner_type
                                                        );
                                                    }}
                                                    className={`mt-2 bg-transparent ${
                                                        field.disabled && '!bg-gray-200  !border-none !cursor-no-drop'
                                                    }`}
                                                />
                                            </div>
                                        )}

                                        {field.type === 'email' && (
                                            <div>
                                                <label className="inputLabel">{field.label}</label>
                                                <input
                                                    type="email"
                                                    disabled={field.disabled ? true : false}
                                                    placeholder={field?.placeholder}
                                                    value={field?.value}
                                                    maxLength={EMAIL_LENGTH}
                                                    onChange={(e) => {
                                                        handleFieldValue(
                                                            e.target.value,
                                                            fieldIndex,
                                                            partnerIndex,
                                                            partner?.partner_type
                                                        );
                                                    }}
                                                    className={`mt-2 bg-transparent  ${
                                                        field.disabled && '!bg-gray-200 !border-none !cursor-no-drop'
                                                    }`}
                                                />

                                                {!isDob && !field.value && (
                                                    <div>
                                                        <span
                                                            className="pl-1 text-xs italic text-gray-600 cursor-pointer"
                                                            onClick={() =>
                                                                handleFieldValue(
                                                                    generateRandomEmail(),
                                                                    fieldIndex,
                                                                    partnerIndex,
                                                                    partner?.partner_type
                                                                )
                                                            }
                                                        >
                                                            Generate Random Email
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {field?.type === 'date' && (
                                            <div>
                                                <div>
                                                    <label>{field.label}</label>
                                                </div>
                                                <DatePick
                                                    maxDate={
                                                        isDob && field?.label === 'Date Of Birth'
                                                            ? dayjs(dateMinus12Years, 'DD-MM-YYYY')
                                                            : dayjs()
                                                    }
                                                    iconSet={{
                                                        right: CalendarSmallIco
                                                    }}
                                                    onDateChange={(date) => {
                                                        handleFieldValue(
                                                            date ? dayjs(date).format('DD-MM-YYYY') : '',
                                                            fieldIndex,
                                                            partnerIndex,
                                                            partner?.partner_type
                                                        );
                                                    }}
                                                    placeholder="DD-MM-YYYY"
                                                    value={field?.value ? dayjs(field?.value, 'DD-MM-YYYY') : ''}
                                                    isDisabled={field.disabled ? true : false}
                                                    format="DD-MM-YYYY"
                                                    sx="addTaskDate !border-gray-400  !rounded-xl mt-2 text-gray-900 !w-full bg-transparent  hover:bg-[#FAF9F6]"
                                                />
                                            </div>
                                        )}

                                        {field?.type === 'switch' && (
                                            <div
                                                className={`${
                                                    partner?.partner_type === 'Non-Individual' && 'w-full lg:w-[23%]'
                                                }`}
                                            >
                                                <label className="inputLabel">{field?.label}</label>
                                                <DefaultDoubleSwitch
                                                    items={[
                                                        {
                                                            title: field?.options[0],
                                                            selected: field?.value === field?.options[0]
                                                        },
                                                        {
                                                            title: field?.options[1],
                                                            selected: field?.value === field?.options[1]
                                                        }
                                                    ]}
                                                    onClick={(value) => {
                                                        handleFieldValue(
                                                            value,
                                                            fieldIndex,
                                                            partnerIndex,
                                                            partner?.partner_type
                                                        );
                                                    }}
                                                    disabled={field.disabled ? true : false}
                                                />
                                            </div>
                                        )}
                                        {field?.fields && (
                                            <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4  gap-5 items-center  mt-5">
                                                {field?.fields[field?.value]?.map((subFields, fieldIndex) => (
                                                    <div
                                                        className={`${subFields?.type === 'multiple' && 'col-span-4'}`}
                                                        key={field?.id + subFields?.id + fieldIndex}
                                                    >
                                                        {' '}
                                                        {subFields.type === 'combobox' && (
                                                            <div className="">
                                                                <label className="inputLabel !capitalize">
                                                                    {convertUnderscoresToSpaces(subFields.label)}
                                                                </label>

                                                                <ComboboxComponentSSR
                                                                    fetchDataFunction={(payload) =>
                                                                        fetchReferencesListFun({
                                                                            ...payload,
                                                                            type:
                                                                                field?.value === 'Association_Club'
                                                                                    ? 'Association'
                                                                                    : field?.value === 'smsf'
                                                                                    ? (field?.value).toUpperCase()
                                                                                    : capitalizeFirstLetter(
                                                                                          field?.value
                                                                                      )
                                                                        })
                                                                    }
                                                                    forceUpdate={field.value}
                                                                    defaultSelected={
                                                                        subFields?.value?.business_client_id
                                                                    }
                                                                    preventLoadingInfo={
                                                                        partner[partnerIndex]?.isFirstLoad
                                                                    }
                                                                    removePreviousSelected
                                                                    onRemove={(_e) => {
                                                                        handeSubFieldValue(
                                                                            '',
                                                                            field?.value,
                                                                            partnerIndex,
                                                                            fieldIndex,
                                                                            true
                                                                        );
                                                                    }}
                                                                    filterSelectedValues={returnSelectedPeople() || []}
                                                                    filterSelectedValueTargetElement="business_client_id"
                                                                    onChange={(res) => {
                                                                        const obj = res[0];
                                                                        handeSubFieldValue(
                                                                            obj,
                                                                            field?.value,
                                                                            partnerIndex,
                                                                            fieldIndex,
                                                                            true,
                                                                            partner[partnerIndex]?.isFirstLoad
                                                                        );
                                                                    }}
                                                                    showNameOnly
                                                                    targetProperty="business_client_id"
                                                                    placeholder={subFields.placeholder || ''}
                                                                    limit={1}
                                                                    sx="rounded-xl ring-gray-400"
                                                                />
                                                            </div>
                                                        )}
                                                        {subFields?.type === 'multiple' &&
                                                            Array.isArray(subFields?.value) && (
                                                                <div className="grid grid-cols-4 gap-5 flex-wrap">
                                                                    {subFields?.value?.map((val, i) => (
                                                                        <div key={'subfielditem' + i}>
                                                                            <div className="flex justify-between items-center gap-2 mb-2">
                                                                                <PrimaryLabel>
                                                                                    {subFields?.label}
                                                                                </PrimaryLabel>
                                                                                {i === subFields?.value?.length - 1 ? (
                                                                                    <div
                                                                                        className="cursor-pointer"
                                                                                        onClick={() => {
                                                                                            handeSubFieldValue(
                                                                                                [
                                                                                                    ...subFields?.value,
                                                                                                    ' '
                                                                                                ],
                                                                                                field?.value,
                                                                                                partnerIndex,
                                                                                                fieldIndex,
                                                                                                false
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <BgPlusIcon
                                                                                            className={
                                                                                                'w-8 h-8 p-[4px]'
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="cursor-pointer"
                                                                                        onClick={() => {
                                                                                            handleRemoveSubfieldVal(
                                                                                                field?.value,
                                                                                                partnerIndex,
                                                                                                fieldIndex,
                                                                                                i
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <BgDeleteIcon
                                                                                            className={'w-8 h-8'}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <Input
                                                                                onChange={(e) => {
                                                                                    handeSubFieldValue(
                                                                                        e.target.value,
                                                                                        field?.value,
                                                                                        partnerIndex,
                                                                                        fieldIndex,
                                                                                        false,
                                                                                        i
                                                                                    );
                                                                                }}
                                                                                sx={'!bg-transparent'}
                                                                                placeholder={
                                                                                    subFields?.placeholder || ''
                                                                                }
                                                                                value={val === ' ' ? '' : val}
                                                                                useWithoutForm
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        {subFields?.type === 'tel' && (
                                                            <div>
                                                                <PhoneInputComponent
                                                                    value={subFields.value ? subFields?.value : ''}
                                                                    title="Phone Number"
                                                                    placeholder="Enter Phone Number"
                                                                    titleSx=""
                                                                    sx="!rounded-xl"
                                                                    bg="#F8F8FE"
                                                                    atChange={(phone) => {
                                                                        handeSubFieldValue(
                                                                            phone,
                                                                            field?.value,
                                                                            partnerIndex,
                                                                            fieldIndex
                                                                        );
                                                                    }}
                                                                    isDisabled={subFields.disabled ? true : false}
                                                                    id={field?.id + subFields?.id + fieldIndex}
                                                                />
                                                            </div>
                                                        )}
                                                        {subFields.type === 'dropdown' && (
                                                            <div>
                                                                <div>
                                                                    <label>{subFields?.label}</label>
                                                                </div>
                                                                <div className="mt-1">
                                                                    <SimpleSelectMenu
                                                                        placeholder="Select"
                                                                        targetProperty="label"
                                                                        selectedValue={subFields.value}
                                                                        valuePropertyName="label"
                                                                        optionsData={subFields.options || []}
                                                                        sx="rounded-xl mt-2 bg-transparent  py-3 ring-gray-400 !font-normal cursor-pointer"
                                                                        showChipOnly={false}
                                                                        onChangeValue={(obj) => {
                                                                            handeSubFieldValue(
                                                                                obj.label,
                                                                                field.value,
                                                                                partnerIndex,
                                                                                fieldIndex
                                                                            );
                                                                        }}
                                                                        disabled={subFields.disabled ? true : false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {subFields.type === 'text' && (
                                                            <InputField
                                                                label={subFields?.label}
                                                                maxLength={30}
                                                                allowSpaces={true}
                                                                onChange={(e) => {
                                                                    handeSubFieldValue(
                                                                        e.target.value,
                                                                        field.value,
                                                                        partnerIndex,
                                                                        fieldIndex
                                                                    );
                                                                }}
                                                                name={subFields?.label}
                                                                inputValue={subFields.value}
                                                                placeholder={subFields?.placeholder}
                                                                classes="mt-2 bg-transparent "
                                                                disabled={subFields.disabled ? true : false}
                                                                id={field?.id + subFields?.id + fieldIndex}
                                                            />
                                                        )}
                                                        {subFields.type === 'number' && (
                                                            <div>
                                                                <label className="inputLabel">{subFields.label}</label>
                                                                <input
                                                                    type="number"
                                                                    step="any" // Allows floating numbers
                                                                    disabled={subFields.disabled ? true : false}
                                                                    placeholder={subFields?.placeholder}
                                                                    value={subFields?.value}
                                                                    onChange={(e) => {
                                                                        let inputValue = e.target.value;

                                                                        // Convert to float and ensure it's not less than 0
                                                                        if (
                                                                            inputValue !== '' &&
                                                                            parseFloat(inputValue) < 0
                                                                        ) {
                                                                            inputValue = '0';
                                                                        }

                                                                        handeSubFieldValue(
                                                                            inputValue,
                                                                            field.value,
                                                                            partnerIndex,
                                                                            fieldIndex
                                                                        );
                                                                    }}
                                                                    className={`mt-2 bg-transparent  ${
                                                                        subFields.disabled &&
                                                                        '!bg-gray-200 !border-none !cursor-no-drop'
                                                                    }`}
                                                                    id={field?.id + subFields?.id + fieldIndex}
                                                                />
                                                            </div>
                                                        )}
                                                        {subFields.type === 'email' && (
                                                            <div>
                                                                <label className="inputLabel">{subFields.label}</label>
                                                                <input
                                                                    type="email"
                                                                    disabled={subFields.disabled ? true : false}
                                                                    placeholder={subFields?.placeholder}
                                                                    value={subFields?.value}
                                                                    maxLength={EMAIL_LENGTH}
                                                                    onChange={(e) => {
                                                                        handeSubFieldValue(
                                                                            e.target.value,
                                                                            field.value,
                                                                            partnerIndex,
                                                                            fieldIndex
                                                                        );
                                                                    }}
                                                                    className={`mt-2  bg-transparent  ${
                                                                        subFields.disabled &&
                                                                        '!bg-gray-200 !border-none !cursor-no-drop'
                                                                    }`}
                                                                    id={field?.id + subFields?.id + fieldIndex}
                                                                />
                                                            </div>
                                                        )}
                                                        {subFields.type === 'address' && (
                                                            <div>
                                                                <PickAddress
                                                                    id={`residential-autocomplete-${fieldIndex}`}
                                                                    address={subFields.value}
                                                                    setAddress={(address) => {
                                                                        handeSubFieldValue(
                                                                            address,
                                                                            field.value,
                                                                            partnerIndex,
                                                                            fieldIndex
                                                                        );
                                                                    }}
                                                                    onSelect={(address) => {
                                                                        handeSubFieldValue(
                                                                            address.formatted_address,
                                                                            field.value,
                                                                            partnerIndex,
                                                                            fieldIndex
                                                                        );
                                                                    }}
                                                                    label={subFields.label}
                                                                    placeholder={subFields.placeholder}
                                                                    name="Residential Address"
                                                                    classes={`${
                                                                        subFields?.disabled ? '' : 'bg-transparent'
                                                                    } `}
                                                                    disabled={subFields?.disabled ? true : false}
                                                                />
                                                            </div>
                                                        )}
                                                        {subFields?.type === 'date' && (
                                                            <div>
                                                                <div>
                                                                    <label>{subFields.label}</label>
                                                                </div>
                                                                <DatePick
                                                                    iconSet={{
                                                                        right: CalendarSmallIco
                                                                    }}
                                                                    onDateChange={(date) => {
                                                                        handeSubFieldValue(
                                                                            date
                                                                                ? dayjs(date).format('DD-MM-YYYY')
                                                                                : '',
                                                                            field.value,
                                                                            partnerIndex,
                                                                            fieldIndex
                                                                        );
                                                                    }}
                                                                    placeholder="DD-MM-YYYY"
                                                                    value={
                                                                        subFields?.value
                                                                            ? dayjs(subFields?.value, 'DD-MM-YYYY')
                                                                            : ''
                                                                    }
                                                                    isDisabled={subFields.disabled ? true : false}
                                                                    format="DD-MM-YYYY"
                                                                    sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-transparent hover:bg-[#FAF9F6]"
                                                                />
                                                            </div>
                                                        )}
                                                        {subFields?.type === 'switch' && (
                                                            <div className="">
                                                                <label className="inputLabel">{subFields?.label}</label>
                                                                <DefaultDoubleSwitch
                                                                    items={[
                                                                        {
                                                                            title: subFields?.options[0],
                                                                            selected:
                                                                                subFields?.value ===
                                                                                subFields?.options[0]
                                                                        },
                                                                        {
                                                                            title: subFields?.options[1],
                                                                            selected:
                                                                                subFields?.value ===
                                                                                subFields?.options[1]
                                                                        }
                                                                    ]}
                                                                    onClick={(value) => {
                                                                        handeSubFieldValue(
                                                                            value,
                                                                            field.value,
                                                                            partnerIndex,
                                                                            fieldIndex
                                                                        );
                                                                    }}
                                                                    disabled={subFields.disabled ? true : false}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}{' '}
                                            </div>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                ))}

                <div className="my-10">
                    {' '}
                    <div className="flex gap-10 items-center justify-center">
                        <Button
                            title="Back"
                            classes="blue-border-btn !border-2 px-14 rounded-lg"
                            atClick={() => (onBack ? onBack() : setCurrentStep(0))}
                            buttonType="button"
                        />

                        <Button title="Next" buttonType="submit" classes="ann-btn px-14 rounded-lg" />
                    </div>
                </div>
            </form>
        </div>
    );
};
