/* eslint-disable react-hooks/exhaustive-deps */
import Input from 'components/atoms/Input';
import MailerInput from 'components/atoms/Mailer/MailerInput';
import MailerInputBody2 from 'components/atoms/Mailer/MailerInputBody2';
import Slideover from 'components/atoms/SlideOver';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import useRoleGuard from 'Hooks/useRoleGuard';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import CollaborationManager from 'pages/settings/job-manager/job-manager-settings/CollaborationManager';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { getBusinessprofileThunk } from 'store/auth/slices';
import { searchClientsThunk } from 'store/client';
import { getAllDraftThunk, setSelectedDraft } from 'store/custom-reducers/RelayRoomDraftReducer';
import {
    getRelayRoomListThunk,
    getTotalThreadsCountThunk,
    setIsLoading,
    updateDraftCount
} from 'store/custom-reducers/RelayRoomLeftBarReducer';
import { setLoader } from 'store/global/globalReducer';
import {
    getSingleJobThunk,
    handleCreateDraftThunk,
    handleUpdateDraftThunk,
    removeDraftThunk,
    sendComposeEmailThunk
} from 'store/JobManager';
import { getDefaultSignatureThunk } from 'store/settings/JobPortal';
import { jsonToFormData } from 'utills/FormValidation';
import { APP_ENVIRONMENT, BUSINESS_ID, getProfileName, TOAST_TYPE_ERROR } from 'utills/globalVars';
import CollapsIcon from 'utills/svgs/CollapsIcon';
import { cn } from 'utills/tailwindUtil';
import { generateId } from 'utills/uid';

const ComposeNewEmail = ({ open = false, setOpen, preSelectedData, setPreSelectedData }) => {
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const { data: getBusinessprofile } = useSelector((state) => state.getBusinessprofile);
    const { data: getDefaultSignatureList } = useSelector((state) => state.getDefaultSignature);
    const { totalDraftEmail } = useSelector((state) => state.getRelayRoomList);

    const { hasPermissions } = useRoleGuard();
    const { selectedDraft } = useSelector((state) => state.draftListReducer);
    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const [data, setData] = useState(null);
    const [jobRefno, setJobRefno] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fromEmailSlider, setFromEmailSlider] = useState(false);
    const [toggleSignature, setToggleSignature] = useState(false);

    useEffect(() => {
        if (selectedDraft) {
            const splitSubject = selectedDraft?.subject?.split(']:');
            setData({
                to: getProfileObject(selectedDraft?.to),
                cc: getProfileObject(selectedDraft?.cc),
                bcc: getProfileObject(selectedDraft?.bcc),
                from: selectedDraft?.from,
                body: selectedDraft?.body,
                subject: splitSubject.splice(1).join(' ')
            });
            setJobRefno(`${splitSubject[0]}]:`);

            if (selectedDraft?.metadata && selectedDraft?.metadata?.length > 0) {
                setSelectedFiles(selectedDraft?.metadata);
            }
        } else {
            setData(null);
            setSelectedFiles([]);
        }
        setForceUpdate(true);
        return () => {
            setData(null);
            setSelectedFiles([]);
            setForceUpdate(false);
            setPreSelectedData(null);
        };
    }, [selectedDraft]);

    const setDataFun = (target, value) => {
        setData((data) => ({ ...(data ? data : {}), [target]: value }));
    };

    useEffect(() => {
        if (!selectedDraft) {
            if (!getBusinessprofile) {
                dispatch(getBusinessprofileThunk());
            } else {
                setDataFun('from', getBusinessprofile?.business_email || '');
            }
        }
    }, [selectedDraft, getBusinessprofile]);

    useEffect(() => {
        if (!selectedDraft && jobDetails) {
            if (preSelectedData) {
                setJobRefno(`[${preSelectedData?.ref_no || ''}]:`);
                setData({
                    ...(data ?? {}),
                    to: getProfileObject(preSelectedData?.to),
                    cc: getProfileObject(preSelectedData?.cc),
                    bcc: getProfileObject(preSelectedData?.bcc),
                    ...(getBusinessprofile ? { from: getBusinessprofile?.business_email } : {})
                });
            } else {
                setJobRefno(`[${jobDetails?.ref_no || ''}]:`);
                setData({
                    ...(data ?? {}),
                    to: jobDetails?.clients,
                    cc: jobDetails?.manager?.map((obj) => ({ ...obj, business_client_id: generateId() })),
                    ...(getBusinessprofile ? { from: getBusinessprofile?.business_email } : {})
                });
            }

            setToggleSignature(true);
        }
    }, [selectedDraft, jobDetails]);

    useEffect(() => {
        if (!selectedDraft && !jobDetails) {
            dispatch(getSingleJobThunk({ job_id: id }));
        }
    }, [selectedDraft, jobDetails]);

    // ------------------------------------------------------------ Get Default Signatures

    useEffect(() => {
        if (toggleSignature) {
            writeSignatureIfExist();
        }
    }, [getDefaultSignatureList, toggleSignature]);
    useEffect(() => {
        if (!getDefaultSignatureList) {
            dispatch(getDefaultSignatureThunk({ business_id }));
        }
    }, []);

    const writeSignatureIfExist = () => {
        const relayRoomSignature = getDefaultSignatureList
            ? getDefaultSignatureList.find((obj) => obj?.email_type === 'relay_room')
            : null;
        const signatureToAdd = relayRoomSignature ? relayRoomSignature?.new_email_id : null;
        if (signatureToAdd) {
            setDataFun(
                'body',
                `${data?.body || ''} <br /> <br /> <br />
                ${relayRoomSignature?.is_positioning === 'null' ? '--' : ''}
                <div style:"margin-top:10px" >
                ${signatureToAdd?.signature}  <br /> <br /> <br /> </div>`
            );
            setForceUpdate(true);
            setToggleSignature(false);
        }
    };

    // ------------------------------------------------------------ Get Default Signatures

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleSubmitData = () => {
        if (!data?.to || data?.to?.length === 0) {
            return toastHandler('"To" Email is required!', TOAST_TYPE_ERROR);
        }
        if (!data?.from || data?.from === '') {
            return toastHandler('"From" Email is required!', TOAST_TYPE_ERROR);
        }
        if (!data?.subject || data?.subject === '') {
            return toastHandler('Subject is required!', TOAST_TYPE_ERROR);
        }
        const jsonData = {
            ...data,
            from: APP_ENVIRONMENT === 'business_dev' ? 'admin@backendtesting.synkli.com.au' : data?.from, // only for testing purpose
            to: data?.to?.map((item) => item?.email)?.join(',') || '', // Default to an empty string, not an array
            cc: data?.cc?.map((item) => item?.email)?.join(',') || '', // Default to an empty string
            bcc: data?.bcc?.map((item) => item?.email)?.join(',') || '', // Default to an empty string
            subject: `${jobRefno}${data?.subject || ''}`, // Fix the concatenation logic
            body: data?.body,
            job_id: id,
            business_id: business_id,
            files: selectedFiles?.filter((obj) => !obj?.url) || []
        };

        if (selectedDraft) {
            jsonData.email_draft_id = selectedDraft?.email_draft_id;
        }

        const formData = jsonToFormData(jsonData);
        dispatch(setLoader(true));
        dispatch(sendComposeEmailThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setData(null);
                    setOpen(false);
                    fetchDraftList();
                    reloadSideBar();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
                setPreSelectedData(null);
            });
    };

    const handleDraftData = () => {
        if (!data?.from || data?.from === '') {
            return toastHandler('"From" Email is required!', TOAST_TYPE_ERROR);
        }
        if (!data?.subject || data?.subject === '') {
            return toastHandler('Subject is required!', TOAST_TYPE_ERROR);
        }

        const jsonData = {
            ...data,
            to: data?.to?.map((item) => item?.email)?.join(',') || [],
            cc: data?.cc?.map((item) => item?.email)?.join(',') || [],
            bcc: data?.bcc?.map((item) => item?.email)?.join(',') || [],
            subject: `${jobRefno}` + data?.subject || '',
            body: data?.body,
            job_id: id,
            business_id: business_id,
            files: selectedFiles?.filter((obj) => !obj?.url),
            email_type: 'inbox'
        };
        if (!selectedDraft) {
            const formData = jsonToFormData(jsonData);
            handleDraftCreate(formData);
        } else {
            jsonData.email_draft_id = selectedDraft?.email_draft_id;
            const formData = jsonToFormData(jsonData);
            handleDraftUpdate(formData);
        }
    };

    const handleDraftCreate = (jsonData) => {
        dispatch(setLoader(true));
        dispatch(handleCreateDraftThunk(jsonData))
            .then((res) => {
                if (res.payload) {
                    fetchDraftList();
                    dispatch(updateDraftCount(totalDraftEmail + 1));
                    setData(null);
                    setOpen(false);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
                setPreSelectedData(null);
            });
    };
    const handleDraftUpdate = (jsonData) => {
        dispatch(setLoader(true));
        dispatch(handleUpdateDraftThunk(jsonData))
            .then((res) => {
                if (res.payload) {
                    fetchDraftList();
                    setData(null);
                    setOpen(false);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
                setPreSelectedData(null);
            });
    };

    const getProfileObject = (inputArray = []) => {
        return inputArray?.map((itemObj) => {
            return typeof itemObj === 'object'
                ? {
                      ...itemObj,
                      business_client_id: itemObj?.business_client_id,
                      display_name: getProfileName(itemObj),
                      email: itemObj?.email
                  }
                : {
                      business_client_id: generateId(),
                      display_name: itemObj,
                      email: itemObj
                  };
        });
    };

    const fetchDraftList = async () => {
        const jsonData = {
            business_id,
            job_id: id,
            email_type: 'inbox'
        };
        await dispatch(getAllDraftThunk(jsonData));
    };

    const reloadSideBar = () => {
        const payload = {
            job_id: id,
            business_id
        };
        dispatch(setIsLoading(true));
        dispatch(getRelayRoomListThunk(payload)).finally(() => {
            dispatch(getTotalThreadsCountThunk(payload));
            dispatch(setIsLoading(false));
        });
    };

    const validateDataFromOtherInputs = (obj) => {
        if (obj) {
            const arrayToValidate = [...(data?.to || []), ...(data?.cc || []), ...(data?.bcc || [])];
            const IsDuplicateFound = arrayToValidate?.find((item) => item?.email === obj?.email);
            if (IsDuplicateFound) {
                return toastHandler('Duplicate Email Found in To / Cc / Bcc', TOAST_TYPE_ERROR);
            }
            return true;
        }
    };

    const handleRemovvDraft = (id) => {
        if (id) {
            dispatch(setLoader(true));
            dispatch(removeDraftThunk({ ids: [id] }))
                .then((res) => {
                    if (res.payload) {
                        fetchDraftList();
                        dispatch(updateDraftCount(totalDraftEmail - 1));
                        dispatch(setSelectedDraft(null));
                        setData(null);
                        setSelectedFiles([]);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const handleUpdateFromEmail = () => {
        dispatch(getBusinessprofileThunk());
    };

    return (
        <div>
            <Slideover
                size="max-w-3xl"
                open={open}
                setOpen={(v) => {
                    setData(null);
                    setOpen(v);
                }}
                title={'Compose Email'}
                titlesx={'text-3xl font-fira font-[500] !m-0'}
                flexSx="!items-center"
                hideBackArrow
                extraButton={
                    <>
                        {hasPermissions('Job', ['write', 'admin']) && (
                            <button
                                type="button"
                                className="cursor-pointer relative rounded-full  text-gray-100 hover:text-gray-200 p-1"
                                onClick={() => handleDraftData()}
                            >
                                <CollapsIcon className={'w-7 h-7'} />
                            </button>
                        )}
                    </>
                }
            >
                <RoleBasedGuard module={'Job'} permissions={['write', 'admin']} sx="h-[50vh]">
                    <div className="mt-6 flex flex-col gap-3">
                        <div>
                            <MailerInput
                                defaultSelected={data && data?.to ? getProfileObject(data?.to) : []}
                                defaultDataLoadType="custom"
                                fetchDataFunction={(payload) =>
                                    fetchReferencesListFun({
                                        ...payload,
                                        type: ''
                                    })
                                }
                                inlineTitle="To:"
                                showNameOnly
                                allowCustomEmails
                                preventDuplicateItems
                                handleDataWithCustomValidators={validateDataFromOtherInputs}
                                onChange={(res) => {
                                    setDataFun('to', res);
                                }}
                                targetProperty="business_client_id"
                                placeholder="Type to Search"
                                sx="!rounded-lg"
                            />
                        </div>
                        <div>
                            <MailerInput
                                defaultSelected={data && data?.cc ? getProfileObject(data?.cc) : []}
                                defaultDataLoadType="custom"
                                fetchDataFunction={(payload) =>
                                    fetchReferencesListFun({
                                        ...payload,
                                        type: ''
                                    })
                                }
                                inlineTitle="Cc:"
                                allowCustomEmails
                                preventDuplicateItems
                                handleDataWithCustomValidators={validateDataFromOtherInputs}
                                showNameOnly
                                onChange={(res) => {
                                    setDataFun('cc', res);
                                }}
                                targetProperty="business_client_id"
                                placeholder="Type to Search"
                                sx="!rounded-lg"
                            />
                        </div>
                        <div>
                            <MailerInput
                                defaultSelected={data && data?.bcc ? getProfileObject(data?.bcc) : []}
                                defaultDataLoadType="custom"
                                fetchDataFunction={(payload) =>
                                    fetchReferencesListFun({
                                        ...payload,
                                        type: ''
                                    })
                                }
                                inlineTitle="Bcc:"
                                allowCustomEmails
                                preventDuplicateItems
                                handleDataWithCustomValidators={validateDataFromOtherInputs}
                                showNameOnly
                                onChange={(res) => {
                                    setDataFun('bcc', res);
                                }}
                                targetProperty="business_client_id"
                                placeholder="Type to Search"
                                sx="!rounded-lg"
                            />
                        </div>
                        <div>
                            <MailerInput
                                fetchDataFunction={(_payload) => {}}
                                defaultSelected={
                                    data?.from
                                        ? [
                                              {
                                                  business_client_id: generateId(),
                                                  display_name: data?.from,
                                                  email: data?.from
                                              }
                                          ]
                                        : []
                                }
                                defaultDataLoadType="custom"
                                inlineTitle="From:"
                                showNameOnly
                                limit={1}
                                disabled
                                onChange={(_res) => {}}
                                targetProperty="business_client_id"
                                placeholder={getBusinessprofile?.business_email ? 'Type to Search' : ''}
                                sx={cn(
                                    '!rounded-lg ',
                                    !getBusinessprofile?.business_email
                                        ? 'bg-gray-500 opacity-75 !pointer-events-none'
                                        : ''
                                )}
                            />
                            {!getBusinessprofile?.business_email && (
                                <div className="bg-dimThird mt-3 rounded-xl p-3 text-sm font-poppins">
                                    &apos;From&apos; Email is required in order to Compose New Email.{' '}
                                    <span
                                        className="text-third cursor-pointer"
                                        onClick={() => {
                                            setFromEmailSlider(true);
                                        }}
                                    >
                                        {' '}
                                        Click here
                                    </span>{' '}
                                    to add
                                </div>
                            )}
                        </div>
                        <div>
                            <PrimaryLabel> Subject </PrimaryLabel>
                            <div className="flex items-center mt-2  w-full rounded-lg shadow-sm  !placeholder-[#979797] border border-secondarybg !outline-none font-poppins text-[0.9rem] font-[400] sm:leading-6">
                                <span className="flex select-none items-center pl-3 text-md font-medium text-gray-800">
                                    {jobRefno}
                                </span>
                                <Input
                                    type="text"
                                    onChange={(e) => setDataFun('subject', e.target.value)}
                                    maxLength={120}
                                    required
                                    value={data?.subject || ''}
                                    placeholder="Type a subject for your email"
                                    useWithoutForm={true}
                                    wrapsx="m-0 !flex-1 !w-full"
                                    sx="!shadowm-none !border-none !py-2.5"
                                />
                            </div>
                        </div>
                        <div>
                            <PrimaryLabel sx="!mt-4 !mb-2"> Email Body </PrimaryLabel>
                            <MailerInputBody2
                                sx={{ wraper: 'mt-2 border border-secondarybg !rounded-lg', innerWrap: 'border-0' }}
                                editorContent={data?.body}
                                setEditorContent={(e) => setDataFun('body', e)}
                                forceUpdate={forceUpdate}
                                setForceUpdate={setForceUpdate}
                                selectedFiles={selectedFiles}
                                setSelectedFilesFun={(files) => setSelectedFiles(files)}
                                handleSubmitData={handleSubmitData}
                                {...(selectedDraft
                                    ? {
                                          onRemove: () => {
                                              handleRemovvDraft(selectedDraft?.email_draft_id);
                                          }
                                      }
                                    : {})}
                            />
                        </div>
                    </div>
                </RoleBasedGuard>

                {open && (
                    <CollaborationManager
                        open={fromEmailSlider}
                        setOpen={setFromEmailSlider}
                        onSubmitHandler={handleUpdateFromEmail}
                    />
                )}
            </Slideover>
        </div>
    );
};

export default ComposeNewEmail;
