export const JOB_CLOSED = 'Closed';
export const JOB_CANCELLED = 'Cancelled';
export const JOB_COMPLETED = 'Completed';

export const getPriorityClass = (priority) => {
    switch (priority) {
        case 'Low':
            return 'bg-[#008C51]/10 text-[#008C51]';
        case 'Normal':
            return 'bg-[#b695f8]/10 text-[#b695f8]';
        case 'Lowest':
            return 'bg-[#666666]/10 text-[#666666]';
        case 'Highest':
            return 'bg-[#F14747]/10 text-[#F14747]';
        case 'High':
            return 'bg-[#0d1b37]/10 text-[#0d1b37]';
        default:
            return 'bg-[#F08201]/10 text-[#F08201]';
    }
};

export const getPriorityColorCode = (priority) => {
    switch (priority) {
        case 'Low':
            return '#008C51';
        case 'Normal':
            return '#b695f8';
        case 'Lowest':
            return '#666666';
        case 'Highest':
            return '#F14747';
        case 'High':
            return '#0d1b37';
        default:
            return '#F08201';
    }
};

export const getStatusColor = (status = '') => {
    switch (status) {
        case 'Completed':
            return 'bg-[#008C51]/10 text-[#008C51]';
        case 'In Progress':
            return 'bg-[#b695f8]/10 text-[#b695f8]';
        case 'On Hold':
            return 'bg-[#666666]/10 text-[#666666]';
        case 'Closed':
            return 'bg-[#F14747]/10 text-[#F14747]';
        case 'Cancelled':
            return 'bg-[#0d1b37]/10 text-[#0d1b37]';
        default:
            return 'bg-[#F08201]/10 text-[#F08201]';
    }
};
export const getStatusColorCode = (status = '') => {
    switch (status) {
        case 'Completed':
            return '#008C51';
        case 'In Progress':
            return '#b695f8';
        case 'On Hold':
            return '#666666';
        case 'Closed':
            return '#F14747';
        case 'Cancelled':
            return '#0d1b37';
        default:
            return '#F08201';
    }
};
