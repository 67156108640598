import React from 'react';

export const XlsxFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#0A1E46" />
            <path
                d="M6.31543 21H4.01758V17.5532L5.28076 16.4487L4.03027 15.3442V11.8975H6.34717V14.3413L7.00732 14.9316L7.66748 14.3413V11.8975H9.98438V15.3442L8.72119 16.4487L9.98438 17.5532V21H7.68018V18.5688L7.00732 17.9722L6.31543 18.5688V21ZM13.3359 11.8975V18.7021H16.9795V21H11.0254V11.8975H13.3359ZM19.2393 21L18.0205 19.9209V18.2578H20.3311V18.8291H21.6577V17.3184H19.2393L18.0205 16.252V12.9766L19.2393 11.8975H22.7495L23.9746 12.9766V14.646H21.6577V14.0684H20.3311V15.541H22.7495L23.9746 16.6201V19.9209L22.7495 21H19.2393ZM27.3135 21H25.0156V17.5532L26.2788 16.4487L25.0283 15.3442V11.8975H27.3452V14.3413L28.0054 14.9316L28.6655 14.3413V11.8975H30.9824V15.3442L29.7192 16.4487L30.9824 17.5532V21H28.6782V18.5688L28.0054 17.9722L27.3135 18.5688V21Z"
                fill="white"
            />
        </svg>
    );
};
