import React from 'react';

export const TransferArrowsIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="185"
            height="84"
            viewBox="0 0 185 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M122.72 74.098H62.228"
                stroke="#0A1E46"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M71.6538 81.6096L62 74.098"
                stroke="#0A1E46"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M62 74.098L71.6538 66.5864"
                stroke="#0A1E46"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M62 52.437H122.492"
                stroke="#0A1E46"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M113.066 44.9253L122.72 52.4369"
                stroke="#0A1E46"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M122.72 52.437L113.066 59.948"
                stroke="#0A1E46"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39.7989 29.4867L13.3702 44.877C11.7694 45.8089 9.69736 45.2621 8.76542 43.6613L0.45479 29.3897C-0.477153 27.7888 0.069624 25.7168 1.67044 24.7848L28.0991 9.39461C29.6999 8.46266 31.7719 9.00945 32.7039 10.6103L41.0145 24.8819C41.9465 26.4823 41.3997 28.5548 39.7989 29.4867Z"
                fill="#F8F4FE"
            />
            <path d="M3.12158 26.765L12.3642 42.637" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M7.45264 24.2432L16.6956 40.1153" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M11.7827 21.7214L21.0257 37.5935" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M16.1133 19.1996L25.3559 35.0716" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M20.4438 16.6779L29.6864 32.5499" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M24.7739 14.1561L34.0169 30.0282" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M29.1045 11.6343L38.3475 27.5063" stroke="#B695F8" strokeMiterlimit="10" />
            <path
                d="M145.201 29.4867L171.63 44.877C173.231 45.8089 175.303 45.2621 176.235 43.6613L184.545 29.3897C185.477 27.7888 184.93 25.7168 183.33 24.7848L156.901 9.39461C155.3 8.46266 153.228 9.00945 152.296 10.6103L143.985 24.8819C143.054 26.4823 143.601 28.5548 145.201 29.4867Z"
                fill="#F8F4FE"
            />
            <path d="M181.878 26.765L172.635 42.637" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M177.548 24.2432L168.305 40.1153" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M173.218 21.7214L163.975 37.5935" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M168.887 19.1996L159.644 35.0716" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M164.557 16.6779L155.314 32.5499" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M160.225 14.1561L150.983 30.0282" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M155.896 11.6343L146.653 27.5063" stroke="#B695F8" strokeMiterlimit="10" />
            <path
                d="M108.453 23.2687L77.8698 23.2507C76.0178 23.2494 74.503 21.7333 74.5039 19.8809L74.5136 3.36589C74.5149 1.51386 76.0309 -0.000877981 77.8834 3.81815e-07L108.467 0.018007C110.319 0.0193245 111.833 1.53537 111.833 3.38784L111.823 19.9028C111.822 21.7553 110.305 23.27 108.453 23.2687Z"
                fill="#F8F4FE"
            />
            <path d="M78.1404 2.44183L78.1294 20.8093" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M83.1516 2.44495L83.1406 20.812" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M88.1628 2.448L88.1519 20.815" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M93.1731 2.45105L93.1621 20.8181" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M98.1853 2.45416L98.1743 20.8212" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M103.196 2.45679L103.185 20.8242" stroke="#B695F8" strokeMiterlimit="10" />
            <path d="M108.207 2.45984L108.196 20.8269" stroke="#B695F8" strokeMiterlimit="10" />
            <circle cx="35.3176" cy="8.87632" r="0.439182" fill="#B695F8" />
            <path
                d="M39.2749 7.40766C45.1902 5.38435 57.0723 1.95347 70.3294 1.6601"
                stroke="#B695F8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="72.4465" cy="1.64078" r="0.439182" fill="#B695F8" />
            <path
                d="M43.8716 26.7124C43.8716 26.7124 57.4045 20.2116 72.4465 20.8273"
                stroke="#B695F8"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="0.01 10"
            />
            <circle cx="151.602" cy="8.87632" r="0.439182" fill="#B695F8" />
            <path
                d="M147.742 7.14725C142.495 5.09978 131.968 1.9302 116.595 1.65967"
                stroke="#B695F8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="114.474" cy="1.64078" r="0.439182" fill="#B695F8" />
            <path
                d="M142.09 26.3092C142.09 26.3092 128.52 20.2111 113.478 20.8269"
                stroke="#B695F8"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="0.01 10"
            />
        </svg>
    );
};
