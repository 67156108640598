import React from 'react';

export const QifFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="34" height="34" rx="5" fill="#A58C7F" />
            <path
                d="M10.9219 22H8.95312L7.46875 20.6875V12.125L8.95312 10.7969H13.2891L14.7969 12.125V20.6875L13.2891 22L13.7109 23.7734H11.3438L10.9219 22ZM10.2969 13.6328V19.1719H11.9453V13.6328H10.2969ZM18.9219 10.7969V22H16.0781V10.7969H18.9219ZM23.0312 17.8047V22H20.2031V10.7969H27.5312V13.6484H23.0312V14.9609H26.0391V17.8047H23.0312Z"
                fill="white"
            />
        </svg>
    );
};
