import React from 'react';

export const AvifFile = ({ className }) => {
    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.2222 0H3.77778C1.7 0 0 1.7 0 3.77778V30.2222C0 32.3 1.7 34 3.77778 34H30.2222C32.3 34 34 32.3 34 30.2222V3.77778C34 1.7 32.3 0 30.2222 0ZM12.2778 16.0556C12.2778 17.5667 10.9556 18.8889 9.44444 18.8889H7.55556V22.6667H4.72222V11.3333H9.44444C10.9556 11.3333 12.2778 12.6556 12.2778 14.1667V16.0556ZM21.7222 19.8333C21.7222 21.3444 20.4 22.6667 18.8889 22.6667H14.1667V11.3333H18.8889C20.4 11.3333 21.7222 12.6556 21.7222 14.1667V19.8333ZM29.2778 14.1667H26.4444V16.0556H29.2778V18.8889H26.4444V22.6667H23.6111V11.3333H29.2778V14.1667ZM17 14.1667H18.8889V19.8333H17V14.1667ZM7.55556 14.1667H9.44444V16.0556H7.55556V14.1667Z"
                fill="#B695F8"
            />
            <rect x="4" y="9" width="26" height="16" rx="4" fill="#B695F8" />
            <path
                d="M5.46875 20.25V23H2.64062V13.125L4.125 11.7969H8.46094L9.96875 13.125V23H7.11719V20.25H5.46875ZM5.46875 14.6328V17.3984H7.11719V14.6328H5.46875ZM19.2344 11.7969L16.6562 23H13.8281L11.25 11.7969H14.0781L15.2344 16.8438L16.3984 11.7969H19.2344ZM23.3594 11.7969V23H20.5156V11.7969H23.3594ZM27.4688 18.8047V23H24.6406V11.7969H31.9688V14.6484H27.4688V15.9609H30.4766V18.8047H27.4688Z"
                fill="white"
            />
        </svg>
    );
};
