export const STATE_TERRITORY = [
    { label: 'New South Wales' },
    { label: 'Victoria' },
    { label: 'Queensland' },
    { label: 'Western Australia' },
    { label: 'South Australia' },
    { label: 'Tasmania' },
    { label: 'Australian Capital Territory' },
    { label: 'Northern Territory' },
    { label: 'Jervis Bay Territory' }
];

export const OFFLINE_CLIENT = 'offline_client';
export const PROSPECTIVE_CLIENT = 'prospective_client';
export const ACTIVE_CLIENT = 'active_client';

export const INACTIVE_CLIENT = 'inactive_client';
export const INDIVIDUAL_CATEGORY = 'Individual';
export const COMPANY_CLIENT = 'Company';

export const CLIENT_CATEGORY_LIST_FILTER = [
    {
        label: 'Select',
        value: ''
    },
    {
        label: 'Individual',
        value: 'Individual'
    },
    {
        label: 'Company',
        value: 'Company'
    },
    {
        label: 'Trust',
        value: 'Trust'
    },
    {
        label: 'Partnership',
        value: 'Partnership'
    },
    {
        label: 'SMSF',
        value: 'SMSF'
    },
    {
        label: 'Association',
        value: 'Association'
    }
];
